import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';
import IMG2 from '../../../assets/img/landing-img2.png';
import IMG6 from '../../../assets/img/landing-img6.png';
import IMG7 from '../../../assets/img/landing-icon2.png';
import IMG8 from '../../../assets/img/landing-icon6.png';
import IMG9 from '../../../assets/img/landing-icon5.png';
import IMG10 from '../../../assets/img/landing-icon7.png';
import BLCKIMG from '../../../assets/img/landing-blockimg1.jpg';
import BLCKIMG4 from '../../../assets/img/landing-blockimg4.jpg';
import BLCKIMG5 from '../../../assets/img/landing-blockimg5.jpg';
import BLCKIMG6 from '../../../assets/img/landin-blockimg6.png';
import BLCKIMG7 from '../../../assets/img/landin-blockimg7.png';
import BLCKIMG8 from '../../../assets/img/landin-blockimg8.png';
import BLCKIMG9 from '../../../assets/img/landin-blockimg9.png';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { getCopyrightYear } from '../../helpers/utils';

const ForwardingLanding = (props) => {
  const copyrightYear = getCopyrightYear();

  return (
    <>
      <PublicHeader defaultClass="inner-menu" />
      <div id="landing-page">
        <Helmet>
          <title>GoAgent for Transporters</title>
        </Helmet>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 header-text">
                <div>
                  <h1>GoAgent for transporters</h1>
                  <br />
                  <h3>
                    Are you a Transporter looking for more jobs? We register Importers who post jobs
                    daily. Start making more money with us today.
                  </h3>
                  <button type="button" onClick={() => props.history.push('/login')}>
                    CREATE YOUR FREE ACCOUNT HERE
                  </button>
                </div>
              </div>
              <div className="col-sm-5 text-align" data-aos="fade-up">
                <img src={IMG2} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
        <div className="body-block">
          <div className="container">
            <div className="header-block">
              <h2>
                A Guaranteed Way to Improve Your Transport business
                <br />
              </h2>
            </div>
            <div className="row">
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG7} alt="" />
                </div>
                <h4>Access to Ready Customers Every day</h4>

                <p>
                  You will meet Importers whose Cargo is already at the Port, Importers who are
                  looking for Transporters just like you.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG8} alt="" />
                </div>
                <h4>Protection from Losing Money</h4>

                <p>
                  Many Importers refuse to pay their due balance after delivery of their Cargo. With
                  GoAgent, you will not deal with that anymore.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG9} alt="" />
                </div>
                <h4>Protection from False Cargo Declaration</h4>

                <p>
                  Many importers falsely declare the tonnage of their Cargo, which overloads your
                  truck and leads to damages. With GoAgent, you will not deal with that anymore.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG10} alt="" />
                </div>
                <h4>Removal of Middle-men</h4>

                <p>
                  Middlemen usually make more money from transport jobs for adding no value. GoAgent
                  removes middlemen and connects you directly to the Importers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="simple-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                <h2>Simple, efficient transport of cargo</h2>
                <p>Register with GoAgent and take your business to the next level.</p>
                <button
                  type="button"
                  className="callToActionBtn"
                  onClick={() => props.history.push('/registration')}
                >
                  CREATE YOUR FREE ACCOUNT HERE
                </button>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <img src={IMG6} width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div id="services" className="services">
          <div className="container">
            <h2>Why GoAgent?</h2>
            <p className="text-align">
              All our services are carefully packaged to ensure a Win-Win solution for all
              concerned. This is why we insist on due-diligence for all parties on our platform. Not
              only do we verify our Customs-certified Agents and Transporters, we also validate our
              Importers, made up of small, medium to large and very large-scale businesses. As long
              as safety is your top priority, your decision to go with GoAgent is 100% on track.{' '}
              <NavLink to="/registration">Discover More About Our Safe Platform Here</NavLink>
            </p>
            <p className="text-align">
              The Transportation business can be very tough and stressful. First of all, to remain
              in business as a transporter, you have to keep getting new jobs. To achieve that, you
              have to satisfy the Importers you work with; control your drivers; manage enforcement
              officers; evade greedy middlemen, while you still have to find a way to ensure all
              your trucks are in good shape, all of the time.
            </p>
            <br />

            {/* Problem #1 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG} width="100%" className="block-img" alt="" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #1: Falsification of Cargo Tonnage</h3>
                      <p>
                        Many Importers are not honest about the actual tonnage of their Cargo. This
                        overloads your truck and often leads to damages.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #1 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG6} width="100%" className="block-img" alt="" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #1: Protection from Falsification of Cargo Tonnage</h3>
                      <p>
                        Your trucks will never suffer falsification of Cargo tonnage, because
                        GoAgent takes Cargo tonnage directly from the <i>‘Bill of Lading’</i> on
                        your behalf. Where <i>‘Bill of Lading’</i> is tampered with, such an
                        Importer is suspended.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #2 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG5} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #2: Losing Money to Middlemen</h3>
                      <p>
                        Middlemen who arrange back-door transport deals mostly make more money than
                        the Transporters who suffer and bear the risk to serve the Importer. True
                        Incident: -
                        <i>
                          {' '}
                          ‘An importer paid for the delivery of a container from Apapa to Ikeja in
                          Lagos, for N800,000. When the business went wrong and a refund was
                          demanded from the owner of the truck, to the shock of the importer, the
                          transporter said he only received N300,000 for the job. Meanwhile, the
                          middleman who took N500,000 was nowhere to be found.’{' '}
                        </i>
                        Such injustice should never be allowed to go unchecked.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #2 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG7} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #2: No More Middlemen. Their Commission is Now Yours!</h3>
                      <p>
                        With GoAgent, you eliminate all middle men and their activities at your port
                        of interest. They CAN NOT interfere with our registered and verified
                        Transporters, as we have put structures in place to insulate our processes
                        from such middle-men. What’s more, all moneys previously collected by the
                        middlemen in form of illegal levies, now mean more profit for you, our
                        verified Transporter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #3 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG5} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #3: Dishonest Truck Drivers</h3>
                      <p>
                        Truck Drivers can often be a serious source of headaches to owners of
                        transport businesses. They are known to tell lies about their fuel
                        consumption, fraudulently create non-existent problems, just to rip off the
                        Truck Owner, in addition to making unofficial stop-overs that eventually
                        delay the Client’s deliveries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #3 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG8} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #3: Protection from Dubious Truck Drivers</h3>
                      <p>
                        With specialised trackers installed on your trucks, GoAgent helps you solve
                        the headache of dubious drivers. On the GoAgent app, you can see the exact
                        amount of fuel your truck consumes, as well as your driver's driving
                        pattern; whether safe or otherwise.
                      </p>
                      <p>
                        The app also tells you top and average speeds, idle time and any fault your
                        truck has (without you making any manual checks yourself).
                      </p>
                      <p>
                        This way, GoAgent empowers you to take charge of your transportation
                        business, and take it to a whole new level of profitability!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #4 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG5} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #4: Dubious Importers can Short-change Transporters</h3>
                      <p>
                        Just as there are dubious Transporters, so are there dishonest Importers.
                        Sometimes, after completion of the delivery business, there have been cases
                        where the Transporter was either not paid their balance at all, or was not
                        paid the correct balance. This can be very disappointing and painful after
                        going through the entire delivery process from the Port to the Importer’s
                        doorstep.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #4 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG9} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #4: No More Worries About Getting Your Payment Balance</h3>
                      <p>
                        With GoAgent, no Importer will abscond or ever refuse to pay your balance
                        after delivery of their Cargo, because all monies will be received on your
                        behalf in advance, and remitted to you after successful delivery of the
                        Cargo. It’s as simple as A, B, C.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #5 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG5} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #5:</h3>
                      <p>
                        Do you have a truck with a sound engine, and in good condition to travel to
                        anywhere in Nigeria; or a fleet of trucks in good condition, yet you find
                        yourself parking these trucks for days, running into weeks and months
                        without any viable job? Yet, you are still forced to pay levies by
                        authorities for parking. This is unfair right?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #5 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG4} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #5: Your Hard Work is Always Rewarded with More Business!</h3>
                      <p>
                        You don’t have to park your truck for months, even weeks or days, waiting
                        for a job. Simply sign up on the GoAgent website to meet with loads of
                        verified Importers, who are looking for verified Transporters to convey
                        their goods to their destinations. Without spending a dime on advertising,
                        you will meet and interact daily with Importers whose Cargo are already at
                        the Port; Importers who are looking for reliable Transporter just like you.
                        Every minute you refresh your App, you get more of them, every other day.
                        What could possibly be better than this for your business?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="app-ready">
          <div className="container">
            <h3>Say Goodbye to All Your Transport Problems, with GoAgent!</h3>
            <div className="text-align">
              <button type="button" onClick={() => props.history.push('/registration')}>
                CREATE YOUR FREE ACCOUNT HERE
              </button>
            </div>
          </div>
        </div>
        <footer>
          ©{copyrightYear} GoAgent |<Link to="/privacy-policy">Privacy Policy</Link>|{' '}
          <Link to="/terms-and-conditions">Terms & Conditions</Link>|<Link to="/faq">FAQ</Link>
        </footer>
      </div>
      <PublicFooter />
    </>
  );
};

export default ForwardingLanding;
