import { Formik, getIn } from 'formik';
import { get } from 'lodash';
import React from 'react';
import Input from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { object, string } from 'yup';
import userImage from '../../../assets/img/placeHolderImage.png';
import config from '../../../config';
import convertJsonToFormData from '../../helpers/helper';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import TextField from '../UI/TextField';
import { getLoggedInUser } from '../../redux/user/UserActions';
import { getClientName } from '../../helpers/utils';

const UpdateProfile = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const user = useSelector((state) => state.user.user);
  const cType = user.sub_type || user.type;

  return (
    <div id="tabblock1" className="tabcontent">
      <section className="bodycont">
        <div>
          {/* <h2>My Profile</h2> */}

          <Formik
            initialValues={{
              clientSelected: props.type === 'client' ? true : false,
              first_name: get(user, 'first_name', '') || '',
              last_name: get(user, 'last_name', '') || '',
              company_name: get(user, 'company_name', '') || '',
              company_address: get(user, 'company_address', '') || '',
              avatar: get(user, 'avatar', ''),
            }}
            onSubmit={(values, { setSubmitting }) => {
              const payload = {
                mobile_number: get(user, 'mobile_number', ''),
                email: get(user, 'email', ''),
                user_uuid: get(user, 'uuid', ''),
              };

              if (cType === 'application_client') {
                payload.first_name = values.first_name;
                payload.last_name = values.last_name;
              }
              if (cType !== 'application_client') {
                payload.company_name = values.company_name;
                payload.company_address = values.company_address;
              }

              dispatch(showLoading());
              POST(`/users/update`, payload)
                .then(({ data }) => {
                  dispatch(showModal('Profile updated successfully!', true));
                  dispatch({
                    type: 'USER',
                    payload: {
                      user: data.user,
                      type: data.user.type,
                    },
                  });
                  dispatch(getLoggedInUser(props.history));
                  dispatch(hideLoading());
                  history.goBack();
                })
                .catch((err) => {
                  dispatch(showModal(err.message));
                  dispatch(hideLoading());
                  setSubmitting(false);
                });
            }}
            validationSchema={object().shape({
              first_name: string().when('clientSelected', {
                is: true,
                then: string().required('First name is required'),
              }),
              last_name: string().when('clientSelected', {
                is: true,
                then: string().required('Last name is required'),
              }),
              company_name: string().when('clientSelected', {
                is: false,
                then: string().required('Company name is required'),
              }),
              company_address: string().when('clientSelected', {
                is: false,
                then: string().required('Address is required'),
              }),
            })}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              } = renderProps;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="personal-info">
                    <div className="profilesec">
                      <div className="profileimage">
                        <img
                          src={
                            get(user, 'avatar')
                              ? `${config.api.URL.replace('/api/v1', '')}/${values.avatar}`
                              : userImage
                          }
                          alt=""
                          height="170px"
                          weight="170px"
                        />
                        <span>
                          <input
                            className="d-none"
                            id={'profile'}
                            type="file"
                            accept="image/jpeg,image/jpg,image/png,image/svg"
                            name="profile"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                dispatch(showLoading());
                                const payload = {
                                  avatar: file,
                                };
                                let formData = convertJsonToFormData(payload);
                                POST('/users/uploadAvatar', formData)
                                  .then(({ data }) => {
                                    setFieldValue('avatar', data.avatar);
                                    dispatch(getLoggedInUser(props.history));

                                    dispatch(hideLoading());
                                  })
                                  .catch((err) => {
                                    dispatch(showModal(err.message));
                                    dispatch(hideLoading());
                                  });
                              }
                            }}
                          />
                          <label htmlFor="profile">
                            <span className="profile-camera">
                              <i className="fa fa-camera" aria-hidden="true"></i>
                            </span>
                          </label>
                        </span>
                      </div>
                      {getClientName(user)}
                    </div>

                    <h3>Account Information</h3>

                    <div className="row">
                      <div className="col-sm-6 text-left mb-2">
                        <label>Email:</label>
                        <TextField
                          id="email"
                          type="email"
                          className="form-control "
                          value={get(user, 'email', '')}
                          disabled={true}
                        />
                      </div>
                      <div className="col-sm-6 text-left mb-2">
                        <label>Mobile:</label>
                        <Input
                          value={get(user, 'mobile_number', '')}
                          id="mobile_number"
                          className={`form-control`}
                          disabled={true}
                          onChange={() => {}}
                        />
                      </div>

                      {cType === 'application_client' ? (
                        <>
                          <div className="col-sm-6 text-left mb-2">
                            <label>First Name:</label>
                            <TextField
                              id="first_name"
                              type="text"
                              className="form-control "
                              placeholder="First Name*"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={getIn(touched, 'first_name') && getIn(errors, 'first_name')}
                            />
                          </div>
                          <div className="col-sm-6 text-left mb-2">
                            <label>Last Name:</label>
                            <TextField
                              id="last_name"
                              type="text"
                              className="form-control "
                              placeholder="Last Name*"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={getIn(touched, 'last_name') && getIn(errors, 'last_name')}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-sm-6 text-left mb-2">
                            <label>Company Name:</label>
                            <TextField
                              id="company_name"
                              type="text"
                              className="form-control "
                              placeholder="Company Name*"
                              value={values.company_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                getIn(touched, 'company_name') && getIn(errors, 'company_name')
                              }
                            />
                          </div>

                          <div className="col-sm-6 text-left mb-2">
                            <label>Company Address:</label>
                            <TextField
                              id="company_address"
                              type="text"
                              className="form-control "
                              placeholder={'Company Reg. Address*'}
                              value={values.company_address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                getIn(touched, 'company_address') &&
                                getIn(errors, 'company_address')
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="buttonsec">
                      <button
                        type="button"
                        onClick={() => history.goBack()}
                        className="submitbut btn-light"
                      >
                        Back
                      </button>
                      <button className="submitbut" type="submit" disabled={isSubmitting}>
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default UpdateProfile;
