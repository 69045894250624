import React, { useState, useRef, useEffect } from 'react';
import { Badge } from '@material-ui/core';
import { isMobile } from '../../helpers/utils';

const Chat = (props) => {
  const {
    name = 'Us',
    chatbox,
    user,
    chatToggle,
    chatlist,
    value,
    handleTextChange,
    handleOnChange,
    sendButton,
    secondChatPresent,
    id = 'us',
    hideMsgInputBlock = false, // this is used to show chat in only view mode,
    unreadMsgsCount = 0,
  } = props;
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const chatboxContainerRef = useRef(null);

  useEffect(() => {
    if (chatboxContainerRef.current) {
      chatboxContainerRef.current.addEventListener('scroll', function() {
        const sh = chatboxContainerRef.current.scrollHeight;
        const st = chatboxContainerRef.current.scrollTop;
        if (!hideMsgInputBlock && st === 0) {
          // console.log('fetch more chat history')
          // fetchChatHistory();
        } else if (!hideMsgInputBlock && st < 50) {
          setShowScrollToBottom(true);
        } else if (!hideMsgInputBlock && st > 100) {
          setShowScrollToBottom(false);
        }
      });
    }
    return function cleanup() {
      if (chatboxContainerRef.current) {
        chatboxContainerRef.current.removeEventListener('scroll', function() {
          // console.log('el removed')
        });
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (chatboxContainerRef.current) {
      const sh = chatboxContainerRef.current.scrollHeight;
      chatboxContainerRef.current.scrollTop = sh;
    }
  };

  const isMobileDevice = isMobile()?.matches;

  return (
    <>
      {isMobileDevice && !chatbox && (
        <button
          id={`${id}-chat-button-mobile`}
          type="button"
          className={`collapsible-mobile ${secondChatPresent ? 'second-chat' : ''}`}
          onClick={() => chatToggle()}
        >
          <Badge badgeContent={unreadMsgsCount} color="secondary" invisible={unreadMsgsCount === 0}>
            <i id="chat-icon" style={{ color: '#fff' }} className="fa fa-fw fa-comments-o"></i>
          </Badge>
        </button>
      )}
      <div className={name === 'Client' ? 'chat-bar-collapsible-client' : 'chat-bar-collapsible'}>
        {((isMobileDevice && chatbox) || !isMobileDevice) && (
          <Badge badgeContent={unreadMsgsCount} color="secondary" invisible={unreadMsgsCount === 0}>
            <button
              id={`${id}-chat-button`}
              type="button"
              className={`collapsible ${secondChatPresent ? 'second-chat-heading' : ''}`}
              onClick={() => chatToggle()}
            >
              <span>
                Chat With {name}!
                <i id="chat-icon" style={{ color: '#fff' }} className="fa fa-fw fa-comments-o" />
              </span>
              <span className="float-end">
                {chatbox ? (
                  <i
                    id="chat-icon"
                    style={{ color: '#fff' }}
                    className="fa fa-fw fa-chevron-down"
                  />
                ) : (
                  <i id="chat-icon" style={{ color: '#fff' }} className="fa fa-fw fa-chevron-up" />
                )}
              </span>
            </button>
          </Badge>
        )}
        <div className={!chatbox ? 'chat-content' : ''}>
          <div
            className="full-chat-block"
            id={name === 'Client' ? 'chat-tops' : 'chat-top'}
            ref={chatboxContainerRef}
          >
            <div className="outer-container">
              <div className="chat-container">
                <div id="chatbox">
                  <h5 id="chat-timestamp"></h5>
                  {chatlist.length !== 0 &&
                    chatlist.map((obj, i) => (
                      <p className={user.uuid === obj.sender_uuid ? 'userText' : 'botText'} key={i}>
                        <span>{obj.message}</span>
                      </p>
                    ))}
                </div>
                {showScrollToBottom && (
                  <span
                    className="scroll-to-bottom d-flex align-items-center justify-content-center"
                    onClick={scrollToBottom}
                  >
                    <i className="bi bi-arrow-down-short"></i>
                  </span>
                )}
                {!hideMsgInputBlock && (
                  <div className="chat-bar-input-block">
                    <div id="userInput">
                      <input
                        type="text"
                        id="textInput"
                        className="input-box"
                        value={value}
                        name="msg"
                        placeholder="Tap 'Enter' to send a message"
                        onKeyDown={handleTextChange}
                        onChange={handleOnChange}
                      />
                    </div>

                    <div className="chat-bar-icons">
                      <i
                        id="chat-icon"
                        style={{ color: '#333' }}
                        className="fa fa-fw fa-send"
                        onClick={() => sendButton()}
                      ></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
