import React, { useState } from 'react';
import FeedbackModal from '../Modals/FeedbackModal';

const FeedbackWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((s) => !s);
  return (
    <>
      <span className="feedback-widget" onClick={toggle}>
        Feedback
      </span>
      {isOpen && <FeedbackModal isOpen={isOpen} toggle={toggle} />}
    </>
  );
};

export default FeedbackWidget;
