import moment from 'moment';
export const GET_DATA = 'GET_DATA';
export const RESET_DATA = 'RESET_DATA';
const today = new Date();
const yesterday = new Date(today);

yesterday.setDate(yesterday.getDate() - 1);
const yesDate = yesterday.toDateString();
// console.log(moment(yesDate).format('DD-MM-YYYY'));
export const getExchangeData = () => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer 239e9a9d7366292708de49039bc540779c41b27e');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`https://abokifx.com/api/v1/rates/date?date=${moment(yesDate).format('DD-MM-YYYY')}&currency=USD`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log(result);
        dispatch({
          type: GET_DATA,
          payload: {
            ...JSON.parse(result),
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: RESET_DATA,
          payload: {},
        });
      });
  };
};
