import { useEffect, useState, useRef, useCallback } from 'react';
import Pusher from 'pusher-js';
import config from '../../config';
import { addQueryParamToUrl, isProd } from './utils';
import { sendOTP, verifyOTP } from '../services/otp.service';
import { VERIFYME_TYPE } from '../helpers/constants';
import { showModal } from '../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../redux/loader/LoaderActions';
import { POST } from '../services/rest.service';
// import verifyMe from '../services/verifyme.service';

// custom hook for initializing and listening to chat
// userId, eventCallback are used to connect to user;s chat and listen to even
// chatbox, , chatboxclient are dependencies used inside eventcallback so needed to mention them in useEffect's dependency
export const useChat = (userId, chatbox, eventCallback, chatboxclient) => {
  // console.log('>> INSIDE useChat CUSTOM HOOK');
  const pusherSubscriptionRef = useRef(null);
  //puser notification
  const envent_name = `Illuminate\\Notifications\\Events\\BroadcastNotificationCreated`;
  useEffect(() => {
    // console.log('>> UEpusherSubscriptionRef.current ', pusherSubscriptionRef.current);
    if (pusherSubscriptionRef.current) {
      const channel = pusherSubscriptionRef.current.subscribe(`Chat.User.${userId}`);
      // console.log('>> channel <<<', channel);
      // Bind the event listener
      channel.bind(envent_name, eventCallback);

      return () => pusherSubscriptionRef.current.unsubscribe(`Chat.User.${userId}`);
    } else {
      // create a pusher instance
      const pusher = new Pusher('2c4a0502ac9f198e2eaa', {
        cluster: 'ap2',
        encrypted: true,
      });
      pusherSubscriptionRef.current = pusher;
    }
  }, [pusherSubscriptionRef.current, chatbox, userId, chatboxclient]);

  // console.log('>>>> pusherSubscriptionRef', pusherSubscriptionRef.current);
};

// Google Analytics Page Hit Hook
export const useGoogleAnalytics = () => {
  // console.log('>>>> INSIDE HOOK >>>> useGoogleAnalytics ');

  useEffect(() => {
    // this ID is also set in public/index.html file while loading script
    // MORE INFO: https://developers.google.com/analytics/devguides/collection/gtagjs

    const googleAnalyticsMeasurementID = config.GOOGLE_ANALYTICS;

    // MORE INFO: https://developers.google.com/analytics/devguides/collection/gtagjs/pages#manual_pageviews
    window?.gtag?.('config', googleAnalyticsMeasurementID, {
      send_page_view: false, // because our app is SPA
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname + window.location.search,
    });

    // console.log('Logged entry to ' + window.location);
  }, []);
};

export const usePagination = (defaultSelectedPage, defaultPerPage, history) => {
  const [selectedPage, setSelectedPage] = useState(defaultSelectedPage || 1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultPerPage || 10);
  const [totalPages, setTotalPages] = useState(0);

  const handleSelected = useCallback(
    ({ selected }) => {
      const page = selected + 1;
      setSelectedPage(page);
      addQueryParamToUrl(history, 'page', page);
    },
    [history],
  );

  const handlePageCountChange = useCallback(
    (e) => {
      const val = e.target.value;
      handleSelected({ selected: 0 });
      addQueryParamToUrl(history, 'perPage', val);
      setItemsPerPage(val);
    },
    [handleSelected, history],
  );

  return {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  };
};

// this custom hook is used for verifyme related functionaliy, i.e verifying nin_phone
export const useVerifyme = (dispatch, docVerified) => {
  const [isDocVerified, setIsDocVerified] = useState(docVerified || false);
  const [showOTPField, setShowOTPField] = useState(false);
  const [verifyMeData, setVerifyMeData] = useState({});
  const [isOTPVerified, setIsOTPVerified] = useState(false);

  const storeVerifyMeData = (userUUID, userType) => {
    return POST('/users/storeVerifyMeData', {
      user_uuid: userUUID,
      data: verifyMeData,
      type: VERIFYME_TYPE,
      user_type: userType,
    })
      .then((res) => {
        // console.log('VM >>>> SAVE response',res)
        // clear the state after storing VerifyMe data
        setVerifyMeData({});
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };

  const sendMsgOtp = (phone) => {
    sendOTP(phone)
      .then((otpResp) => {
        let otpMsg = 'OTP sent to mobile number';
        otpMsg += isProd() ? '' : ` (TEST OTP: ${otpResp.data?.otp})`;
        dispatch(showModal(otpMsg, true));
        dispatch(hideLoading());
        setShowOTPField(true);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const verifyDocument = (verifymeType, data) => {
    dispatch(showLoading());

    sendMsgOtp(data.referenceId);

    // >> Verifyme Verification related

    // verifyMe(verifymeType, data)
    //   .then((response) => {
    //     // console.log('VM >>>> response ',response);
    //     setVerifyMeData(response.data);

    //     // send OTP to the phone number recieved from verifyme data
    //     if (response.data?.phone) {
    //       sendMsgOtp(response.data.phone);
    //     } else {
    //       dispatch(hideLoading());
    //     }
    //   })
    //   .catch((err) => {
    //     dispatch(showModal(err.message));
    //     dispatch(hideLoading());
    //   });
  };

  const verifyUserOtp = (phone, otp, verifier) => {
    verifyOTP(phone, otp, verifier)
      .then((response) => {
        setIsDocVerified(true);
        setShowOTPField(false);
        setIsOTPVerified(true);

        dispatch(showModal(response.message, true));
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  return {
    // states and state updaters
    isDocVerified,
    setIsDocVerified,
    showOTPField,
    setShowOTPField,
    verifyMeData,
    setVerifyMeData,
    isOTPVerified,
    setIsOTPVerified,

    // methods
    storeVerifyMeData,
    sendMsgOtp,
    verifyDocument,
    verifyUserOtp,
  };
};
