import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import userImage from '../../../assets/img/placeHolderImage.png';
import config from '../../../config';
import { getAccountStatus, getClientAddress, getClientName } from '../../helpers/utils';

const Dashboard = (props) => {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <div className="bodycont">
          {/* <h2>My Profile</h2> */}
          <div className="personal-info ">
            <div className="profilesec">
              <div className="profileimage">
                <img
                  src={
                    get(user, 'avatar')
                      ? `${config.api.URL.replace('/api/v1', '')}/${get(user, 'avatar')}`
                      : userImage
                  }
                  alt=""
                  height="170px"
                  weight="170px"
                />
              </div>
              {getClientName(user)}
            </div>

            <h3>Account Information</h3>

            <div className="profilecont">
              <div className="profilecontleft">
                <p>
                  <strong>Name:</strong> {getClientName(user)}
                </p>
                <p>
                  <strong>Email:</strong> {user.email || 'N/A'}
                </p>
                <p>
                  <strong>Mobile:</strong> {user.mobile_number || 'N/A'}
                </p>
              </div>
              <div className="profilecontright">
                <p>
                  <strong>Address</strong>
                  <br />
                  {getClientAddress(user)}
                </p>
                <p>
                  <strong>Account Status:</strong>
                  <br />
                  {getAccountStatus(user)}
                </p>
              </div>
            </div>

            <div className="buttonsec">
              <button
                onClick={() => props.history.push('/client/profile/update-password')}
                className="submitbut"
              >
                Change Password
              </button>
              <button
                onClick={() => props.history.push('/client/edit-profile')}
                className="submitbut"
              >
                Edit Profile
              </button>
              <button onClick={() => props.history.push('/client/documents')} className="submitbut">
                {user.type === 'application_client' && user.sub_type === 'application_client'
                  ? 'Personal Details'
                  : 'Corporate Details'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
