import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Formik, getIn } from 'formik';
import { object, number } from 'yup';
import moment from 'moment';
import TextField from '../../components/UI/TextField';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import convertJsonToFormData from '../../helpers/helper';
import { DATE_TIME_FORMAT } from '../../helpers/constants';
import { getErrorClassNames } from '../../helpers/utils';

const DocumentsVerifyingMsg = ({ isOpen, toggle, onExtend, jobDetails }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const formData = convertJsonToFormData({
      job_uuid: jobDetails.uuid,
      payment_extension: moment()
        .add(values.payment_extension, 'hours')
        .format(DATE_TIME_FORMAT),
    });

    dispatch(showLoading());
    POST(`/jobs/update`, formData)
      .then(({ data }) => {
        dispatch(hideLoading());
        dispatch(showModal(`Time Extended successfully!`, true));
        onExtend();
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} container="client-page" className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5>Extend Payment Time</h5>
          <Formik
            initialValues={{
              payment_extension: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={object().shape({
              payment_extension: number()
                .required('Extention Time is required')
                .min(1, 'Extention Time must be greater than or atleast 1'),
            })}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
              } = renderProps;

              // console.log('>> values, touched, errors,', values, touched, errors);
              return (
                <form className={'row'} onSubmit={handleSubmit}>
                  <Fragment>
                    <div className="col-sm-12 text-left">
                      <label>Extention Time (in Hours):</label>
                      <TextField
                        id="payment_extension"
                        name="payment_extension"
                        type="number"
                        // placeholder="*"
                        className={getErrorClassNames(
                          getIn(touched, 'payment_extension') && getIn(errors, 'payment_extension'),
                        )}
                        min={1}
                        value={values.payment_extension}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          getIn(touched, 'payment_extension') && getIn(errors, 'payment_extension')
                        }
                      />
                    </div>
                  </Fragment>

                  <div className="buttonsec1" style={{ textAlign: 'center' }}>
                    <button type="submit" className="submitbut">
                      Submit
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DocumentsVerifyingMsg;
