import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const DocumentsVerifyingMsg = ({ isOpen, toggle, container }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      container={container}
      className="modal-md document-verifying-msg"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalHeader className="p-0">
        <h3>We are verifying your documents</h3>
      </ModalHeader>
      <ModalBody>
        <div className="text-center fs-2">
          <p>
            Your account is currently not operational until your documents are verified, we will
            notify you once that is done. Thank you.
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DocumentsVerifyingMsg;
