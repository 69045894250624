import { POST } from './rest.service';
import { isProd } from '../helpers/utils';

export const sendOTP = (phone) => {
  return POST('/users/sendSmsOtp', { receiver: phone, for: 'registration', test: !isProd() });
};

export const verifyOTP = (phone, otp, verifier) => {
  return POST('/users/verifyOtp', { receiver: phone, for: 'registration', otp, verifier });
};
