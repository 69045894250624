import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { PaystackButton } from 'react-paystack';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { POST } from '../../services/rest.service';
import paystackLogo from '../../../assets/img/paystack.png';
import flutterwaveLogo from '../../../assets/img/flutterwave.png';
import remitaLogo from '../../../assets/img/remita.png';
import {
  getPaystackConfig,
  getFlutterwaveConfig,
  getPaypalConfig,
} from '../../helpers/paymentsConfig';
import { displayAmount, converToNumber, formatAmount } from '../../helpers/utils';
import { showModal } from '../../redux/error/ErrorAction';
import tick from '../../../assets/img/tick.png';
import generateRRR from '../../services/remita.service';
import configObject from '../../../config';
import convertJsonToFormData from '../../helpers/helper';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import {
  SERVICETYPE_CLEARING,
  SERVICETYPE_FORWARDING,
  SERVICETYPE_SUSPENSION,
  SERVICETYPE_SUBSCRIPTION,
  PAYMENT_RELATED_TO_JOB,
  PAYMENT_RELATED_TO_SUSPENSION,
  PAYMENT_RELATED_TO_SUBSCRIPTION,
  CURRENCY_USD,
  CURRENCY_NGN,
} from '../../helpers/constants';

const PaymentRadioBtn = ({ id, label, onChange, img }) => {
  return (
    <div className="col-sm-12">
      <div className="payment-method2 custom02">
        <input type="radio" id={id} value={id} name="method" onChange={onChange} />
        <label htmlFor={id}>{label}</label>
        <img src={img} alt={`payment method ${id}`} />
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

// THIS COMPONENT IS ALL USED IN PLACES WHERE PAYMENT IS REQUIRED
const Payments = (props) => {
  const {
    paymentAmount = 0,
    paymentRelatedTo = '',
    job = {},
    handleBackFromPaymentMethod = () => {},
    onPaymentSuccess = () => {},
    onPaymentFail = () => {},
    handleProceedToPaymentMethod = () => {},
    nextButtonClassName = '',
    backButtonClassName = '',
  } = props;

  const job_uuid = job?.uuid || null;
  const serviceData = useSelector((state) => state.enumData);
  const REMITA_THRESHOLD = converToNumber(serviceData?.settings?.general?.threshold);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const exchangeRate = converToNumber(serviceData?.settings?.general?.dollar_exchange_rate);
  // console.log(exchangeRate);
  const exchangeValue = exchangeRate ? 1 / exchangeRate : 0;

  const [radioMethod, setRadiMethod] = useState('');
  const [amount, setAmount] = useState(converToNumber(0));

  const [radioValue, setRadioValue] = useState(CURRENCY_NGN);

  const [step, setStep] = useState(1);

  useEffect(() => setAmount(converToNumber(paymentAmount)), [paymentAmount]);

  const nextStep = useCallback(() => setStep((s) => s + 1), []);
  const prevStep = useCallback(() => setStep((s) => s - 1), []);

  const handleProccedCurrency = useCallback(() => {
    if (isEmpty(radioValue)) {
      dispatch(showModal('please choose currency !'));
    } else {
      nextStep();
      if (handleProceedToPaymentMethod) handleProceedToPaymentMethod();
    }
  }, [dispatch, handleProceedToPaymentMethod, nextStep, radioValue]);

  //convert ngn to usd by radio button
  const hdleCurrency = useCallback(
    (e) => {
      if (paymentAmount) {
        const { value } = e.target;
        setRadioValue(value);
        let n_value = converToNumber(paymentAmount);
        if (value === CURRENCY_USD) {
          const u_value = converToNumber(Number(n_value * exchangeValue).toFixed(2));
          setAmount(u_value);
          dispatch(showModal(`You Have to Pay ${value} ${formatAmount(u_value)}`, true));
        } else {
          setAmount(n_value);
          dispatch(showModal(`You Have to Pay ${value} ${formatAmount(n_value)}`, true));
        }
      }
    },
    [dispatch, paymentAmount, exchangeValue],
  );

  const handleStepBack = useCallback(() => {
    if (step > 1) {
      prevStep();
    }

    if (handleBackFromPaymentMethod) handleBackFromPaymentMethod(step);
  }, [handleBackFromPaymentMethod, step, prevStep]);

  const handleProceed = useCallback(() => {
    if (!radioMethod) dispatch(showModal('please select one option!'));
  }, [dispatch, radioMethod]);

  const handlePaymentMethodChange = useCallback((e) => setRadiMethod(e.target.value), []);

  //payment success  api
  const paySuceess = (data) => {
    dispatch(showLoading());
    POST('/payment/success', data)
      .then((data) => {
        // console.log(data);
        dispatch(showModal('Payment Done successfully!', true));
        dispatch(hideLoading());
        nextStep();
        if (onPaymentSuccess) onPaymentSuccess();
      })
      .catch((err) => {
        // console.log(err);
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  //payment fail api
  const payFail = (data) => {
    dispatch(showLoading());
    POST('/payment/failed', data)
      .then((data) => {
        // console.log(data);
        dispatch(showModal('Payment Failed!'));
        dispatch(hideLoading());
        if (onPaymentFail) onPaymentFail();
      })
      .catch((err) => {
        // console.log(err);
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  // >> start paystack config
  const paystackConfig = getPaystackConfig({
    user: {
      email: user.email,
    },
    amount: amount * 100,
    onSuccess: (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      const data = {
        payload: reference,
        related: paymentRelatedTo,
        user_uuid: user.uuid,
        job_uuid: job_uuid,
        amount: amount,
        discount: null,
        channel: 'paystack',
      };
      // console.log(data);
      paySuceess(data);
      // console.log(reference);
    },
    onClose: (close) => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      const data = {
        payload: ['cancle'],
        related: paymentRelatedTo,
        job_uuid: job_uuid,
        user_uuid: user.uuid,
        amount: amount,
        discount: null,
        channel: 'paystack',
      };
      // console.log(close);
      payFail(data);
    },
  });
  // console.log('>> paystackConfig',paystackConfig);
  // << end paystack config

  // >> start flutterpay config
  const fwConfig = getFlutterwaveConfig({
    amount: amount,
    user: {
      email: user.email,
      phonenumber: user.mobile_number,
      name: user.first_name + ' ' + user.last_name || user.company_name,
    },
    callback: (response) => {
      // console.log(response);
      closePaymentModal();
      const data = {
        payload: response,
        related: paymentRelatedTo,
        job_uuid: job_uuid,
        user_uuid: user.uuid,
        amount: amount,
        discount: null,
        channel: 'flutterwave',
      };
      // console.log(data);
      paySuceess(data);

      // this will close the modal programmatically
    },
    onClose: (close) => {
      const data = {
        payload: ['fail'],
        related: paymentRelatedTo,
        job_uuid: job_uuid,
        user_uuid: user.uuid,
        amount: amount,
        discount: null,
        channel: 'flutterwave',
      };
      payFail(data);
      // console.log(close);
    },
  });
  // console.log('>> fwConfig',fwConfig);
  // << end flutter pay config

  const handleRemitaPay = async () => {
    dispatch(showLoading());

    let serviceType = '';
    let orderId = '';

    if (paymentRelatedTo === PAYMENT_RELATED_TO_SUBSCRIPTION) {
      serviceType = SERVICETYPE_SUBSCRIPTION;
      orderId = user.uuid;
    } else if (paymentRelatedTo === PAYMENT_RELATED_TO_SUSPENSION) {
      serviceType = SERVICETYPE_SUSPENSION;
      orderId = user.uuid;
    } else if (paymentRelatedTo === PAYMENT_RELATED_TO_JOB && job?.is_forwarding_only) {
      serviceType = SERVICETYPE_FORWARDING;
      orderId = job_uuid;
    } else {
      serviceType = SERVICETYPE_CLEARING;
      orderId = job_uuid;
    }

    const genRRRResp = await generateRRR({
      amount: amount,
      orderId,
      first_name: user.first_name || user.company_name,
      last_name: user.last_name || user.company_name,
      email: user.email,
      mobile_number: user.mobile_number,
      serviceType: serviceType,
    });

    // console.log('>> genRRRResp', genRRRResp);

    if (window.RmPaymentEngine && genRRRResp?.RRR) {
      // update job and save the RRR for future reference
      if (
        paymentRelatedTo === PAYMENT_RELATED_TO_SUBSCRIPTION ||
        paymentRelatedTo === PAYMENT_RELATED_TO_SUSPENSION
      ) {
        const payld = {
          user_uuid: user.uuid,
        };
        if (paymentRelatedTo === PAYMENT_RELATED_TO_SUBSCRIPTION) {
          payld.registration_rrr = genRRRResp.RRR;
        } else if (paymentRelatedTo === PAYMENT_RELATED_TO_SUSPENSION) {
          payld.suspension_rrr = genRRRResp.RRR;
        }
        const userUpdatePayload = convertJsonToFormData(payld);
        const userUpdateResp = await POST('/users/update', userUpdatePayload);
        // console.log('>> userUpdateResp', userUpdateResp);
      } else if (paymentRelatedTo === PAYMENT_RELATED_TO_JOB) {
        const jobUpdatePayload = convertJsonToFormData({ job_uuid, remita_rrr: genRRRResp.RRR });
        const jobUpdateResp = await POST('/jobs/update', jobUpdatePayload);
        // console.log('>> jobUpdateResp', jobUpdateResp);
      }

      const paymentEngine = window.RmPaymentEngine.init({
        key: configObject.REMITA_PUBLIC_KEY,
        processRrr: true,
        transactionId: genRRRResp.RRR,
        extendedData: {
          customFields: [
            {
              name: 'rrr',
              value: genRRRResp.RRR,
            },
          ],
        },
        onSuccess: function(response) {
          // function callback when payment is successful
          // console.log('callback Successful Response', response);
          const data = {
            payload: response,
            related: paymentRelatedTo,
            job_uuid: job_uuid,
            user_uuid: user.uuid,
            amount: amount,
            discount: null,
            channel: 'remita',
          };
          // console.log(data);
          paySuceess(data);
        },
        onError: function(response) {
          // function callback when payment fails
          // console.log('callback Error Response', response);
          const data = {
            payload: ['fail'],
            related: paymentRelatedTo,
            job_uuid: job_uuid,
            user_uuid: user.uuid,
            amount: amount,
            discount: null,
            channel: 'remita',
          };
          payFail(data);
        },
        onClose: function() {
          // console.log('closed');
          const data = {
            payload: ['fail'],
            related: paymentRelatedTo,
            job_uuid: job_uuid,
            user_uuid: user.uuid,
            amount: amount,
            discount: null,
            channel: 'remita',
          };
          payFail(data);
        },
      });

      dispatch(hideLoading());
      paymentEngine.showPaymentWidget();
    } else {
      dispatch(hideLoading());
    }
  };

  // >> start paypal config
  const paypalConfig = getPaypalConfig({
    amount: amount,
    onSuccess: (details, resdata) => {
      // console.log(resdata);
      const data = {
        payload: resdata,
        related: paymentRelatedTo,
        job_uuid: job_uuid,
        user_uuid: user.uuid,
        amount: amount,
        discount: null,
        channel: 'paypal',
      };
      paySuceess(data);
    },
    onCancel: (closedata) => {
      const data = {
        payload: closedata,
        related: paymentRelatedTo,
        job_uuid: job_uuid,
        user_uuid: user.uuid,
        amount: amount,
        discount: null,
        channel: 'paypal',
      };
      payFail(data);

      // console.log(closedata);
    },
  });
  // console.log('>> paypalConfig',paypalConfig);
  // << end paypal config

  // This method is for completing payment step while developement (TEST PURPOSE ONLY)
  // const dummyPaySuccess = () => {
  //   const data = {
  //     payload: Date.now(),
  //     related: paymentRelatedTo,
  //     user_uuid: user.uuid,
  //     job_uuid: job_uuid,
  //     amount: amount,
  //     discount: null,
  //     channel: 'paystack',
  //   };
  //   // console.log(data);

  //   paySuceess(data);
  // };

  // console.log('>> STEP', step);

  const nextBtnClassName = nextButtonClassName || 'submitbut next-btn';
  const backBtnClassName = backButtonClassName || 'submitbut back-btn btn-light';

  return (
    <div className={paymentRelatedTo}>
      <hr />
      <h3>Total Amount to Pay: {displayAmount(amount, radioValue === CURRENCY_USD)}</h3>
      <hr className="mb-0" />
      {step === 1 && (
        <div className="payment-info">
          {!!exchangeRate && (
            <div className="col-sm-6 mt-15">
              <div className="payment-method2 custom02">
                <input
                  type="radio"
                  id="usd"
                  value="USD"
                  name="curchange"
                  onChange={hdleCurrency}
                  checked={radioValue === CURRENCY_USD}
                />
                <label htmlFor="usd">USD</label>

                <div className="clearfix"></div>
              </div>
            </div>
          )}
          <div className={exchangeRate ? 'col-sm-6 mt-15' : 'col-sm-12 mt-15'}>
            <div className="payment-method2 custom02">
              <input
                type="radio"
                id="ngn"
                value="NGN"
                name="curchange"
                onChange={hdleCurrency}
                checked={radioValue === CURRENCY_NGN}
              />
              <label htmlFor="ngn">NGN</label>

              <div className="clearfix"></div>
            </div>
          </div>

          <div className="buttonsec">
            <button className={backBtnClassName} onClick={handleStepBack}>
              Back
            </button>
            <button
              onClick={handleProccedCurrency}
              className={nextBtnClassName}
              data-toggle="modal"
              data-target="#myModal"
            >
              Proceed to Next
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="payment-info">
          <h3>Payment Method</h3>

          <div className="row">
            {serviceData?.settings?.general?.payments?.length > 0 &&
              serviceData.settings.general.payments.map((p) => {
                let comp = null;
                // console.log('>> p', p)
                if (radioValue === CURRENCY_NGN) {
                  if (p.value === 'paystack' && amount < REMITA_THRESHOLD) {
                    comp = (
                      <PaymentRadioBtn
                        id="paystack"
                        onChange={handlePaymentMethodChange}
                        img={paystackLogo}
                        label="Paystack"
                        key={p.value}
                      />
                    );
                  } else if (p.value === 'flutterwave' && amount < REMITA_THRESHOLD) {
                    comp = (
                      <PaymentRadioBtn
                        id="flutterwave"
                        onChange={handlePaymentMethodChange}
                        img={flutterwaveLogo}
                        label="Flutterwave"
                        key={p.value}
                      />
                    );
                  } else if (p.value === 'remita') {
                    comp = (
                      <PaymentRadioBtn
                        id="remita"
                        onChange={handlePaymentMethodChange}
                        img={remitaLogo}
                        label="Remita"
                        key={p.value}
                      />
                    );
                  }
                } else if (p.value === 'paypal') {
                  comp = <PayPalButton key={p.value} {...paypalConfig} />;
                }
                return comp;
              })}
            <div className="col-sm-12">
              <div className="buttonsec">
                <button className={backBtnClassName} onClick={handleStepBack}>
                  Back
                </button>
                {radioValue === CURRENCY_NGN && isEmpty(radioMethod) && (
                  <button
                    className={nextBtnClassName}
                    onClick={handleProceed}
                    // onClick={dummyPaySuccess}
                  >
                    Proceed to Next
                  </button>
                )}
                {!isEmpty(radioMethod) && (
                  <Fragment>
                    {radioMethod === 'paystack' ? (
                      <PaystackButton className={nextBtnClassName} {...paystackConfig} />
                    ) : radioMethod === 'flutterwave' ? (
                      <FlutterWaveButton className={nextBtnClassName} {...fwConfig} />
                    ) : radioMethod === 'remita' ? (
                      <button className={nextBtnClassName} onClick={handleRemitaPay}>
                        Pay with Remita
                      </button>
                    ) : null}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="payment-info">
          <div className="finish">
            <p>
              <img src={tick} alt="" />
            </p>
            <h5>Payment Successful</h5>
            <p>Your payment of {displayAmount(amount)} was successful.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payments;
