import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Formik, getIn } from 'formik';
import { object, string, number } from 'yup';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import { InputFeedback } from '../../components/UI/TextField';
import {
  displayAmount,
  getErrorClassNames,
  getName,
  converToNumber,
  checkAgentOperatesOnThisPort,
} from '../../helpers/utils';

const AssignAgentToJobModal = ({ isOpen, toggle, jobDetails, onAssign }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const serviceData = useSelector((state) => state.enumData);

  const clearing_surcharge = serviceData && serviceData.settings.general.clearing_surcharge;
  const forwarding_surcharge = serviceData && serviceData.settings.general.forwarding_surcharge;
  const jobId = jobDetails?.uuid;

  const [customValue, setCustomValue] = useState(0);
  const [truckData, setTruckData] = useState([]);
  const [agents, setAgents] = useState([]);

  // No Compatible trucks found
  const noCompatibleTrucks = () => {
    swal({
      title: 'No Compatible Truck Found',
      text: 'Agent does not have any compatible trucks for the job',
      icon: 'error',
    });
  };

  const getTruckList = (agentId) => {
    dispatch(showLoading());
    const payload = {
      agent_uuid: agentId,
      job_uuid: jobId,
    };
    return POST(`/trucks/getTruckByAgent`, payload)
      .then(({ data }) => {
        // console.log(data);
        dispatch(hideLoading());
        if (data.trucks?.length > 0) {
          setTruckData(data.trucks);
          return true;
        } else {
          noCompatibleTrucks();
          return false;
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
        return false;
      });
  };

  const getAgentsList = (uType) => {
    dispatch(showLoading());
    const payload = {
      user_type: uType,
    };
    POST(`/users/getUsers`, payload)
      .then(({ data }) => {
        // console.log(data);
        if (data.users?.length > 0) {
          setAgents(data.users);
        } else {
          setAgents([]);
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  // No Port of Operations
  const noPortOfOperation = () => {
    swal({
      title: 'No Port of Operation',
      text: "Selected Agent does not operate in the job's Port of Discharge",
      icon: 'error',
    });
  };

  const getAgentPortOfOperations = (agentId) => {
    dispatch(showLoading());
    return POST('/ports/getAgentPort', {
      agent_uuid: agentId,
      document_status: 1, // getting only the approved ports
    })
      .then(({ data }) => {
        dispatch(hideLoading());
        if (
          data.ports?.length > 0 &&
          checkAgentOperatesOnThisPort(data.ports, jobDetails.clearing_discharge_port)
        ) {
          // if the agent's operates on the port that job discharges, then do nothing
          return true;
        } else {
          // else show message
          noPortOfOperation();
          return false;
        }
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
        return false;
      });
  };

  const handleAgentSelect = async (val, setFieldValue) => {
    // for forwarding job - fetch transporter's trucks that matches the job requirement
    if (jobDetails?.is_forwarding_only) {
      const res = await getTruckList(val);
      setFieldValue('agent', res ? val : '');
    } else {
      // for clearing job - fetch the clearing agent port's of operations
      // this is to check if the agent operates in the port that the job discharges from
      const res = await getAgentPortOfOperations(val);
      setFieldValue('agent', res ? val : '');
    }
  };

  useEffect(() => {
    if (jobDetails) {
      if (jobDetails.custom_duty) setCustomValue(jobDetails.custom_duty);
      const userType = jobDetails?.is_forwarding_only ? 'forwarding_agent' : 'clearing_agent';
      getAgentsList(userType);
    }
  }, [jobDetails]);

  const createBidForJob = async (values) => {
    const payload = {
      clearing_expenses: Number(values.clearing_expenses).toFixed(2),
      fright_cost: values.fright_cost,
      job_uuid: jobId,
      job_type: values.clearingJob ? 'clearing' : 'forwarding',
      agent_uuid: values.agent,
    };
    if (get(user, 'type') === 'forwarding_agent') {
      payload.truck_uuid = values.truck;
    }
    return await POST('/bids/create', payload);
  };

  const acceptBidForTheJob = async (jobId, bidId, agentId) => {
    const payload = {
      job_uuid: jobId,
      bid_uuid: bidId,
      agent_uuid: agentId,
    };
    return await POST(`/jobs/acceptBid`, payload);
  };

  const handleSubmit = async (values) => {
    // console.log('>> handleSubmit values', values);

    dispatch(showLoading());
    try {
      // 1. Create bid for agent
      const bidResponse = await createBidForJob(values);
      // console.log('>> handleSubmit ', bidResponse);
      // if (bidResponse) dispatch(showModal('Bid created successfully', true));

      // 2. accept the bid by client
      const jobAcceptedResponse = await acceptBidForTheJob(
        values.jobId,
        bidResponse.uuid,
        values.agent,
      );
      // console.log('>> handleSubmit ', jobAcceptedResponse);
      if (jobAcceptedResponse) dispatch(showModal('Agent assigned successfully', true));
      dispatch(hideLoading());
      onAssign();
    } catch (error) {
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} container="client-page" className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5>Assign Agent to Job</h5>
          <Formik
            initialValues={{
              clearing_expenses: '',
              fright_cost: '',
              clearingJob: jobDetails?.is_forwarding_only ? false : true,
              agent: '',
              truck: '',
              jobId: jobId,
            }}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={object().shape({
              agent: string().required('Please select Agent'),
              clearing_expenses: string().when('clearingJob', {
                is: true,
                then: string().required('Please add clearing expenses'),
              }),

              fright_cost: number().when('clearingJob', {
                is: false,
                then: number().required('Please add fright cost'),
              }),
              truck: string().when('clearingJob', {
                is: false,
                then: string().required('Please select a truck'),
              }),
            })}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = renderProps;

              const clearingSurcharge = customValue
                ? (customValue * converToNumber(clearing_surcharge)) / 100
                : 0;
              const clearingTotalamount =
                converToNumber(customValue) +
                converToNumber(values.clearing_expenses) +
                converToNumber(clearingSurcharge);

              const forwardingSurcharge = values.fright_cost
                ? (values.fright_cost * converToNumber(forwarding_surcharge)) / 100
                : 0;
              const forwardingTotalAmount =
                converToNumber(values.fright_cost) + converToNumber(forwardingSurcharge);

              return (
                <form className={'row'} onSubmit={handleSubmit}>
                  <div className="text-left mb-4">
                    <label>Select Agent</label>
                    <select
                      className={getErrorClassNames(
                        getIn(touched, 'agent') && getIn(errors, 'agent'),
                      )}
                      value={values.agent}
                      onBlur={handleBlur}
                      id="agent"
                      onChange={(e) => handleAgentSelect(e.target.value, setFieldValue)}
                    >
                      <optgroup label="Name - Id">
                        {agents?.length > 0 ? (
                          <>
                            <option value="" label="Select"></option>
                            {agents.map((agent) => (
                              <option
                                key={agent.uuid}
                                value={agent.uuid}
                                label={`${getName(agent)} - ${agent.user_id}`}
                              />
                            ))}
                          </>
                        ) : (
                          <option value="" label="Not Available" />
                        )}
                      </optgroup>
                    </select>
                    <InputFeedback error={getIn(touched, 'agent') && getIn(errors, 'agent')} />
                  </div>
                  {values.agent && (
                    <>
                      {values.clearingJob ? (
                        <>
                          <div className="text-left mb-4">
                            <label>Custom Duty</label>
                            <NumberFormat
                              thousandsGroupStyle="thousand"
                              decimalSeparator="."
                              value={customValue}
                              className={getErrorClassNames(
                                getIn(touched, 'custom_value') && getIn(errors, 'custom_value'),
                              )}
                              displayType="input"
                              type="text"
                              thousandSeparator={true}
                              prefix=""
                              id="custom_value"
                              readOnly
                            />
                          </div>

                          <div className="text-left mb-4">
                            <label>Agency, Shipping, Terminal and All charges</label>
                            <NumberFormat
                              thousandsGroupStyle="thousand"
                              decimalSeparator="."
                              value={values.clearing_expenses}
                              className={getErrorClassNames(
                                getIn(touched, 'clearing_expenses') &&
                                  getIn(errors, 'clearing_expenses'),
                              )}
                              displayType="input"
                              type="text"
                              thousandSeparator={true}
                              prefix=""
                              id="clearing_expenses"
                              name="clearing_expenses"
                              onValueChange={(e) => setFieldValue('clearing_expenses', e.value)}
                              autoComplete="off"
                            />
                            <InputFeedback
                              error={
                                getIn(touched, 'clearing_expenses') &&
                                getIn(errors, 'clearing_expenses')
                              }
                            />
                          </div>
                          <div className="text-left mb-4">
                            <label>Surcharge</label>
                            <NumberFormat
                              thousandsGroupStyle="thousand"
                              decimalSeparator="."
                              value={clearingSurcharge}
                              className={getErrorClassNames(
                                getIn(touched, 'surcharge') && getIn(errors, 'surcharge'),
                              )}
                              displayType="input"
                              type="text"
                              thousandSeparator={true}
                              prefix=""
                              id="surcharge"
                              name="surcharge"
                              readOnly
                              disabled
                            />
                            <InputFeedback
                              error={getIn(touched, 'surcharge') && getIn(errors, 'surcharge')}
                            />
                          </div>
                          <div className="text-left">
                            <h4>Total: {displayAmount(clearingTotalamount)}</h4>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-left mb-4">
                            <label>Truck</label>
                            <select
                              className={getErrorClassNames(
                                getIn(touched, 'truck') && getIn(errors, 'truck'),
                              )}
                              value={values.truck}
                              onBlur={handleBlur}
                              id="truck"
                              onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue('truck', value);
                              }}
                            >
                              <optgroup label="Registration No  -  Brand  -  Model">
                                {truckData?.length > 0 ? (
                                  <>
                                    <option value="" label="Select"></option>
                                    {truckData.map((truck) => (
                                      <option
                                        key={truck.uuid}
                                        value={truck.uuid}
                                        label={`${truck.reg_number} - ${truck.make} - ${truck.model}`}
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <option value="" label="Not Available" />
                                )}
                              </optgroup>
                            </select>
                            <InputFeedback
                              error={getIn(touched, 'truck') && getIn(errors, 'truck')}
                            />
                          </div>
                          <div className="text-left mb-4">
                            <label>Fright Cost</label>
                            <NumberFormat
                              thousandsGroupStyle="thousand"
                              decimalSeparator="."
                              value={values.fright_cost}
                              className={getErrorClassNames(
                                getIn(touched, 'fright_cost') && getIn(errors, 'fright_cost'),
                              )}
                              displayType="input"
                              type="text"
                              thousandSeparator={true}
                              prefix=""
                              id="fright_cost"
                              name="fright_cost"
                              onValueChange={(e) => setFieldValue('fright_cost', e.value)}
                              placeholder="0"
                              min={0}
                              autoComplete="off"
                            />
                            <InputFeedback
                              error={getIn(touched, 'fright_cost') && getIn(errors, 'fright_cost')}
                            />
                          </div>
                          <div className="text-left mb-4">
                            <label>Surcharge</label>
                            <NumberFormat
                              thousandsGroupStyle="thousand"
                              decimalSeparator="."
                              value={forwardingSurcharge}
                              className={getErrorClassNames(
                                getIn(touched, 'surcharge') && getIn(errors, 'surcharge'),
                              )}
                              displayType="input"
                              type="text"
                              thousandSeparator={true}
                              prefix=""
                              id="surcharge"
                              name="surcharge"
                              readOnly
                              disabled
                            />
                          </div>
                          <div className="text-left">
                            <h4>Total: {displayAmount(forwardingTotalAmount)}</h4>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                    <button type="submit" className="submitbut">
                      Place Bid
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AssignAgentToJobModal;
