import React, { useEffect, useState } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ScrollToStart from '../UI/ScrollToStart';
import Logo from '../../../assets/img/logo.png';
import LogoWhite from '../../../assets/img/logo-white.png';
import userImage from '../../../assets/img/placeHolderImage.png';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { logoutUser } from '../../redux/user/UserActions';
import { applicationUserType } from '../../helpers/constants';
import config from '../../../config';
import { useGoogleAnalytics } from '../../helpers/customHooks';
// import WebinarBanner from '../global/WebinarBanner';

const PublicHeader = (props) => {
  const [addHeaderClass, setHeaderClass] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    const calculateHeader = (e) => {
      if (window.scrollY >= 100) {
        setHeaderClass(true);
      } else {
        setHeaderClass(false);
      }
    };
    window.addEventListener('scroll', calculateHeader);

    return () => {
      window.removeEventListener('scroll', calculateHeader);
    };
  }, []);
  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  // track pageview with google analytics
  useGoogleAnalytics();

  const currentPath = window.location.pathname;
  const servicesMenuActive =
    currentPath === '/importers-landing' ||
    currentPath === '/clearing-agents-landing' ||
    currentPath === '/forwarding-agents-landing';

  return (
    <>
      {/* THIS IS COMMENTED FOR THE TIME BEING  */}
      {/* <WebinarBanner /> */}
      <header
        id="header"
        className={`header fixed-top ${
          addHeaderClass ? 'header-scrolled' : ''
        } ${props.defaultClass || ''}`}
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
            {addHeaderClass ? (
              <img src={LogoWhite} alt="goagent-logo" />
            ) : (
              <img src={Logo} alt="goagent-logo" />
            )}
          </Link>

          <nav id="navbar" className={`navbar ${mobileMenu ? 'navbar-mobile' : ''}`}>
            <ul>
              <li onClick={closeMobileMenu}>
                <NavLink to="/" className="nav-link" exact>
                  Home
                </NavLink>
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink to="/about-us" className="nav-link">
                  About Us
                </NavLink>
              </li>
              <li className="dropdown">
                <a className={`dropbtn nav-link ${servicesMenuActive ? 'active' : ''}`}>Services</a>
                <div className="dropdown-content">
                  <NavLink to="/importers-landing" className="nav-link" onClick={closeMobileMenu}>
                    GoAgent for Importers
                  </NavLink>

                  <NavLink
                    to="/clearing-agents-landing"
                    className="nav-link"
                    onClick={closeMobileMenu}
                  >
                    GoAgent for Clearing Agents
                  </NavLink>

                  <NavLink
                    to="/forwarding-agents-landing"
                    className="nav-link"
                    onClick={closeMobileMenu}
                  >
                    GoAgent for Transporters
                  </NavLink>
                </div>
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink to="/career" className="nav-link">
                  Careers
                </NavLink>
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink to="/blog" className="nav-link">
                  Blog
                </NavLink>
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink to="/contact-us" className="nav-link">
                  Contact
                </NavLink>
              </li>

              {!get(user, 'isLoggedIn', false) && (
                <li onClick={closeMobileMenu}>
                  <NavLink to="/login" className="nav-link">
                    Login
                  </NavLink>
                </li>
              )}
              {!get(user, 'isLoggedIn', false) && (
                <li onClick={closeMobileMenu}>
                  <NavLink to="/registration" className="active nav-link">
                    Create free Account
                  </NavLink>
                </li>
              )}
            </ul>
            <i
              className={`bi bi-${mobileMenu ? 'x' : 'list'} mobile-nav-toggle`}
              onClick={() => setMobileMenu(!mobileMenu)}
            ></i>
          </nav>

          {get(user, 'isLoggedIn', false) && (
            <>
              {' '}
              <div className="loginright">
                <a
                  href="#"
                  className="topprofile"
                  id="dropdownMenu1"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      get(user, 'avatar')
                        ? `${config.api.URL.replace('/api/v1', '')}/${get(user, 'avatar')}`
                        : userImage
                    }
                    alt=""
                  />
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li>
                    <a
                      onClick={() => {
                        history.push(`/${applicationUserType[get(user, 'type')]}/dashboard`);
                      }}
                    >
                      Dashboard
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={() => {
                        dispatch(logoutUser(history));
                      }}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
                {/* <div className="notification">
                  <a href="#">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                  </a>
                  <span>{user.notifications.length}</span>
                </div> */}
                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
            </>
          )}
        </div>
      </header>

      <ScrollToStart addHeaderClass={addHeaderClass} />
    </>
  );
};
export default withRouter(PublicHeader);
