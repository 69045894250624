import React from 'react';
import UpdateProfile from '../../components/global/UpdateProfle';
const EditProfile = (props) => {
  return (
    <>
      <UpdateProfile type="client" />
    </>
  );
};

export default EditProfile;
