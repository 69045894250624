import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import UserHeader from '../components/global/UserHeader';
import AppLoader from '../components/loaders/AppLoader';
import Dashboard from '../containers/Supervisor/Dashboard';
import JobList from '../containers/Supervisor/Jobs/JobList';
import { ValidateRoutes } from './ClientRoute';
import Notification from '../components/global/Notification';
import DisputesList from '../components/global/DisputesList';
import { hideDocumentModal } from '../redux/user/UserActions';
import DocumentModal from '../components/Modals/DocumentModal';
import OnGoingJobs from '../containers/Operator/OnGoingJobs';
import NewJobDetail from '../containers/Operator/NewJobDetail';
import DisputesDetails from '../components/global/DisputesDetails';

const SupervisorRouting = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userStoreState = useSelector((state) => state.user);
  const { user } = userStoreState;

  const [siderMemuToggle, setSiderMemuToggle] = useState(false);

  const { match } = props;
  const { pathname } = props.location;

  if (isEmpty(user)) {
    return <AppLoader />;
  }

  const onMenuClick = (path) => {
    history.push(path);
    setSiderMemuToggle(false);
  };

  return (
    <div id="superviser-page">
      <Helmet>
        <title>GoAgent</title>
      </Helmet>
      <UserHeader />
      <main id="main" className="main-block">
        <div className="bodycont">
          <div style={{ clear: 'both' }}></div>

          <div className="tab">
            <div className="cormen-btn">
              <label htmlFor="cormid"></label>
            </div>
            <div className="cormen">
              <input
                id="cormid"
                type="checkbox"
                checked={siderMemuToggle}
                onChange={(e) => setSiderMemuToggle(e.target.checked)}
              />
              <ul>
                <button
                  className={`tablinks ${
                    pathname.includes('/supervisor/dashboard') ||
                    pathname.includes('/supervisor/disputes')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => onMenuClick('/supervisor/dashboard')}
                >
                  Dashboard
                </button>{' '}
                {/* <button className={`tablinks ${pathname.includes('/supervisor/jobs') ? 'active' : ''}`} onClick={() => onMenuClick('/supervisor/jobs')}>
                  My Job
                </button> */}
                <button
                  className={`tablinks ${pathname.includes('/notifications') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/supervisor/notifications')}
                >
                  My Notification
                </button>
              </ul>
            </div>
          </div>

          <Switch>
            <ValidateRoutes
              exact
              path={`${match.url}/dashboard`}
              component={Dashboard}
              has_permission={get(user, 'type') === 'work_supervisor'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/operator/:operatorId/jobs`}
              component={JobList}
              has_permission={get(user, 'type') === 'work_supervisor'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/operator/new-job/:id/details`}
              component={NewJobDetail}
              has_permission={get(user, 'type') === 'work_supervisor'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/operator/ongoing-job/:jobId/details`}
              component={OnGoingJobs}
              has_permission={get(user, 'type') === 'work_supervisor'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/notifications`}
              component={Notification}
              has_permission={get(user, 'type') === 'work_supervisor'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/operator/:operatorId/dispute/details`}
              component={DisputesDetails}
              has_permission={get(user, 'type') === 'work_supervisor'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/operator/:operatorId/disputes`}
              component={DisputesList}
              has_permission={get(user, 'type') === 'work_supervisor'}
            />
            <Redirect from={match.url} to={`${match.url}/dashboard`} />
          </Switch>
          <div className="clearfix"></div>
        </div>
        {/* Modal to Display Document */}
        {userStoreState?.displayDocumentModal && (
          <DocumentModal
            isOpen={userStoreState.displayDocumentModal}
            toggle={() => dispatch(hideDocumentModal())}
            documentToShow={userStoreState.documentToDisplay}
            container="superviser-page"
          />
        )}
      </main>
    </div>
  );
};

export default SupervisorRouting;
