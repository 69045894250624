import React, { useEffect, useState, Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { GET } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import { getPortName, weightFormat } from '../../helpers/utils';
import { accessGeneralOrCargoType, getJobStatus } from '../../helpers/utils';

const JobDetailsModal = (props) => {
  const { jobId, isOpen, toggle } = props;
  const dispatch = useDispatch();

  const serviceData = useSelector((state) => state.enumData);
  const [jobDetails, setJobDetails] = useState({});

  const getJobDetails = (id) => {
    dispatch(showLoading());
    GET(`/jobs/getDetails/${id}`)
      .then(({ data }) => {
        // console.log('data', data);
        setJobDetails(data.job);
        dispatch(hideLoading());
      })
      .catch((err) => {
        // console.log('err', err);
        dispatch(showModal(err.message));
        dispatch(hideLoading());
        props.history.goBack();
      });
  };

  useEffect(() => {
    if (jobId) {
      getJobDetails(jobId);
    }
  }, [jobId]);

  const getDetails = () => {
    let jd = null;
    let jdTransport = null;

    if (jobDetails.category === 1 && jobDetails.type === 1) {
      // 'roro';
      jd = (
        <>
          <p>
            Type of vehicle: {capitalize(serviceData.job.vehicle_type[jobDetails.type]) || 'N/A'}{' '}
          </p>

          {jobDetails?.vehicles?.length > 0 && (
            <>
              <hr />
              <div className="row mx-0">
                {jobDetails.vehicles.map((x, index) => (
                  <div className="col-lg-4 col-sm-12" key={index}>
                    <div className="alert alert-info">
                      {jobDetails.type === 1 ? (
                        <h6>Vehicle Details</h6>
                      ) : (
                        <h6>Vehicle {index + 1}</h6>
                      )}
                      <p>Brand: {x.brand}</p>
                      <p>Year: {x.year}</p>
                      <p>Model: {x.model}</p>
                      <p>
                        Conditions: {capitalize(serviceData.job.vehicle_state[x.vehicle_state])}
                      </p>
                      <p>VREG Number: {x.vreg_no || 'N/A'}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      );
    } else if (jobDetails.category === 1 && jobDetails.type === 2) {
      // 'container';
      jd = (
        <>
          <p>
            Type of vehicle: {capitalize(serviceData.job.vehicle_type[jobDetails.type]) || 'N/A'}{' '}
          </p>
          <p>Number of containers: {jobDetails.no_of_containers || 'N/A'} </p>
          {jobDetails.containers.map((x, index) => (
            <div className="row mx-0" key={index}>
              <hr />
              <h6>Container {index + 1}</h6>
              <p>Content of the container: {x.load_description || 'N/A'}</p>
              <p>Number of vehicles: {x.vehicles?.length || 'N/A'}</p>
              {x.vehicles.map((v, i) => (
                <div className="col-lg-4 col-sm-12" key={i}>
                  <div className="alert alert-info">
                    {jobDetails.type === 1 ? <h6>Vehicle Details</h6> : <h6>Vehicle {i + 1}</h6>}
                    <ul>
                      <li>Brand: {v.brand}</li>
                      <li>Year: {v.year}</li>
                      <li>Model: {v.model}</li>
                      <li>
                        Conditions: {capitalize(serviceData.job.vehicle_state[v.vehicle_state])}
                      </li>
                      <li>VREG Number: {v.vreg_no || 'N/A'}</li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </>
      );
    } else if (jobDetails.category === 2 || jobDetails.category === 3) {
      // 'cargo';
      jd = (
        <Fragment>
          {jobDetails.category === 2 && (
            <Fragment>
              <hr />
              <p>Number of containers: {jobDetails.no_of_containers || 'N/A'} </p>
              <p>Type of cargo: {accessGeneralOrCargoType(jobDetails, serviceData)} </p>
              {jobDetails.type_of_cargo === 1 && (
                <p>Item Name: {jobDetails.single_item_title || 'N/A'} </p>
              )}
            </Fragment>
          )}
          {jobDetails.category === 3 && (
            <Fragment>
              <p>Tonnage: {jobDetails.tonnage || 'N/A'} </p>
              <p>Type of cargo: {accessGeneralOrCargoType(jobDetails, serviceData)} </p>
              <p>Item Name: {jobDetails.single_item_title || 'N/A'} </p>
            </Fragment>
          )}
        </Fragment>
      );
    }

    if (jobDetails.is_forwarding_only) {
      // 'transport';
      jdTransport = (
        <>
          {jobDetails.has_forwarding === 1 && (
            <Fragment>
              <hr />
              <h6>Transport Service Details:</h6>

              <p>
                Port of discharge:{' '}
                {capitalize(serviceData.port_locations[jobDetails.port_of_discharge]) || 'N/A'}{' '}
              </p>
              <p>Destination: {jobDetails.destination || 'N/A'} </p>
              <p>
                State:{' '}
                {serviceData.states.find((s) => s.uuid === jobDetails.destination_state)?.state ||
                  'N/A'}{' '}
              </p>

              <div className="alert alert-info col-lg-4 col-sm-12">
                <h4>Truck Requirement</h4>
                <ul>
                  <li>Size: {capitalize(serviceData.truck.size[jobDetails.size])}</li>
                  <li>Capacity: {weightFormat(jobDetails.weight)}</li>
                  <li>Hooks: {jobDetails.hooks || 'N/A'}</li>
                </ul>
              </div>
            </Fragment>
          )}
          {jobDetails.children_packages && jobDetails.children_packages.length ? (
            <Fragment>
              {jobDetails.children_packages.map((x, index) => (
                <Fragment key={index}>
                  <hr />
                  {index === 0 && <h6>Unbundled Jobs</h6>}
                  <h6>
                    Job {index + 1}: {x.job_id}
                  </h6>

                  <p>Size: {capitalize(serviceData.truck.size[x.size])}</p>
                  <p>Weight: {weightFormat(x.weight)}</p>
                  <div className="alert alert-info">
                    <h4>Truck Requirement</h4>
                    <ul>
                      <li>Size: {x.truck.size}</li>
                      {jobDetails.category !== 3 && <li>Hooks: {x.truck.hooks}</li>}
                    </ul>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ) : (
            ''
          )}
        </>
      );
    }
    return (
      <>
        {jd}
        {jdTransport}
      </>
    );
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} container="clearing-agent-page" className="modal-xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="job-info">
          <h2>Job Details</h2>
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <h5> {capitalize(serviceData.job.category[jobDetails.category]) || 'N/A'}</h5>
              <h6>
                {`${jobDetails.job_id}-${jobDetails.is_forwarding_only ? 'T' : 'C'}` || 'N/A'}
              </h6>
            </div>
            <div className="col-lg-2 col-sm-12 text-right-lg">
              <h4>
                {' '}
                {jobDetails.job_type_tags &&
                  jobDetails.job_type_tags.map((y, i) => (
                    <p
                      className="badge  badge-success"
                      style={{ backgroundColor: y == 'clearing' ? '#007679' : '#1daeb2' }}
                      key={i}
                    >
                      {capitalize(y === 'forwarding' ? 'Transport' : y)} Service
                    </p>
                  ))}
              </h4>
            </div>
          </div>

          <hr />
          <h6>Job Details:</h6>

          <p>
            Status: <strong>{getJobStatus(jobDetails, serviceData)}</strong>
          </p>

          <p>
            Expected date of arrival:{' '}
            {moment(jobDetails.date_of_arrival).format('DD/MM/YYYY') || 'N/A'}{' '}
          </p>

          {jobDetails?.clearing_discharge_port && (
            <p>
              Clearing Port of Discharge:{' '}
              {getPortName(jobDetails?.clearing_discharge_port, serviceData)}
            </p>
          )}
          {jobDetails?.port_of_discharge && (
            <p>
              Clearing Port of Delivery: {getPortName(jobDetails?.port_of_discharge, serviceData)}
            </p>
          )}

          {/*  */}
          {getDetails()}
          {/*  */}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default JobDetailsModal;
