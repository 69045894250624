import React from 'react';
import DisplayDocument from '../../../global/DisplayDocument';
import { getRegYearType } from '../../../../helpers/utils';

const DisplayFields = ({ values, showDoc }) => {
  const regYear = getRegYearType(values);
  return (
    <div className="mx-2">
      {regYear === 1 && (
        <>
          <DisplayDocument
            label="Status Report (Application for Registration)"
            onClick={() => showDoc(values.registration_application_status)}
            type="file"
            hasDocument={values.registration_application_status ? true : false}
            fileColClassName="document"
            documentLabel="Document"
          />
        </>
      )}
      {regYear === 2 && (
        <>
          <DisplayDocument
            label="CTC of Form CAC 1.1 (Application for Registration)"
            onClick={() => showDoc(values.ctc_cac_1_1)}
            type="file"
            hasDocument={values.ctc_cac_1_1 ? true : false}
            fileColClassName="document"
            documentLabel="Document"
          />
        </>
      )}
      {regYear === 3 && (
        <>
          <DisplayDocument
            label="Form CAC 2 (Statement of Share Capital and Return of Allotment)"
            onClick={() => showDoc(values.cac_2)}
            type="file"
            hasDocument={values.cac_2 ? true : false}
            fileColClassName="document"
            documentLabel="Document"
          />
          <DisplayDocument
            label="Form CAC 7 (Particulars of Directors)"
            onClick={() => showDoc(values.cac_7)}
            type="file"
            hasDocument={values.cac_7 ? true : false}
            fileColClassName="document"
            documentLabel="Document"
          />
        </>
      )}
      <DisplayDocument
        label="Certificate of incorporation"
        onClick={() => showDoc(values.incorporation_certificate)}
        type="file"
        hasDocument={values.incorporation_certificate ? true : false}
        fileColClassName="document"
        documentLabel="Document"
      />
      <DisplayDocument
        label="RC Number"
        type="text"
        textColClassName="document"
        value={values.rc_no}
      />
      <DisplayDocument
        label="Memorandum and Articles of Association"
        onClick={() => showDoc(values.memorandum_article_association)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.memorandum_article_association ? true : false}
      />
      <DisplayDocument
        label="Proof of corporate address(Utility bills or Tax payment receipt)"
        onClick={() => showDoc(values.corporate_address_proof)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.corporate_address_proof ? true : false}
      />
    </div>
  );
};

export default DisplayFields;
