import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { displayAmount } from '../../../helpers/utils';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET } from '../../../services/rest.service';

const TABLEHEADER = [
  { width: '', name: 'Suspension Reason', value: 'action', class: '' },
  { width: '', name: 'Suspension Fine', value: 'action', class: '' },
  { width: '', name: 'Suspended By', value: 'action', class: '' },
  { width: '', name: 'Suspension Date & Time', value: 'action', class: '' },
];

const SuspensionHistoryTable = ({ userId, userDetails }) => {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.enumData);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
  } = usePagination();

  const [records, setRecords] = useState([]);

  const getSuspensionHistory = useCallback(
    (page, perPage) => {
      dispatch(showLoading());
      const payload = {
        page: page,
        per_page: perPage,
        sort_by: 'created_at',
        sort_type: 'desc',
      };
      GET(`/admin/getSuspensionLog/${userId}`, payload)
        .then(({ data }) => {
          setTotalPages(data.total_count / perPage);
          setRecords(data.suspension_log);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    },
    [userId, dispatch, setTotalPages, userDetails], // listening to userDetails cause we need to fetch suspension history everytime userdetails is fetched
  );

  useEffect(() => {
    getSuspensionHistory(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage, getSuspensionHistory]);

  const handleSelected = ({ selected }) => {
    // console.log('selected', selected);
    setSelectedPage(selected + 1);
  };

  const handlePageCountChange = (e) => {
    handleSelected({ selected: 0 });
    setItemsPerPage(e.target.value);
  };

  return (
    <div>
      <h3>Suspension History</h3>
      <div className="table-responsive clearingtable agent-bids-table p-2">
        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              {TABLEHEADER.map((x, index) => {
                return (
                  <th className={x.class} width={x.width} key={index}>
                    {x.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          {records.length > 0 && (
            <tbody className="tbody">
              {records.map((x, index) => (
                <tr key={index}>
                  <td>
                    <strong>{x.suspension_reason || 'N/A'}</strong>
                  </td>
                  <td>
                    <strong>{displayAmount(x.suspension_fine) || 'N/A'}</strong>
                  </td>
                  <td>
                    <strong>{serviceData?.user_types?.[x.suspended_by] || 'N/A'}</strong>
                  </td>
                  <td>
                    <div>
                      <p>
                        <strong>{moment(x.created_at).format(DATE_FORMAT)}</strong>
                      </p>
                      <p>
                        <strong>{moment(x.created_at).format(TIME_FORMAT)}</strong>
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {records.length <= 0 && <h4 className="text-center my-4">Records Not Found</h4>}
      </div>
      <div className="d-flex justify-content-end">
        {records.length > 0 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={handleSelected}
            selectedPage={selectedPage}
            setItemsPerPage={handlePageCountChange}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default SuspensionHistoryTable;
