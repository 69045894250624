import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import DisplayDocument from '../../../components/global/DisplayDocument';
import { weightFormat } from '../../../helpers/utils';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';

const TruckDetailsModal = (props) => {
  const { isOpen, toggle, truckId, serviceData } = props;
  const dispatch = useDispatch();
  const [truckDetails, setTruckDetails] = useState({});

  useEffect(() => {
    if (isOpen) {
      dispatch(showLoading());
      POST(`/trucks/getDetails`, { truck_uuid: truckId })
        .then(({ data }) => {
          setTruckDetails(data.truck);
          dispatch(hideLoading());
        })
        .catch((err) => {
          setTruckDetails({});
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  }, [isOpen, truckId]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={'client-page'} className="modal-xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject nopadding-sm">
          <h5>Truck Information</h5>
          <div className="mx-5 text-left">
            <div className="row">
              <div className="col-6">
                <DisplayDocument
                  label="Brand"
                  value={truckDetails.make}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className="col-6">
                <DisplayDocument
                  label="Model"
                  value={truckDetails.model}
                  type="text"
                  textColClassName="document"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <DisplayDocument
                  label="Year of Manufacture"
                  value={truckDetails.year}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className="col-6">
                <DisplayDocument
                  label="Capacity"
                  value={weightFormat(truckDetails.tonnage_weight)}
                  type="text"
                  textColClassName="document"
                />
              </div>
            </div>
            {/*  */}
            <div className="row">
              <div className="col-6">
                <DisplayDocument
                  label="Number of Hooks"
                  value={truckDetails.no_of_hooks}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className="col-6">
                <DisplayDocument
                  label="Number of Tyres"
                  value={truckDetails.no_of_tyres}
                  type="text"
                  textColClassName="document"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <DisplayDocument
                  label="Size"
                  value={truckDetails.size && `${serviceData.truck.size[truckDetails.size]} ft`}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TruckDetailsModal;
