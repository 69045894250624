import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import swal from 'sweetalert';
import AppLoader from '../../components/loaders/AppLoader';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST, GET } from '../../services/rest.service';
import Chat from '../../components/UI/Chat';
import { displayAmount } from '../../helpers/utils';
import DriverBehaviour from '../../components/global/DriverBehaviour';
import { DATE_TIME_FORMAT } from '../../helpers/constants';
import { LeftArrowSvg } from '../../components/UI/CommanUI';
import {
  generateHarshDrivingReport,
  getHarshDrivingReportStatus,
  getHarshDrivingReport,
} from '../../services/navixy.service';
import DisplayDocument from '../../components/global/DisplayDocument';
import NavixyWebLocator from '../../components/global/NavixyWebLocator';
import { useChat } from '../../helpers/customHooks';
import ClearingCargoStatus from '../../components/global/ClearingCargoStatus';

const CHAT_TYPE_CLEARING = 'clearing';
const CHAT_TYPE_FORWARDING = 'forwarding';
const CHAT_TYPE_CLIENT = 'client';

const calculateCount = (state, type) => {
  let count = 1;
  if (state[type]) {
    count += state[type];
  }
  return {
    ...state,
    [type]: count,
  };
};

const clearCount = (state, type) => ({
  ...state,
  [type]: 0,
});

const OnGoingJobs = (props) => {
  const [details, setDetails] = useState({});
  const date = new Date();
  const params = useParams();
  const dispatch = useDispatch();
  const job_id = params?.jobId;

  // console.log(job_id);
  const serviceData = useSelector((state) => state.enumData);
  const [chatbox, setChatbox] = useState(false);
  const [chatboxclient, setChatboxclient] = useState(false);
  const [chatlistclient, setChatlistclient] = useState([]);
  const [chatlistclearingagent, setChatlistclearingagent] = useState([]);
  const [chatlistforwardingagent, setChatlistforwardingagent] = useState([]);
  const [msg, setMsg] = useState('');
  const [msgclient, setMsgclient] = useState('');
  const [hoveringIcon, setHoveringIcon] = useState('');
  const { user } = useSelector((state) => state.user);
  const [trackerId, setTrackerId] = useState(null);
  const [operator, setOperator] = useState(null);
  const [unreadCount, setUnreadCount] = useState({});

  const userIsWorkSupervisor = user.type === 'work_supervisor';

  //get Job Details
  const jobDetails = async () => {
    try {
      dispatch(showLoading());
      const jobRes = await POST(`/jobs/getClearingCargoStatus`, { job_uuid: job_id });
      // console.log('>> jobRes', jobRes);
      setDetails(jobRes.data);
      setOperator({ uuid: jobRes?.data?.job?.operator_uuid });

      if (jobRes?.data?.job?.truck_uuid) {
        const truckDataResp = await POST(`/trucks/getDetails`, {
          truck_uuid: jobRes.data.job.truck_uuid,
        });
        // console.log('>>. truckDataResp', truckDataResp)
        const trackerid = truckDataResp?.data?.truck?.tracker_id;
        if (trackerid) setTrackerId(trackerid);
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    jobDetails();
    // getChat();
  }, []);

  // initialize and subcsribe to chat here
  const handleChatEvent = (data1) => {
    // console.log('>> INCOMING CHAT', data1);
    if (data1.chat_type === CHAT_TYPE_CLEARING) {
      // set unread count of this type when chat is not openend
      if (!chatbox) {
        setUnreadCount((prevState) => calculateCount(prevState, CHAT_TYPE_CLEARING));
      }
      setChatlistclearingagent((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById('chat-top');
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    if (data1.chat_type === CHAT_TYPE_FORWARDING) {
      // set unread count of this type when chat is not openend
      if (!chatbox) {
        setUnreadCount((prevState) => calculateCount(prevState, CHAT_TYPE_FORWARDING));
      }

      setChatlistforwardingagent((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById('chat-top');
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    if (data1.chat_type === CHAT_TYPE_CLIENT) {
      // set unread count of this type when chat is not openend
      if (!chatboxclient) {
        setUnreadCount((prevState) => calculateCount(prevState, CHAT_TYPE_CLIENT));
      }

      setChatlistclient((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById('chat-tops');
      if (objDiv && objDiv.scrollHeight) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }

    dispatch(showModal('you have a new message', true));
  };
  useChat(user.uuid, chatbox, handleChatEvent, chatboxclient);

  //chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${job_id}`)
      .then(({ data }) => {
        // console.log('>> /chats/getByJob data ',data);
        const cc = data.chats.client?.length > 0 ? data.chats.client.reverse() : [];
        setChatlistclient(cc);

        const clc =
          data.chats.clearing_agent?.length > 0 ? data.chats.clearing_agent.reverse() : [];
        setChatlistclearingagent(clc);

        const fwc =
          data.chats.forwarding_agent?.length > 0 ? data.chats.forwarding_agent.reverse() : [];
        setChatlistforwardingagent(fwc);
        const objDiv = document.getElementById('chat-top');
        const objDivs = document.getElementById('chat-tops');
        objDiv.scrollTop = objDiv.scrollHeight;
        objDivs.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const chatToggle = (chatType) => {
    if (!chatbox) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount((prevState) => clearCount(prevState, chatType));
    }
    setChatbox(!chatbox);

    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };
  const chatToggleclient = () => {
    if (!chatboxclient) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount((prevState) => clearCount(prevState, CHAT_TYPE_CLIENT));
    }
    setChatboxclient(!chatboxclient);

    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }
    const payLoad = {
      job_uuid: job_id,
      sender_uuid: user.uuid,
      receiver_uuid:
        details.job && details.job.clearing_agent_uuid
          ? details.job.clearing_agent_uuid
          : details.job.forwarding_agent_uuid,
      chat_type: details.job && details.job.clearing_agent_uuid ? 'clearing' : 'forwarding',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        // console.log('submit', data);
        if (details.job.clearing_agent_uuid) {
          setChatlistclearingagent((prevState) => [...prevState, data.chat]);
        } else {
          setChatlistforwardingagent((prevState) => [...prevState, data.chat]);
        }
        // getChat();
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
      // const objDiv = document.getElementById('chat-top');
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };
  //end chat code
  //client chat
  const sendMessageclient = () => {
    let message = 'Hi';
    if (!isEmpty(msgclient)) {
      message = msgclient;
    }
    const payLoad = {
      job_uuid: job_id,
      sender_uuid: user.uuid,
      receiver_uuid: details.job.created_by,
      chat_type: 'client',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        setChatlistclient((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById('chat-tops');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButtonclient = () => {
    sendMessageclient();
    setMsgclient('');
    // const objDiv = document.getElementById('chat-tops');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChangeclient = (e) => {
    if (e.key === 'Enter') {
      sendMessageclient();
      setMsgclient('');
      // const objDiv = document.getElementById('chat-tops');
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChangeclient = (e) => {
    setMsgclient(e.target.value);
  };
  const completeJob = () => {
    swal({
      title: 'Complete Job Status',
      text: `Are you sure you want to do complete this job?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        const payLoad = {
          job_type: details.job.is_forwarding_only ? 'forwarding' : 'clearing',
          job_uuid: job_id,
          status: 8, // done
        };
        dispatch(showLoading());
        POST(`/jobs/changeStatus`, payLoad)
          .then(({ data }) => {
            // console.log(data);
            // setDetails(data);
            dispatch(showModal('job completed', true));

            dispatch(hideLoading());
            props.history.push('/operator/dashboard');
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    });
  };
  // chat code ends here

  // driver behaviour
  const [harshDrivingData, setHarshDrivingData] = useState({});

  const fetchHarshDrivingData = async (trackerId) => {
    try {
      dispatch(showLoading());
      // harsh driving
      // job?.assigned_at is starting date of job
      const fromDate =
        details?.job?.assigned_at ||
        moment()
          .startOf('day')
          .format(DATE_TIME_FORMAT);
      // job?.forwarding_completed_at is completion date of job
      const toDate =
        details?.job?.forwarding_completed_at ||
        moment()
          .endOf('day')
          .format(DATE_TIME_FORMAT);
      const harshDriving = await generateHarshDrivingReport({
        trackerId: trackerId,
        from: fromDate,
        to: toDate,
      });
      // console.log('>> harshDriving',harshDriving);

      if (harshDriving?.success && harshDriving?.id) {
        // adding a one second delay for now
        await new Promise((r) => setTimeout(r, 2000));

        const hdrStatus = await getHarshDrivingReportStatus(harshDriving.id);

        if (hdrStatus?.success && hdrStatus?.percent_ready === 100) {
          const hdReport = await getHarshDrivingReport(harshDriving.id);
          // console.log('>> hdReport',hdReport);
          setHarshDrivingData(hdReport?.report || {});
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  };

  //fetch Truck data
  useEffect(() => {
    if (trackerId) {
      fetchHarshDrivingData(trackerId);
    }
  }, [trackerId]);

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div>
            <h2>
              <span onClick={props.history.goBack} style={{ cursor: 'pointer', float: 'left' }}>
                <LeftArrowSvg />
              </span>
              {!isEmpty(details.clearingBid) ? 'Cargo Status' : 'Transport Status'}
            </h2>
          </div>
          <div className="tab-pane active" id="resp-tab7">
            <>
              <div style={{ position: 'relative' }}>
                {!isEmpty(details.clearingBid) && (
                  <div className="row mx-0">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Job Id"
                                value={details.job.job_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Bid Price"
                                value={displayAmount(details.clearingBid?.offer_price)}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Agent Id"
                                value={details.clearingBid.agent.user_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 job-status-container">
                            <ClearingCargoStatus
                              details={details}
                              jobId={job_id}
                              refresh={jobDetails}
                            />
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isEmpty(details.forwardingBid) && (
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        {/* <h3>Transport Status:</h3> */}
                        <div className="col-12 col-md-12 col-sm-12 mt-4">
                          <div className="col-lg-4 col-sm-6 col-xs-6">
                            <DisplayDocument
                              label="Job Id"
                              value={details.job.job_id}
                              type="text"
                              textColClassName="document"
                            />
                          </div>
                          <div className="col-lg-4 col-sm-6 col-xs-6">
                            <DisplayDocument
                              label="Bid Price"
                              value={displayAmount(details.forwardingBid.offer_price)}
                              type="text"
                              textColClassName="document"
                            />
                          </div>
                          <div className="col-lg-4 col-sm-6 col-xs-6">
                            <DisplayDocument
                              label="Agent Id"
                              value={details.forwardingBid.agent.user_id}
                              type="text"
                              textColClassName="document"
                            />
                          </div>
                          <div className="col-lg-4 col-sm-6 col-xs-6">
                            <DisplayDocument
                              label="Destination"
                              value={details.job.destination}
                              type="text"
                              textColClassName="document"
                            />
                          </div>
                          <div className="col-lg-4 col-sm-6 col-xs-6">
                            <DisplayDocument
                              label="Port of Discharge"
                              value={serviceData.port_locations[details.job.port_of_discharge]}
                              type="text"
                              textColClassName="document"
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h3>Truck Current Location:</h3>
                      <div className="mapouter">
                        <div className="gmap_canvas">
                          <NavixyWebLocator trackerId={trackerId} />
                        </div>
                      </div>
                      <hr />
                      {/* Harsh Driving */}
                      <DriverBehaviour data={harshDrivingData} />
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
          <div className="row mt-3 mb-5">
            <div className="col-md-4"></div>
            <div className="col-md-12 mt-2">
              <center>
                <a onClick={() => props.history.goBack()} className="rejectbut">
                  Back
                </a>
                {!userIsWorkSupervisor &&
                  details.clearingCargoStatus &&
                  details.clearingCargoStatus.custom_exit_gate && (
                    <a className="rejectbut" onClick={completeJob}>
                      Complete Job
                    </a>
                  )}
              </center>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
      <Chat
        name="Client"
        id="client"
        chatbox={chatboxclient}
        user={userIsWorkSupervisor ? operator : user}
        chatToggle={chatToggleclient}
        chatlist={chatlistclient}
        value={msgclient}
        handleTextChange={handleTextChangeclient}
        handleOnChange={handleOnChangeclient}
        sendButton={sendButtonclient}
        hideMsgInputBlock={!!userIsWorkSupervisor}
        unreadMsgsCount={unreadCount[CHAT_TYPE_CLIENT]}
      />
      {details.job && details.job.clearing_agent_uuid && (
        <Chat
          name="Clearing Agent"
          id="clearing-agent"
          chatbox={chatbox}
          user={userIsWorkSupervisor ? operator : user}
          chatToggle={() => chatToggle(CHAT_TYPE_CLEARING)}
          chatlist={chatlistclearingagent}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
          secondChatPresent
          hideMsgInputBlock={!!userIsWorkSupervisor}
          unreadMsgsCount={unreadCount[CHAT_TYPE_CLEARING]}
        />
      )}
      {details.job && details.job.forwarding_agent_uuid && (
        <Chat
          name="Transporter"
          id="transporter"
          chatbox={chatbox}
          user={userIsWorkSupervisor ? operator : user}
          chatToggle={() => chatToggle(CHAT_TYPE_FORWARDING)}
          chatlist={chatlistforwardingagent}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
          secondChatPresent
          hideMsgInputBlock={!!userIsWorkSupervisor}
          unreadMsgsCount={unreadCount[CHAT_TYPE_FORWARDING]}
        />
      )}
    </>
  );
};

export default OnGoingJobs;
