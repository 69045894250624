import React from 'react';
import classnames from 'classnames';
import { IconButton, Input, InputAdornment } from '@material-ui/core';
import InputFeedback from './InputFeedback';

const Visibility = () => <i className="fa fa-solid fa-eye fs-2" />;
const VisibilityOff = () => <i className="fa fa-solid fa-eye-slash fs-2" />;

const Password = (props) => {
  const {
    id,
    name,
    password,
    showPassword,
    toggleShowPw,
    onChange,
    className,
    error,
    placeholder,
    onBlur,
  } = props;

  const InputClasses = classnames(
    'form-control password-field',
    {
      'is-invalid': !!error,
    },
    className,
  );

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Input
        id={id}
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={onChange}
        className={InputClasses}
        placeholder={placeholder}
        onBlur={onBlur}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => toggleShowPw(showPassword ? false : true)}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <InputFeedback error={error} />
    </>
  );
};

export default Password;
