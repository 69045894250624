import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import config from '../../../config';

const DocumentModal = ({ isOpen, toggle, documentToShow, container="client-page" })=>{
    return (
      <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-xl">
        <div className="modal-header">
          <button type="button" className="close" onClick={toggle}>
              &times;
          </button>
        </div>
        <ModalBody>
        <div>
            <iframe
            name="documentIframe"
            src={`${config.api.URL.replace('/api/v1', '')}/${documentToShow}#zoom=100`}
            width="100%"
            style={{
                height: '80vh'
            }}
            />        
        </div>
        </ModalBody>
      </Modal>
    )
};

export default DocumentModal;