import React from 'react';
import config from '../../../config';

const NavixyWebLocator = ({ trackerId }) => (
  <div>
    <iframe
      src={`${config.NAVIXY_WEBLOCATOR_URL}/?key=${config.NAVIXY_WEBLOCATOR_KEY}&objects=${trackerId}&tail_size=60`}
      width="100%"
      height="500"
      title="Truck Locator"
    />
  </div>
);

export default NavixyWebLocator;
