import React from 'react';
import { get } from 'lodash';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import convertJsonToFormData, { agentSchema } from '../../../helpers/helper';
import { getRegYearType } from '../../../helpers/utils';
import ClearingForwardingDocFields from '../../global/ClearingForwardingFormDocFields';
import { getLoggedInUser } from '../../../redux/user/UserActions';

const VerficationModalForm = (props) => {
  const {
    formValues,
    details,
    isEditMode,
    totalShareVal,
    user,
    rightOpen,
    editRight,
    images,
    showDoc,
    deleteRight,
    handleNext,
    onDocumentUpdateSuccess,
  } = props;
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        incorporation_certificate: '',
        rc_no: get(formValues, 'rc_no', '') || '',
        memorandum_article_association: '',
        corporate_address_proof: '',
        registration_certificate: '',
        registration_certificate_no: get(formValues, 'registration_certificate_no', '') || '',
        registration_application_status: '',
        ctc_cac_1_1: '',
        cac_2: '',
        cac_7: '',
        shareholder: (details.shareholders && get(details.shareholders[0], 'uuid', '')) || '',
        director: (details.directors && get(details.directors[0], 'uuid', '')) || '',
        reg_year: (details.documents && getRegYearType(details.documents)) || '',
        userType: user.type,
        port_of_operation: '',
      }}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        dispatch(showLoading());
        const payLoad = {
          incorporation_certificate: values.incorporation_certificate,
          rc_no: values.rc_no,
          memorandum_article_association: values.memorandum_article_association,
          corporate_address_proof: values.corporate_address_proof,
          registration_certificate: values.registration_certificate,
          registration_certificate_no: values.registration_certificate_no,
          registration_application_status: values.registration_application_status,
          ctc_cac_1_1: values.ctc_cac_1_1,
          cac_2: values.cac_2,
          cac_7: values.cac_7,
        };
        if (user.type === 'clearing_agent') {
          payLoad.clearing_agent_uuid = user.uuid;
        } else {
          payLoad.forwarding_agent_uuid = user.uuid;
        }
        let formData = convertJsonToFormData(payLoad);

        POST(`/users/uploadUserDocs`, formData)
          .then((data) => {
            //console.log('data', data);
            setSubmitting(false);
            onDocumentUpdateSuccess();
            dispatch(getLoggedInUser());
            dispatch(showModal('Documents uploaded successfully', true));
            dispatch(hideLoading());
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
            setSubmitting(false);
          });
      }}
      validationSchema={agentSchema}
    >
      {(renderProps) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = renderProps;

        return (
          <form className="col-sm-9" onSubmit={handleSubmit}>
            <ClearingForwardingDocFields
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              details={details}
              totalShareVal={totalShareVal}
              rightOpen={rightOpen}
              editRight={editRight}
              images={images}
              showDoc={showDoc}
              deleteRight={deleteRight}
              userType={user.type}
              agentType={user.type}
            />
            <div>
              <button className="edit-model-button submitbut mt-5" type="submit">
                {isEditMode ? 'Update' : 'Save'}
              </button>
              {isEditMode && (
                <a className="next-model-button submitbut mt-5" onClick={handleNext}>
                  Next
                </a>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default VerficationModalForm;
