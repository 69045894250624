import React, { useState, useEffect } from 'react';
import { isEmpty, get } from 'lodash';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { getIn, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '../UI/TextField';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { getLoggedInUser } from '../../redux/user/UserActions';
import { GET, POST } from '../../services/rest.service';
import convertJsonToFormData, { agentSchema } from '../../helpers/helper';
import {
  getRegYearType,
  getTotalShareVal,
  extractUserDocs,
  // checkVeifymeVerified,
} from '../../helpers/utils';
import { PHONE_NUMBER_REGEX } from '../../helpers/constants';
import IndividualClientFormFields from '../global/ClientVerification/FormFields/Individual';
// import IncorporatedCompanyFormFields from '../global/ClientVerification/FormFields/IncorporatedCompany';
// import IncorporatedTrusteesFormFields from '../global/ClientVerification/FormFields/IncorporatedTrustees';
import AuthorizedRepForm from '../global/ClientVerification/AuthorizedRepForm';
// import FreeZoneEntityFormFields from '../global/ClientVerification/FormFields/FreeZoneEntity';
// import GovernmentEntityFormFields from '../global/ClientVerification/FormFields/GovernmentEntity';
// import PrivateEntityFormFields from '../global/ClientVerification/FormFields/PrivateEntity';
// import OTPFormField from '../global/ClientVerification/FormFields/OTP';
// import verifyMe from '../../services/verifyme.service';
import ClearingForwardingDocFields from '../global/ClearingForwardingFormDocFields';
import { showDocumentModal } from '../../redux/user/UserActions';
import DirectorShareholderForm from './DirectorShareholderForm';
import { useVerifyme } from '../../helpers/customHooks';

const UpdateDocuments = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [formValues, setFormValues] = useState({});
  const [shareValues, setShareValues] = useState({});
  const [type, setType] = useState('director');
  const [details, setDetails] = useState({});
  const [right, setRight] = useState(false);

  const [images, setImages] = useState({});

  const [id, setId] = useState('');
  const [isEdit, setisEdit] = useState(false);
  const [totalShareVal, setTotalShareVal] = useState(0);
  const [showAuthRepForm, setShowAuthRepForm] = useState(false);
  const [clientType, setClientType] = useState('');

  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  // initialize verifyme hook
  const {
    isDocVerified,
    showOTPField,
    verifyMeData,
    setIsDocVerified,
    setShowOTPField,
    setVerifyMeData,

    storeVerifyMeData,
    sendMsgOtp,
    verifyDocument,
    verifyUserOtp,
  } = useVerifyme(dispatch);

  //user details
  const getUserDetails = () => {
    dispatch(showLoading());
    if (user.uuid) {
      GET(`/users/getDetails/${user.uuid}`)
        .then(({ data }) => {
          // console.log(data.documents);

          // >> Verifyme Verification related
          // if (checkVeifymeVerified(data?.user)) {
          //   setIsDocVerified(true);
          // }

          if (data?.user?.phone_verified_at) {
            setIsDocVerified(true);
          }

          if (user.type == 'clearing_agent' || user.type == 'forwarding_agent') {
            // calculate totalShareholder percentage
            const totalShares = getTotalShareVal(data);
            setTotalShareVal(totalShares);
            if (!isEmpty(data.documents)) {
              const item = extractUserDocs(data);

              setDetails({
                ...data,
                documents: item,
              });

              setImages({
                ...images,
                incorporation_certificate: item.incorporation_certificate,
                memorandum_article_association: item.memorandum_article_association,
                corporate_address_proof: item.corporate_address_proof,
                registration_certificate: item.registration_certificate,
                registration_application_status: item.registration_application_status,
                ctc_cac_1_1: item.ctc_cac_1_1,
                cac_2: item.cac_2,
                cac_7: item.cac_7,
              });
              const modifiedItem = {
                ...item,
                incorporation_certificate: null,
                memorandum_article_association: null,
                corporate_address_proof: null,
                registration_certificate: null,
                registration_application_status: null,
                ctc_cac_1_1: null,
                cac_2: null,
                cac_7: null,
              };
              // console.log(modifiedItem);
              setFormValues(modifiedItem);
            } else {
              setDetails(data);
              setFormValues({});
            }
          } else {
            const item = extractUserDocs(data);
            setDetails({
              ...data,
              documents: item,
            });

            setImages({
              ...images,
              national_identity_card: item.national_identity_card,
              // incorporated company
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
              incorporation_certificate: item.incorporation_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,

              // Incorporated Trustees
              cac_it_1: item.cac_it_1,
              // corporate_address_proof: item.corporate_address_proof,

              // Free Zone Entity
              nepza_operating_license: item.nepza_operating_license,
              ctc_situation_notice: item.ctc_situation_notice,
              director_particulars: item.director_particulars,
              nepza_registration_certificate: item.nepza_registration_certificate,
              share_capital_statement: item.share_capital_statement,
              return_allotement_statement: item.return_allotement_statement,
              // memorandum_article_association: item.memorandum_article_association,

              // (Government Entity
              official_gazetted_establishment_law: item.official_gazetted_establishment_law,
              corporate_address: item.corporate_address,

              // Private Entity
              governement_approval_document: item.governement_approval_document,
              business_owner_document: item.business_owner_document,
            });
            const modifiedItem = {
              ...item,
              national_identity_card: null,
            };
            // console.log(modifiedItem);
            setFormValues({
              ...modifiedItem,
              user: data.user,
            });
          }

          // setJob(data.job);
          const uType = data.user.sub_type || data.user.type;
          setClientType(uType);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  };

  //useEffect
  useEffect(() => {
    getUserDetails();
  }, []);

  // next payment menthod choose step
  const rightOpen = (type) => {
    setRight(true);
    setType(type);
  };
  // right siderbar close function
  const rightClose = () => {
    setRight(false);
    setShareValues({});
    setId('');
    setisEdit(false);

    setIsDocVerified(false);
    setShowOTPField(false);
    setVerifyMeData({});
  };

  const editRight = (id, type) => {
    swal({
      title: `Edit ${type}?`,
      text: `Do you want to edit ${type}?`,
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        setisEdit(true);

        let filt = {};
        if (type === 'shareholder') {
          filt = details.shareholders.filter((obj) => obj.uuid == id);
        } else {
          filt = details.directors.filter((obj) => obj.uuid == id);
        }
        setType(type);
        setId(id);
        setShareValues(filt[0]);

        // >> Verifyme Verification related
        // if (checkVeifymeVerified(filt[0])) {
        //   setIsDocVerified(true);
        // }

        if (filt[0]?.phone_verified_at) {
          setIsDocVerified(true);
        }

        dispatch(hideLoading());
        setRight(true);
      }
    });
  };

  const deleteRight = (id, type) => {
    swal({
      title: `Delete ${type}?`,
      text: `Do you want to delete ${type}?`,
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      dispatch(showLoading());
      if (result) {
        if (type === 'shareholder') {
          POST(`/users/deleteShareholder`, { id: id })
            .then(({ data }) => {
              dispatch(showModal(`${type} delete successfully`, true));
              getUserDetails();
              dispatch(hideLoading());
            })
            .catch((err) => {
              dispatch(hideLoading());
            });
        } else {
          POST(`/users/deleteDirector`, { id: id })
            .then(({ data }) => {
              dispatch(showModal(`${type} delete successfully`, true));
              getUserDetails();
              dispatch(hideLoading());
            })
            .catch((err) => {
              dispatch(hideLoading());
            });
        }
      }
    });
  };

  const toggleAuthRepForm = () => {
    setShowAuthRepForm((state) => !state);
  };

  // console.log('formValues', formValues);
  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <section className="bodycont">
          <div className="">
            <h2>
              {user.type === 'application_client'
                ? 'Update Details'
                : user.type == 'clearing_agent' || user.type == 'forwarding_agent'
                ? 'Update Documents'
                : ''}
            </h2>

            <div className="personal-info">
              {user.type == 'clearing_agent' || user.type == 'forwarding_agent' ? (
                <Formik
                  initialValues={{
                    incorporation_certificate: '',
                    rc_no: get(formValues, 'rc_no', '') || '',
                    memorandum_article_association: '',
                    corporate_address_proof: '',
                    registration_certificate: '',
                    registration_certificate_no:
                      get(formValues, 'registration_certificate_no', '') || '',
                    registration_application_status: '',
                    ctc_cac_1_1: '',
                    cac_2: '',
                    cac_7: '',
                    shareholder:
                      (details.shareholders && get(details.shareholders[0], 'uuid', '')) || '',
                    director: (details.directors && get(details.directors[0], 'uuid', '')) || '',
                    reg_year: (details.documents && getRegYearType(details.documents)) || '',
                    userType: user.type,
                  }}
                  enableReinitialize
                  onSubmit={(values, { setSubmitting }) => {
                    dispatch(showLoading());
                    const payload = {
                      incorporation_certificate: values.incorporation_certificate,
                      rc_no: values.rc_no,
                      memorandum_article_association: values.memorandum_article_association,
                      corporate_address_proof: values.corporate_address_proof,
                      registration_certificate: values.registration_certificate,
                      registration_certificate_no: values.registration_certificate_no,
                      registration_application_status: values.registration_application_status,
                      ctc_cac_1_1: values.ctc_cac_1_1,
                      cac_2: values.cac_2,
                      cac_7: values.cac_7,
                      // port_of_operation: values.port_of_operation,
                    };
                    if (user.type == 'clearing_agent') {
                      payload.clearing_agent_uuid = user.uuid;
                    } else {
                      payload.forwarding_agent_uuid = user.uuid;
                    }

                    let formData = convertJsonToFormData(payload);

                    POST(`/users/uploadUserDocs`, formData)
                      .then((data) => {
                        //console.log('data', data);
                        dispatch(getLoggedInUser());
                        getUserDetails();
                        setSubmitting(false);
                        dispatch(showModal('Documents uploaded successfully', true));
                        dispatch(hideLoading());
                        const path =
                          user.type == 'clearing_agent'
                            ? '/clearing-agent/documents'
                            : '/forwarding-agent/documents';
                        props.history.push(path);
                      })
                      .catch((err) => {
                        dispatch(showModal(err.message));
                        dispatch(hideLoading());
                      });
                  }}
                  validationSchema={agentSchema}
                >
                  {(renderProps) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    } = renderProps;

                    // console.log('values, touched, errors', values, touched, errors);
                    return (
                      <form className={'row mx-15'} onSubmit={handleSubmit}>
                        <ClearingForwardingDocFields
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          handleChange={handleChange}
                          details={details}
                          totalShareVal={totalShareVal}
                          rightOpen={rightOpen}
                          editRight={editRight}
                          images={images}
                          showDoc={showDoc}
                          deleteRight={deleteRight}
                          userType={user.type}
                          agentType={user.type}
                        />
                        <div className="buttonsec">
                          <button
                            type="button"
                            className="submitbut btn-light"
                            onClick={() => props.history.goBack()}
                          >
                            Cancel
                          </button>
                          <button className="submitbut" type="submit">
                            Update
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              ) : (
                <>
                  {!isEmpty(formValues) && (
                    <Formik
                      initialValues={{
                        // Individual
                        mobile_number: get(formValues.user, 'mobile_number', '') || '',

                        nationality: get(formValues.user, 'nationality', '') || '',
                        dob: get(formValues.user, 'dob', '') || '',
                        reg_year: (formValues && getRegYearType(formValues)) || '',
                        address: get(formValues.user, 'address', '') || '',
                        // Incorporated company
                        rc_no: get(formValues, 'rc_no', '') || '',

                        // Incorporated trustee
                        cac_it_no: get(formValues, 'cac_it_no', '') || '',

                        // Free zone entity
                        nepza_no: get(formValues, 'nepza_no', '') || '',

                        // Government Entity & Private entity (share same text fields)
                        corporate_address: get(formValues, 'corporate_address', '') || '',
                        website_url: get(formValues, 'website_url', '') || '',
                        email: get(formValues, 'email', '') || '',
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        dispatch(showLoading());
                        try {
                          if (clientType === 'application_client') {
                            const payload = {
                              user_uuid: user.uuid,
                              dob: values.dob,
                              nationality: values.nationality,
                              identity_proof: values.identity_proof,
                              address: values.address,
                              mobile_number: values.mobile_number,
                            };

                            // console.log('CVM Individual payload',payload)

                            const response = await POST(
                              `/users/update`,
                              convertJsonToFormData(payload),
                            );
                            //console.log('resp', resp);
                            dispatch(showModal('Details Updated successfully', true));
                            dispatch(hideLoading());
                            setSubmitting(false);

                            // save verifymeData to our database
                            if (!isEmpty(verifyMeData)) {
                              await storeVerifyMeData(user.uuid, 'user');
                            }
                            props.history.goBack();
                          } else {
                            const payLoad = {
                              // individual
                              // national_identity_card: values.national_identity_card,
                              identity_proof: values.identity_proof,
                              mobile_number: values.mobile_number,

                              address: values.address,
                              // incorporated company
                              rc_no: values.rc_no,
                              registration_application_status:
                                values.registration_application_status,
                              ctc_cac_1_1: values.ctc_cac_1_1,
                              cac_2: values.cac_2,
                              cac_7: values.cac_7,
                              incorporation_certificate: values.incorporation_certificate,
                              memorandum_article_association: values.memorandum_article_association,
                              corporate_address_proof: values.corporate_address_proof,

                              // Incorporated trustee
                              cac_it_no: values.cac_it_no,
                              cac_it_1: values.cac_it_1,
                              // corporate_address_proof: values.corporate_address_proof,

                              // Free zone entity
                              nepza_no: values.nepza_no,
                              nepza_operating_license: values.nepza_operating_license,
                              ctc_situation_notice: values.ctc_situation_notice,
                              director_particulars: values.director_particulars,
                              nepza_registration_certificate: values.nepza_registration_certificate,
                              share_capital_statement: values.share_capital_statement,
                              return_allotement_statement: values.return_allotement_statement,
                              // memorandum_article_association: values.memorandum_article_association,

                              // Government Entity & Private entity (share same text fields)
                              corporate_address: values.corporate_address,
                              website_url: values.website_url,
                              email: values.email,

                              // Government Entity
                              official_gazetted_establishment_law:
                                values.official_gazetted_establishment_law,

                              // Private entity
                              governement_approval_document: values.governement_approval_document,
                              business_owner_document: values.business_owner_document,
                            };

                            // console.log('CVM', payLoad);

                            const formData = convertJsonToFormData(payLoad);

                            const response = await POST(`/users/uploadUserDocs`, formData);
                            // console.log('response', response);
                            dispatch(getLoggedInUser());
                            dispatch(showModal('Documents uploaded successfully', true));
                            dispatch(hideLoading());

                            if (!isEmpty(verifyMeData)) {
                              // save verifymeData to our database
                              await storeVerifyMeData(user.uuid, 'user');
                            }

                            setSubmitting(false);
                            props.history.goBack();
                          }
                        } catch (error) {
                          dispatch(showModal(error.message));
                          dispatch(hideLoading());
                          setSubmitting(false);
                        }
                      }}
                      validationSchema={Yup.object().shape({
                        mobile_number: Yup.string()
                          .required('Phone Number No Required')
                          .matches(
                            PHONE_NUMBER_REGEX,
                            'Enter Phone Number without Country Code and without Space',
                          ),
                      })}
                    >
                      {(renderProps) => {
                        const {
                          handleSubmit,
                          setFieldValue,
                          values,
                          handleBlur,
                          handleChange,
                          touched,
                          errors,
                        } = renderProps;

                        let showVerificationBtn = false;
                        if (
                          clientType === 'application_client' &&
                          values.dob &&
                          !isDocVerified
                          // && isEmpty(verifyMeData)  // >> Verifyme Verification related
                        ) {
                          showVerificationBtn = true;
                        }

                        return (
                          <form className={'row form-new mx-15'} onSubmit={handleSubmit}>
                            {/* Individual Client / application_client  */}
                            {clientType === 'application_client' && (
                              <IndividualClientFormFields
                                {...renderProps}
                                onPhoneNumberChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue('mobile_number', value);
                                  if (value) {
                                    const oldPhone = get(formValues.user, 'mobile_number');
                                    if (value !== oldPhone) {
                                      setIsDocVerified(false);
                                    } else if (value === oldPhone) {
                                      setIsDocVerified(true);

                                      // >> Verifyme Verification related
                                      // if (checkVeifymeVerified(details?.user)) {
                                      //   setIsDocVerified(true);
                                      // } else {
                                      //   setIsDocVerified(false);
                                      // }
                                    }
                                  } else {
                                    setIsDocVerified(false);
                                  }
                                }}
                                showVerificationBtn={showVerificationBtn}
                                showOTPField={showOTPField}
                                formValues={formValues}
                                verifyUserOtp={verifyUserOtp}
                                sendMsgOtp={sendMsgOtp}
                                verifyDocument={verifyDocument}
                              />
                            )}
                            {clientType !== 'application_client' && (
                              <div className="col-sm-12 col-lg-12 mb-5">
                                <button
                                  className="form-control submitbut"
                                  type="button"
                                  onClick={toggleAuthRepForm}
                                >
                                  Add / Update Particulars of Authorized Representative
                                </button>
                                {/* <span className="v2-text-label v2-text">Note: Add Authorized Representative details first.</span> */}
                              </div>
                            )}
                            {/* Corporate Type - InCorporated Company */}
                            {/* {clientType === 'incorporated_company' && (
                              <IncorporatedCompanyFormFields
                                {...renderProps}
                                images={images}
                                showDoc={showDoc}
                              />
                            )} */}

                            {/* RC Number needs to be Uploaded by Client */}
                            {clientType === 'incorporated_company' && (
                              <div className="col-sm-12 col-lg-12 mb-3">
                                <div className="text-left">
                                  <label className="v2-text-label v2-text">RC Number</label>
                                  <TextField
                                    id="rc_no"
                                    type="text"
                                    className="form-control"
                                    placeholder="RC Number"
                                    value={values.rc_no}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={getIn(touched, 'rc_no') && getIn(errors, 'rc_no')}
                                  />
                                </div>
                              </div>
                            )}

                            {/* Corporate Type - InCorporated Trustee */}
                            {/* {clientType === 'incorporated_trustee' && (
                              <IncorporatedTrusteesFormFields
                                {...renderProps}
                                images={images}
                                showDoc={showDoc}
                              />
                            )} */}

                            {/* Corporate Type - Free Zone Entity */}
                            {/* {clientType === 'free_zone_entity' && (
                              <FreeZoneEntityFormFields
                                {...renderProps}
                                images={images}
                                showDoc={showDoc}
                              />
                            )} */}

                            {/* Corporate Type - Government Entity*/}
                            {/* {clientType === 'government_entity' && (
                              <GovernmentEntityFormFields
                                {...renderProps}
                                images={images}
                                showDoc={showDoc}
                              />
                            )} */}

                            {/* Corporate Type - Private Entity created by Government Approval */}
                            {/* {clientType === 'government_approved_private_entity' && (
                              <PrivateEntityFormFields
                                {...renderProps}
                                images={images}
                                showDoc={showDoc}
                              />
                            )} */}

                            <div className="buttonsec">
                              <button
                                type="button"
                                className="submitbut btn-light"
                                onClick={() => props.history.goBack()}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="submitbut"
                                disabled={
                                  clientType === 'application_client' ? !isDocVerified : false
                                }
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      {right && (
        <DirectorShareholderForm
          isEdit={isEdit}
          toggle={rightClose}
          type={type}
          shareValues={shareValues}
          onSubmit={async (response) => {
            setShareValues({});
            setRight(false);
            setId('');
            setisEdit(false);

            // this condition is for saving the BVN/NIN verifyme data to our db
            if (!isEmpty(verifyMeData)) {
              let directorOrShareHolderId = '';
              if (type === 'director') {
                directorOrShareHolderId = response?.data?.director?.uuid;
              } else {
                directorOrShareHolderId = response?.data?.shareholder?.uuid;
              }
              let vmId = isEdit ? id : directorOrShareHolderId;
              await storeVerifyMeData(vmId, type);
            }

            // bvn/nin
            setIsDocVerified(false);
            setShowOTPField(false);
            setVerifyMeData({});

            getUserDetails();
          }}
          id={id}
          isDocVerified={isDocVerified}
          verifyMeData={verifyMeData}
          setIsDocVerified={setIsDocVerified}
          showDoc={showDoc}
          verifyDocument={verifyDocument}
          showOTPField={showOTPField}
          verifyUserOtp={verifyUserOtp}
          sendMsgOtp={sendMsgOtp}
        />
      )}
      {showAuthRepForm && (
        <AuthorizedRepForm
          showRightModal={showAuthRepForm}
          toggleAuthRepForm={toggleAuthRepForm}
          clientType={clientType}
          repValues={details.user}
          getUserDetails={getUserDetails}
        />
      )}
    </>
  );
};

export default UpdateDocuments;
