import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { POST } from '../../../services/rest.service';
import { showLoading, hideLoading } from '../../../redux/loader/LoaderActions';
import { showModal } from '../../../redux/error/ErrorAction';
import {
  getPaymentStatus,
  getPaymentClassName,
  displayAmount,
  getQueryParamValue,
} from '../../../helpers/utils';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';

const MyReceipts = (props) => {
  const dispatch = useDispatch();
  const [paymentList, setPaymentList] = useState([]);
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  useEffect(() => {
    dispatch(showLoading());
    POST('/payment/getAllPaymentReciept', {
      page: selectedPage,
      per_page: itemsPerPage,
    })
      .then(({ data }) => {
        // console.log(data);
        setTotalPages(data.totalRecords / itemsPerPage);
        setPaymentList(data.payment);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());

        dispatch(showModal(err.message));
      });
  }, [selectedPage, itemsPerPage]);

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>Transaction History</h2>
            <div className="tab-pane active" id="resp-tab7">
              <div className="table-responsive clearingtable">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th>Job ID</th>
                      <th>Job Type</th>
                      <th>Date of paid</th>
                      <th>Amount Paid</th>
                      <th>Channel Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentList.length > 0 &&
                      paymentList.map((obj, i) => (
                        <tr key={i}>
                          <td>{obj.job && obj.job.job_id}</td>

                          <td>
                            {obj.job &&
                              obj.job.job_type_tags.map((y, i) => (
                                <div key={i}>
                                  <p
                                    className={`${
                                      y == 'clearing' ? 'alert  alert-info' : 'alert  alert-success'
                                    }`}
                                  >
                                    {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                  </p>
                                </div>
                              ))}
                          </td>
                          <td>
                            <p>{moment(obj.created_at).format('DD/MM/YYYY')}</p>
                          </td>
                          {obj.channel === 'paypal' ? (
                            <td> &#x24;{parseFloat(obj.amount).toFixed(2)}</td>
                          ) : (
                            <td>{displayAmount(obj.amount)}</td>
                          )}

                          <td>
                            <div>
                              <p className="alert  alert-info">
                                {obj.channel ? capitalize(obj.channel) : 'N/A'}
                              </p>
                            </div>
                          </td>
                          <td>
                            <p className={`alert ${getPaymentClassName(obj.status)}`}>
                              {getPaymentStatus(obj.status)}
                            </p>
                          </td>
                          <td>
                            {obj.status == 1 && (
                              <Link
                                to={`/client/receipt/${obj.id}/details`}
                                className="review"
                                data-toggle="modal"
                                data-target=".bs-example-modal-lg"
                              >
                                View Details
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {paymentList.length <= 0 && <h3>No Payment Found!</h3>}
              </div>
              {paymentList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyReceipts;
