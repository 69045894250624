import React from 'react';
import { getIn } from 'formik';
import moment from 'moment';
import TextField from '../../../UI/TextField';
import PhoneNumber from '../../PhoneNumber';
import OTPFormField from '../../ClientVerification/FormFields/OTP';
import { VERIFYME_TYPE } from '../../../../helpers/constants';

const FormFields = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldTouched,
    onPhoneNumberChange,
    showVerificationBtn,
    showOTPField,
    verifyDocument,
    formValues,
    verifyUserOtp,
    sendMsgOtp,
  } = props;

  return (
    <>
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">Date Of Birth</label>
          <TextField
            id="dob"
            type="date"
            name="dob"
            className="form-control"
            max={moment().format('YYYY-MM-DD')}
            value={values.dob}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'dob') && getIn(errors, 'dob')}
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">Nationality</label>
          <TextField
            id="nationality"
            type="text"
            name="nationality"
            className="form-control"
            placeholder="Nationality"
            value={values.nationality}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'nationality') && getIn(errors, 'nationality')}
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left mb-3">
          <label className="v2-text-label v2-text">Phone Number</label>
          <PhoneNumber
            value={values.mobile_number}
            onChange={onPhoneNumberChange}
            onBlur={handleBlur}
            error={getIn(touched, 'mobile_number') && getIn(errors, 'mobile_number')}
          />
        </div>
        <div className="text-left mb-3">
          <label className="v2-text-label v2-text">Residential Address</label>
          <TextField
            id="address"
            type="text"
            className="form-control"
            placeholder="Residential Address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'address') && getIn(errors, 'address')}
          />
        </div>
      </div>
      {/* NIN/BVN/DL verification button */}
      {showVerificationBtn && !showOTPField && (
        <div className="col-sm-12 col-lg-12 mb-5">
          <button
            className="form-control submitbut"
            type="button"
            onClick={async () => {
              const sft = await setFieldTouched('mobile_number', true);
              if (!sft?.mobile_number) {
                verifyDocument(VERIFYME_TYPE, {
                  referenceId: values.mobile_number,
                  dob: moment(values.dob).format('DD-MM-YYYY'),
                  firstname: formValues.user.first_name,
                  lastname: formValues.user.last_name,
                });
              }
            }}
          >
            Verify Phone Number
          </button>
        </div>
      )}
      {/* NIN/BVN/DL verification OTP FIELD */}
      {showOTPField && (
        <div className="col-sm-12 col-lg-12 mb-5">
          <div className="text-left">
            <OTPFormField
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              phone={values.mobile_number}
              verifyUserOtp={(phone, otp) => verifyUserOtp(phone, otp, 'user')}
              resendOtp={sendMsgOtp}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FormFields;
