import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Collapse, Modal, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import Input from 'react-phone-number-input/input';
import TextField from '../../components/UI/TextField';
import FIleField from '../../components/UI/FIleField';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';
import convertJsonToFormData from '../../helpers/helper';
import { acceptedFileTypes } from '../../helpers/constants';
import Pagination from '../../components/global/Pagination';
import { usePagination } from '../../helpers/customHooks';

const APPLY_JOB = 'apply-job';
const UPLOAD_RESUME = 'upload-resume';

const Career = () => {
  const dispatch = useDispatch();
  const [cardId, setCardId] = useState([]);
  const [jobs, setJobs] = useState([]);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
  } = usePagination();

  const [applyJobModal, setApplyJobModal] = useState(false);
  const [jobId, setJobId] = useState('');
  const [actionType, setActionType] = useState('');

  const getJobsList = (page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    POST('/career/careers', payload)
      .then(({ data }) => {
        // console.log('data', data);
        setTotalPages(data.count / perPage);
        setJobs(data.career);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    getJobsList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  const toggle = (id) => {
    let tempCaardId = [...cardId];
    let index = tempCaardId.findIndex((x) => x === id);
    if (index > -1) {
      tempCaardId.splice(index, 1);
    } else {
      tempCaardId.push(id);
    }
    setCardId(tempCaardId);
  };

  const handleSelected = ({ selected }) => {
    // console.log('bids selected', selected);
    setSelectedPage(selected + 1);
  };

  const toggleModal = (id = '', type = '') => {
    setJobId(id);
    setActionType(type);
    setApplyJobModal((state) => !state);
  };

  const handleApplyJob = (id) => {
    toggleModal(id, APPLY_JOB);
  };

  const handleUploadResume = () => {
    toggleModal('', UPLOAD_RESUME);
  };

  const handlePageCountChange = (e) => {
    handleSelected({ selected: 0 });
    setItemsPerPage(e.target.value);
  };

  return (
    <div id="career-page">
      <Helmet>
        <title>GoAgent - Careers</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main">
        <section id="clering-goagent" className="clering-goagent">
          <div className="container">
            <h2>Careers</h2>
            <div className="clering-goagent-block career-block">
              <p>
                We are a team of diverse, professional and highly technical people, strongly bound
                by our mission, vision and core values. We are looking for people with excellent
                teamwork abilities, problem-solving skills and intellectual honesty. Join GoAgent
                and help us solve the problems we are trying to solve.
              </p>
            </div>
          </div>
        </section>
        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-12">
                <h3>Job Openings</h3>
                <div className="accordion accordion-flush" id="faqlist1">
                  {jobs.length > 0 &&
                    jobs.map((job, JIndex) => (
                      <div className="accordion-item" key={`job-${JIndex}`}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            onClick={() => toggle(JIndex)}
                          >
                            {job.title}
                          </button>
                        </h2>
                        <Collapse
                          isOpen={cardId.includes(JIndex)}
                          className="accordion-collapse collapse"
                        >
                          <div className="accordion-body">
                            <h5>Job Details:</h5>
                            <p>{job.details}</p>
                            <h5>Key Responsibilities:</h5>
                            <ul>
                              {job.responsibilities &&
                                job.responsibilities.length > 0 &&
                                job.responsibilities.map((rp, RPIndex) => (
                                  <li key={`responsibility-${RPIndex}`}>{rp}</li>
                                ))}
                            </ul>
                            <h5>Requirements:</h5>
                            <ul>
                              {job.requirements &&
                                job.requirements.length > 0 &&
                                job.requirements.map((rq, RQIndex) => (
                                  <li key={`requirement-${RQIndex}`}>{rq}</li>
                                ))}
                            </ul>
                            <button
                              type="button"
                              className="post-button"
                              onClick={() => handleApplyJob(job.uuid)}
                            >
                              Apply Now
                            </button>
                          </div>
                        </Collapse>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {jobs.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </section>

        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-12">
                <h3>Send Us Your CV</h3>
                <div className="send-cv">
                  <div className="row">
                    <div className="col-lg-8">
                      Attach your CV if you haven’t found any job openings you like or not qualified
                      for the available job openings.
                    </div>
                    <div className="col-lg-4">
                      <button type="button" className="upload-button" onClick={handleUploadResume}>
                        Upload Your CV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal isOpen={applyJobModal} toggle={() => toggleModal()} className="modal-xl">
        <div className="modal-header">
          <button type="button" className="close" onClick={() => toggleModal()}>
            &times;
          </button>
        </div>
        {actionType === APPLY_JOB && (
          <ModalBody>
            <h3 className="text-center">Job Application</h3>
            <br />
            <Formik
              initialValues={{ name: '', email: '', mobile: '', resume: '' }}
              validationSchema={Yup.object({
                name: Yup.string().required('Name is Required'),
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Email is Required'),
                mobile: Yup.string().required('Mobile is required'),
                resume: Yup.mixed().required('Resume is required'),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const payload = {
                  career_uuid: jobId,
                  ...values,
                };
                const formData = convertJsonToFormData(payload);

                dispatch(showLoading());
                setSubmitting(true);

                POST('/career/careerApply', formData)
                  .then(({ data }) => {
                    resetForm();
                    dispatch(showModal('Application Submitted succssefully!', true));
                    dispatch(hideLoading());
                    setSubmitting(false);
                    toggleModal();
                  })
                  .catch((err) => {
                    dispatch(showModal(err.message));
                    dispatch(hideLoading());
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form className={'row'} onSubmit={handleSubmit}>
                  <div className="col-sm-6">
                    <div className="text-left mb-5">
                      <label>Name:</label>
                      <TextField
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name*"
                        className="form-control"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'name') && getIn(errors, 'name')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="text-left mb-5">
                      <label>Email:</label>
                      <TextField
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email*"
                        className="form-control"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'email') && getIn(errors, 'email')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="text-left mb-5">
                      <label>Mobile:</label>
                      <Input
                        value={values.mobile}
                        id="mobile"
                        className={`form-control ${
                          getIn(touched, 'mobile') && getIn(errors, 'mobile') ? 'is-invalid' : ''
                        }`}
                        placeholder="Mobile*"
                        onBlur={handleBlur}
                        error={getIn(touched, 'mobile') && getIn(errors, 'mobile')}
                        onChange={(number) => {
                          setFieldValue('mobile', number);
                        }}
                      />
                      {touched.mobile && errors.mobile && (
                        <div className="invalid-feedback  mb-3  d-block">{errors.mobile}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="text-left mb-5">
                      <label>Resume:</label>
                      <FIleField
                        type="file"
                        accept={acceptedFileTypes}
                        className={` ${
                          getIn(touched, 'resume') && getIn(errors, 'resume')
                            ? 'Upload is-invalid'
                            : 'Upload'
                        }`}
                        name="resume"
                        onChange={(event) => setFieldValue('resume', event.target.files[0])}
                      />
                      {getIn(touched, 'resume') && getIn(errors, 'resume') && (
                        <div className="invalid-feedback mb-3">
                          {getIn(touched, 'resume') && getIn(errors, 'resume')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 text-center">
                    <button type="submit" className="post-button" disabled={isSubmitting}>
                      Apply
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </ModalBody>
        )}
        {actionType === UPLOAD_RESUME && (
          <ModalBody>
            <h3 className="text-center">Upload Resume</h3>
            <br />
            <Formik
              initialValues={{ resume: '' }}
              validationSchema={Yup.object({
                resume: Yup.mixed().required('Resume is required'),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = convertJsonToFormData(values);

                dispatch(showLoading());
                setSubmitting(true);

                POST('/career/careerApply', formData)
                  .then(({ data }) => {
                    resetForm();
                    dispatch(showModal('Resume Uploaded succssefully!', true));
                    dispatch(hideLoading());
                    setSubmitting(false);
                    toggleModal();
                  })
                  .catch((err) => {
                    dispatch(showModal(err.message));
                    dispatch(hideLoading());
                    setSubmitting(false);
                  });
              }}
            >
              {({ touched, errors, handleSubmit, setFieldValue, isSubmitting }) => (
                <form className={'row'} onSubmit={handleSubmit}>
                  <div className="col-sm-6">
                    <div className="text-left mb-5">
                      <label>Resume:</label>
                      <FIleField
                        type="file"
                        accept={acceptedFileTypes}
                        className={` ${
                          getIn(touched, 'resume') && getIn(errors, 'resume')
                            ? 'Upload is-invalid'
                            : 'Upload'
                        }`}
                        name="resume"
                        onChange={(event) => setFieldValue('resume', event.target.files[0])}
                      />
                      {getIn(touched, 'resume') && getIn(errors, 'resume') && (
                        <div className="invalid-feedback mb-3">
                          {getIn(touched, 'resume') && getIn(errors, 'resume')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <button type="submit" className="post-button" disabled={isSubmitting}>
                      Upload
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </ModalBody>
        )}
      </Modal>
      <PublicFooter />
    </div>
  );
};

export default Career;
