import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { Rating } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import TextareaInput from '../UI/TextareaInput';
import TextField from '../../components/UI/TextField';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';

const FeedbackModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.user);

  let showEmailField = true;
  if (isLoggedIn) {
    showEmailField = false;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-md suspension-modal feedback-modal"
      container="header"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="text-center">
          <h3>👋 Hey! Help us improve by sharing your feedback</h3>
          <Formik
            initialValues={{
              rating: '',
              comment: '',
              email: user?.email || '',
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log({ values });
              dispatch(showLoading());
              setSubmitting(true);
              const payload = {
                // username: 'Saurabh Suman',
                // mobile: '98989898',
                rating: values.rating,
                comment: values.comment,
              };
              if (values.email) payload.email = values.email;

              POST('/feedback/store', payload)
                .then(({ data }) => {
                  resetForm();
                  dispatch(showModal('Success!, Thank you for your valuable feedback', true));
                  dispatch(hideLoading());
                  setSubmitting(false);
                  toggle();
                })
                .catch((err) => {
                  dispatch(showModal(err.message));
                  dispatch(hideLoading());
                  setSubmitting(false);
                });
            }}
            validationSchema={Yup.object().shape({
              rating: Yup.string()
                .nullable()
                .required('Rating is Required'),
              comment: Yup.string().trim(),
              email: Yup.string()
                .trim()
                .required('Email is Required'),
            })}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleChange,
              } = renderProps;

              // console.log({ values, touched, errors });
              return (
                <form
                  className={'row justify-content-center mt-5'}
                  id="feedbackform"
                  onSubmit={handleSubmit}
                >
                  <div className="col-lg-12 col-sm-12">
                    <label>How would you rate our website?</label>
                    <br />
                    <Rating
                      style={{ fontSize: 50, margin: 10 }}
                      value={values.rating}
                      onChange={(event, newValue) => {
                        setFieldValue('rating', newValue);
                      }}
                    />
                    {errors.rating && (
                      <div className="invalid-feedback mb-3 d-block">{errors.rating}</div>
                    )}
                  </div>
                  {showEmailField && (
                    <div className="col-lg-8 col-sm-8">
                      <TextField
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'email') && getIn(errors, 'email')}
                      />
                    </div>
                  )}
                  <div className="col-lg-8 col-sm-8">
                    <TextareaInput
                      id="message"
                      name="comment"
                      placeholder="Please provide comments if you have any"
                      cols="40"
                      rows="6"
                      className="form-control"
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={getIn(touched, 'comment') && getIn(errors, 'comment')}
                    />
                  </div>
                  <div className="px-3">
                    <button type="submit" className="feedback-submitbut">
                      Send Feedback
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FeedbackModal;
