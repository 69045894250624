import React from 'react';
import { useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';
import userImage from '../../../../assets/img/placeHolderImage.png';
import config from '../../../../config';
import { getAccountStatus } from '../../../helpers/utils';

const Profile = (props) => {
  const user = useSelector((state) => state.user.user);
  // console.log(user);
  return (
    <>
      <div id="tabblock2" className="tabcontent">
        <div className="bodycont">
          {/* <h2>My Profile</h2> */}
          <div className="personal-info ">
            <div className="profilesec">
              <div className="profileimage">
                <img
                  src={
                    get(user, 'avatar')
                      ? `${config.api.URL.replace('/api/v1', '')}/${get(user, 'avatar')}`
                      : userImage
                  }
                  alt=""
                  height="170px"
                  weight="170px"
                />
              </div>
              {user.company_name ? capitalize(user.company_name) : 'N/A'}
            </div>

            <h3>Account Information</h3>

            <div className="profilecont">
              <div className="profilecontleft">
                <p>
                  <strong>Name:</strong> {user.company_name ? capitalize(user.company_name) : 'N/A'}
                </p>
                <p>
                  <strong>Email:</strong> {user.email || 'N/A'}
                </p>
                <p>
                  <strong>Mobile:</strong> {user.mobile_number || 'N/A'}
                </p>
              </div>
              <div className="profilecontright">
                <p>
                  <strong>Address</strong>
                  <br />
                  {user.company_address || 'N/A'}
                </p>
                <p>
                  <strong>Account Status:</strong>
                  <br />
                  {getAccountStatus(user)}
                </p>
              </div>
            </div>

            <div className="buttonsec">
              <button
                onClick={() => {
                  user.type === 'clearing_agent'
                    ? props.history.push('/clearing-agent/profile/update-password')
                    : props.history.push('/forwarding-agent/profile/update-password');
                }}
                className="submitbut"
              >
                Change Password
              </button>
              <button
                onClick={() => {
                  user.type === 'clearing_agent'
                    ? props.history.push('/clearing-agent/edit-profile')
                    : props.history.push('/forwarding-agent/edit-profile');
                }}
                className="submitbut"
              >
                Edit Profile
              </button>
              <button
                onClick={() => {
                  user.type === 'clearing_agent'
                    ? props.history.push('/clearing-agent/documents')
                    : props.history.push('/forwarding-agent/documents');
                }}
                className="submitbut"
              >
                Corporate Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
