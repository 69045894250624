import { Formik, getIn } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { object, string } from 'yup';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import TextField from '../../components/UI/TextField';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { url } = props.match;

  if (get(user, 'isLoggedIn', false)) {
    return <Redirect from={url} to={'/'} />;
  }
  return (
    <div id="client-page">
      <Helmet>
        <title>GoAgent - Forgot Password</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main" className="main-block">
        <section className="container">
          <section className="bodycont" style={{marginTop: 100, padding: '0 0 35px 0'}}>
            <div className="container">
              <h2>Forgot Password</h2>
              <div className="logindiv">
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    dispatch(showLoading());
                    POST('auth/password/email', values)
                      .then(({ data }) => {
                        dispatch(showModal('Reset mail sent successfully, please follow the instructions in mail to reset your password', true));
                        setSubmitting(false);
                        dispatch(hideLoading());
                        resetForm({});
                      })
                      .catch((err) => {
                        setSubmitting(false);
                        dispatch(hideLoading());
                        dispatch(showModal(err.message));
                      });
                  }}
                  validationSchema={object().shape({
                    email: string()
                      .email('Enter a valid email')
                      .required('Email is required'),
                  })}
                >
                  {(renderProps) => {
                    const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting } = renderProps;
                    return (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          id="email"
                          type="email"
                          className="form-control "
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'email') && getIn(errors, 'email')}
                        />

                        <button type="submit" disabled={isSubmitting} className="submitbut">
                          Submit
                        </button>
                        <button type="submit" onClick={() => props.history.push('/login')} className="submitbut">
                          Back to Login
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </section>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
export default ForgotPassword;
