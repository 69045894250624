import React, { Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Formik, getIn } from 'formik';
import TextField from '../../components/UI/TextField';
import { object, string } from 'yup';

const RejectModal = ({ isOpen, toggle, container, onSubmit }) => (
  <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-md">
    <div className="modal-header">
      <button type="button" className="close" onClick={toggle}>
        &times;
      </button>
    </div>
    <ModalBody>
      <div className="reson-reject nopadding-sm">
        <h5>Rejection</h5>
        <Formik
          initialValues={{
            comment: '',
          }}
          onSubmit={onSubmit}
          validationSchema={object().shape({
            comment: string().required('Comment is required'),
          })}
        >
          {(renderProps) => {
            const { values, touched, errors, handleChange, handleBlur, handleSubmit } = renderProps;

            return (
              <form className={'row'} onSubmit={handleSubmit}>
                <Fragment>
                  <div className="col-sm-12 text-left">
                    <label>Comment:</label>
                    <TextField
                      type="text"
                      className="form-control"
                      placeholder="Comment"
                      value={values.comment}
                      name="comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={getIn(touched, 'comment') && getIn(errors, 'comment')}
                    />
                  </div>
                </Fragment>

                <div className="buttonsec1 nopadding" style={{ textAlign: 'center' }}>
                  <button type="submit" className="submitbut">
                    Reject
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </ModalBody>
  </Modal>
);

export default RejectModal;
