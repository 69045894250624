import { POST } from '../../services/rest.service';
import { showModal } from '../error/ErrorAction';
import { getLoggedInUser } from '../user/UserActions';

export const readNotification = (payload) => {
  return (dispatch, getState) => {
    const { user } = getState();
    // console.log('>> readNotification user >> ', user);
    const { notificationTypes } = payload;
    // console.log('>> readNotification notificationTypes >> ', notificationTypes);
    const notifications = user?.user?.unread_notifications || [];

    if (notifications?.length > 0) {
      const notificationsToClear = [];
      if (notifications?.length > 0) {
        // console.log('>> readNotification notifications >> ', notifications);
        notifications.forEach((item) => {
          if (item.type) {
            const ntypes = item.type.split('\\');
            // App\\Domains\\Bid\\Notifications\\BidCreatedNotification
            // 0      1       2       3               4
            // after splitting item.type at '\\'
            // we are comparing the 4th string for deciding type
            if (ntypes?.length > 0 && notificationTypes?.find((x) => x === ntypes[4])) {
              notificationsToClear.push(item.id);
            }
          }
        });
      }
      // console.log('>> readNotification notificationsToClear >> ', notificationsToClear);
      if (notificationsToClear.length > 0) {
        POST(`/users/markAsReadNotification`, { notification_id: notificationsToClear })
          .then(({ data }) => {
            dispatch(getLoggedInUser());
          })
          .catch((err) => {
            dispatch(showModal(err.message));
          });
      }
    }
  };
};
