import { Formik, getIn } from 'formik';
import { get, isEmpty, isUndefined } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { object, ref, string } from 'yup';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import { PASSWORD_REGEX } from '../../helpers/constants';
import PasswordField from '../../components/global/Password';

const ResetPassword = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { url } = props.match;

  const [isInvalidRoute, setIsInvalidRoute] = useState(false);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const queryParams = props.location.search;
    const { email, token } = queryString.parse(queryParams);

    if ((isEmpty(token) || isUndefined(token)) && (isEmpty(email) || isUndefined(email))) {
      setIsInvalidRoute(false);
    } else {
      setToken(token);
      setEmail(email);
    }
  }, []);

  if (get(user, 'isLoggedIn', false)) {
    return <Redirect from={url} to={'/'} />;
  }
  if (isInvalidRoute) {
    return <Redirect from={`${url}`} to={'/'} />;
  }

  return (
    <div id="client-page">
      <Helmet>
        <title>GoAgent - Reset Password</title>
      </Helmet>
      <PublicHeader />
      <main id="main" className="main-block">
        <section className="container">
          <section className="bodycont" style={{ paddingTop: '130px' }}>
            <div className="container">
              <h2>Reset Password</h2>

              <div className="payment-info">
                <h3>Create New Password</h3>

                <Formik
                  initialValues={{ password: '', confirm_password: '' }}
                  onSubmit={(values, { setSubmitting }) => {
                    const payload = {
                      email: email,
                      password: values.password,
                      password_confirmation: values.confirm_password,
                      token: token,
                    };
                    dispatch(showLoading());
                    POST(`/auth/password/reset`, payload)
                      .then(({ data }) => {
                        dispatch(showModal('Password set successfully!', true));
                        dispatch(hideLoading());
                        props.history.push('/login');
                      })
                      .catch((err) => {
                        let errMsg =
                          err.message?.firstNameInPassword ||
                          err.message?.lastNameInPassword ||
                          err.message?.companyNameInPassword ||
                          err.message ||
                          '';
                        dispatch(showModal(errMsg || 'Something went wrong!'));
                        dispatch(hideLoading());
                        setSubmitting(false);
                      });
                  }}
                  validationSchema={object().shape({
                    password: string()
                      .matches(
                        PASSWORD_REGEX,
                        'Password must contain minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                      )
                      .required('Enter your password'),
                    confirm_password: string()
                      .required('Please confirm your password')
                      .oneOf([ref('password')], 'Passwords does not match'),
                  })}
                >
                  {(renderProps) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    } = renderProps;

                    return (
                      <form className={'row'} onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-sm-6 text-left">
                            <PasswordField
                              id="password"
                              placeholder="New Password"
                              password={values.password}
                              showPassword={values.showPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              toggleShowPw={() =>
                                setFieldValue('showPassword', values.showPassword ? false : true)
                              }
                              error={getIn(touched, 'password') && getIn(errors, 'password')}
                            />
                          </div>
                          <div className="col-sm-6 text-left">
                            <PasswordField
                              id="confirm_password"
                              placeholder="Confirm New Password"
                              password={values.confirm_password}
                              showPassword={values.showCP}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              toggleShowPw={() =>
                                setFieldValue('showCP', values.showCP ? false : true)
                              }
                              error={
                                getIn(touched, 'confirm_password') &&
                                getIn(errors, 'confirm_password')
                              }
                            />
                          </div>
                          <div className="col-sm-12">
                            <div className="buttonsec">
                              <button
                                className="submitbut"
                                style={{ textAlign: 'center' }}
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Set Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </section>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
export default ResetPassword;
