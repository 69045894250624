import React from 'react';
// import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
/* HELPERS */
// import history from './app/helpers/history';
/* COMPONENTS */
import App from './App';
import ScrollToTop from './app/components/global/ScrollToTop';
/* VENDOR */

import './vendor';

// React-Redux store
import store from './app/redux/store';
import { hydrate, render } from 'react-dom';

const root = (
  <Provider store={store}>
    <Router>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </Provider>
);

const rootElement = document.getElementById('root');
// ReactDOM.render(root, rootElement);
if (rootElement.hasChildNodes()) {
  hydrate(root, rootElement);
} else {
  render(root, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
