import React from 'react';
import { Helmet } from 'react-helmet';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import serviceImg3 from '../../../assets/img/service-img3.jpg';
import serviceImg2 from '../../../assets/img/service-img2.jpg';
import AccountabilityImg from '../../../assets/img/accountability.jpg';
import InnovationImg from '../../../assets/img/innovation.jpg';
import CustomerOrientedImg from '../../../assets/img/customer-oriented.jpg';
import ProfessionalismImg from '../../../assets/img/professionalism.jpg';
import StakeholderEngagementImg from '../../../assets/img/goagent_team.jpg';
// import portVideo from '../../../assets/video/aboutvideo.mp4';

const AboutUs = () => {
  return (
    <div id="home-page">
      <Helmet>
        <title>GoAgent - About GoAgent</title>
      </Helmet>

      <div id="home-page">
        <div className="video-banner1">
          <div className="video-banner">
            <video className="about-goagent-video" autoPlay={'autoplay'} muted loop>
              {/* <source src={portVideo} type="video/mp4" /> */}
            </video>
          </div>
        </div>
      </div>
      <div className="website-home">
        <PublicHeader />
      </div>
      <section id="about-goagent" className="about-goagent">
        <div className="container">
          <h2>About GoAgent</h2>
          <div className="about-goagent-block">
            <h3>In a Nutshell</h3>
            <p>
              GoAgent is a tech-enabled platform built to connect individual and corporate importers
              with verified clearing agents and transporters around the clock, including weekends
              and public holidays.
            </p>
          </div>
          <div className="about-goagent-block">
            <h3>Our Story</h3>
            <p>
              Before GoAgent, clearing and transporting imported goods in Nigeria was challenging,
              and required a complex set of skills, which only a few importers had. We knew things
              could be done safer, better, faster and cheaper; and so we engaged the key players and
              stakeholders in the clearing and forwarding industry. Having identified the gaps and
              pain points of the port ecosystem, a team of young, technology-driven shipping
              logistics professionals launched the GoAgent platform in 2020. The platform addressed
              every stakeholder’s concern and erased the pains and frustrations of the past.
            </p>
            <p>
              Built upon a secure, reliable and efficient technology, GoAgent offers individual and
              corporate importers all the solutions they need to clear and transport their goods,
              safe and secure to their door steps.
            </p>
            <p>
              While we’re proud of our work so far, we continue to invest in innovation, networking
              and team-spirit to further simplify and ensure transparency in the Nigeria’s clearing
              and forwarding industry.
            </p>
            <p>
              <i>
                The solutions we provide give us purpose; our happy customers give us assurance; and
                our vision keeps us going every single day!
              </i>
            </p>
          </div>
        </div>
      </section>
      <main id="main" className="inner-about-block">
        <section id="inner-about" className="inner-about">
          <div className="container">
            <div className="row vision" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 padding-right1">
                <img src={serviceImg3} width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Our Vision</h3>
                    <p>
                      To become the leading platform for the delivery of shipping, clearing and
                      transport services in Africa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mission" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Our Mission</h3>
                    <p>
                      Using innovative technology to make import clearance and transport easy,
                      transparent and affordable for everyone.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 padding-left1">
                <img src={serviceImg2} width="100%" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section id="our-values" className="our-values">
          <div className="container">
            <h4>Our Values</h4>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6" style={{ padding: 0, marginLeft: '-15px' }}>
                <img src={CustomerOrientedImg} className="round-img" width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Customer Oriented</h3>
                    <p>
                      We are committed to continuously identifying the needs and requirements of our
                      internal and external customers, and we take the required action to meet the
                      needs effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 services-block1-1">
                <div className="services-block">
                  <div>
                    <h3>Accountability</h3>
                    <p>
                      We assume responsibility for the delivery of high quality outcomes by honoring
                      our obligations to all stakeholders.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6" style={{ padding: '0' }}>
                <img src={AccountabilityImg} className="round-img" width="100%" alt="" />
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6" style={{ padding: 0, marginLeft: '-15px' }}>
                <img src={InnovationImg} className="round-img" width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Innovation</h3>
                    <p>
                      We constantly seek better ways to deliver service and create value in every
                      area of the business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 services-block1-1">
                <div className="services-block">
                  <div>
                    <h3>Professionalism</h3>
                    <p>
                      We exercise high level of expertise and discipline in achieving the company’s
                      strategic objectives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6" style={{ padding: '0' }}>
                <img src={ProfessionalismImg} width="100%" className="round-img" alt="" />
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6" style={{ padding: 0, marginLeft: '-15px' }}>
                <img src={StakeholderEngagementImg} width="100%" className="round-img" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Stakeholder Engagement</h3>
                    <p>
                      We identify, engage and manage the competing needs of all stakeholder-groups
                      for the betterment of the clearing and forwarding industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <PublicFooter />
    </div>
  );
};

export default AboutUs;
