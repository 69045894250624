import React from 'react';

const PaymentStepsDisplay = ({ step, showInsuranceStep }) => {
  const steps = showInsuranceStep ? (
    <>
      <li className={`${step === 1 ? 'active' : ''}`}>
        <span>1</span>
        <p>Insurance</p>
      </li>
      <li className={`${step === 2 ? 'active' : ''}`}>
        <span>2</span>
        <p>Payment Details</p>
      </li>
      <li className={`${step === 3 ? 'active' : ''}`}>
        <span>3</span>
        <p>Payment Method</p>
      </li>
      <li className={`${step === 4 ? 'active' : ''}`}>
        <span>4</span>
        <p>Finish</p>
      </li>
    </>
  ) : (
    <>
      <li className={`${step === 1 ? 'active' : ''}`}>
        <span>1</span>
        <p>Payment Details</p>
      </li>
      <li className={`${step === 2 ? 'active' : ''}`}>
        <span>2</span>
        <p>Payment Method</p>
      </li>
      <li className={`${step === 3 ? 'active' : ''}`}>
        <span>3</span>
        <p>Finish</p>
      </li>
    </>
  );
  return (
    <div className="paymentstep">
      <ul className="nopadding nomargin">{steps}</ul>
    </div>
  );
};

export default PaymentStepsDisplay;
