import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { GET } from '../../services/rest.service';
import config from '../../../config';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';

const BlogListing = () => {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    dispatch(showLoading());
    // fetch
    GET('/blogs/index', { sort_type: 'desc', sort_by: 'created_at' })
      .then(({ data }) => {
        setBlogs(data.blogs);
        dispatch(hideLoading());
      })
      .catch((err) => console.log('error fetching blogs'));
  }, []);

  return (
    <div id="home-page">
      <Helmet>
        <title>GoAgent - Blogs</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main">
        <section id="blog" className="payment-policy">
          <div className="container">
            <h2>Blogs</h2>
            <div className="row" style={{ marginTop: 20 }}>
              {blogs &&
                blogs.length > 0 &&
                blogs.map((blog) => (
                  <div className="col-sm-4" key={blog.uuid}>
                    <div className="blog-section">
                      <img src={`${config.api.URL.replace('/api/v1', '')}/${blog.image}`} width="100%" alt={blog.title} />
                      <h5>{blog.title}</h5>
                      <span>
                        By - {blog.created_by}, {moment(blog.created_at).format('Do MMMM YYYY')}
                      </span>
                      <br />
                      <p>{blog.description.length > 100 ? blog.description.substring(0, 100) : blog.description}</p>
                      <Link to={`/blog/${blog.uuid}`}>Read More...</Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};

export default BlogListing;
