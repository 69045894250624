import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Public Routes
import AboutUs from './app/containers/PublicWebsite/AboutUs';
import BlogDetails from './app/containers/PublicWebsite/BlogDetails';
import BlogListing from './app/containers/PublicWebsite/BlogListing';
import Career from './app/containers/PublicWebsite/Career';
import ContactUs from './app/containers/PublicWebsite/ContactUs';
import ForgotPassword from './app/containers/PublicWebsite/ForgotPassword';
import Home from './app/containers/PublicWebsite/Home';
import Login from './app/containers/PublicWebsite/Login';
import PaymentPolicy from './app/containers/PublicWebsite/PaymentPolicy';
import PrivacyPolicy from './app/containers/PublicWebsite/PrivacyPolicy';
import Registration from './app/containers/PublicWebsite/Registration';
import ResetPassword from './app/containers/PublicWebsite/ResetPassword';
import TermsConditions from './app/containers/PublicWebsite/TermsConditions';
import ClearingLanding from './app/containers/PublicWebsite/ClearingLanding';
import ForwardingLanding from './app/containers/PublicWebsite/ForwardingLanding';
import ImpoterLanding from './app/containers/PublicWebsite/ImpoterLanding';
import Faq from './app/containers/PublicWebsite/Faq';
import Webinar from './app/containers/PublicWebsite/Webinar';

// Private routes
import ClearingAgentRoute from './app/routing/ClearingAgentRoute';
import ClientRoute from './app/routing/ClientRoute';
import ForwardingAgentRoute from './app/routing/ForwardingAgentRoute';
import LawyerRoute from './app/routing/LawyerRoute';
import OperatorRouting from './app/routing/OperatorRouting';
import SupervisorRouting from './app/routing/SupervisorRouting';
import TruckInspectorRouting from './app/routing/TruckInspectorRouting';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem('_GATKEN') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const Router = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/career" component={Career} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/payment-policy" component={PaymentPolicy} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/faq" component={Faq} />
      {/* <Route exact path="/services" component={Services} /> */}
      <Route exact path="/terms-and-conditions" component={TermsConditions} />
      <Route exact path="/blog" component={BlogListing} />
      <Route exact path="/blog/:slug" component={BlogDetails} />
      <Route exact path="/clearing-agents-landing" component={ClearingLanding} />
      <Route exact path="/forwarding-agents-landing" component={ForwardingLanding} />
      <Route exact path="/importers-landing" component={ImpoterLanding} />
      <Route exact path="/webinar" component={Webinar} />

      {/* Login and Registration */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />

      <Route exact path="/registration" component={Registration} />
      <Route exact path="/reset-password" component={ResetPassword} />
      {/* Client Routes */}
      <PrivateRoute path="/client" component={ClientRoute} />

      {/* Clearing Agent */}
      <PrivateRoute path="/clearing-agent" component={ClearingAgentRoute} />

      {/* Forwarding Agent */}
      <PrivateRoute path="/forwarding-agent" component={ForwardingAgentRoute} />

      {/* Lawyer */}
      <PrivateRoute path="/lawyer" component={LawyerRoute} />

      {/* Supervisor */}
      <PrivateRoute path="/supervisor" component={SupervisorRouting} />

      {/* Truck Inspector */}
      <PrivateRoute path="/truck-inspector" component={TruckInspectorRouting} />

      {/* Operator */}
      <PrivateRoute path="/operator" component={OperatorRouting} />
      <Redirect from={props?.match?.url} to={`/`} />
    </Switch>
  );
};

export default Router;
