import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import AppLoader from '../../../components/loaders/AppLoader';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import Chat from '../../../components/UI/Chat';
import { displayAmount } from '../../../helpers/utils';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import DriverBehaviour from '../../../components/global/DriverBehaviour';
import { DATE_TIME_FORMAT } from '../../../helpers/constants';
import { showSuspensionModal } from '../../../redux/user/UserActions';
import {
  generateHarshDrivingReport,
  getHarshDrivingReportStatus,
  getHarshDrivingReport,
} from '../../../services/navixy.service';
import DisplayDocument from '../../../components/global/DisplayDocument';
import NavixyWebLocator from '../../../components/global/NavixyWebLocator';
import FeatureNotAvailable from '../../../components/Modals/FeatureNotAvailable';
import { useChat } from '../../../helpers/customHooks';
import FwJobCompleteConfirm from '../../../components/Modals/FwJobCompletedConfirmModal';
import ClearingCargoStatus from '../../../components/global/ClearingCargoStatus';
import ClientVerificationModal from '../../../components/Modals/ClientVerificationModal';
import ClientRegSuccessModal from '../../../components/Modals/ClientRegSuccessModal';
import DocumentsVerifyingMsg from '../../../components/Modals/DocumentsVerifyingMsg';

const ClientJobDetails = (props) => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const params = new URLSearchParams(props.location.search).get('id');
  const job_id = params;
  const serviceData = useSelector((state) => state.enumData);
  const [chatbox, setChatbox] = useState(false);
  const [chatlist, setChatlist] = useState([]);
  const [msg, setMsg] = useState('');
  const { user } = useSelector((state) => state.user);

  const [trackerId, setTrackerId] = useState(null);
  const [showFeatureNA, setShowFeatureNA] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  // used when forwarding job is completed by FW agent & show confirm modal to importer
  const [showFwJobCompletedModal, setShowFwJobCompletedModal] = useState(false);

  const [verficationModal, setVerificationModal] = useState(false);
  const toggleVerificationModal = () => setVerificationModal((s) => !s);
  const [documentVerifyingModal, setDocumentVerifyingModal] = useState(false);
  const toggleDocumentVerifyingModal = () => setDocumentVerifyingModal((s) => !s);

  // console.log(user);
  //get Job Deta ils
  const jobDetails = async () => {
    try {
      dispatch(showLoading());
      const jobRes = await POST(`/jobs/getClearingCargoStatus`, { job_uuid: job_id });
      // console.log('>> jobRes', jobRes);
      setDetails(jobRes?.data || {});
      if (jobRes?.data?.job?.truck_uuid) {
        if (jobRes.data.job.completed_at && !jobRes.data.job.forwarding_completed_at)
          setShowFwJobCompletedModal(true);
        const truckDataResp = await POST(`/trucks/getDetails`, {
          truck_uuid: jobRes.data.job.truck_uuid,
        });
        // console.log('>>. truckDataResp', truckDataResp)
        const trackerid = truckDataResp?.data?.truck?.tracker_id;
        if (trackerid) setTrackerId(trackerid);
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    jobDetails();
  }, []);

  // initialize and subcsribe to chat here
  const handleChatEvent = (data1) => {
    if (data1.chat_type === 'client') {
      if (!chatbox) {
        // set unread count when chat is not openend
        setUnreadCount((prevState) => prevState + 1);
      }

      setChatlist((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById('chat-top');
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    dispatch(showModal('you have a new message', true));
  };
  useChat(user.uuid, chatbox, handleChatEvent);

  //chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${job_id}`)
      .then(({ data }) => {
        // console.log(data);
        const oc = data.chats.operator?.length > 0 ? data.chats.operator.reverse() : [];
        setChatlist(oc);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const chatToggle = () => {
    if (!chatbox) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount(0);
    }
    setChatbox(!chatbox);

    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }
    const payLoad = {
      job_uuid: job_id,
      sender_uuid: user.uuid,
      receiver_uuid: details.job.operator_uuid,
      chat_type: 'client',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        setChatlist((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
        // getChat();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
      // const objDiv = document.getElementById('chat-top');
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };

  //end chat code

  // driver behaviour
  const [harshDrivingData, setHarshDrivingData] = useState({});

  const fetchHarshDrivingData = async (trackerId) => {
    try {
      dispatch(showLoading());
      // harsh driving
      const fromDate =
        details?.forwardingBid?.assigned_at ||
        moment()
          .startOf('day')
          .format(DATE_TIME_FORMAT);
      const toDate =
        details?.job?.forwarding_completed_at ||
        moment()
          .endOf('day')
          .format(DATE_TIME_FORMAT);
      const harshDriving = await generateHarshDrivingReport({
        trackerId: trackerId,
        from: fromDate,
        to: toDate,
      });
      // console.log('>> harshDriving',harshDriving);

      if (harshDriving?.success && harshDriving?.id) {
        // adding a one second delay for now
        await new Promise((r) => setTimeout(r, 2000));

        const hdrStatus = await getHarshDrivingReportStatus(harshDriving.id);

        if (hdrStatus?.success && hdrStatus?.percent_ready === 100) {
          const hdReport = await getHarshDrivingReport(harshDriving.id);
          // console.log('>> hdReport',hdReport);
          setHarshDrivingData(hdReport?.report || {});
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  };

  //fetch Truck data
  useEffect(() => {
    if (trackerId) {
      fetchHarshDrivingData(trackerId);
    }
  }, [trackerId]);

  const handlePostJob = () => {
    const userIdVerified = user?.identity_verified === 1;
    const userSuspended = user?.identity_verified === 3;

    // if (user?.account_no !== 'test') {
    //   setShowFeatureNA((s) => !s);
    // } else
    if (userSuspended) {
      dispatch(showSuspensionModal());
    } else if (!userIdVerified) {
      toggleVerificationModal();
    } else {
      props.history.push('/client/job/create-service-request');
    }
  };

  // used when forwarding job is completed by FW agent & show confirm modal to importer
  const handleFwJobCompleteConfirm = () => {
    const payLoad = {
      job_type: 'forwarding',
      job_uuid: job_id,
      status: 8, // done
    };
    dispatch(showLoading());
    POST(`/jobs/changeStatus`, payLoad)
      .then(({ data }) => {
        dispatch(showModal('Success', true));
        dispatch(hideLoading());
        setShowFwJobCompletedModal(false);
        props.history.goBack();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <div className="tab-pane active" id="resp-tab7">
              <>
                <div>
                  <h2>
                    <span
                      onClick={props.history.goBack}
                      style={{ cursor: 'pointer', float: 'left' }}
                    >
                      <LeftArrowSvg />
                    </span>
                    {!isEmpty(details.forwardingBid) ? 'Transport Status' : 'Clearing Status'}
                  </h2>
                </div>
                {/* <h3>General Cargo</h3> */}
                <div style={{ position: 'relative' }}>
                  {!isEmpty(details.clearingBid) && (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Job Id"
                                value={details.job.job_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Bid Price"
                                value={displayAmount(details.clearingBid?.offer_price)}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Agent Id"
                                value={details.clearingBid.agent.user_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 job-status-container">
                            {/* <h3>Clearing Status</h3> */}
                            <ClearingCargoStatus details={details} jobId={job_id} />
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isEmpty(details.forwardingBid) && (
                    <div className="card job-status-card">
                      <div className="card-body">
                        <div className="row">
                          {/* <h3>Transport Status:</h3> */}
                          <div className="col-12 col-md-12 col-sm-12 mt-2">
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Job Id"
                                value={details.job.job_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Bid Price"
                                value={displayAmount(details.forwardingBid.offer_price)}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Agent Id"
                                value={details.forwardingBid.agent.user_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Destination"
                                value={details.job.destination}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Port of Discharge"
                                value={serviceData.port_locations[details.job.port_of_discharge]}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3>Truck Current Location:</h3>
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <NavixyWebLocator trackerId={trackerId} />
                          </div>
                        </div>
                        <hr />
                        {/* Harsh Driving */}
                        <DriverBehaviour data={harshDrivingData} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="buttonsec relative-right-10">
                  <button onClick={props.history.goBack} className="submitbut btn-light">
                    Back
                  </button>{' '}
                  <button onClick={handlePostJob} className="submitbut">
                    Post a New Job
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <Chat
        name="Us"
        id="us"
        chatbox={chatbox}
        user={user}
        chatToggle={chatToggle}
        chatlist={chatlist}
        value={msg}
        handleTextChange={handleTextChange}
        handleOnChange={handleOnChange}
        sendButton={sendButton}
        unreadMsgsCount={unreadCount}
      />
      <FeatureNotAvailable
        isOpen={showFeatureNA}
        toggle={() => setShowFeatureNA((s) => !s)}
        container="client-page"
      />
      <FwJobCompleteConfirm
        isOpen={showFwJobCompletedModal}
        toggle={() => setShowFwJobCompletedModal((s) => !s)}
        container="client-page"
        onConfirm={handleFwJobCompleteConfirm}
      />
      {/* <ClientVerificationModal
        verficationModal={verficationModal}
        toggleVerificationModal={toggleVerificationModal}
        container="client-page"
      /> */}
      <ClientRegSuccessModal
        isOpen={verficationModal}
        toggle={toggleVerificationModal}
        container="client-page"
      />
      {documentVerifyingModal && (
        <DocumentsVerifyingMsg
          isOpen={documentVerifyingModal}
          toggle={toggleDocumentVerifyingModal}
          container="client-page"
        />
      )}
    </>
  );
};

export default ClientJobDetails;
