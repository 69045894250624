import { Formik, getIn } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { object, ref, string } from 'yup';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import TextField from '../../components/UI/TextField';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import SelectBox from '../../components/UI/SelectBox';
import { PASSWORD_REGEX, PHONE_NUMBER_REGEX } from '../../helpers/constants';
import PasswordField from '../../components/global/Password';
import PhoneNumber from '../../components/global/PhoneNumber';

const corporate_types = {
  incorporated_company: 'Incorporated Company',
  incorporated_trustee: 'Incorporated Trustees',
  free_zone_entity: 'Free Zone Entity',
  government_entity: 'Government Entity',
  government_approved_private_entity: 'Private Entity created by Government Approval',
};

const rcNoRequired = (uType, cType) => {
  let rcFieldRequired = false;
  if (uType === 'application_corporate_client' && cType === 'incorporated_company') {
    rcFieldRequired = true;
  }
  if (uType === 'clearing_agent' || uType === 'forwarding_agent') {
    rcFieldRequired = true;
  }
  return rcFieldRequired;
};

const Registration = ({ history, match }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const serviceData = useSelector((state) => state.enumData);

  const [registrationDone, setRegistrationDone] = useState(false);

  const { url } = match;

  const adChannels = serviceData?.settings?.general?.ad_channel || [];

  if (get(user, 'isLoggedIn', false)) {
    return <Redirect from={url} to={'/'} />;
  }
  return (
    <div id="client-page">
      {' '}
      <Helmet>
        <title>GoAgent - Sign Up</title>
      </Helmet>
      <PublicHeader />
      <main id="main" className="main-block">
        <section className="container">
          <section className="bodycont login-page">
            <Formik
              initialValues={{
                userType: 'application_client',
                clientSelected: true,
                coporateClientSelected: false,
                first_name: '',
                last_name: '',
                company_name: '',
                address: '',
                company_address: '',
                email: '',
                mobile_number: '',
                password: '',
                confirm_password: '',
                source: '',
                source_other: '',
                acceptTerms: false,
                corporate_type: '',
                rc_no: '',
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                if (!values.acceptTerms) {
                  setSubmitting(false);
                  return dispatch(showModal('Please accept terms and conditions.'));
                } else {
                  const type =
                    values.userType === 'application_corporate_client'
                      ? 'application_client'
                      : values.userType;

                  // -> type values sent
                  // Client -> application_client
                  // Clearing Agent-> clearing_agent
                  // Forwarding Agent/Transporter -> forwarding_agent

                  // ->sub_type values sent
                  // Incorporated Company -> incorporated_company
                  // Incorporated Trustees -> incorporated_trustee
                  // Free Zone Entity -> free_zone_entity
                  // Government Entity -> government_entity
                  // Private Entity created by Government Approval -> government_approved_private_entity

                  const payload = {
                    first_name: values.userType === 'application_client' ? values.first_name : '',
                    last_name: values.userType === 'application_client' ? values.last_name : '',
                    address: values.userType === 'application_client' ? values.address : '',
                    company_name:
                      values.userType !== 'application_client' ? values.company_name : '',
                    company_address:
                      values.userType !== 'application_client' ? values.company_address : '',
                    source: values.source,
                    source_other: values.source_other,
                    email: values.email,
                    mobile_number: values.mobile_number,
                    password: values.password,
                    type: type,
                    sub_type: values.corporate_type || type,
                  };
                  if (values.rc_no && rcNoRequired(values.userType, values.corporate_type)) {
                    payload.rc_no = values.rc_no;
                  }

                  // console.log('>> payload', payload);
                  dispatch(showLoading());
                  POST(`/auth/register`, payload)
                    .then(({ data }) => {
                      dispatch(showModal('Registration done successfully', true));
                      dispatch(hideLoading());
                      setSubmitting(false);

                      // show message to inform verificaton email has been sent
                      // and scroll to that element so user get;s notified
                      setRegistrationDone(true);

                      const div = document.getElementById('verificationMailSent');
                      if (div) {
                        window.scrollTo({ top: div.offsetTop - 200, behavior: 'smooth' });
                      }
                      resetForm();

                      // --> Conversion Tracking code
                      // Google
                      window?.gtag?.('event', 'conversion', {
                        send_to: 'AW-324795882/UdLhCP_Q9OYDEOr775oB',
                      });

                      function gtag_report_conversion(url) {
                        var callback = function() {
                          if (typeof url != 'undefined') {
                            window.location = url;
                          }
                        };
                        window?.gtag('event', 'conversion', {
                          send_to: 'AW-324795882/OJWsCP7fz4EYEOr775oB',
                          event_callback: callback,
                        });
                        return false;
                      }
                      gtag_report_conversion();

                      // Facebook
                      window?.fbq?.('track', 'CompleteRegistration');
                      // <--
                    })
                    .catch((err) => {
                      let errMsg =
                        err.message?.firstNameInPassword ||
                        err.message?.lastNameInPassword ||
                        err.message?.companyNameInPassword ||
                        err.message?.email ||
                        err.message?.mobile_number ||
                        err.message ||
                        '';

                      dispatch(showModal(errMsg || 'Something went wrong!'));
                      dispatch(hideLoading());
                      setSubmitting(false);
                    });
                }
              }}
              validationSchema={object().shape({
                first_name: string().when('clientSelected', {
                  is: true,
                  then: string().required('First name is required'),
                }),
                last_name: string().when('clientSelected', {
                  is: true,
                  then: string().required('Last name is required'),
                }),

                company_name: string().when('clientSelected', {
                  is: false,
                  then: string().required('Company name is required'),
                }),

                address: string().when('clientSelected', {
                  is: true,
                  then: string().required('Address is required'),
                }),

                company_address: string().when('clientSelected', {
                  is: false,
                  then: string().required('Address is required'),
                }),

                email: string()
                  .email('Enter a valid email')
                  .required('Email is required'),

                mobile_number: string()
                  .required('Phone Number is required')
                  .matches(
                    PHONE_NUMBER_REGEX,
                    'Enter Phone Number without Country Code and without Space',
                  ),

                password: string()
                  .matches(
                    PASSWORD_REGEX,
                    'Password must contain minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                  )
                  .required('Enter your password'),

                confirm_password: string()
                  .required('Please confirm your password')
                  .oneOf([ref('password')], 'Passwords does not match'),
                // acceptTerms: bool().oneOf([true], ''),
                corporate_type: string().when('coporateClientSelected', {
                  is: true,
                  then: string().required('Corporate Type is required'),
                }),

                rc_no: string().when(['userType', 'corporate_type'], {
                  is: rcNoRequired,
                  then: string().required('RC Number is required'),
                }),
              })}
            >
              {(renderProps) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                } = renderProps;
                // console.log({ values, touched, errors });
                const showRcNoField = rcNoRequired(values.userType, values.corporate_type);

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="container">
                      <h2>Create free Account</h2>

                      <div className="payment-info">
                        {/* <h3>Create Your Account</h3> */}
                        <div className="resistertab">
                          <ul className="nav nav-tabs responsive" id="nonResponsiveTabs">
                            <li
                              className={`test-class ${
                                values.userType === 'application_client' ? 'active' : ''
                              }`}
                              style={{ marginBottom: '10px' }}
                            >
                              <a
                                className="deco-none red-class"
                                onClick={() => {
                                  setFieldValue('userType', 'application_client');
                                  setFieldValue('clientSelected', true);
                                  setFieldValue('coporateClientSelected', false);
                                  setFieldValue('corporate_type', '');
                                }}
                              >
                                Sign up as an individual importer
                              </a>
                            </li>
                            <li
                              className={`test-class ${
                                values.userType === 'application_corporate_client' ? 'active' : ''
                              }`}
                            >
                              <a
                                className="deco-none red-class"
                                onClick={() => {
                                  setFieldValue('userType', 'application_corporate_client');
                                  setFieldValue('clientSelected', false);
                                  setFieldValue('coporateClientSelected', true);
                                  setFieldValue('corporate_type', '');
                                }}
                              >
                                Sign up as a corporate importer
                              </a>
                            </li>
                            <li
                              className={`test-class ${
                                values.userType === 'clearing_agent' ? 'active' : ''
                              }`}
                            >
                              <a
                                onClick={() => {
                                  setFieldValue('userType', 'clearing_agent');
                                  setFieldValue('clientSelected', false);
                                  setFieldValue('coporateClientSelected', false);
                                  setFieldValue('corporate_type', '');
                                }}
                              >
                                Sign up as a Clearing Agent
                              </a>
                            </li>
                            <li
                              className={`${
                                values.userType === 'forwarding_agent' ? 'active' : ''
                              }`}
                            >
                              <a
                                className={`deco-none`}
                                onClick={() => {
                                  setFieldValue('userType', 'forwarding_agent');
                                  setFieldValue('clientSelected', false);
                                  setFieldValue('coporateClientSelected', false);
                                  setFieldValue('corporate_type', '');
                                }}
                              >
                                Sign up as Transporter
                              </a>
                            </li>
                          </ul>

                          <div className="tab-content responsive">
                            <div className="tab-pane active" id="resp-tab1">
                              <div className="row">
                                {values.userType === 'application_corporate_client' && (
                                  <div className="col-sm-6 text-left">
                                    <SelectBox
                                      id="corporate_type"
                                      name="corporate_type"
                                      className="form-control"
                                      placeholder="Select Corporate Type"
                                      value={values.corporate_type}
                                      options={corporate_types}
                                      onChange={handleChange}
                                      error={
                                        getIn(touched, 'corporate_type') &&
                                        getIn(errors, 'corporate_type')
                                      }
                                    />
                                  </div>
                                )}
                                {values.userType === 'application_client' ? (
                                  <>
                                    <div className="col-sm-6 text-left">
                                      <TextField
                                        id="first_name"
                                        type="text"
                                        className="form-control "
                                        placeholder="First Name*"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          getIn(touched, 'first_name') &&
                                          getIn(errors, 'first_name')
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-6 text-left">
                                      <TextField
                                        id="last_name"
                                        type="text"
                                        className="form-control "
                                        placeholder="Last Name*"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          getIn(touched, 'last_name') && getIn(errors, 'last_name')
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-6 text-left">
                                      <TextField
                                        id="address"
                                        type="text"
                                        className="form-control "
                                        placeholder={'Address*'}
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          getIn(touched, 'address') && getIn(errors, 'address')
                                        }
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-sm-6 text-left">
                                      <TextField
                                        id="company_name"
                                        type="text"
                                        className="form-control "
                                        placeholder="Company Name*"
                                        value={values.company_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          getIn(touched, 'company_name') &&
                                          getIn(errors, 'company_name')
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-6 text-left">
                                      <TextField
                                        id="company_address"
                                        type="text"
                                        className="form-control "
                                        placeholder={'Company Reg. Address*'}
                                        value={values.company_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          getIn(touched, 'company_address') &&
                                          getIn(errors, 'company_address')
                                        }
                                      />
                                    </div>
                                  </>
                                )}

                                <div className="col-sm-6 text-left">
                                  <TextField
                                    id="email"
                                    type="email"
                                    className="form-control "
                                    placeholder="Email*"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={getIn(touched, 'email') && getIn(errors, 'email')}
                                  />
                                </div>
                                <div className="col-sm-6 text-left">
                                  <PhoneNumber
                                    value={values.mobile_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      getIn(touched, 'mobile_number') &&
                                      getIn(errors, 'mobile_number')
                                    }
                                  />
                                </div>

                                <div className="col-sm-6 text-left">
                                  <PasswordField
                                    id="password"
                                    placeholder="Password*"
                                    password={values.password}
                                    showPassword={values.showPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    toggleShowPw={() =>
                                      setFieldValue(
                                        'showPassword',
                                        values.showPassword ? false : true,
                                      )
                                    }
                                    error={getIn(touched, 'password') && getIn(errors, 'password')}
                                  />
                                </div>
                                <div className="col-sm-6 text-left">
                                  <PasswordField
                                    id="confirm_password"
                                    placeholder="Confirm Password*"
                                    password={values.confirm_password}
                                    showPassword={values.showCP}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    toggleShowPw={() =>
                                      setFieldValue('showCP', values.showCP ? false : true)
                                    }
                                    error={
                                      getIn(touched, 'confirm_password') &&
                                      getIn(errors, 'confirm_password')
                                    }
                                  />
                                </div>
                                {showRcNoField && (
                                  <div className="col-sm-6 text-left">
                                    <TextField
                                      id="rc_no"
                                      type="text"
                                      className="form-control"
                                      placeholder="RC Number"
                                      value={values.rc_no}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={getIn(touched, 'rc_no') && getIn(errors, 'rc_no')}
                                    />
                                  </div>
                                )}
                                <div className="col-sm-12">
                                  <label className="checkcontainer" id="acceptTerms">
                                    <p>
                                      By login, you agree to GoAgent's{' '}
                                      <a href="/terms-and-conditions" target="_blank">
                                        Terms and Conditions
                                      </a>{' '}
                                      and{' '}
                                      <a href="/privacy-policy" target="_blank">
                                        Privacy Policy
                                      </a>
                                    </p>
                                    <input
                                      type="checkbox"
                                      value={values.acceptTerms}
                                      name="acceptTerms"
                                      id="acceptTerms"
                                      onChange={() => {
                                        setFieldValue('acceptTerms', !values.acceptTerms);
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div className="col-sm-6 text-left">
                                  <select
                                    className="form-control"
                                    id="source"
                                    name="source"
                                    value={values.source}
                                    onChange={(e) => {
                                      // console.log('e', e, e.target.value);
                                      setFieldValue('source', e.target.value);
                                      setFieldValue('source_other', '');
                                    }}
                                  >
                                    <option value="">How did you hear about us?</option>
                                    {adChannels?.length > 0 &&
                                      adChannels.map((ac) => (
                                        <option value={ac.label} key={ac.id}>
                                          {ac.label}
                                        </option>
                                      ))}
                                    <option value="others" key="others">
                                      Others
                                    </option>
                                  </select>
                                </div>
                                {values.source && values.source !== 'others' && (
                                  <div className="col-sm-6 text-left">
                                    <select
                                      className="form-control"
                                      id="source_other"
                                      name="source_other"
                                      value={values.source_other}
                                      onChange={handleChange}
                                    >
                                      <option value="">Select</option>

                                      {adChannels?.length &&
                                        adChannels
                                          .find((ac) => ac.label === values.source)
                                          .sub_channels.map((sc) => (
                                            <option value={sc.label} key={sc.id}>
                                              {sc.label}
                                            </option>
                                          ))}
                                    </select>
                                  </div>
                                )}
                                {values.source === 'others' && (
                                  <div className="col-sm-6 text-left">
                                    <TextField
                                      id="source_other"
                                      type="text"
                                      placeholder="Other Media"
                                      className="form-control "
                                      value={values.source_other}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        getIn(touched, 'source_other') &&
                                        getIn(errors, 'source_other')
                                      }
                                    />
                                  </div>
                                )}

                                <div className="col-sm-12">
                                  <div className="buttonsec">
                                    {registrationDone ? (
                                      <button
                                        className="submitbut"
                                        type="button"
                                        onClick={() => history.push('/login')}
                                      >
                                        Proceed to Login
                                      </button>
                                    ) : (
                                      <button
                                        className="submitbut"
                                        type="submit"
                                        disabled={isSubmitting}
                                      >
                                        Confirm Registration
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm-12 fs-2" id="verificationMailSent">
                                  {registrationDone && (
                                    <p className="alert alert-success">
                                      A verification email has been sent to your email,
                                      <br />
                                      Please check your inbox or spam folder.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </section>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
export default Registration;
