import React from 'react';
import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../helpers/constants';

const DriverJournal =(props)=>{
  const { data, dateRanges, toggleDateRangeModal } = props;
  return (
    <>
      <div className="row">
        <div className='col-lg-8 col-sm-12'>
          <h3>Driver journal:</h3>
        </div>
        <div className='col-lg-4 col-sm-12 mb-3'>
          <span onClick={toggleDateRangeModal} className='date-range-btn'>
            {/* {moment(dateRanges.startDate).format(DATE_FORMAT)} - {moment(dateRanges.endDate).format(DATE_FORMAT)} */}
            View Driver Journal History
          </span>
        </div>
      </div>
      <div className="table-responsive clearingtable">
        <table className='driver-journal'>
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Start Location</th>
              <th>End Location</th>
              <th>Distance (km)</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? data.map((dj, idx)=>{
              return (
                <tr key={`journal-${idx}`}>
                  <td>{dj.start_date ? moment(dj.start_date).format(DATE_TIME_FORMAT) : 'N/A' }</td>
                  <td>{dj.end_date? moment(dj.end_date).format(DATE_TIME_FORMAT) : 'N/A' }</td>
                  <td>{dj?.start_location?.address || 'N/A'}</td>
                  <td>{dj?.end_location?.address || 'N/A'}</td>
                  <td>{dj.length}</td>
                </tr>
              )
            }): <span className='fs-3'>No Data!</span>}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DriverJournal;