import { GET } from './rest.service';
import configObject from '../../config';
import moment from 'moment';

const API_INACTIVITY_KEY = '_GAAPIACTIVE';
const LOGIN_SESSION_KEY = '_GATKEN';

const logoutUser = () => {
  sessionStorage.removeItem(LOGIN_SESSION_KEY);
  window.location.href = '/';
};

const fetchAndSetToken = async () => {
  try {
    const resp = await GET('/auth/refreshToken');
    if (resp?.data?._token) sessionStorage.setItem(LOGIN_SESSION_KEY, resp.data._token);
  } catch (error) {
    logoutUser();
  }
};

export const refreshToken = async (refreshImmidiately = false, cb) => {
  const exp =
    configObject.TOKEN_EXPIRY_TIME > 1
      ? configObject.TOKEN_EXPIRY_TIME - 1
      : configObject.TOKEN_EXPIRY_TIME;
  const refreshIntervalTime = exp * 60 * 1000; // in milliseconds , -1 to refresh one minute before token expires

  if (sessionStorage.getItem(LOGIN_SESSION_KEY)) {
    if (refreshImmidiately) {
      // call refresh API immediately and then start the refresh interval
      await fetchAndSetToken();
      setInterval(fetchAndSetToken, refreshIntervalTime);
    } else {
      // start the refresh interval
      setInterval(fetchAndSetToken, refreshIntervalTime);
    }
    cb?.(); // execute callback method if present
  }
};

// this method will check for api inactivity in the application and logout the user if inactive
// after every 10 seconds it checks if last activity is not greater or equal to "configObject.APP_INACTIVITY_TIME"
// example: if last activity is greater than 5 minutes then it will logout the user
// we set the API activity with method setUserActivity for every API call except refresh token ( cause token is also refreshed on interval and it will conflict with this interval )

export const logoutAfterInactivity = () => {
  const intervalTime = 10000; // in milliseconds ( 10 milliseconds )
  setInterval(() => {
    const now = moment();
    const lastActivityTime = moment(+sessionStorage.getItem(API_INACTIVITY_KEY));
    const duration = moment.duration(now.diff(lastActivityTime));
    const minutesDiffrence = duration.asMinutes();
    // console.log(
    //   '>> INACTIVITY INTERVAL >> ',
    //   Math.round(minutesDiffrence),
    //   '>> last activity time >> ',
    //   lastActivityTime.format('DD-MM-YY HH:mm:ss'),
    // );

    // if user is logged in and last activity is >= configObject.APP_INACTIVITY_TIME then logout the user
    if (
      sessionStorage.getItem(LOGIN_SESSION_KEY) &&
      Math.round(minutesDiffrence) >= configObject.APP_INACTIVITY_TIME
    ) {
      // console.log('>>> USER INACTIVE <<<');
      logoutUser();
    }
  }, intervalTime);
};

export const setUserActivity = () => {
  // console.log('>> SET USER ACTIVITY');
  sessionStorage.setItem(API_INACTIVITY_KEY, Date.now());
};

export const trackMouseAndKeyboardActivity = () => {
  // remove listner if already available
  document.removeEventListener('onkeypress', setUserActivity);
  document.removeEventListener('onclick', setUserActivity);

  // keyboard
  document.onkeypress = setUserActivity;
  // mouse
  document.onclick = setUserActivity;
};
