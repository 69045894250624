import React, { useEffect, useState } from 'react';
import { Formik, getIn } from 'formik';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import TextField from '../../components/UI/TextField';
import { login } from '../../redux/user/UserActions';
import { applicationUserType } from '../../helpers/constants';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';
import PasswordField from '../../components/global/Password';

const Login = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { url } = props.match;
  // console.log(user);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  let history = useHistory();

  useEffect(() => {
    if (user?.status === 'email-verification-false') {
      // when the user email not verified show the message
      // and scroll to that element so user get;s notified
      const div = document.getElementById('notVerifiedContainer');
      if (div) {
        window.scrollTo({ top: div.offsetTop - 200, behavior: 'smooth' });
      }
    }
  }, [user]);

  // redirecting user to his particular dashboard, if user visits this page while logged in
  if (get(user, 'isLoggedIn', false)) {
    if (applicationUserType[get(user, 'user.type')]) {
      return (
        <Redirect from={url} to={`/${applicationUserType[get(user, 'user.type')]}/dashboard`} />
      );
    }
  }

  const handleResendVM = (email) => {
    dispatch(showLoading());
    POST('/auth/sendVerificationMail', { email })
      .then((resp) => {
        dispatch(showModal('Email sent successfully', true));
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const savedRememberMe = localStorage.getItem('rememberme') === 'true' ? true : false;
  const savedEmail = savedRememberMe ? atob(localStorage.getItem('email')) : '';
  const savedPassword = savedRememberMe ? atob(localStorage.getItem('password')) : '';
  return (
    <div id="client-page">
      <Helmet>
        <title>GoAgent - Login</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main" className="main-block">
        <section className="container">
          <section className="bodycont login-page">
            <div className="container">
              <h2>Login</h2>
              <div className="logindiv">
                {step === 1 && (
                  <Formik
                    initialValues={{
                      email: savedRememberMe ? savedEmail : '',
                    }}
                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                      setSubmitting(false);
                      setStep(2);
                      setEmail(values.email);
                    }}
                    validationSchema={object().shape({
                      email: string()
                        .email('Enter a valid email')
                        .required('Email is required'),
                    })}
                  >
                    {(renderProps) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      } = renderProps;
                      return (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            id="email"
                            type="email"
                            className="form-control "
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getIn(touched, 'email') && getIn(errors, 'email')}
                          />

                          <p>
                            By login, you agree to GoAgent's{' '}
                            <Link to="/terms-and-conditions">Terms and Conditions</Link> and{' '}
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </p>

                          <button type="submit" disabled={isSubmitting} className="submitbut">
                            Continue
                          </button>
                        </form>
                      );
                    }}
                  </Formik>
                )}

                {step === 2 && (
                  <Formik
                    initialValues={{
                      password: savedRememberMe ? savedPassword : '',
                      rememberme: savedRememberMe ? savedRememberMe : false,
                    }}
                    onSubmit={(values) => {
                      const payload = {
                        email,
                        password: values.password,
                        rememberme: values.rememberme,
                      };
                      dispatch(showLoading());
                      dispatch(login(payload, history));
                    }}
                    validationSchema={object().shape({
                      password: string()
                        .min(8, 'Password must contain at least 8 characters')
                        .required('Enter your password'),
                    })}
                  >
                    {(renderProps) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      } = renderProps;
                      return (
                        <form onSubmit={handleSubmit}>
                          <PasswordField
                            id="password"
                            placeholder="Password"
                            password={values.password}
                            showPassword={values.showPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            toggleShowPw={() =>
                              setFieldValue('showPassword', values.showPassword ? false : true)
                            }
                            error={getIn(touched, 'password') && getIn(errors, 'password')}
                          />
                          <p>
                            By login, you agree to GoAgent's{' '}
                            <Link to="/terms-and-conditions">Terms and Conditions</Link> and{' '}
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </p>

                          <div className="keepcheck">
                            <label className="checkcontainer" id="rememberme">
                              Keep me signed in
                              {/* <input type="checkbox" /> */}
                              <input
                                type="checkbox"
                                checked={values.rememberme}
                                name="rememberme"
                                id="rememberme"
                                onChange={() => {
                                  setFieldValue('rememberme', !values.rememberme);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>

                            <Link to="/forgot-password">Forgot Password?</Link>
                            <div className="clearfix"></div>
                          </div>

                          <button type="submit" className="submitbut">
                            Login
                          </button>
                          <button type="button" onClick={() => setStep(1)} className="submitbut">
                            Go Back
                          </button>
                          <div>
                            {user?.status === 'email-verification-false' && (
                              <p className="alert alert-warning fs-2" id="notVerifiedContainer">
                                Email is not verified!
                                <br /> Please check your inbox or spam folder for Verification
                                Email.
                                <br />
                                <br /> Please{' '}
                                <a onClick={() => handleResendVM(email)}>click here</a> to resend
                                verification email.
                              </p>
                            )}
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                )}

                {step === 1 && (
                  <>
                    <h3>New to GoAgent?</h3>
                    <Link
                      className="submitbut"
                      to="/registration"
                      style={{ textAlign: 'center', textDecoration: 'none' }}
                    >
                      Open Your Account With Us Today
                    </Link>
                  </>
                )}
              </div>
            </div>
          </section>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
export default Login;
