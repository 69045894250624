import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { GET } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import ShowClientDocument from './ClientVerification/ShowClientDocument';
import { extractUserDocs } from '../../helpers/utils';
import ShowClearingForwardingDocs from './ShowClearingForwardingDocs';
import { showDocumentModal } from '../../redux/user/UserActions';
import { LeftArrowSvg } from '../UI/CommanUI';
import PortOfOperations from './PortOfOperations/PortOfOperations';

const ShowDocument = (props) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState({});
  const [formValues, setFormValues] = useState({});
  const { user } = useSelector((state) => state.user);
  const [clientType, setClientType] = useState('');
  const [details, setDetails] = useState({});

  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  // user details
  const getUserDetails = () => {
    dispatch(showLoading());
    if (user.uuid) {
      GET(`/users/getDetails/${user.uuid}`)
        .then(({ data }) => {
          // console.log(data.documents);
          if (user.type == 'clearing_agent' || user.type == 'forwarding_agent') {
            if (!isEmpty(data.documents)) {
              const item = extractUserDocs(data);
              setDetails({
                ...data,
                documents: item,
              });

              setImages({
                ...images,
                incorporation_certificate: item.incorporation_certificate,
                memorandum_article_association: item.memorandum_article_association,
                corporate_address_proof: item.corporate_address_proof,
                registration_certificate: item.registration_certificate,
                registration_application_status: item.registration_application_status,
                ctc_cac_1_1: item.ctc_cac_1_1,
                cac_2: item.cac_2,
                cac_7: item.cac_7,
              });
              const modifiedItem = {
                ...item,
                incorporation_certificate: null,
                memorandum_article_association: null,
                corporate_address_proof: null,
                registration_certificate: null,
                registration_application_status: null,
                ctc_cac_1_1: null,
                cac_2: null,
                cac_7: null,
              };
              // console.log(modifiedItem);
              setFormValues(modifiedItem);
            } else {
              setDetails(data);
              setFormValues({});
            }
          } else {
            const item = extractUserDocs(data);
            setDetails({
              ...data,
              documents: item,
            });
            if (!isEmpty(item)) {
              setImages({
                ...images,
                national_identity_card: item.national_identity_card,
              });
              const modifiedItem = {
                ...item,
                national_identity_card: null,
              };
              // console.log(modifiedItem);
              setFormValues(modifiedItem);
            } else {
              setFormValues({});
            }
          }
          const uType = data.user.sub_type || data.user.type;
          setClientType(uType);
          // setJob(data.job);

          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  };

  // useEffect
  useEffect(() => {
    getUserDetails();
  }, []);

  // show message when try to edit
  const handleEdit = () => {
    swal({
      title: 'Are you sure?',
      text: `Once a document is updated it needs to be re-approved, before you can do any action in the application.`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        let path = '/client/update-documents';
        if (user.type === 'forwarding_agent') path = '/forwarding-agent/update-documents';
        else if (user.type === 'clearing_agent') path = '/clearing-agent/update-documents';
        props.history.push(path);
      }
    });
  };

  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>
              <span onClick={props.history.goBack} style={{ cursor: 'pointer', float: 'left' }}>
                <LeftArrowSvg />
              </span>
              {user.type === 'application_client' && user.sub_type === 'application_client'
                ? 'Personal Details'
                : 'Corporate Details'}
            </h2>
            {user.type === 'clearing_agent' && <PortOfOperations />}
            <div className="personal-info mb-5">
              {user.type == 'clearing_agent' || user.type == 'forwarding_agent' ? (
                <div className="profilecont">
                  {formValues && (
                    <ShowClearingForwardingDocs
                      showDoc={showDoc}
                      images={images}
                      formValues={formValues}
                      userType={user.type}
                      details={details}
                    />
                  )}
                </div>
              ) : (
                <div className="client-docs">
                  <ShowClientDocument
                    formValues={{
                      ...formValues,
                      user: {
                        ...details?.user,
                      },
                    }}
                    clientType={clientType}
                    repValues={details?.user || {}}
                    showAuthRepDetails={clientType === 'application_client' ? false : true}
                    showDocumentModal={showDoc}
                  />
                </div>
              )}

              <div className="buttonsec">
                <button className="submitbut" onClick={handleEdit}>
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowDocument;
