import { isEmpty } from 'lodash';
import React from 'react';
import AuthRepDocFields from './DocumentFields/AuthRep';
import FreeZoneEntity from './DocumentFields/FreeZoneEntity';
import GovernmentEntity from './DocumentFields/GovernmentEntity';
import IncorporatedCompany from './DocumentFields/IncorporatedCompany';
import IncorporatedTrustees from './DocumentFields/IncorporatedTrustees';
import Individual from './DocumentFields/Individual';
import PrivateEntity from './DocumentFields/PrivateEntity';
import {
  USER_ROLE_LAWYER,
  SUB_USER_ROLE_CLIENT_INC_COMPANY,
  SUB_USER_ROLE_CLIENT_INC_TRUSTEE,
  SUB_USER_ROLE_CLIENT_FREEZONE_ENTITY,
  SUB_USER_ROLE_CLIENT_GOV_ENTITY,
  SUB_USER_ROLE_CLIENT_PVT_ENTITY,
} from '../../../helpers/constants';
import DisplayDocument from '../../global/DisplayDocument';

const ClientDocuments = ({
  formValues,
  clientType,
  repValues,
  showAuthRepDetails,
  showDocumentModal,
  userType,
}) => {
  const userIsComplianceOfficer = userType === USER_ROLE_LAWYER;

  const userIsCorporateClient =
    clientType === SUB_USER_ROLE_CLIENT_INC_COMPANY ||
    clientType === SUB_USER_ROLE_CLIENT_INC_TRUSTEE ||
    clientType === SUB_USER_ROLE_CLIENT_FREEZONE_ENTITY ||
    clientType === SUB_USER_ROLE_CLIENT_GOV_ENTITY ||
    clientType === SUB_USER_ROLE_CLIENT_PVT_ENTITY;

  return (
    <div style={{ textAlign: 'left' }}>
      {/* {formValues.national_identity_no && (
        <>
          <div className="mb-5">
            <strong className="v2-text-label v2-text">National Identity No:</strong>
            {' '}{formValues.national_identity_no}
          </div>
          <div className="mb-5">
            <strong className="v2-text-label v2-text mb-2">National Identity Card Image:</strong>
            <br />{' '}
            {images.national_identity_card && (
              <div className="form-group">
                <a target="_blank" rel="noopener noreferrer" href={`${baseURL}/${images.national_identity_card}`} className="">
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i>national_identity_card Image
                </a>
              </div>
            )}
          </div>
        </>
      )}
      */}

      {clientType === 'application_client' && <Individual values={formValues.user || {}} />}
      {/* RC Number needs to be Displayed to Client type Incorporated company*/}
      {!userIsComplianceOfficer &&
        userIsCorporateClient &&
        clientType === 'incorporated_company' && (
          <DisplayDocument
            label="RC Number"
            type="text"
            textColClassName="document"
            value={formValues.rc_no}
          />
        )}
      {userIsComplianceOfficer && userIsCorporateClient && (
        <>
          {clientType === 'incorporated_company' && (
            <IncorporatedCompany values={formValues} showDoc={showDocumentModal} />
          )}
          {clientType === 'incorporated_trustee' && (
            <IncorporatedTrustees values={formValues} showDoc={showDocumentModal} />
          )}
          {clientType === 'free_zone_entity' && (
            <FreeZoneEntity values={formValues} showDoc={showDocumentModal} />
          )}
          {clientType === 'government_entity' && (
            <GovernmentEntity values={formValues} showDoc={showDocumentModal} />
          )}
          {clientType === 'government_approved_private_entity' && (
            <PrivateEntity values={formValues} showDoc={showDocumentModal} />
          )}
        </>
      )}

      {/* Authorized representative */}
      {clientType !== 'application_client' && !isEmpty(repValues) && showAuthRepDetails && (
        <AuthRepDocFields values={repValues} clientType={clientType} />
      )}
    </div>
  );
};

export default ClientDocuments;
