import { get, isEmpty } from 'lodash';
import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import UpdatePassword from '../components/global/UpdatePassword';
import UserHeader from '../components/global/UserHeader';
import AppLoader from '../components/loaders/AppLoader';
import ClearingAgentBids from '../containers/ClearingAgent/Bids/ClearingAgentBids';
import Dashboard from '../containers/ClearingAgent/Dashboard';
import DisputesList from '../components/global/DisputesList';
// import MessageList from '../containers/ClearingAgent/Messages/MessageList';
import EditProfile from '../containers/ClearingAgent/Profile/EditProfile';
import Profile from '../containers/ClearingAgent/Profile/Profile';
import PaymentsList from '../containers/ClearingAgent/Receipts/PaymentsList';
import { ValidateRoutes } from './ClientRoute';
import VerificationModal from '../components/Modals/AgentVerificationModal';
import Documents from '../components/global/ShowDocument';
import UpdateDocuments from '../components/global/UpdateDocuments';
import JobStatus from '../containers/ClearingAgent/Bids/JobStatus';
import Notification from '../components/global/Notification';
import DisputesDetails from '../components/global/DisputesDetails';
import NotificationMenuItem from '../components/global/NotificationBadge';
import {
  NT_CA_NEW_JOBS,
  NT_CA_DISPUTES,
  NT_CA_PROFILE,
  NT_CA_MY_JOBS,
  NT_CA_JOBS_UNDER_BIDDING,
} from '../helpers/constants';
import SuspensionModal from '../components/Modals/SuspensionModal';
import { hideSuspensionModal, hideDocumentModal } from '../redux/user/UserActions';
import DocumentModal from '../components/Modals/DocumentModal';
import { readNotification } from '../redux/notification/NotificationActions';

const ClearingAgentRoute = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userStoreState = useSelector((state) => state.user);
  const { user } = userStoreState;
  const [verficationModal, setVerificationModal] = useState(false);
  const [siderMemuToggle, setSiderMemuToggle] = useState(false);

  const toggleVerificationModal = useCallback(() => {
    // console.log(verficationModal);
    setVerificationModal(!verficationModal);
  }, [verficationModal]);

  const { match } = props;
  const { pathname } = props.location;

  if (isEmpty(user)) {
    return <AppLoader />;
  }

  const onMenuClick = (path, notificationTypes) => {
    history.push(path);
    setSiderMemuToggle(false);

    // mark the notifications of the menu as read
    if (notificationTypes) {
      dispatch(readNotification({ notificationTypes }));
    }
  };

  return (
    <div id="clearing-agent-page">
      <Helmet>
        <title>GoAgent</title>
      </Helmet>
      <UserHeader />
      <main id="main" className="main-block">
        <div className="bodycont">
          <div style={{ clear: 'both' }}></div>

          <div className="tab">
            <div className="cormen-btn">
              <label htmlFor="cormid"></label>
            </div>
            <div className="cormen">
              <input
                id="cormid"
                type="checkbox"
                checked={siderMemuToggle}
                onChange={(e) => setSiderMemuToggle(e.target.checked)}
              />
              <ul>
                <button
                  className={`tablinks ${
                    pathname.includes('/clearing-agent/dashboard') ? 'active' : ''
                  }`}
                  onClick={() => onMenuClick('/clearing-agent/dashboard', NT_CA_NEW_JOBS)}
                >
                  <NotificationMenuItem title="New Jobs" types={NT_CA_NEW_JOBS} />
                </button>{' '}
                {user.identity_verified === 0 || user.identity_verified === 2 ? (
                  <button
                    className={`tablinks ${
                      pathname.includes('/clearing-agent/bids') ? 'active' : ''
                    }`}
                    onClick={() => {
                      toggleVerificationModal();
                    }}
                  >
                    <NotificationMenuItem title="My Jobs" types={NT_CA_MY_JOBS} />
                  </button>
                ) : (
                  <button
                    className={`tablinks ${
                      pathname.includes('/clearing-agent/bids') ? 'active' : ''
                    }`}
                    onClick={() => {
                      // NT_CA_JOBS_UNDER_BIDDING is used as types because it is the default sub tab inside the page
                      // Jobs under bidding
                      onMenuClick('/clearing-agent/bids', NT_CA_JOBS_UNDER_BIDDING);
                    }}
                  >
                    <NotificationMenuItem title="My Jobs" types={NT_CA_MY_JOBS} />
                  </button>
                )}
                <button
                  className={`tablinks ${
                    pathname.includes('/clearing-agent/profile') ||
                    pathname.includes('/clearing-agent/edit-profile')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => onMenuClick('/clearing-agent/profile', NT_CA_PROFILE)}
                >
                  <NotificationMenuItem title="Profile" types={NT_CA_PROFILE} />
                </button>
                <button
                  className={`tablinks ${pathname.includes('/payments') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/clearing-agent/payments')}
                >
                  Transaction History
                </button>
                <button
                  className={`tablinks ${pathname.includes('/dispute') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/clearing-agent/disputes', NT_CA_DISPUTES)}
                >
                  <NotificationMenuItem title="Disputes" types={NT_CA_DISPUTES} />
                </button>
                {/* <button className={`tablinks ${pathname.includes('/messages') ? 'active' : ''}`} onClick={() => onMenuClick('/clearing-agent/messages')}>
                  My Message
                </button> */}
                <button
                  className={`tablinks ${pathname.includes('/notifications') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/clearing-agent/notifications')}
                >
                  Messages
                </button>
              </ul>
            </div>
          </div>

          <Switch>
            <ValidateRoutes
              exact
              path={`${match.url}/dashboard`}
              component={Dashboard}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />

            <ValidateRoutes
              exact
              path={`${match.url}/profile`}
              component={Profile}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/profile/update-password`}
              component={UpdatePassword}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />

            <ValidateRoutes
              exact
              path={`${match.url}/documents`}
              component={Documents}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/edit-profile`}
              component={EditProfile}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/bids`}
              component={ClearingAgentBids}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/payments`}
              component={PaymentsList}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />

            <ValidateRoutes
              exact
              path={`${match.url}/disputes`}
              component={DisputesList}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/dispute/details`}
              component={DisputesDetails}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            {/* <ValidateRoutes exact path={`${match.url}/messages`} component={MessageList} has_permission={get(user, 'type') === 'clearing_agent'} /> */}
            <ValidateRoutes
              exact
              path={`${match.url}/update-documents`}
              component={UpdateDocuments}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/job-status`}
              component={JobStatus}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/job/details`}
              component={JobStatus}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/notifications`}
              component={Notification}
              has_permission={get(user, 'type') === 'clearing_agent'}
            />
            <Redirect from={match.url} to={`${match.url}/dashboard`} />
          </Switch>
          <div className="clearfix"></div>
        </div>

        <VerificationModal
          verficationModal={verficationModal}
          toggleVerificationModal={toggleVerificationModal}
          container="clearing-agent-page"
        />
        {/* Modal to Display Suspension */}
        {userStoreState?.displaySuspensionModal && (
          <SuspensionModal
            container="clearing-agent-page"
            isOpen={userStoreState.displaySuspensionModal}
            toggle={() => {
              dispatch(hideSuspensionModal());
            }}
          />
        )}
        {/* Modal to Display Document */}
        {userStoreState?.displayDocumentModal && (
          <DocumentModal
            isOpen={userStoreState.displayDocumentModal}
            toggle={() => dispatch(hideDocumentModal())}
            documentToShow={userStoreState.documentToDisplay}
            container="clearing-agent-page"
          />
        )}
      </main>
    </div>
  );
};

export default ClearingAgentRoute;
