import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import DisplayDocument from '../../../components/global/DisplayDocument';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { showDocumentModal } from '../../../redux/user/UserActions';
import { getPortName } from '../../../helpers/utils';

const AgentPortOfOperations = ({ userId }) => {
  const serviceData = useSelector((state) => state.enumData);
  const dispatch = useDispatch();
  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  const [agentPorts, setAgentPorts] = useState([]);

  const getAgentPorts = useCallback(
    (uid, isUnmounted = false) => {
      dispatch(showLoading());
      POST('/ports/getAgentPort', {
        agent_uuid: uid,
      })
        .then(({ data }) => {
          // console.log(data);
          if (!isUnmounted) setAgentPorts(data.ports);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());

          dispatch(showModal(err.message));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    let isUnmounted = false; // used for not setting state when component unmounts, prevents memory leaks
    if (userId) {
      getAgentPorts(userId, isUnmounted);
    }
    return () => {
      isUnmounted = true;
    };
  }, [userId, getAgentPorts]);

  // portId is not the port's uuid from settings, but is the id we get when agent adds his port of operation
  const changeAgentPortStatus = useCallback(
    (portId, status) => {
      dispatch(showLoading());
      POST('/ports/updateAgentPortStatus', {
        port_uuid: portId,
        document_verified: status,
      })
        .then(({ data }) => {
          getAgentPorts(userId);
          dispatch(showModal('Port Status Updated', true));
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
        });
    },
    [userId, dispatch, getAgentPorts],
  );

  const handlePOOApprove = useCallback(
    (agentPort) => {
      swal({
        title: 'Are you sure?',
        text: `Are you sure you want to Approve this Port of Operation?`,
        icon: 'warning',
        buttons: ['No', 'Yes'],
      }).then((result) => {
        if (result) {
          changeAgentPortStatus(agentPort.uuid, 1); // status 1 is approved
        }
      });
    },
    [changeAgentPortStatus],
  );

  const handlePOOReject = useCallback(
    (agentPort) => {
      swal({
        title: 'Are you sure?',
        text: `Are you sure you want to Reject this Port of Operation?`,
        icon: 'warning',
        buttons: ['No', 'Yes'],
      }).then((result) => {
        if (result) {
          changeAgentPortStatus(agentPort.uuid, 2); // status 1 is rejected
        }
      });
    },
    [changeAgentPortStatus],
  );

  return (
    <div className="personal-info mb-5">
      <h3>Port of Operations</h3>
      {agentPorts?.length > 0 ? (
        agentPorts.map((ap) => (
          <div className="row mx-0 mt-5" key={ap.uuid}>
            <DisplayDocument
              label={getPortName(ap.port_uuid, serviceData)}
              onClick={() => showDoc(ap.document_value)}
              type="file"
              fileColClassName="document col-lg-6 col-md-6 col-xs-12 col-sm-12"
              documentLabel="Document"
              hasDocument={ap.document_value ? true : false}
            />

            <div className="col-lg-2 col-md-2 col-xs-6 col-sm-6">
              {ap.document_verified === 2 && <span className="alert  alert-danger">Rejected</span>}
              {(ap.document_verified === 0 || ap.document_verified === 1) && (
                <button
                  type="button"
                  className="action-btn btn-red"
                  onClick={() => handlePOOReject(ap)}
                >
                  Reject
                </button>
              )}
            </div>
            <div className="col-lg-2 col-md-2 col-xs-6 col-sm-6">
              {ap.document_verified === 1 && <span className="alert  alert-success">Approved</span>}
              {(ap.document_verified === 0 || ap.document_verified === 2) && (
                <button
                  type="button"
                  className="action-btn btn-green"
                  onClick={() => handlePOOApprove(ap)}
                >
                  Approve
                </button>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>Port of Operations Not Available</p>
      )}
    </div>
  );
};

export default AgentPortOfOperations;
