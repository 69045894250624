import React from 'react';
import DisplayDocument from '../../../global/DisplayDocument';

const DisplayFields = ({ values, showDoc })=>{
  return (
    <div className="mx-2">
      <DisplayDocument 
        label="Form CAC IT/1 (Incorporated Trustees Application Form)"
        onClick={() => showDoc(values.cac_it_1)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.cac_it_1 ? true : false}
      />
      <DisplayDocument 
        label="CAC/IT Number"
        type="text"
        value={values.cac_it_no}
        textColClassName="document"
      />
      <DisplayDocument 
        label="Proof of corporate address(Utility bills or Tax payment receipt)"
        onClick={() => showDoc(values.corporate_address_proof)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={values.corporate_address_proof ? true : false}
      />
    </div>
  )
};

export default DisplayFields;