import React from 'react';
import DisplayDocument from './DisplayDocument';

const DisplayFields = ({ values, type, showDoc }) => {
  return (
    <div className="document-group">
      <DisplayDocument
        label="First Name"
        value={values.first_name}
        type="text"
        textColClassName="document"
      />
      <DisplayDocument
        label="Last Name"
        value={values.last_name}
        type="text"
        textColClassName="document"
      />
      <DisplayDocument label="Email" value={values.email} type="text" textColClassName="document" />
      <DisplayDocument
        label="Phone Number"
        value={values.mobile_number}
        type="text"
        textColClassName="document"
      />
      {/* {type === 'shareholders' && (
        <DisplayDocument
          label="Percentage of Shareholding"
          value={values.share_percentage ? `${values.share_percentage}%` : ''}
          type="text"
          textColClassName="document"
        />
      )} */}
      <DisplayDocument
        label="Date of birth"
        value={values.dob}
        type="text"
        textColClassName="document"
      />
      <DisplayDocument
        label="Nationality"
        value={values.nationality}
        type="text"
        textColClassName="document"
      />
      {/* <DisplayDocument
        label="National Identity number"
        value={values.national_identity_no}
        type="text"
        textColClassName="document"
      /> */}
      <DisplayDocument
        label="Residential Address"
        value={values.address}
        type="text"
        textColClassName="document"
      />
      <DisplayDocument
        label="Proof of Home Address"
        type="file"
        fileColClassName="document"
        onClick={() => showDoc(values.home_address_proof)}
        documentLabel="Document"
        hasDocument={values.home_address_proof ? true : false}
      />
    </div>
  );
};

export default DisplayFields;
