import React from 'react';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import NumberFormat from 'react-number-format';
import TextareaInput from '../../components/UI/TextareaInput';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';
import { InputFeedback } from '../../components/UI/TextField';

const SuspensionFormModal = (props) => {
  const { isOpen, toggle, userId, refreshTabData } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const handleFormSubmit = (values) => {
    // console.log('>> onSubmit values', values);
    dispatch(showLoading());
    const payload = {
      uuid: userId,
      suspension_reason: values.reason,
      suspension_fine: values.fine,
      suspended_by: user?.type || '',
    };
    POST(`/admin/suspendUser`, payload)
      .then(() => {
        dispatch(hideLoading());
        dispatch(showModal('User Suspended successfully', true));
        refreshTabData();
        toggle();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} container="lawear-page" className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject nopadding-sm">
          <h5>Suspend User</h5>
          <Formik
            initialValues={{ fine: '', reason: '' }}
            validationSchema={Yup.object({
              fine: Yup.number().required('Suspension Fine is Required'),
              reason: Yup.string().required('Suspension Reason is required'),
            })}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* {console.log('>> values, touched, errors, ', values, touched, errors)} */}
                <div className="text-left mb-4">
                  <label>Fine</label>
                  <div className="form-group">
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      decimalSeparator="."
                      value={values.fine}
                      className={`${
                        getIn(touched, 'fine') && getIn(errors, 'fine')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }`}
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      prefix=""
                      id="fine"
                      onValueChange={(e) => setFieldValue('fine', e.value)}
                    />
                    <InputFeedback error={getIn(touched, 'fine') && getIn(errors, 'fine')} />
                  </div>
                </div>
                <div className="text-left mb-4">
                  <label>Reason</label>
                  <TextareaInput
                    id="reason"
                    name="reason"
                    placeholder="Reason*"
                    cols=""
                    rows="6"
                    value={values.reason}
                    onChange={handleChange}
                    className="form-control"
                    onBlur={handleBlur}
                    error={getIn(touched, 'reason') && getIn(errors, 'reason')}
                  />
                </div>
                <div className="buttonsec1 nopadding" style={{ textAlign: 'center' }}>
                  <button type="submit" className="submitbut" disabled={isSubmitting}>
                    Suspend
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuspensionFormModal;
