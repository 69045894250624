import React from 'react';

const ShareIcon = () => <i className="fa fa-solid fa-share" />;

const ShareBtn = ({ data }) => {
  const handleShare = () => {
    if (navigator.canShare(data)) {
      navigator
        .share(data)
        .then(() => console.log('shared successfully'))
        .catch((e) => console.log('Error: ' + e));
    }
  };

  return (
    <div onClick={handleShare} className="share-btn float-end" title="share">
      <ShareIcon />
    </div>
  );
};

export default ShareBtn;
