import React from 'react';
import { getUserName } from '../../../helpers/utils';
import DisplayDocument from '../../../components/global/DisplayDocument';
import ShowAgentsData from '../ClientApproval/ShowAgentsData';

const AgentsDetails = ({ userDetails, images, formValues, details, showDoc }) => (
  <>
    <div className="profilecont">
      <div className="profilecontleft">
        <h4 style={{ fontSize: '32px', color: '#000', fontWeight: '600' }} className="mb-5">
          {getUserName(userDetails)}
        </h4>
        <h5
          style={{
            fontSize: '20px',
            color: '#000',
            fontWeight: '600',
            marginBottom: '20px',
          }}
          className="mb-5"
        >
          {userDetails?.user_id}
        </h5>
        <DisplayDocument
          label="Email"
          value={userDetails?.email}
          type="text"
          textColClassName="document mx-2"
        />
        <DisplayDocument
          label="Phone number"
          value={userDetails?.mobile_number}
          type="text"
          textColClassName="document mx-2"
        />
      </div>

      <div className="profilecontright"></div>
    </div>
    <hr />
    <ShowAgentsData
      showDoc={showDoc}
      images={images}
      formValues={formValues}
      userType={userDetails.type}
      details={details}
    />
  </>
);

export default AgentsDetails;
