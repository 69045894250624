import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import {
  addQueryParamToUrl,
  getQueryParamValue,
  getClientAddress,
  getClientName,
} from '../../../helpers/utils';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';
import SearchBar from '../../../components/global/SearchBar';

const ClientApprovalList = (props) => {
  const dispatch = useDispatch();
  const { match } = props;
  const [userList, setUserList] = useState([]);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );
  const [searchText, setSearchText] = useState(
    getQueryParamValue(props.location, 'searchText') || '',
  );

  const path_name = match.path;
  let user_type = 'application_client';

  if (path_name == '/lawyer/fowardingagent-approval-list') {
    user_type = 'forwarding_agent';
  } else if (path_name == '/lawyer/clearingagent-approval-list') {
    user_type = 'clearing_agent';
  } else if (path_name == '/lawyer/client-approval-list') {
    user_type = 'application_client';
  }

  const getUserList = (ut, page, perPage, st) => {
    dispatch(showLoading());
    const payload = {
      user_type: ut,
      page: page,
      per_page: perPage,
      sort_by: 'updated_at',
      sort_type: 'desc',
    };
    if (st) payload.search = st; // Need to add search field in BE

    POST('/users/getNonApprovedUsers', payload)
      .then(({ data }) => {
        // console.log(data);
        setUserList(data.users);
        dispatch(hideLoading());
        setTotalPages(data.total_count / perPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());

        setUserList([]);
      });
  };

  useEffect(() => {
    getUserList(user_type, selectedPage, itemsPerPage, searchText);
  }, [user_type, selectedPage, itemsPerPage, searchText]);

  let heading = '';
  if (user_type == 'clearing_agent') {
    heading = 'Clearing Agent Approval';
  } else if (user_type == 'forwarding_agent') {
    heading = 'Transporter Approval';
  } else if (user_type == 'application_client') {
    heading = 'Client Approval';
  }

  const handleView = (id) => {
    let path = '';
    if (user_type == 'clearing_agent') {
      path = 'clearingagent';
    } else if (user_type == 'forwarding_agent') {
      path = 'fowardingagent';
    } else if (user_type == 'application_client') {
      path = 'client';
    }

    return props.history.push(`/lawyer/${path}-approval/details?id=${id}`);
  };

  const handleSearch = (text) => {
    const value = text || '';
    addQueryParamToUrl(props.history, 'searchText', value);
    setSearchText(value);
  };

  const handleSearchClear = () => {
    if (searchText) {
      addQueryParamToUrl(props.history, 'searchText', '');
      setSearchText('');
      setUserList([]);
      setSelectedPage(1);
      setTotalPages(0);
    }
  };

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <div className="heading-with-btn">
              <div>
                <h2>{heading}</h2>
              </div>
              <div>
                <SearchBar
                  id="search"
                  onSearch={handleSearch}
                  onClearSearchText={handleSearchClear}
                  value={searchText}
                />
              </div>
            </div>
            <div className="tab-pane active" id="resp-tab7">
              <div className="table-responsive clearingtable">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th>Agent ID</th>
                      <th>Contact Info</th>
                      <th>Name &amp; Address</th>
                      <th>Registered At</th>
                      <th>Updated At</th>
                      <th>View Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.length > 0 &&
                      userList.map((x, index) => {
                        const cType = x.sub_type || x.type;

                        let nameLabel = '';
                        let addrLabel = '';
                        if (cType === 'application_client') {
                          nameLabel = 'Name:';
                          addrLabel = 'Address:';
                        } else {
                          nameLabel = 'Company Name:';
                          addrLabel = 'Company Address:';
                        }
                        return (
                          <tr key={index}>
                            <td>
                              <strong>{x.user_id || 'N/A'}</strong>
                            </td>
                            <td className="description1">
                              <p>Email: {x.email}</p> <p>Mobile: {x.mobile_number}</p>
                            </td>
                            <td className="description1">
                              <p>
                                {nameLabel} {getClientName(x)}
                              </p>
                              <p>
                                {addrLabel} {getClientAddress(x)}
                              </p>
                              {/* <p>Email: {x.email}</p> <p>Mobile: {x.mobile_number}</p> */}
                            </td>
                            <td>
                              <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                              <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                            </td>
                            <td>
                              <p>{moment(x.updated_at).format(DATE_FORMAT)}</p>
                              <p>{moment(x.updated_at).format(TIME_FORMAT)}</p>
                            </td>
                            <td>
                              <div>
                                <a onClick={() => handleView(x.uuid)} className="review1">
                                  View
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {userList.length <= 0 && <h3>No Data Found!</h3>}
              </div>
              {userList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientApprovalList;
