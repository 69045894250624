import React, { useState, useEffect, useRef } from 'react';
import { getIn } from 'formik';
import TextField from '../../../UI/TextField';
import { OTP_TIMER_SECONDS } from '../../../../helpers/constants';

const FormFields = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    phone,
    verifyUserOtp,
    resendOtp,
  } = props;

  const timerRef = useRef(null);
  const [second, setSecond] = useState(OTP_TIMER_SECONDS);
  const [reset, setReset] = useState(Date.now());

  const resetTimer = () => {
    setSecond(OTP_TIMER_SECONDS);
    setReset(Date.now());
  };

  // set second remaining after every second untill timeout
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setSecond((s) => {
        if (s > 0) {
          return s - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
    };
  }, [reset]);

  // clear timer after timeout
  useEffect(() => {
    if (second === 0) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, [second]);

  return (
    <>
      <label className="v2-text-label v2-text">Enter OTP</label>
      <TextField
        id="otp"
        type="number"
        name="otp"
        className="form-control"
        placeholder="0000"
        value={values.otp || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={getIn(touched, 'otp') && getIn(errors, 'otp')}
        onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
      />
      <div className="buttonsec">
        <button
          type="button"
          onClick={() => verifyUserOtp(phone, values.otp)}
          className="form-control submitbut"
          disabled={!values.otp}
        >
          Verify
        </button>
      </div>
      <div>
        <p>
          <span className="v2-text-label v2-text">
            OTP is sent to the Phone Number {`XXXXXX${String(phone).substr(phone?.length - 4)}`}.
          </span>
        </p>
        {second !== 0 && <p>Resend OTP in {second}s</p>}
        {second === 0 && phone && (
          <p>
            Did not recieve OTP?
            <span
              className="flat-btn mx-2"
              onClick={() => {
                resetTimer();
                resendOtp(phone);
              }}
            >
              Click here to Resend
            </span>
          </p>
        )}
      </div>
    </>
  );
};

export default FormFields;
