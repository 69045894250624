import React from 'react';
import DisplayDocument from '../../../components/global/DisplayDocument';
import DisplayFields from '../../../components/global/DirectorShareholderDocFields';
import { getRegYearType } from '../../../helpers/utils';
import { displayVMData } from '../../../components/global/DisplayVerifymeData';

const ShowAgentsData = (props) => {
  const { images = {}, showDoc, formValues, userType, details = {} } = props;

  let regCertDocLabel = '';
  let regCertTextLabel = '';
  if (userType === 'clearing_agent') {
    regCertDocLabel =
      'Certificate of registration with Nigeria Customs Service as a clearing agent (Document)';
    regCertTextLabel =
      'Certificate of registration with Nigeria Customs Service as a clearing agent (Certificate number)';
  }
  // else {
  //   regCertDocLabel =
  //     'Certificate of registration with Council for the Regulation of Freight Transport in Nigeria (CRFFN) (Document)';
  //   regCertTextLabel =
  //     'Certificate of registration with Council for the Regulation of Freight Transport in Nigeria (CRFFN) (Certificate number)';
  // }
  const regYear = getRegYearType(images);

  return (
    <>
      <div className="profilecont">
        <div className="profilecontleft py-0">
          <h3 className="mt-3">Documents</h3>
          <div className="row mx-0">
            {regYear === 1 && (
              <>
                <DisplayDocument
                  label="Status Report (Application for Registration)"
                  onClick={() => showDoc(images.registration_application_status)}
                  type="file"
                  fileColClassName="document"
                  documentLabel="Document"
                  hasDocument={images.registration_application_status ? true : false}
                />
              </>
            )}
            {regYear === 2 && (
              <>
                <DisplayDocument
                  label="CTC of Form CAC 1.1 (Application for Registration)"
                  onClick={() => showDoc(images.ctc_cac_1_1)}
                  type="file"
                  fileColClassName="document"
                  documentLabel="Document"
                  hasDocument={images.ctc_cac_1_1 ? true : false}
                />
              </>
            )}
            {regYear === 3 && (
              <>
                <DisplayDocument
                  label="Form CAC 2 (Statement of Share Capital and Return of Allotment)"
                  onClick={() => showDoc(images.cac_2)}
                  type="file"
                  fileColClassName="document"
                  documentLabel="Document"
                  hasDocument={images.cac_2 ? true : false}
                />
                <DisplayDocument
                  label="Form CAC 7 (Particulars of Directors)"
                  onClick={() => showDoc(images.cac_7)}
                  type="file"
                  fileColClassName="document"
                  documentLabel="Document"
                  hasDocument={images.cac_7 ? true : false}
                />
              </>
            )}
            <DisplayDocument
              label="Certificate of incorporation"
              onClick={() => showDoc(images.incorporation_certificate)}
              type="file"
              fileColClassName="document"
              documentLabel="Document"
              hasDocument={images.incorporation_certificate ? true : false}
            />
            <DisplayDocument
              label="RC NO"
              type="text"
              textColClassName="document"
              value={formValues.rc_no}
            />
            <DisplayDocument
              label="Memorandum and Articles of Association"
              onClick={() => showDoc(images.memorandum_article_association)}
              type="file"
              fileColClassName="document"
              documentLabel="Document"
              hasDocument={images.memorandum_article_association ? true : false}
            />
            <DisplayDocument
              label="Proof of corporate address (Utility bills or Tax payment receipt)"
              onClick={() => showDoc(images.corporate_address_proof)}
              type="file"
              fileColClassName="document"
              documentLabel="Document"
              hasDocument={images.corporate_address_proof ? true : false}
            />
            {userType === 'clearing_agent' && (
              <>
                <DisplayDocument
                  label={regCertDocLabel}
                  onClick={() => showDoc(images.registration_certificate)}
                  type="file"
                  fileColClassName="document"
                  documentLabel="Document"
                  hasDocument={images.registration_certificate ? true : false}
                />
                <DisplayDocument
                  label={regCertTextLabel}
                  type="text"
                  textColClassName="document"
                  value={formValues.registration_certificate_no}
                />
              </>
            )}
          </div>
        </div>

        <div className="profilecontright py-0">
          <h3 className="mt-3">Company Details</h3>
        </div>
      </div>
      <hr />
      <div className="profilecont">
        {details?.directors?.length > 0 ? (
          details.directors.map((director, index) => (
            <>
              <div className="profilecontleft py-0">
                <h3 className="mt-3">Director Details</h3>
                <DisplayFields
                  type="directors"
                  values={director}
                  key={director.uuid}
                  showDoc={showDoc}
                />
              </div>
              <div className="profilecontright py-0">
                {director?.verify_me_data?.length > 0 && <h3>NIN Phone Details</h3>}
                {director?.verify_me_data?.length > 0 &&
                  director.verify_me_data.map((vm) => displayVMData(vm, true))}
              </div>
            </>
          ))
        ) : (
          <div className="profilecontleft  py-0">
            <p>Director data not available.</p>
          </div>
        )}
      </div>
      {/* <hr />
      {details?.shareholders?.length > 0 ? (
        details.shareholders.map((shareholder, index) => (
          <>
            <div className="profilecont">
              <div className="profilecontleft py-0" key={shareholder.uuid}>
                <h3 className="mt-3">Shareholder {index + 1} Details</h3>
                <DisplayFields
                  type="shareholders"
                  values={shareholder}
                  key={shareholder.uuid}
                  showDoc={showDoc}
                />
              </div>
              <div className="profilecontright py-0">
                {shareholder?.verify_me_data?.length > 0 && <h3>NIN Phone Details</h3>}
                {shareholder?.verify_me_data?.length > 0 &&
                  shareholder.verify_me_data.map((vm) => displayVMData(vm, true))}
              </div>
            </div>
            <hr />
          </>
        ))
      ) : (
        <div className="profilecontleft py-0">
          <p>Shareholder's data not available.</p>
        </div>
      )} */}
    </>
  );
};

export default ShowAgentsData;
