import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import UpdatePassword from '../components/global/UpdatePassword';
import UserHeader from '../components/global/UserHeader';
import AppLoader from '../components/loaders/AppLoader';
import Dashboard from '../containers/Operator/Dashboard';
import DisputesDetails from '../components/global/DisputesDetails';
import DisputesList from '../components/global/DisputesList';
// import MessageList from '../containers/Operator/Messages/MessageList';
import NewJobDetail from '../containers/Operator/NewJobDetail';
import OnGoingJobs from '../containers/Operator/OnGoingJobs';
import EditProfile from '../containers/Operator/Profile/EditProfile';
import Profile from '../containers/Operator/Profile/Profile';
// import ReceiptList from '../containers/Operator/Receipts/ReceiptList';
// import ApprovalList from '../containers/Operator/Approval/ApprovalList';
// import ApprovalDetails from '../containers/Operator/Approval/ApprovalDetails';
import Notification from '../components/global/Notification';
import NotificationMenuItem from '../components/global/NotificationBadge';
import { NT_ON_DISPUTES, NT_ON_MY_JOBS, NT_ON_NEW_JOBS } from '../helpers/constants';
import { hideDocumentModal } from '../redux/user/UserActions';
import DocumentModal from '../components/Modals/DocumentModal';
import { readNotification } from '../redux/notification/NotificationActions';

import { ValidateRoutes } from './ClientRoute';
const OperatorRouting = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userStoreState = useSelector((state) => state.user);
  const { user } = userStoreState;

  const [siderMemuToggle, setSiderMemuToggle] = useState(false);

  const { match } = props;
  const { pathname } = props.location;

  if (isEmpty(user)) {
    return <AppLoader />;
  }

  const onMenuClick = (path, notificationTypes) => {
    history.push(path);
    setSiderMemuToggle(false);

    // mark the notifications of the menu as read
    if (notificationTypes) {
      dispatch(readNotification({ notificationTypes }));
    }
  };

  return (
    <div id="client-page">
      <Helmet>
        <title>GoAgent</title>
      </Helmet>
      <UserHeader />
      <main id="main" className="main-block">
        <div className="bodycont">
          <div style={{ clear: 'both' }}></div>

          <div className="tab">
            <div className="cormen-btn">
              <label htmlFor="cormid"></label>
            </div>
            <div className="cormen">
              <input
                id="cormid"
                type="checkbox"
                checked={siderMemuToggle}
                onChange={(e) => setSiderMemuToggle(e.target.checked)}
              />
              <ul>
                <button
                  className={`tablinks ${
                    pathname.includes('/operator/dashboard') ||
                    pathname.includes('/operator/new-job') ||
                    pathname.includes('/operator/ongoing-job')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => onMenuClick('/operator/dashboard', NT_ON_NEW_JOBS)}
                >
                  <NotificationMenuItem title="My Jobs" types={NT_ON_MY_JOBS} />
                </button>{' '}
                <button
                  className={`tablinks ${
                    pathname.includes('/operator/profile') ||
                    pathname.includes('/operator/edit-profile')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => onMenuClick('/operator/profile')}
                >
                  Profile
                </button>
                {/* <button className={`tablinks ${pathname.includes('/client-approval') ? 'active' : ''}`} onClick={() => onMenuClick('/operator/client-approval-list')}>
                  Approval
                </button> */}
                {/* <button
                  className={`tablinks ${pathname.includes('/operator/receipts') ? 'active' : ''}`}
                  onClick={() => {
                    onMenuClick('/operator/receipts');
                  }}
                >
                  My Receipts
                </button> */}
                <button
                  className={`tablinks ${pathname.includes('/dispute') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/operator/disputes', NT_ON_DISPUTES)}
                >
                  <NotificationMenuItem title="Claims" types={NT_ON_DISPUTES} />
                </button>
                {/* <button className={`tablinks ${pathname.includes('/messages') ? 'active' : ''}`} onClick={() => onMenuClick('/operator/messages')}>
                  My Message
                </button> */}
                <button
                  className={`tablinks ${pathname.includes('/notifications') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/operator/notifications')}
                >
                  Messages
                </button>
              </ul>
            </div>
          </div>

          <Switch>
            <ValidateRoutes
              exact
              path={`${match.url}/dashboard`}
              component={Dashboard}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/new-job/:id/details`}
              component={NewJobDetail}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/ongoing-job/:jobId/details`}
              component={OnGoingJobs}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />
            {/* <ValidateRoutes exact path={`${match.url}/client-approval-list`} component={ApprovalList} has_permission={get(user, 'type') === 'managenmnt_operator'} />
            <ValidateRoutes exact path={`${match.url}/client-approval/details`} component={ApprovalDetails} has_permission={get(user, 'type') === 'managenmnt_operator'} /> */}
            <ValidateRoutes
              exact
              path={`${match.url}/profile`}
              component={Profile}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/profile/update-password`}
              component={UpdatePassword}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />

            <ValidateRoutes
              exact
              path={`${match.url}/edit-profile`}
              component={EditProfile}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />
            {/* <ValidateRoutes
              exact
              path={`${match.url}/receipts`}
              component={ReceiptList}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            /> */}
            <ValidateRoutes
              exact
              path={`${match.url}/disputes`}
              component={DisputesList}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/dispute/details`}
              component={DisputesDetails}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />
            {/* <ValidateRoutes exact path={`${match.url}/messages`} component={MessageList} has_permission={get(user, 'type') === 'managenmnt_operator'} /> */}
            <ValidateRoutes
              exact
              path={`${match.url}/notifications`}
              component={Notification}
              has_permission={get(user, 'type') === 'managenmnt_operator'}
            />

            <Redirect from={match.url} to={`${match.url}/dashboard`} />
          </Switch>
          <div className="clearfix"></div>
        </div>
        {/* Modal to Display Document */}
        {userStoreState?.displayDocumentModal && (
          <DocumentModal
            isOpen={userStoreState.displayDocumentModal}
            toggle={() => dispatch(hideDocumentModal())}
            documentToShow={userStoreState.documentToDisplay}
            container="client-page"
          />
        )}
      </main>
    </div>
  );
};

export default OperatorRouting;
