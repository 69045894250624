import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { InputFeedback } from './TextField';
const SelectBox = ({ id, name, className, placeholder, value, onChange, error, options, ...props }) => {
  const InputClasses = classnames(
    'input',
    {
      'is-invalid': !!error,
    },
    className,
  );

  return (
    <>
      <select id={id} className={InputClasses} name={name} value={value} onChange={onChange} {...props}>
        {placeholder && (<option value="">{placeholder}</option>)}
        {Array.isArray(options)
          ? options.length !== 0 &&
            options.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))
          : !isEmpty(options) &&
            Object.keys(options).map((item, index) => (
              <option key={index} value={item}>
                {options[item]}
              </option>
            ))}
      </select>
      <InputFeedback error={error} />
    </>
  );
};
export default SelectBox;
