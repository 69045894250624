import React from 'react';
import { getIn } from 'formik';
import TextField from '../../../UI/TextField';
import FIleField from '../../../UI/FIleField';
import { acceptedFileTypes } from '../../../../helpers/constants';
import DisplayDocument from '../../../global/DisplayDocument';

const FormFields = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    images,
    showDoc,
  } = props;

  return (
    <>
      <div className="col-sm-12 col-lg-12 mb-3">
        {images.nepza_operating_license && (
          <DisplayDocument
            label="Operating License issued by NEPZA"
            onClick={() => showDoc(images.nepza_operating_license)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.nepza_operating_license ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Operating License issued by
            NEPZA
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="nepza_operating_license"
            onChange={(event) => setFieldValue('nepza_operating_license', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-12 mb-3">
        {images.ctc_situation_notice && (
          <DisplayDocument
            label="CTC of Notice of Situation"
            onClick={() => showDoc(images.ctc_situation_notice)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.ctc_situation_notice ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> CTC of Notice of Situation
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="ctc_situation_notice"
            onChange={(event) => setFieldValue('ctc_situation_notice', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-12 mb-3">
        {images.director_particulars && (
          <DisplayDocument
            label="Particulars of Directors"
            onClick={() => showDoc(images.director_particulars)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.director_particulars ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Particulars of Directors
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="director_particulars"
            onChange={(event) => setFieldValue('director_particulars', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-12 mb-3">
        {images.nepza_registration_certificate && (
          <DisplayDocument
            label="NEPZA Certificate of Registration"
            onClick={() => showDoc(images.nepza_registration_certificate)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.nepza_registration_certificate ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> NEPZA Certificate of
            Registration
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="nepza_registration_certificate"
            onChange={(event) =>
              setFieldValue('nepza_registration_certificate', event.target.files[0])
            }
            className="uploads"
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-12 mb-3">
        {images.share_capital_statement && (
          <DisplayDocument
            label="Statement of Share Capital"
            onClick={() => showDoc(images.share_capital_statement)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.share_capital_statement ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Statement of Share Capital
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="share_capital_statement"
            onChange={(event) => setFieldValue('share_capital_statement', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-12 mb-3">
        {images.return_allotement_statement && (
          <DisplayDocument
            label="Return of Allotment"
            onClick={() => showDoc(images.return_allotement_statement)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.return_allotement_statement ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Return of Allotment
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="return_allotement_statement"
            onChange={(event) =>
              setFieldValue('return_allotement_statement', event.target.files[0])
            }
            className="uploads"
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">NEPZA Number</label>
          <TextField
            id="nepza_no"
            type="text"
            name="nepza_no"
            className="form-control"
            placeholder="NEPZA Number"
            value={values.nepza_no}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'nepza_no') && getIn(errors, 'nepza_no')}
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-12 mb-3">
        {images.memorandum_article_association && (
          <DisplayDocument
            label="Memorandum and Articles of Association"
            onClick={() => showDoc(images.memorandum_article_association)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.memorandum_article_association ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Memorandum and Articles of
            Association
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="memorandum_article_association"
            onChange={(event) =>
              setFieldValue('memorandum_article_association', event.target.files[0])
            }
            className="uploads"
          />
        </div>
      </div>
    </>
  );
};

export default FormFields;
