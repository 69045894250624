import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Formik, getIn } from 'formik';
import { object, string, number } from 'yup';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { showModal } from '../../../redux/error/ErrorAction';
import { InputFeedback } from '../../../components/UI/TextField';
import { displayAmount, getErrorClassNames, converToNumber } from '../../../helpers/utils';
import { COUNTER_BID_MINIMUM } from '../../../helpers/constants';

const getPlaceBidMinimumAmount = (settings, jobDetails) => {
  if (!jobDetails || !settings) return 0;

  let amount = 0;
  // Vehicle RORO
  if (jobDetails.category === 1 && jobDetails.type === 1) {
    amount = settings.min_bid_vehicle_roro;
  } else if (jobDetails.category === 1 && jobDetails.type === 2) {
    // Vehicle Container
    amount = converToNumber(settings.min_bid_vehicle_container) * jobDetails.no_of_containers;
  } else if (jobDetails.category === 2) {
    // General Cargo
    amount = converToNumber(settings.min_bid_general_cargo) * jobDetails.no_of_containers;
  } else if (jobDetails.category === 3) {
    // Bulk Cargo - there is no containers for bulk cargo type so just use the minimun
    amount = converToNumber(settings.min_bid_bulk_cargo);
  }

  return converToNumber(amount);
};

const PlaceBidModal = (props) => {
  const {
    bidModal,
    toggleModal,
    edit,
    editItem = {},
    jobId,
    getJobList,
    customValue,
    setEdit,
    setJobId,
    job,
  } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const serviceData = useSelector((state) => state.enumData);
  const generalSettings = serviceData?.settings?.general || null;

  // use the job specific minimum_bid_amount from job details or the defaults from settings
  // the user should not be able to enter amount less than placeBidMinimum
  const placeBidMinimum =
    job?.minimum_bid_amount || getPlaceBidMinimumAmount(generalSettings, job) || 0;

  const counterBidMinimun = converToNumber(
    generalSettings?.counter_bid_amount || COUNTER_BID_MINIMUM,
  );

  const clearing_surcharge = generalSettings?.clearing_surcharge;
  const forwarding_surcharge = generalSettings?.forwarding_surcharge;

  const [truckData, setTruckData] = useState([]);

  // No Compatible trucks found
  const noCompatibleTrucks = () => {
    swal({
      title: 'No Compatible Truck Found',
      text:
        'None of your registered trucks is compatible for the job you are trying to bid for. To learn more about truck compatibility on GoAgent',
      icon: 'error',
      buttons: ['Cancel', 'Please read here'],
    }).then((result) => {
      toggleModal();
    });
  };

  const getTruckList = (uid, jid) => {
    dispatch(showLoading());
    const payData = {
      agent_uuid: uid,
      job_uuid: jid,
    };
    POST(`/trucks/getTruckByAgent`, payData)
      .then(({ data }) => {
        // console.log(data);
        if (data.trucks?.length > 0) {
          setTruckData(data.trucks);
        } else {
          noCompatibleTrucks();
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const getTruckDetails = (tid) => {
    POST(`/trucks/getDetails`, {
      truck_uuid: tid,
    })
      .then(({ data }) => {
        // console.log(data);
        if (data.truck) {
          setTruckData([data.truck]);
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  // when counter bidding fetch the truck details for the truck that was selected during bid placement
  // this is for displaying the truck details that was already selected
  useEffect(() => {
    if (edit && get(user, 'type') === 'forwarding_agent') {
      getTruckDetails(get(editItem, 'truck_uuid'));
    }
  }, [editItem, edit, user]);

  // when placing a bid on transport job, fetch the trucks that match to the job requirements
  useEffect(() => {
    if (jobId && get(user, 'type') === 'forwarding_agent') {
      if (!edit) getTruckList(get(user, 'user.uuid'), jobId);
    }
  }, [jobId, user, edit]);

  const winningBid = edit ? job?.winning_bid : null;
  const surcharge = customValue ? (customValue * converToNumber(clearing_surcharge)) / 100 : 0;

  let clearingExpenses = '';
  let forwardingExpenses = '';
  if (winningBid) {
    clearingExpenses =
      converToNumber(winningBid) -
      converToNumber(customValue) -
      converToNumber(surcharge) -
      counterBidMinimun;

    forwardingExpenses = converToNumber(winningBid) - counterBidMinimun;

    // the user should not be able to bid less than placeBidMinimum
    if (clearingExpenses < placeBidMinimum) clearingExpenses = placeBidMinimum;
    if (forwardingExpenses < placeBidMinimum) forwardingExpenses = placeBidMinimum;
  }

  return (
    <Modal
      isOpen={bidModal}
      toggle={toggleModal}
      container="clearing-agent-page"
      className="modal-xl"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggleModal}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5>{edit ? 'Edit Bids' : 'Place a Bid'}</h5>
          <Formik
            initialValues={{
              // custom_duty: '',
              clearing_expenses: clearingExpenses,
              fright_cost: forwardingExpenses,
              // comment: get(editItem, 'comment', '') || '',
              clearingAgent: get(user, 'type') === 'clearing_agent' ? true : false,
              truck: get(editItem, 'truck_uuid', '') ? get(editItem, 'truck_uuid', '') : '',
            }}
            enableReinitialize
            onSubmit={async (values) => {
              // console.log('values', values);
              let alertResponse = '';
              let alertDisplayed = false;
              if (
                (get(user, 'type') === 'forwarding_agent' &&
                  values.fright_cost <= placeBidMinimum) ||
                (get(user, 'type') === 'clearing_agent' &&
                  values.clearing_expenses <= placeBidMinimum)
              ) {
                alertDisplayed = true;
                alertResponse = await swal({
                  title: 'Attention!',
                  text:
                    'The amount you bided for is too low for this job. Please note that by proceeding with this bid, you are undertaking to complete this job at no additional cost.',
                  icon: 'warning',
                  buttons: ['Close', 'Proceed'],
                });
              }

              // this condition is for allowing submit when
              // 1: if alert is displayed and Proceed btn is clicked
              // 2: if alert not displayed and submitted
              // when alert is displayed and cancelled we should not submit
              if (alertResponse !== null) {
                const payload = {
                  // custom_duty: values.custom_duty,
                  // comment: values.comment,
                  job_uuid: jobId,
                  job_type: get(user, 'type') === 'clearing_agent' ? 'clearing' : 'forwarding',
                  is_dormant: alertDisplayed && alertResponse ? 1 : 0,
                };
                if (get(user, 'type') === 'forwarding_agent') {
                  payload.truck_uuid = values.truck;
                  payload.fright_cost = values.fright_cost;
                } else {
                  payload.clearing_expenses = Number(values.clearing_expenses).toFixed(2);
                }

                let url = '',
                  message = '';
                if (edit) {
                  payload.bid_id = editItem.uuid;
                  url = '/bids/update';
                  message = 'Bid update successfully';
                } else {
                  url = '/bids/create';
                  message = 'Bid Created successfully';
                }

                // console.log('>> Bid SUbmitted', payload);

                dispatch(showLoading());
                POST(url, payload)
                  .then(({ data }) => {
                    // console.log('data', data);
                    setJobId('');
                    getJobList();
                    toggleModal();
                    setEdit(false);
                    dispatch(showModal(message, true));
                    dispatch(hideLoading());
                  })
                  .catch((err) => {
                    dispatch(showModal(err.message));
                    dispatch(hideLoading());
                  });
              }
            }}
            validationSchema={object().shape({
              // custom_duty: number().when('clearingAgent', {
              //   is: true,
              //   then: number().required('Please add custom duty'),
              // }),

              clearing_expenses: number().when('clearingAgent', {
                is: true,
                then: number().required('Please add Agency, Shipping, Terminal and All charges'),
              }),

              fright_cost: number().when('clearingAgent', {
                is: false,
                then: number().required('Please add fright cost'),
              }),
              truck: string().when('clearingAgent', {
                is: false,
                then: string().required('Please select a truck'),
              }),
              // comment: string(),
            })}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                // handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = renderProps;

              // console.log('>> values,touched, errors', { values, touched, errors });
              const totalamount =
                converToNumber(customValue) +
                converToNumber(values.clearing_expenses) +
                converToNumber(surcharge);

              const fSurcharge = values.fright_cost
                ? (values.fright_cost * converToNumber(forwarding_surcharge)) / 100
                : 0;
              const fTotalAmount = converToNumber(values.fright_cost) + converToNumber(fSurcharge);

              return (
                <form className={'row'} onSubmit={handleSubmit}>
                  {values.clearingAgent ? (
                    <>
                      <div className="text-left mb-4">
                        <label>Custom Duty</label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          decimalSeparator="."
                          value={customValue}
                          className={getErrorClassNames(
                            getIn(touched, 'custom_value') && getIn(errors, 'custom_value'),
                          )}
                          displayType="input"
                          type="text"
                          thousandSeparator={true}
                          prefix=""
                          id="custom_value"
                          readOnly
                        />
                      </div>

                      <div className="text-left mb-4">
                        <label>Agency, Shipping, Terminal and All charges</label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          decimalSeparator="."
                          value={values.clearing_expenses}
                          className={getErrorClassNames(
                            getIn(touched, 'clearing_expenses') &&
                              getIn(errors, 'clearing_expenses'),
                          )}
                          displayType="input"
                          type="text"
                          thousandSeparator={true}
                          prefix=""
                          id="clearing_expenses"
                          name="clearing_expenses"
                          onValueChange={(e) => setFieldValue('clearing_expenses', e.value)}
                          autoComplete="off"
                          disabled={edit}
                        />
                        <InputFeedback
                          error={
                            getIn(touched, 'clearing_expenses') &&
                            getIn(errors, 'clearing_expenses')
                          }
                        />
                      </div>
                      <div className="text-left mb-4">
                        <label>Surcharge</label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          decimalSeparator="."
                          value={surcharge}
                          className={getErrorClassNames(
                            getIn(touched, 'surcharge') && getIn(errors, 'surcharge'),
                          )}
                          displayType="input"
                          type="text"
                          thousandSeparator={true}
                          prefix=""
                          id="surcharge"
                          name="surcharge"
                          readOnly
                          disabled
                        />
                        <InputFeedback
                          error={getIn(touched, 'surcharge') && getIn(errors, 'surcharge')}
                        />
                      </div>
                      <div className="text-left">
                        <h4>Total: {displayAmount(totalamount)}</h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-left mb-4">
                        <label>Truck</label>
                        <select
                          className={getErrorClassNames(
                            getIn(touched, 'truck') && getIn(errors, 'truck'),
                          )}
                          value={values.truck}
                          onBlur={handleBlur}
                          id="truck"
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue('truck', value);
                          }}
                          disabled={edit}
                        >
                          <optgroup label="Registration No  -  Brand  -  Model">
                            {truckData?.length > 0 ? (
                              <>
                                <option value="" label="Select"></option>
                                {truckData.map((truck) => (
                                  <option
                                    key={truck.uuid}
                                    value={truck.uuid}
                                    label={`${truck.reg_number} - ${truck.make} - ${truck.model}`}
                                  />
                                ))}
                              </>
                            ) : (
                              <option value="" label="Not Available" />
                            )}
                          </optgroup>
                        </select>
                        <InputFeedback error={getIn(touched, 'truck') && getIn(errors, 'truck')} />
                      </div>
                      <div className="text-left mb-4">
                        <label>Fright Cost</label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          decimalSeparator="."
                          value={values.fright_cost}
                          className={getErrorClassNames(
                            getIn(touched, 'fright_cost') && getIn(errors, 'fright_cost'),
                          )}
                          displayType="input"
                          type="text"
                          thousandSeparator={true}
                          prefix=""
                          id="fright_cost"
                          name="fright_cost"
                          onValueChange={(e) => setFieldValue('fright_cost', e.value)}
                          placeholder="0"
                          min={0}
                          autoComplete="off"
                          disabled={edit}
                        />
                        <InputFeedback
                          error={getIn(touched, 'fright_cost') && getIn(errors, 'fright_cost')}
                        />
                      </div>
                      <div className="text-left mb-4">
                        <label>Surcharge</label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          decimalSeparator="."
                          value={fSurcharge}
                          className={getErrorClassNames(
                            getIn(touched, 'surcharge') && getIn(errors, 'surcharge'),
                          )}
                          displayType="input"
                          type="text"
                          thousandSeparator={true}
                          prefix=""
                          id="surcharge"
                          name="surcharge"
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="text-left">
                        <h4>Total: {displayAmount(fTotalAmount)}</h4>
                      </div>
                    </>
                  )}
                  {/* <div className="text-left">
                    <label>Comment</label>

                    <textarea rows="3" placeholder="Message" className="tracking-textarea" value={values.comment} onChange={handleChange} id="comment"></textarea>
                  </div> */}
                  <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                    <button type="submit" className="submitbut">
                      Place Bid
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PlaceBidModal;
