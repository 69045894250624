import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { acceptedFileTypes } from '../../helpers/constants';

const InsuranceBuyConfirm = ({
  isOpen,
  toggle,
  container = 'client-page',
  insuranceAmount,
  onConfirm,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      container={container}
      className="modal-md document-verifying-msg"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalHeader className="p-0">
        <h3>Are you sure?</h3>
      </ModalHeader>
      <ModalBody>
        <div className="text-center fs-2">
          <p>
            Are you sure you want to continue buying insurance for{' '}
            <strong>{insuranceAmount}</strong>
          </p>
          <hr />
          <Formik
            initialValues={{
              marine_inspection_report: '',
            }}
            onSubmit={onConfirm}
            validationSchema={Yup.object().shape({
              marine_inspection_report: Yup.string().required(
                'Marine Inspection Report is required',
              ),
            })}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = renderProps;

              return (
                <form className={'row justify-content-center'} onSubmit={handleSubmit}>
                  <div className="col-lg-6 col-sm-8 col-xs-8 text-left fs-4 mb-3">
                    <label className="fs-3">Marine Inspection Report</label>
                    <input
                      type="file"
                      accept={acceptedFileTypes}
                      className={`${
                        getIn(touched, 'marine_inspection_report') &&
                        getIn(errors, 'marine_inspection_report')
                          ? 'is-invalid'
                          : ''
                      }`}
                      name="marine_inspection_report"
                      id="marine_inspection_report"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue('marine_inspection_report', file);
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    {getIn(touched, 'marine_inspection_report') &&
                    getIn(errors, 'marine_inspection_report') ? (
                      <div className="invalid-feedback mb-3">
                        {getIn(touched, 'marine_inspection_report') &&
                          getIn(errors, 'marine_inspection_report')}
                      </div>
                    ) : null}
                  </div>
                  <div className="buttonsec1 px-3">
                    {/* <button
                      type="button"
                      className="submitbut2 color-secondary float-lg-start"
                      onClick={toggle}
                    >
                      Cancel
                    </button> */}
                    <button type="submit" className="submitbut2">
                      Buy Insurance
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InsuranceBuyConfirm;
