import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import convertJsonToFormData from '../../helpers/helper';
import { POST } from '../../services/rest.service';
import { acceptedFileTypes } from '../../helpers/constants';
import { InputFeedback } from '../UI/TextField';
import DisplayDocument from '../global/DisplayDocument';
import { showDocumentModal } from '../../redux/user/UserActions';

const disputesSchema = Yup.object().shape({
  message: Yup.string().required('Message Required'),
});

const DisputeModal = (props) => {
  const {
    disputeModal,
    toggleDisputeModal,
    container,
    jobDetails,
    disputeDetails,
    refreshData,
  } = props;

  // console.log('>> DisputeModal', { jobDetails, disputeDetails });

  const dispatch = useDispatch();
  const showDoc = (docc) => dispatch(showDocumentModal(docc));
  const [dispute, setDispute] = useState(() => {
    if (disputeDetails) {
      let files = [];
      if (typeof disputeDetails.files === 'string') {
        files = JSON.parse(disputeDetails.files);
      } else if (typeof disputeDetails.files === 'object') {
        files = [...disputeDetails.files];
      }
      return {
        ...disputeDetails,
        files: files,
      };
    }
    return null;
  });

  const isClearingJob =
    jobDetails?.job_type_tags?.length > 0 &&
    jobDetails?.job_type_tags.find((jt) => jt === 'clearing');
  const insuranceIsPurchased = jobDetails?.insurance_purchashed_on;

  const disputeEdit = dispute;
  let heading = '';
  if (!isClearingJob && insuranceIsPurchased) {
    heading = disputeEdit ? 'Edit Insurance Indemnity' : 'Request Insurance Indemnity';
  } else {
    heading = disputeEdit ? 'Edit Dispute' : 'Open Dispute';
  }

  const removeDocFile = (file) =>
    setDispute((ps) => {
      const oldFiles = [...ps.files];
      const newFiles = [];
      oldFiles.forEach((f2) => {
        if (f2 !== file) {
          newFiles.push(f2);
        }
      });
      return {
        ...ps,
        files: newFiles,
      };
    });

  return (
    <Modal
      isOpen={disputeModal}
      toggle={toggleDisputeModal}
      container={container}
      className="modal-xl"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggleDisputeModal}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5>{heading}</h5>
          <Formik
            initialValues={{
              message: dispute?.message || '',
              evidence: [],
            }}
            onSubmit={(values) => {
              let url = '/jobs/createDispute';

              const agentUUID =
                disputeDetails?.agent_uuid ||
                jobDetails?.clearing_agent_uuid ||
                jobDetails?.forwarding_agent_uuid;

              const payLoad = {
                job_uuid: jobDetails?.uuid,
                agent_uuid: agentUUID,
                message: values.message,
                images: {
                  ...values.evidence,
                  // ...dispute.files,
                },
              };

              if (disputeEdit) {
                url = '/jobs/updateDispute';
                payLoad.dispute_uuid = dispute.uuid;
              }

              let formData = convertJsonToFormData(payLoad);
              // console.log(...formData);
              dispatch(showLoading());

              POST(url, formData)
                .then((data) => {
                  // console.log('data', data);
                  toggleDisputeModal();
                  dispatch(hideLoading());
                  let msg = '';
                  if (!isClearingJob && insuranceIsPurchased) {
                    msg = 'Insurance Indemnity requested';
                    if (disputeEdit) msg = 'Insurance Indemnity updated';
                  } else {
                    msg = 'Dispute opened';
                    if (disputeEdit) msg = 'Dispute updated';
                  }
                  msg += ' Sucessfully!!';

                  dispatch(showModal(msg, true));
                  refreshData();
                })
                .catch((err) => {
                  dispatch(showModal(err.message));
                  dispatch(hideLoading());
                });
            }}
            validationSchema={disputesSchema}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = renderProps;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="text-left">
                    <label>Message</label>
                    <textarea
                      rows="3"
                      placeholder="Message"
                      value={values.message}
                      className={`form-control ${
                        getIn(touched, 'message') && getIn(errors, 'message') ? 'is-invalid' : ''
                      }`}
                      name="message"
                      onChange={(event) => setFieldValue('message', event.target.value)}
                      onBlur={handleBlur}
                    ></textarea>
                    <InputFeedback error={getIn(touched, 'message') && getIn(errors, 'message')} />
                  </div>
                  <div className="text-left">
                    <label>
                      <p>Documents</p>
                      <p>
                        {dispute?.files?.length > 0 &&
                          dispute.files.map((obj, i) => (
                            <DisplayDocument
                              label={`Document: ${i + 1}`}
                              onClick={() => showDoc(obj)}
                              type="file"
                              fileColClassName="disputes-img dispute-doc"
                              nolabel
                              // postfixContent={
                              //   <span className="close ps-2" onClick={() => removeDocFile(obj)}>
                              //     &times;
                              //   </span>
                              // }
                            />
                          ))}
                      </p>
                    </label>
                    <input
                      type="file"
                      accept={acceptedFileTypes}
                      className={`form-control ${
                        getIn(touched, 'evidence') && getIn(errors, 'evidence') ? 'is-invalid' : ''
                      }`}
                      name="evidence"
                      multiple
                      // required
                      onChange={(event) => setFieldValue('evidence', event.target.files)}
                      onBlur={handleBlur}
                    />
                    <InputFeedback
                      error={getIn(touched, 'evidence') && getIn(errors, 'evidence')}
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <button type="submit" className="submitbut">
                      Submit
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DisputeModal;
