import * as Yup from 'yup';
import { get, filter, isEmpty } from 'lodash';
import { PHONE_NUMBER_REGEX } from './constants';

const convertJsonToFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data || '';
    if (value) {
      formData.append(parentKey, value);
    }
  }
}

export default convertJsonToFormData;

// adding custom unique method
// method validates if array of object has unique enteries. i.e when there is a duplicate value it throws error
Yup.addMethod(Yup.array, 'uniqueProperty', function(propertyPath, message) {
  return this.test('unique', '', function(list) {
    const errors = [];

    list.forEach((item, index) => {
      const propertyValue = get(item, propertyPath);

      if (propertyValue && filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          }),
        );
      }
    });

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});

export const agentSchema = Yup.object().shape({
  rc_no: Yup.string().required('Please enter RC Number'),
  registration_certificate_no: Yup.string().when('userType', {
    is: 'clearing_agent',
    then: Yup.string().required('Please add Certificate of registration number'),
  }),

  // shareholder: Yup.string().required('Please add shareholder'),
  // director: Yup.string().required('Please add director'),
});

export const userSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  address: Yup.string().required('Address is required'),
  dob: Yup.string().required('DOB is required'),
  nationality: Yup.string().required('Nationality is required'),
  mobile_number: Yup.string()
    .required('Phone Number is required')
    .matches(PHONE_NUMBER_REGEX, 'Enter Phone Number without Country Code and without Space'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
});
