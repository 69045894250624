import React, { useEffect, useState, useCallback } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import { isEmpty } from 'lodash';
import swal from 'sweetalert';
import classnames from 'classnames';
import AppLoader from '../../../components/loaders/AppLoader';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { GET, POST } from '../../../services/rest.service';
import { showModal } from '../../../redux/error/ErrorAction';
import PlaceBidModal from './PlaceBidModal';
import JobDetailsModal from '../JobDetailsModal';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  NT_CA_JOBS_UNDER_BIDDING,
  NT_CA_JOBS_COMPLETED,
  COUNTER_BID_MINIMUM,
} from '../../../helpers/constants';
import {
  displayAmount,
  accessGeneralOrCargoType,
  toTitleCase,
  addQueryParamToUrl,
  getQueryParamValue,
  checkAgentOperatesOnThisPort,
} from '../../../helpers/utils';
import NotificationMenuItem from '../../../components/global/NotificationBadge';
import { showSuspensionModal } from '../../../redux/user/UserActions';
import DisplayDocument from '../../../components/global/DisplayDocument';
import DocumentsVerifyingMsg from '../../../components/Modals/DocumentsVerifyingMsg';
import { readNotification } from '../../../redux/notification/NotificationActions';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';

const ClearingAgentBids = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [documentVerifyingModal, setDocumentVerifyingModal] = useState(false);
  const toggleDocumentVerifyingModal = () => setDocumentVerifyingModal((s) => !s);

  // console.log(user);
  const serviceData = useSelector((state) => state.enumData);
  const agentPortsOfOperations = useSelector((state) => state.user.agentPortsOfOperations);

  const counterBidMinimun =
    serviceData?.settings?.general?.counter_bid_amount || COUNTER_BID_MINIMUM;
  // console.log(serviceData);
  const [tab, setTab] = useState(getQueryParamValue(props.location, 'tab')?.toString() || '5');
  const [jobList, setJobList] = useState([]);
  // const [jobList, setClientJobList] = useState([]);
  const [jobId, setJobId] = useState('');
  const [editItem, setEditItem] = useState({});
  const [edit, setEdit] = useState(false);
  const [truckCheck, setTruckCheck] = useState(false);
  const [customValue, setCustomValue] = useState(0);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const resetTabStates = () => {
    setJobList([]);
    setSelectedPage(1);
    setTotalPages(0);
  };

  const switchTab = (data, notificationTypes) => {
    resetTabStates();
    setTab(data);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'tab', data);

    // mark the notifications of the tab as read
    if (notificationTypes) {
      dispatch(readNotification({ notificationTypes }));
    }
  };

  const [bidModal, setBidModal] = useState(false);
  const [viewbidModal, setViewBidModal] = useState(false);
  const [jobModal, setJobModal] = useState(false);

  const toggleBidModal = useCallback(
    (id, val) => {
      setEdit(false);

      setBidModal(!bidModal);
      if (!bidModal) {
        setCustomValue(val);
        setJobId(id);
      } else {
        setJobId('');
      }
    },
    [bidModal],
  );

  const toggleviewBidModal = () => {
    setViewBidModal(!viewbidModal);
  };

  const toggleJobModal = (id) => {
    setJobModal((s) => !s);
    if (id) {
      setJobId(id);
    }
  };

  //edit modal
  const editModal = (item) => {
    setEdit(true);
    dispatch(showLoading());
    setJobId(item.uuid);
    POST(`/bids/getByUserBidByJob`, { job_uuid: item.uuid })
      .then(({ data }) => {
        // console.log('data', data);
        setCustomValue(item.custom_duty);

        dispatch(hideLoading());
        // console.log(item);
        setEditItem(data.bid);
        setBidModal(!bidModal);
      })
      .catch((err) => {
        dispatch(hideLoading());

        dispatch(showModal(err.message));
      });
  };
  //view modal
  const viewModal = (item) => {
    // setEdit(true);
    dispatch(showLoading());
    setJobId(item.uuid);
    POST(`/bids/getByUserBidByJob`, { job_uuid: item.uuid })
      .then(({ data }) => {
        // console.log('data', data);
        setCustomValue(item.custom_duty);

        dispatch(hideLoading());
        // console.log(item);
        setEditItem(data.bid);
        setViewBidModal(!bidModal);
      })
      .catch((err) => {
        dispatch(hideLoading());

        dispatch(showModal(err.message));
      });
  };

  //truck status
  const checkStatus = () => {
    POST(`/users/isEligibleForBid`)
      .then(({ data }) => {
        // console.log('truck eligible', data);
        if (data.has_trucks >= 1 && data.has_truck_verfied == true) {
          setTruckCheck(true);
          // console.log('sad');
        } else {
          setTruckCheck(false);
          // console.log('sdf');
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  useEffect(() => {
    let pagination = {
      type: tab,
      selectedPage: selectedPage,
      itemsPerPage,
    };
    getJobList(pagination);
    checkStatus();
  }, [selectedPage, tab, itemsPerPage]);

  const getJobList = (type) => {
    // console.log('type', type);
    dispatch(showLoading());
    let url = `/jobs/getByStatus/${type.type}?page=${type.selectedPage}&per_page=${type.itemsPerPage}`;
    if (type.type === '5') {
      url += '&sort_type=desc&sort_by=created_at';
    }
    GET(url)
      .then(({ data }) => {
        // console.log('data', data);
        const jobs = [];
        if (type.type === '8') {
          setJobList(data.jobs);
        } else {
          data.jobs?.length > 0 &&
            data.jobs.forEach((j) => {
              if (j.current_user_bid) {
                jobs.push(j);
              }
            });
          setJobList(jobs);
        }
        dispatch(hideLoading());
        setTotalPages(data.total_count / type.itemsPerPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  //checkEligible
  const checkEligible = () => {
    // dispatch(showModal('you have not elegible for make bid'));
    swal({
      title: 'Your have not elegibility for make bid!',
      text: 'You have to add Truck.Do you want to add truck?',
      icon: 'error',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      if (result) {
        props.history.push('/forwarding-agent/trucks');
      }
    });
    // toggleBidModal(x)
  };

  const handleCounterBid = (a) => {
    if (a.winning_bid <= counterBidMinimun) return;
    editModal(a);
  };

  const onCounterBid = (jobDetails) => {
    const regFeePaid = user?.user?.registration_fee_paid === 1;
    const userIdVerified = user?.user?.identity_verified === 1;
    const agentCanBid = userIdVerified && regFeePaid;
    const userSuspended = user?.user?.identity_verified === 3;

    const isDormantBid = jobDetails.current_user_bid && jobDetails.is_dormant_bid === 1;

    if (jobDetails.current_user_bid && jobDetails.winning_bid === jobDetails.current_user_bid) {
      dispatch(showModal('Your bid is the winning bid', true));
      return null;
    } else if (isDormantBid) {
      dispatch(
        showModal('You cannot place bid on this job because the bid you have placed is dormant'),
      );
      return null;
    } else if (
      jobDetails.is_forwarding_only === false &&
      !checkAgentOperatesOnThisPort(agentPortsOfOperations, jobDetails.clearing_discharge_port)
    ) {
      dispatch(
        showModal(
          'You cannot place bid on this job since it discharges outside of your ports of operation',
        ),
      );
      return null;
    } else if (userSuspended) {
      dispatch(showSuspensionModal());
    } else if (!agentCanBid) {
      // here no verification check modal because it is checked when menu is clicked
      toggleDocumentVerifyingModal();
    } else if (user.type === 'forwarding_agent' && !truckCheck) {
      checkEligible();
    } else if (jobDetails.current_user_bid) {
      handleCounterBid(jobDetails);
    }
  };

  const closePlaceBidModal = useCallback(() => toggleBidModal('', ''), [toggleBidModal]);

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }
  return (
    <>
      <div id="tabblock3" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>My Jobs</h2>
            <div className="servicetab">
              <ul className="nav nav-tabs responsive" id="nonResponsiveTabs1">
                <li className={`test-class ${tab === '5' ? 'active' : ''}`}>
                  <a
                    className="deco-none red-class"
                    onClick={() => switchTab('5', NT_CA_JOBS_UNDER_BIDDING)}
                  >
                    <NotificationMenuItem
                      title="Jobs under Bidding"
                      types={NT_CA_JOBS_UNDER_BIDDING}
                    />
                  </a>
                </li>
                <li className={`test-class ${tab === '1' ? 'active' : ''}`}>
                  <a onClick={() => switchTab('1')}>Jobs in Progress</a>
                </li>
                <li className={`${tab === '8' ? 'active' : ''}`}>
                  <a className="deco-none" onClick={() => switchTab('8', NT_CA_JOBS_COMPLETED)}>
                    <NotificationMenuItem title="Completed Jobs" types={NT_CA_JOBS_COMPLETED} />
                  </a>
                </li>
              </ul>

              <div className="tab-content responsive">
                {tab === '5' && (
                  <div className={`tab-pane ${tab === '5' ? 'active' : ''}`} id="resp-tab4">
                    <div className="table-responsive clearingtable agent-bids-table">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Descriptions</th>
                            <th>Wining Bid</th>
                            <th>My Bid</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList.length > 0 &&
                            jobList.map((x, index) => {
                              let disableCounterBid = false;
                              if (x.current_user_bid && x.winning_bid === x.current_user_bid) {
                                disableCounterBid = true;
                              }
                              const AgentOperatesOnJobPort = checkAgentOperatesOnThisPort(
                                agentPortsOfOperations,
                                x.clearing_discharge_port,
                              );
                              const isDormantBid = x.is_dormant_bid === 1;

                              const isClearingAgent = user.type === 'clearing_agent';
                              const counterBidClass = classnames('detailsdiv2', {
                                'disabled-btn':
                                  (!AgentOperatesOnJobPort && isClearingAgent) ||
                                  disableCounterBid ||
                                  isDormantBid,
                              });

                              const isWinningBid = x.current_user_bid === x.winning_bid;

                              const myBidAmountColor = classnames('price', {
                                'color-green': isWinningBid,
                                'color-red': !isWinningBid || isDormantBid,
                              });

                              return (
                                <tr key={index}>
                                  <td>
                                    {`${x.job_id}-${x.is_forwarding_only ? 'T' : 'C'}` || 'N/A'}
                                  </td>
                                  <td className="description1">
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-12">
                                        <h4>
                                          {toTitleCase(serviceData.job.category[x.category]) ||
                                            'N/A'}
                                        </h4>
                                        <p>
                                          {x.type &&
                                            toTitleCase(serviceData.job.vehicle_type[x.type])}
                                        </p>
                                        <p>
                                          {x.type_of_cargo &&
                                            accessGeneralOrCargoType(x, serviceData)}
                                        </p>
                                      </div>
                                      <div className="col-lg-6 col-sm-12 align-self-center">
                                        <a
                                          onClick={() => toggleJobModal(x.uuid)}
                                          className="detailsdiv color-secondary"
                                        >
                                          View Details
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <strong className="price">
                                      {x.winning_bid ? displayAmount(x.winning_bid) : '-'}
                                    </strong>
                                  </td>
                                  <td>
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-12">
                                        <strong className={myBidAmountColor}>
                                          {x.current_user_bid
                                            ? displayAmount(x.current_user_bid)
                                            : '-'}
                                        </strong>
                                      </div>
                                      <div className="col-lg-6 col-sm-12">
                                        {x.current_user_bid && (
                                          <a
                                            className="detailsdiv color-secondary"
                                            onClick={() => viewModal(x)}
                                          >
                                            View Bid
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a onClick={() => onCounterBid(x)} className={counterBidClass}>
                                      Counter Bid
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                    </div>
                  </div>
                )}
                {tab === '1' && (
                  <div className={`tab-pane ${tab === '1' ? 'active' : ''}`} id="resp-tab5">
                    <div className="table-responsive clearingtable">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Desciption</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList.length > 0 &&
                            jobList.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  {x.job_id}-{x.is_forwarding_only ? 'T' : 'C'}
                                </td>
                                <td className="description1">
                                  <h4>
                                    {toTitleCase(serviceData.job.category[x.category]) || 'N/A'}
                                  </h4>
                                  <p>
                                    {x.type && toTitleCase(serviceData.job.vehicle_type[x.type])}
                                  </p>
                                  <p>
                                    {x.type_of_cargo && accessGeneralOrCargoType(x, serviceData)}
                                  </p>
                                </td>
                                <td>
                                  <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                                  <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                                </td>
                                <td>
                                  {user.type === 'forwarding_agent' ? (
                                    <a
                                      onClick={() => {
                                        props.history.push(
                                          `/forwarding-agent/job-status?id=${x.uuid}`,
                                        );
                                      }}
                                      className="review"
                                    >
                                      View Details
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() => {
                                        props.history.push(
                                          `/clearing-agent/job-status?id=${x.uuid}`,
                                        );
                                      }}
                                      className="review"
                                    >
                                      View Details
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                    </div>
                  </div>
                )}
                {tab === '8' && (
                  <div className={`tab-pane ${tab === '8' ? 'active' : ''}`} id="resp-tab6">
                    <div className="table-responsive clearingtable">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Descriptions</th>
                            <th>Date Started</th>
                            <th>Date Completed</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList.length > 0 &&
                            jobList.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  {x.job_id}-{x.is_forwarding_only ? 'T' : 'C'}
                                </td>
                                <td className="description1">
                                  <h4>
                                    {toTitleCase(serviceData.job.category[x.category]) || 'N/A'}
                                  </h4>
                                  <p>
                                    {x.type && toTitleCase(serviceData.job.vehicle_type[x.type])}
                                  </p>
                                  <p>
                                    {x.type_of_cargo && accessGeneralOrCargoType(x, serviceData)}
                                  </p>
                                </td>
                                <td className="pt-1">
                                  {x.assigned_at ? (
                                    <>
                                      <p>{moment(x.assigned_at).format(DATE_FORMAT)}</p>
                                      <p>{moment(x.assigned_at).format(TIME_FORMAT)}</p>
                                    </>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </td>
                                <td className="pt-1">
                                  <p>
                                    {moment(
                                      x.clearing_completed_at || x.forwarding_completed_at,
                                    ).format(DATE_FORMAT)}
                                  </p>
                                  <p>
                                    {moment(
                                      x.clearing_completed_at || x.forwarding_completed_at,
                                    ).format(TIME_FORMAT)}
                                  </p>
                                </td>
                                <td>
                                  {user.type === 'forwarding_agent' ? (
                                    <a
                                      onClick={() =>
                                        props.history.push(
                                          `/forwarding-agent/job/details?id=${x.uuid}`,
                                        )
                                      }
                                      className="review"
                                    >
                                      View Details
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() =>
                                        props.history.push(
                                          `/clearing-agent/job/details?id=${x.uuid}`,
                                        )
                                      }
                                      className="review"
                                    >
                                      View Details
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                    </div>
                  </div>
                )}
              </div>
              {jobList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Bid modal  */}
      {bidModal && (
        <PlaceBidModal
          bidModal={bidModal}
          toggleModal={closePlaceBidModal}
          edit={edit}
          editItem={editItem}
          jobId={jobId}
          getJobList={() =>
            getJobList({
              type: tab,
              selectedPage: selectedPage,
              itemsPerPage,
            })
          }
          customValue={customValue}
          setEdit={setEdit}
          setJobId={setJobId}
          job={jobList.find((j) => j.uuid == jobId)}
        />
      )}
      {/* Job Details modal  */}
      {jobModal && (
        <JobDetailsModal isOpen={jobModal} toggle={() => toggleJobModal('')} jobId={jobId} />
      )}
      <Modal
        isOpen={viewbidModal}
        toggle={toggleviewBidModal}
        container="clearing-agent-page"
        className="modal-md"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={toggleviewBidModal}>
            &times;
          </button>
        </div>
        <ModalBody>
          <div className="reson-reject">
            <h5>View Bid</h5>
            <div>
              {editItem?.is_dormant === 1 && (
                <p className="alert alert-danger">
                  This Bid is a Dormant Bid. <br />
                  Meaning the amount you bid is lower than the{' '}
                  <strong>minimum bid amount</strong> acceptable for the job
                </p>
              )}
              {user.type === 'clearing_agent' ? (
                <>
                  <DisplayDocument
                    label="Custom Duty"
                    value={displayAmount(customValue)}
                    type="text"
                    textColClassName="document"
                  />
                  <DisplayDocument
                    label="Agency, Shipping, Terminal and All charges"
                    value={displayAmount(editItem.clearing_expenses)}
                    type="text"
                    textColClassName="document"
                  />
                  <DisplayDocument
                    label="Surcharge"
                    value={displayAmount(editItem.surcharge)}
                    type="text"
                    textColClassName="document"
                  />
                </>
              ) : (
                <>
                  <DisplayDocument
                    label="Fright Cost"
                    value={displayAmount(editItem.fright_cost)}
                    type="text"
                    textColClassName="document"
                  />
                  <DisplayDocument
                    label="Surcharge"
                    value={displayAmount(editItem.surcharge)}
                    type="text"
                    textColClassName="document"
                  />
                </>
              )}
              {/* <div className="text-left">
                <label>Comment:</label>
                {editItem.comment}
              </div> */}
            </div>
          </div>
        </ModalBody>
      </Modal>
      {documentVerifyingModal && (
        <DocumentsVerifyingMsg
          isOpen={documentVerifyingModal}
          toggle={toggleDocumentVerifyingModal}
          container="clearing-agent-page"
        />
      )}
    </>
  );
};

export default ClearingAgentBids;
