import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { capitalize, isEmpty } from 'lodash';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import Payments from '../../../components/global/Payments';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import InsuranceBuyConfirm from '../../../components/Modals/InsuranceBuyConfirm';
import NoInsuranceWarning from '../../../components/Modals/NoInsuranceWarning';
import { PAYMENT_RELATED_TO_JOB, DATE_FORMAT, DATE_TIME_FORMAT } from '../../../helpers/constants';
import convertJsonToFormData from '../../../helpers/helper';
import {
  displayAmount,
  accessGeneralOrCargoType,
  getRiskPercentage,
  getJobStatus,
  converToNumber,
} from '../../../helpers/utils';
import PaymentStepsDisplay from './PaymentStepsDisplay';
import tick from '../../../../assets/img/tick.png';

const calculateInsuranceAmount = (job, serviceData) => {
  if (job && serviceData) {
    // insurance amount = value of goods * Risk percentage
    const goodsVal = converToNumber(job?.goods_value);
    const riskPercentage = getRiskPercentage(job?.goods_nature, serviceData);
    const insuranceAmount = goodsVal * riskPercentage;
    // console.log('>> insuranceAmount', insuranceAmount);
    return insuranceAmount;
  }
  return 0;
};

const calculatePaymentAmount = (job, bid, serviceData) => {
  if (job && bid && serviceData) {
    let a = converToNumber(bid.offer_price);
    // add insurance amount to total if insurance is purchased
    if (job?.is_forwarding_only === true && job?.insurance_purchashed_on)
      a += calculateInsuranceAmount(job, serviceData);
    return a;
  }
  return 0;
};

const PaymentFirstStep = (props) => {
  const params = new URLSearchParams(props.location.search);

  const bid_id = params.get('bid_id');
  const job_id = params.get('job_id');
  const history = useHistory();
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.enumData);

  const [job, setJob] = useState({});
  const [bid, setBid] = useState({});

  const [showInsuranceConfirm, setShowInsuranceConfirm] = useState(false);
  const toggleShowInsuranceConfirm = () => setShowInsuranceConfirm((s) => !s);

  const [showNoInsuranceWarning, setShowNoInsuranceWarning] = useState(false);
  const toggleShowNoInsuranceWarning = useCallback(() => setShowNoInsuranceWarning((s) => !s), []);

  const showInsuranceStep = job?.is_forwarding_only === true;

  const [step, setStep] = useState(1);

  //job details
  const getjob = useCallback(
    (jid) => {
      dispatch(showLoading());
      if (jid) {
        return GET(`/jobs/getDetails/${jid}`)
          .then(({ data }) => {
            // console.log(data.job);
            setJob(data.job);
            dispatch(hideLoading());
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    },
    [dispatch],
  );

  // bid details
  const getBidDetails = useCallback(
    (bid) => {
      dispatch(showLoading());
      if (bid) {
        const payload = {
          bid_uuid: bid,
        };
        POST(`/bids/getBidDetails`, payload)
          .then(({ data }) => {
            // console.log(data.bid);
            setBid(data.bid);
            dispatch(hideLoading());
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    getjob(job_id);
  }, [job_id, getjob]);

  useEffect(() => {
    getBidDetails(bid_id);
  }, [bid_id, getBidDetails]);

  useEffect(() => {
    if (job) {
      if (job?.is_forwarding_only === true && job?.insurance_purchashed_on) {
        // if insurance is already bought skip insurance step
        setStep(2);
      }
    }
  }, [job]);

  const nextStep = useCallback(() => setStep((s) => s + 1), []);
  const prevStep = useCallback(() => setStep((s) => s - 1), []);

  const handlepaymentSuccess = useCallback(() => {
    nextStep(); // change step
    setTimeout(() => {
      history.replace(`/client/job/details?id=${job_id}`);
    }, 2000);
  }, [job_id, history, nextStep]);

  const handleBuyInsurance = (val) => {
    // console.log('>> handleBuyInsurance', val);
    const payload = {
      job_uuid: job_id,
      insurance_purchashed_on: moment().format(DATE_TIME_FORMAT),
      marine_inspection_report: val.marine_inspection_report,
    };
    const formData = convertJsonToFormData(payload);
    dispatch(showLoading());
    POST(`/jobs/update`, formData)
      .then(({ data }) => {
        dispatch(showModal('Insurance bought successfully!', true));
        dispatch(hideLoading());
        getjob(job_id).then(() => {
          toggleShowInsuranceConfirm();
          nextStep();
        });
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  const handleProceedWithoutInsurance = useCallback(() => {
    // close the warning popup
    toggleShowNoInsuranceWarning();
    // go to next step
    nextStep();
  }, [nextStep, toggleShowNoInsuranceWarning]);

  const handleBackFromPaymentMethod = useCallback(
    (paymentStep = 1) => {
      if (showInsuranceStep && job?.insurance_purchashed_on) {
        if (paymentStep === 2) {
          // the payment component also has internal steps 1 & 2, so need to handle back properly
          prevStep();
        } else {
          dispatch(showModal('Insurance already purchased!', true));
        }
      } else if (step > 1) {
        prevStep();
      } else {
        history.goBack();
      }
    },
    [dispatch, prevStep, history, showInsuranceStep, job, step],
  );

  const insuranceAmount = calculateInsuranceAmount(job, serviceData);
  const paymentAmount = calculatePaymentAmount(job, bid, serviceData);

  return (
    <>
      <div id="tabblock3" className="tabcontent">
        <section className="bodycont">
          <div className="container">
            <div>
              <h2>
                <span onClick={props.history.goBack} style={{ cursor: 'pointer', float: 'left' }}>
                  <LeftArrowSvg />
                </span>
                Payment
              </h2>
            </div>
            <PaymentStepsDisplay step={step} showInsuranceStep={showInsuranceStep} />
            <div className="payment-info">
              {showInsuranceStep && step === 1 && (
                <div>
                  <h3>Insurance</h3>
                  <hr className="mb-0" />
                  <div className="payment-details-block">
                    Nature of Goods:{' '}
                    <strong>{`${serviceData.job.nature_of_goods[job?.goods_nature]} Risk`}</strong>
                  </div>
                  <div className="payment-details-block1 price">
                    Goods Value: {displayAmount(converToNumber(job?.goods_value))}
                  </div>
                  <div className="buttonsec">
                    <button
                      className="submitbut  mt-15 btn-light"
                      onClick={() => history.goBack()}
                      style={{ textAlign: 'center' }}
                    >
                      Back
                    </button>
                    <button
                      onClick={toggleShowInsuranceConfirm}
                      className="submitbut  mt-15"
                      data-toggle="modal"
                      data-target="#myModal"
                      style={{ textAlign: 'center', marginTop: '8px' }}
                    >
                      Buy Insurance {displayAmount(insuranceAmount)}
                    </button>
                    <button
                      onClick={toggleShowNoInsuranceWarning}
                      className="submitbut  mt-15"
                      data-toggle="modal"
                      data-target="#myModal"
                      style={{ textAlign: 'center', marginTop: '8px' }}
                    >
                      Proceed without insurance
                    </button>
                  </div>
                </div>
              )}
              {((showInsuranceStep && step === 2) || (!showInsuranceStep && step === 1)) && (
                <div>
                  <h3>Payment Details</h3>
                  <hr className="mb-0" />
                  {job ? (
                    <div className="payment-details-block">
                      {job.is_forwarding_only ? 'Transport Service' : 'Clearing Service'}
                    </div>
                  ) : (
                    <div className="payment-details-block">Amount</div>
                  )}

                  <div className="payment-details-block1 price">
                    Bid Amount: {displayAmount(converToNumber(paymentAmount))}
                  </div>
                  <div className="clearfix"></div>
                  {showInsuranceStep && job?.insurance_purchashed_on && (
                    <>
                      <div className="payment-details-block">
                        Insurance Purchased on:{' '}
                        {moment(job?.insurance_purchashed_on).format(DATE_FORMAT) || 'N/A'}
                      </div>
                      <div className="payment-details-block1 price">
                        Insurance Amount: {displayAmount(insuranceAmount)}
                      </div>
                    </>
                  )}
                  <div className="clearfix"></div>
                </div>
              )}
              {/* Payment Methods */}
              {((showInsuranceStep && (step === 2 || step === 3)) ||
                (!showInsuranceStep && (step === 1 || step === 2))) && (
                <Payments
                  paymentAmount={paymentAmount}
                  paymentRelatedTo={PAYMENT_RELATED_TO_JOB}
                  job={job}
                  onPaymentSuccess={handlepaymentSuccess}
                  handleBackFromPaymentMethod={handleBackFromPaymentMethod}
                  handleProceedToPaymentMethod={nextStep}
                  nextButtonClassName="submitbut next-btn"
                  backButtonClassName="submitbut back-btn btn-light mt-5"
                />
              )}
              {/* Job Details */}
              {((showInsuranceStep && step === 2) || (!showInsuranceStep && step === 1)) &&
                !isEmpty(job) && (
                  <div className="row">
                    <h3>Job details</h3>
                    <hr />
                    <p className="text-left">
                      Job ID:{' '}
                      <strong>
                        {' '}
                        {job.job_id}-{job.is_forwarding_only ? 'T' : 'C'}
                      </strong>
                    </p>
                    <p className="text-left">
                      Status: <strong>{getJobStatus(job, serviceData)}</strong>
                    </p>
                    {job.category === 1 && (
                      <Fragment>
                        <p className="text-left">
                          Type of vehicle:{' '}
                          {capitalize(serviceData.job.vehicle_type[job.type]) || 'N/A'}{' '}
                        </p>
                        <p className="text-left">
                          No of containers : {job.no_of_containers || 'N/A'}{' '}
                        </p>
                        <p className="text-left">
                          Expected date of arrival :{' '}
                          {moment(job.date_of_arrival).format(DATE_FORMAT) || 'N/A'}{' '}
                        </p>

                        {job.containers.map((x, index) => (
                          <div className="row" key={index}>
                            <hr />
                            <h3 className="text-left">Container {index + 1}</h3>
                            <p>Description of load : {x.load_description || 'N/A'}</p>
                            {x.vehicles.map((v, i) => (
                              <div className="alert alert-info col-3 m-2 mb-3" key={i}>
                                <h3>Vehicle {index + 1}</h3>
                                <p>Brand: {v.brand}</p>
                                <p>Year: {v.year}</p>
                                <p>Model: {v.model}</p>
                                <p>
                                  Conditions:{' '}
                                  {capitalize(serviceData.job.vehicle_state[v.vehicle_state])}
                                </p>
                                <p>VREG Number : {v.vreg_no || 'N/A'}</p>
                              </div>
                            ))}
                          </div>
                        ))}
                        {job?.vehicles?.length > 0 && (
                          <>
                            <hr />
                            <div className="row mx-0">
                              {job.vehicles.map((x, index) => (
                                <div className="col-lg-4 col-sm-12" key={index}>
                                  <div className="alert alert-info">
                                    <h3>Vehicle {index + 1}</h3>
                                    <p>Brand: {x.brand}</p>
                                    <p>Year: {x.year}</p>
                                    <p>Model: {x.model}</p>
                                    <p>
                                      Conditions:{' '}
                                      {capitalize(serviceData.job.vehicle_state[x.vehicle_state])}
                                    </p>
                                    <p>VREG Number : {x.vreg_no || 'N/A'}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </Fragment>
                    )}
                    {job.category === 2 && (
                      <Fragment>
                        <p className="text-left">
                          No of containers : {job.no_of_containers || 'N/A'}{' '}
                        </p>
                        <p className="text-left">
                          Type of cargo : {accessGeneralOrCargoType(job, serviceData)}{' '}
                        </p>
                        {job.type_of_cargo === 1 && (
                          <p className="text-left">Item Name : {job.single_item_title || 'N/A'} </p>
                        )}
                      </Fragment>
                    )}
                    {job.category === 3 && (
                      <Fragment>
                        <p className="text-left">Tonnage : {job.no_of_containers || 'N/A'} </p>
                        <p className="text-left">
                          Type of cargo : {accessGeneralOrCargoType(job, serviceData)}{' '}
                        </p>
                        <p>Item Name : {job.single_item_title || 'N/A'} </p>
                      </Fragment>
                    )}
                  </div>
                )}

              {/* Payment success: Final step */}
              {((showInsuranceStep && step === 4) || (!showInsuranceStep && step === 3)) && (
                <div className="payment-info">
                  <div className="finish">
                    <p>
                      <img src={tick} alt="" />
                    </p>
                    <h5>Payment Successful</h5>
                    <p>Your payment of {displayAmount(paymentAmount)} was successful.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      {showInsuranceConfirm && (
        <InsuranceBuyConfirm
          isOpen={showInsuranceConfirm}
          toggle={toggleShowInsuranceConfirm}
          insuranceAmount={displayAmount(insuranceAmount)}
          onConfirm={handleBuyInsurance}
        />
      )}
      {showNoInsuranceWarning && (
        <NoInsuranceWarning
          isOpen={showNoInsuranceWarning}
          toggle={toggleShowNoInsuranceWarning}
          onProceed={handleProceedWithoutInsurance}
          onBuyInsurance={() => {
            // close warning poppup
            toggleShowNoInsuranceWarning();
            // open insurane confirm popup
            toggleShowInsuranceConfirm();
          }}
        />
      )}
    </>
  );
};

export default PaymentFirstStep;
