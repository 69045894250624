import React from 'react';
import { isEmpty } from 'lodash';
import { getIn } from 'formik';
import TextField from '../UI/TextField';
import FIleField from '../UI/FIleField';
import SelectBox from '../UI/SelectBox';
import {
  acceptedFileTypes,
  USER_ROLE_LAWYER,
  USER_ROLE_CLEARING_AGENT,
  USER_ROLE_FORWARDING_AGENT,
  // TOTAL_SHARE_PERCENTAGE_REQUIRED
} from '../../helpers/constants';
import DisplayDocument from '../global/DisplayDocument';

const reg_year = {
  1: 'Companies Registered between December 2020 to date',
  2: 'Companies Registered between 2017 and 2020',
  3: 'Companies Registered Pre-2017',
};

const ClearingForwardingDocFields = (props) => {
  const {
    values,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    handleChange,
    details,
    // totalShareVal,
    rightOpen = () => {},
    editRight = () => {},
    images,
    showDoc,
    deleteRight = () => {},
    userType,
    agentType,
  } = props;

  let regCertDocLabel = '';
  let regCertTextLabel = '';

  const userIsComplianceOfficer = userType === USER_ROLE_LAWYER;
  const userIsAgent =
    agentType === USER_ROLE_CLEARING_AGENT || agentType === USER_ROLE_FORWARDING_AGENT;

  if (agentType === USER_ROLE_CLEARING_AGENT) {
    regCertDocLabel =
      'Certificate of registration with Nigeria Customs Service as a clearing agent (Document)';
    regCertTextLabel =
      'Certificate of registration with Nigeria Customs Service as a clearing agent (Certificate number)';
  }
  // else {
  //   regCertDocLabel =
  //     'Certificate of registration with Council for the Regulation of Freight Transport in Nigeria (CRFFN) (Document)';
  //   regCertTextLabel =
  //     'Certificate of registration with Council for the Regulation of Freight Transport in Nigeria (CRFFN) (Certificate number)';
  // }

  return (
    <>
      <div className="complian-block mb-5" style={{ width: '100%' }}>
        <div className="profilecont">
          <div className="row">
            {/* Documents to be uploaded by Clearing agent */}
            {agentType === USER_ROLE_CLEARING_AGENT && !userIsComplianceOfficer && (
              <>
                <div className="col-sm-12 mt-4">
                  {images.registration_certificate && (
                    <DisplayDocument
                      label={regCertDocLabel}
                      onClick={() => showDoc(images.registration_certificate)}
                      type="file"
                      fileColClassName="form-document"
                      nolabel={true}
                    />
                  )}

                  <div
                    className="fileUploads"
                    style={{ background: values.registration_certificate ? '#01a8af' : '' }}
                  >
                    <span>
                      <i className="fa fa-file-text-o" aria-hidden="true"></i> {regCertDocLabel}
                    </span>
                    <FIleField
                      type="file"
                      accept={acceptedFileTypes}
                      className="uploads"
                      name="registration_certificate"
                      onChange={(event) =>
                        setFieldValue('registration_certificate', event.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-12 mt-4">
                  <div className="text-left">
                    <label className="v2-text-label v2-text">{regCertTextLabel}</label>
                    <TextField
                      id="registration_certificate_no"
                      type="text"
                      className="textbox-control"
                      placeholder="XXXX"
                      value={values.registration_certificate_no}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        getIn(touched, 'registration_certificate_no') &&
                        getIn(errors, 'registration_certificate_no')
                      }
                    />
                  </div>
                </div>
              </>
            )}

            {/* RC Number needs to be Uploaded by Agents not Compliance officer */}
            {userIsAgent && !userIsComplianceOfficer && (
              <div className="col-sm-12 mt-4">
                <div className="text-left">
                  <label className="v2-text-label v2-text">RC Number</label>
                  <TextField
                    id="rc_no"
                    type="text"
                    className="textbox-control"
                    placeholder="XXXX"
                    value={values.rc_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={getIn(touched, 'rc_no') && getIn(errors, 'rc_no')}
                  />
                </div>
                <br />
              </div>
            )}

            {/* <div className="complian-block mb-5" style={{ width: '100%' }}>
                <h3>Shareholders</h3>
                <span className="v2-text-label v2-text mt-5">
                  Total Share Value: <strong>{`${totalShareVal}%`}</strong>
                </span>
                <div className="profilecont">
                  <div className="row">
                    <div className="col-12">
                      <div className="col-sm-12 col-lg-8 nopadding">
                        <select
                          className="select-controls"
                          value={values.shareholder}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue('shareholder', value);
                          }}
                          id="shareholder"
                          name="shareholder"
                          onBlur={handleBlur}
                        >
                          {details.shareholders &&
                            details.shareholders.map((obj, i) => (
                              <option value={obj.uuid} key={i}>
                                {obj.first_name} {obj.last_name}
                              </option>
                            ))}
                        </select>
                        {getIn(touched, 'shareholder') && getIn(errors, 'shareholder') ? (
                          <div className="invalid-feedback mb-3" style={{ display: 'block' }}>
                            {getIn(touched, 'shareholder') && getIn(errors, 'shareholder')}
                          </div>
                        ) : null}
                        {totalShareVal < TOTAL_SHARE_PERCENTAGE_REQUIRED && (
                          <div className="invalid-feedback mb-3" style={{ display: 'block' }}>
                            {`Total Share Percentage is less than ${TOTAL_SHARE_PERCENTAGE_REQUIRED}%`}
                          </div>
                        )}
                      </div>
                      {!isEmpty(details.shareholders) && (
                        <>
                          {' '}
                          <div
                            className="col-sm-6 col-lg-2"
                            onClick={() => editRight(values.shareholder, 'shareholder')}
                          >
                            <div className="del-button">
                              <i className="fa fa-edit" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div
                            className="col-sm-6 col-lg-2"
                            onClick={() => deleteRight(values.shareholder, 'shareholder')}
                          >
                            <div className="del-button">
                              <i className="fa fa-trash-o" aria-hidden="true"></i>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-sm-12 mb-3">
                      <button type="button" onClick={() => rightOpen('shareholder')} className="">
                        Add Shareholder
                      </button>
                    </div>
                  </div>
                  <span className="v2-text-label v2-text">
                    Note: Add One or More Shareholders to match 50% of share.
                  </span>
                </div>
              </div> */}

            {/* Director's data will be uploaded by Clearing agent & Transporter */}
            {userIsAgent && !userIsComplianceOfficer && (
              <div className="col-sm-12 mt-4 complian-block">
                <h3>Director</h3>

                <div className="profilecont ">
                  <div className="row">
                    <div className="col-sm-12">
                      {details.directors?.length > 0 && (
                        <div className="col-sm-8 nopadding">
                          <span>
                            <strong>
                              {details.directors[0].first_name} {details.directors[0].last_name}
                            </strong>
                          </span>
                        </div>
                      )}
                      {!isEmpty(details.directors) ? (
                        <>
                          {' '}
                          <div
                            className="col-sm-2"
                            onClick={() => editRight(values.director, 'director')}
                          >
                            <div className="del-button">
                              <i className="fa fa-edit" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div
                            className="col-sm-2"
                            onClick={() => deleteRight(values.director, 'director')}
                          >
                            <div className="del-button">
                              <i className="fa fa-trash-o" aria-hidden="true"></i>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <button type="button" onClick={() => rightOpen('director')}>
                            Add Director
                          </button>
                          {getIn(touched, 'director') && getIn(errors, 'director') ? (
                            <div className="invalid-feedback mb-3" style={{ display: 'block' }}>
                              {getIn(touched, 'director') && getIn(errors, 'director')}
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Documents to be uploaded by Compliance officer */}
            {userIsComplianceOfficer && (
              <>
                <div className="col-sm-12 mt-4">
                  <SelectBox
                    id="reg_year"
                    name="reg_year"
                    className="select-controls"
                    placeholder="Select Company Registration Year:"
                    value={values.reg_year}
                    options={reg_year}
                    onChange={handleChange}
                    error={getIn(touched, 'reg_year') && getIn(errors, 'reg_year')}
                  />
                </div>
                {values.reg_year == 1 && (
                  <div className="col-sm-12 mt-4">
                    {images.registration_application_status && (
                      <DisplayDocument
                        label="Status Report (Application for Registration)"
                        onClick={() => showDoc(images.registration_application_status)}
                        type="file"
                        fileColClassName="form-document"
                        nolabel={true}
                      />
                    )}
                    <div
                      className="fileUploads"
                      style={{
                        background: values.registration_application_status ? '#01a8af' : '',
                      }}
                    >
                      <span>
                        <i className="fa fa-file-text-o" aria-hidden="true"></i> Status Report
                        (Application for Registration)
                      </span>
                      <FIleField
                        type="file"
                        accept={acceptedFileTypes}
                        className="uploads"
                        name="registration_application_status"
                        onChange={(event) =>
                          setFieldValue('registration_application_status', event.target.files[0])
                        }
                      />
                    </div>
                  </div>
                )}
                {values.reg_year == 2 && (
                  <div className="col-sm-12 mt-4">
                    {images.ctc_cac_1_1 && (
                      <DisplayDocument
                        label="CTC of Form CAC 1.1 (Application for Registration)"
                        onClick={() => showDoc(images.ctc_cac_1_1)}
                        type="file"
                        fileColClassName="form-document"
                        nolabel={true}
                      />
                    )}
                    <div
                      className="fileUploads"
                      style={{ background: values.ctc_cac_1_1 ? '#01a8af' : '' }}
                    >
                      <span>
                        <i className="fa fa-file-text-o" aria-hidden="true"></i> CTC of Form CAC
                        1.1(Application for Registration)
                      </span>
                      <FIleField
                        type="file"
                        accept={acceptedFileTypes}
                        className="uploads"
                        name="ctc_cac_1_1"
                        onChange={(event) => setFieldValue('ctc_cac_1_1', event.target.files[0])}
                      />
                    </div>
                  </div>
                )}
                {values.reg_year == 3 && (
                  <>
                    <div className="col-sm-12 mt-4">
                      {images.cac_2 && (
                        <DisplayDocument
                          label="Form CAC 2 (Statement of Share Capital and Return of Allotment)"
                          onClick={() => showDoc(images.cac_2)}
                          type="file"
                          fileColClassName="form-document"
                          nolabel={true}
                        />
                      )}
                      <div
                        className="fileUploads"
                        style={{ background: values.cac_2 ? '#01a8af' : '' }}
                      >
                        <span>
                          <i className="fa fa-file-text-o" aria-hidden="true"></i> Form CAC 2
                          (Statement of Share Capital and Return of Allotment)
                        </span>
                        <FIleField
                          type="file"
                          accept={acceptedFileTypes}
                          className="uploads"
                          name="cac_2"
                          onChange={(event) => setFieldValue('cac_2', event.target.files[0])}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                      {images.cac_7 && (
                        <DisplayDocument
                          label="Form CAC 7 (Particulars of Directors)"
                          onClick={() => showDoc(images.cac_7)}
                          type="file"
                          fileColClassName="form-document"
                          nolabel={true}
                        />
                      )}
                      <div
                        className="fileUploads"
                        style={{ background: values.cac_7 ? '#01a8af' : '' }}
                      >
                        <span>
                          <i className="fa fa-file-text-o" aria-hidden="true"></i> Form CAC 7
                          (Particulars of Directors):
                        </span>
                        <FIleField
                          type="file"
                          accept={acceptedFileTypes}
                          className="uploads"
                          name="cac_7"
                          onChange={(event) => setFieldValue('cac_7', event.target.files[0])}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="col-sm-12 mt-4">
                  {images.incorporation_certificate && (
                    <DisplayDocument
                      label="Certificate of incorporation"
                      onClick={() => showDoc(images.incorporation_certificate)}
                      type="file"
                      fileColClassName="form-document"
                      nolabel={true}
                    />
                  )}
                  <div
                    className="fileUploads"
                    style={{ background: values.incorporation_certificate ? '#01a8af' : '' }}
                  >
                    <span>
                      <i className="fa fa-file-text-o" aria-hidden="true"></i> Certificate of
                      incorporation
                    </span>
                    <FIleField
                      type="file"
                      accept={acceptedFileTypes}
                      className="uploads"
                      name="incorporation_certificate"
                      onChange={(event) =>
                        setFieldValue('incorporation_certificate', event.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-12 mt-4">
                  {images.memorandum_article_association && (
                    <DisplayDocument
                      label="Memorandum and Articles of Association"
                      onClick={() => showDoc(images.memorandum_article_association)}
                      type="file"
                      fileColClassName="form-document"
                      nolabel={true}
                    />
                  )}
                  <div
                    className="fileUploads"
                    style={{ background: values.memorandum_article_association ? '#01a8af' : '' }}
                  >
                    <span>
                      <i className="fa fa-file-text-o" aria-hidden="true"></i> Memorandum and
                      Articles of Association
                    </span>
                    <FIleField
                      type="file"
                      accept={acceptedFileTypes}
                      className="uploads"
                      name="memorandum_article_association"
                      onChange={(event) =>
                        setFieldValue('memorandum_article_association', event.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-12 mt-4">
                  {images.corporate_address_proof && (
                    <DisplayDocument
                      label="Proof of corporate address(Utility bills or Tax payment receipt)"
                      onClick={() => showDoc(images.corporate_address_proof)}
                      type="file"
                      fileColClassName="form-document"
                      nolabel={true}
                    />
                  )}
                  <div
                    className="fileUploads"
                    style={{ background: values.corporate_address_proof ? '#01a8af' : '' }}
                  >
                    <span>
                      <i className="fa fa-file-text-o" aria-hidden="true"></i> Proof of corporate
                      address(Utility bills or Tax payment receipt)
                    </span>
                    <FIleField
                      type="file"
                      accept={acceptedFileTypes}
                      className="uploads"
                      name="corporate_address_proof"
                      onChange={(event) =>
                        setFieldValue('corporate_address_proof', event.target.files[0])
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClearingForwardingDocFields;
