import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { object, date, string } from 'yup';
import moment from 'moment';
import { saveAs } from 'file-saver';
import swal from 'sweetalert';
import { Formik, getIn } from 'formik';
// import MuiAutocomplete from '@material-ui/lab/Autocomplete';
// import MuiTextField from '@material-ui/core/TextField';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import TextField from '../../components/UI/TextField';
import config from '../../../config';
// import docImage from '../../../assets/img/doc.png';
// import { getTrackersList } from '../../services/navixy.service';
import { DATE_FORMAT, TIME_FORMAT } from '../../helpers/constants';
import Pagination from '../../components/global/Pagination';
import { usePagination } from '../../helpers/customHooks';

const baseURL = config.api.BASE_URL;

const size = {
  '1': '20ft',
  '2': '40ft',
};

const Dashboard = (props) => {
  const [detailModal, setDetailModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [truckList, setTruckList] = useState([]);
  const [truckDetails, setTruckDetails] = useState({});
  const [truckID, setTruckID] = useState('');
  // const [link, setLink] = useState('');
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
  } = usePagination();

  const [approvalDetailModal, setApprovalDetailModal] = useState(false);
  const toggleApprovalDetailModal = () => setApprovalDetailModal((s) => !s);
  // const [trackerOptions, setTrackerOptions] = useState([]);

  //page changes
  const handleSelected = ({ selected }) => {
    // console.log('selected', );
    setSelectedPage(selected + 1);
  };
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const toggleDate = (id) => {
    setTruckID(id);
    setDateModal(!dateModal);
  };
  const tooglDetailModal = (obj) => {
    // console.log(obj);
    setTruckID(obj.uuid);

    setDetailModal(!detailModal);
    setTruckDetails(!detailModal ? obj : {});
  };
  // get download
  const getDownload = () => {
    const payload = {
      truck_uuid: truckID,
    };
    dispatch(showLoading());
    POST('/trucks/downloadTruckDocZip', payload)
      .then(({ data }) => {
        // console.log(data);
        saveAs(`${baseURL}/${data.link}`, `allfiles`);

        // setLink(data.link);

        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  //get truck list
  const getTruck = (page, perPage) => {
    const payload = {
      inspector_uuid: get(user, 'uuid'),
      page: page,
      per_page: perPage,
    };
    dispatch(showLoading());
    POST('/trucks/getTrucksByInspector', payload)
      .then(({ data: { trucks, total_count } }) => {
        // console.log(trucks);
        // console.log(total_count);
        setTruckList(trucks);
        setTotalPages(total_count / perPage);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  useEffect(() => {
    getTruck(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  //truck approve status
  const truckApproved = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to approve this truck?',
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      if (result) {
        setTruckID(id);
        // get trackers list
        toggleApprovalDetailModal();

        // This feature of fetching the trackers list from navixy and providing autocomplete option was disabled

        // dispatch(showLoading());
        // getTrackersList()
        //   .then((response) => {
        //     if (response?.list?.length > 0) {
        //       setTrackerOptions(response.list);
        //       toggleApprovalDetailModal();
        //     } else {
        //       dispatch(showModal('No Trackers found'));
        //     }
        //     dispatch(hideLoading());
        //   })
        //   .catch((err) => {
        //     dispatch(showModal(err.message));
        //     dispatch(hideLoading());
        //   });
      }
    });
  };

  //truck reject status
  const truckRejected = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to reject this truck?',
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      if (result) {
        const truckUID = {
          truck_uuid: id,
        };
        POST('/trucks/inspectorRejectTruck', truckUID)
          .then((data) => {
            dispatch(showModal(`Truck Rejected successfully!`, true));
            getTruck(selectedPage, itemsPerPage);
          })
          .catch((err) => {
            dispatch(showModal(err.message));
          });
      }
    });
  };

  const handlePageCountChange = (e) => {
    handleSelected({ selected: 0 });
    setItemsPerPage(e.target.value);
  };

  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <div className="dashrightcont">
          <div className="bodycont">
            <div className="">
              <h2>Truck Inspection</h2>

              <div className="table-responsive clearingtable">
                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                  <thead>
                    <tr>
                      <th>Truck Id</th>
                      <th>Truck Model</th>
                      <th>Truck Reg. No</th>
                      <th>Schedule Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(truckList) && truckList.length !== 0 ? (
                      truckList.map((obj, i) => (
                        <tr key={i} className={obj.verification_start_date ? 'graybg' : ''}>
                          <td>
                            <div className="trackcount">
                              <span>{i + 1}</span>
                              <strong>{obj.truck_id}</strong>
                            </div>
                          </td>
                          <td>
                            <strong>{obj.model}</strong>
                          </td>
                          <td>
                            <div>
                              <p>
                                <strong>{obj.reg_number}</strong>
                              </p>
                              <button onClick={() => tooglDetailModal(obj)} className="schedules">
                                Details
                              </button>
                            </div>
                          </td>
                          <td className="trackdate">
                            {obj.verification_start_date && obj.verification_end_date ? (
                              <>
                                <p>{moment(obj.verification_start_date).format(DATE_FORMAT)}</p>
                                <p>
                                  <span>
                                    {moment(obj.verification_start_date).format(TIME_FORMAT)}{' '}
                                    {' - '}
                                    {moment(obj.verification_end_date).format(TIME_FORMAT)}
                                  </span>
                                </p>
                                <div>
                                  <button
                                    className="schedules"
                                    onClick={() => toggleDate(obj.uuid)}
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-lg2"
                                  >
                                    Reschedule Inspection Date
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <button
                                    className="schedules"
                                    onClick={() => toggleDate(obj.uuid)}
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-lg2"
                                    disabled={obj.status === '2'}
                                  >
                                    Schedule Inspection Date
                                  </button>
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            {obj.status === '1' && (
                              <p className="badge badge-info success-colors badge-button">
                                Approved
                              </p>
                            )}
                            {obj.status === '2' && (
                              <p className="badge badge-danger reject-colors badge-button">
                                Rejected
                              </p>
                            )}
                            {obj.status === '3' && (
                              <p className="badge badge-danger  badge-button">Pending</p>
                            )}
                          </td>
                          <td>
                            {obj.status !== '2' && (
                              <button
                                className={`detailsapprove`}
                                onClick={() => truckRejected(obj.uuid)}
                                data-toggle="modal"
                                data-target=".bs-example-modal-lg"
                              >
                                Reject
                              </button>
                            )}
                            {obj.status !== '1' && (
                              <button
                                className={`detailsapprove`}
                                onClick={() => truckApproved(obj.uuid)}
                                data-toggle="modal"
                                data-target=".bs-example-modal-lg1"
                              >
                                Approve
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No Truck Founds!</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {truckList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={detailModal}
        toggle={tooglDetailModal}
        container="truck-inspector-page"
        className="modal-xl"
        style={{ width: '500px!important' }}
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={tooglDetailModal}>
            &times;
          </button>
        </div>
        <ModalBody>
          <div className="reson-reject">
            <h5>Truck details</h5>
            <div style={{ textAlign: 'left', lineHeight: '40px', fontWeight: '700' }}>
              <div className="row">
                <div className="col-sm-6">Brand:</div>
                <div className="col-sm-6">{truckDetails && truckDetails.make}</div>
                <div className="col-sm-6">Year of manufacture:</div>
                <div className="col-sm-6">{truckDetails && truckDetails.year}</div>
                <div className="col-sm-6">Model:</div>
                <div className="col-sm-6">{truckDetails && truckDetails.model}</div>
                <div className="col-sm-6">Size:</div>
                <div className="col-sm-6">{truckDetails && size[truckDetails.size]}</div>
                <div className="col-sm-6">No of Hooks:</div>
                <div className="col-sm-6">{truckDetails && truckDetails.no_of_hooks}</div>
                <div className="col-sm-6">Register No:</div>
                <div className="col-sm-6">{truckDetails && truckDetails.reg_number}</div>
                {/* <div className="col-sm-6">Npa Safety Certificate No:</div>
                <div className="col-sm-6">{truckDetails && truckDetails.npa_safety_certificate_no}</div> */}
                <div className="col-sm-6">Download all files:</div>
                <div className="col-sm-6">
                  {' '}
                  <>
                    <button type="button" className="btn" onClick={() => getDownload()}>
                      <i className="fa fa-download" style={{ color: 'white' }}></i>
                    </button>
                  </>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={dateModal}
        toggle={toggleDate}
        container="truck-inspector-page"
        className="modal-md"
        style={{ width: '200px!important' }}
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={toggleDate}>
            &times;
          </button>
        </div>
        <ModalBody>
          <div className="reson-reject">
            <h5>Verification Sechedule Date</h5>
            <div style={{ textAlign: 'left', lineHeight: '40px', fontWeight: '700' }}>
              <Formik
                initialValues={{
                  truck_uuid: '',
                  verification_start_date: '',
                  verification_start_hour: '',
                  verification_end_hour: '',
                  // verification_end_date: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  // console.log('values', values);
                  const startDate =
                    values.verification_start_date + ' ' + values.verification_start_hour;
                  const endDate =
                    values.verification_start_date + ' ' + values.verification_end_hour;
                  const payload = {
                    truck_uuid: truckID,
                    verification_start_date: startDate,
                    verification_end_date: endDate,
                  };
                  dispatch(showLoading());
                  // console.log('payload', payload);
                  POST(`/trucks/scheduleVerificationDate`, payload)
                    .then((data) => {
                      // console.log('data', data);
                      toggleDate();
                      dispatch(showModal('Date Schedule successfully', true));
                      getTruck(selectedPage, itemsPerPage);
                    })
                    .catch((err) => {
                      dispatch(showModal(err.message));
                    });
                }}
                validationSchema={object().shape({
                  verification_start_date: date().required('Please Select Verification Start Date'),
                  // verification_end_date: date().required('Please  Select Verification End Date'),
                  verification_start_hour: string().required(
                    'Please Select Verification Start Hour',
                  ),
                  verification_end_hour: string().required('Please Select Verification End Hour'),
                })}
              >
                {(renderProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                  } = renderProps;

                  return (
                    <form className={'row'} onSubmit={handleSubmit}>
                      <div className="col-sm-12 text-left">
                        <label>Select Verification Start Date</label>

                        <TextField
                          id="verification_start_date"
                          type="date"
                          className="form-control "
                          value={values.verification_start_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(touched, 'verification_start_date') &&
                            getIn(errors, 'verification_start_date')
                          }
                          min={moment().format('YYYY-MM-DD')}
                        />
                      </div>
                      <div className="col-sm-6 col-lg-6 text-left">
                        <label>Start Hour</label>

                        <TextField
                          id="verification_start_hour"
                          type="time"
                          className="form-control "
                          value={values.verification_start_hour}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(touched, 'verification_start_hour') &&
                            getIn(errors, 'verification_start_hour')
                          }
                          min={moment().format('YYYY-MM-DD')}
                        />
                      </div>
                      <div className="col-sm-6 col-lg-6 text-left">
                        <label>End Hour</label>

                        <TextField
                          id="verification_end_hour"
                          type="time"
                          className="form-control "
                          value={values.verification_end_hour}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(touched, 'verification_end_hour') &&
                            getIn(errors, 'verification_end_hour')
                          }
                          min={moment().format('YYYY-MM-DD')}
                        />
                      </div>
                      {/* <div className="col-sm-6 text-left">
                        <label>Select Verification End Date</label>

                        <TextField
                          id="verification_end_date"
                          type="date"
                          className="form-control "
                          value={values.verification_end_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'verification_end_date') && getIn(errors, 'verification_end_date')}
                        />
                      </div> */}
                      <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                        <button
                          type="submit"
                          className="submitbut"
                          disabled={!isValid || isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* Approve Modal */}
      <Modal
        isOpen={approvalDetailModal}
        toggle={toggleApprovalDetailModal}
        container="truck-inspector-page"
        className="modal-xl"
        style={{ width: '500px!important' }}
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={toggleApprovalDetailModal}>
            &times;
          </button>
        </div>
        <ModalBody>
          <div className="reson-reject">
            <h5>Approval details</h5>
            <div style={{ textAlign: 'left', lineHeight: '40px', fontWeight: '700' }}>
              <Formik
                initialValues={{
                  npa_sticker_expiry: '',
                  tracker_id: '',
                }}
                onSubmit={(values) => {
                  const payload = {
                    truck_uuid: truckID,
                    npa_sticker_expiry: values.npa_sticker_expiry,
                    tracker_id: values.tracker_id,
                  };
                  POST('/trucks/inspectorApproveTruck', payload)
                    .then((data) => {
                      dispatch(showModal(`Truck Approved successfully!`, true));
                      getTruck(selectedPage, itemsPerPage);
                      toggleApprovalDetailModal();
                    })
                    .catch((err) => {
                      dispatch(showModal(err.message));
                    });
                }}
                validationSchema={object().shape({
                  npa_sticker_expiry: string().required('Please Select Nezpa Sticker Expiry Date'),
                  tracker_id: string().required('Please enter Tracker ID'),
                })}
              >
                {(renderProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                  } = renderProps;
                  return (
                    <form className={'row'} onSubmit={handleSubmit}>
                      <div className="col-sm-6 text-left">
                        <label>Nezpa Sticker Expiry Date</label>

                        <TextField
                          id="npa_sticker_expiry"
                          type="date"
                          className="form-control "
                          value={values.npa_sticker_expiry}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(touched, 'npa_sticker_expiry') &&
                            getIn(errors, 'npa_sticker_expiry')
                          }
                        />
                      </div>

                      <div className="col-sm-6 text-left">
                        <label>Tracker ID</label>
                        <TextField
                          id="tracker_id"
                          type="text"
                          className="form-control"
                          value={values.tracker_id}
                          placeholder="1234"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'tracker_id') && getIn(errors, 'tracker_id')}
                        />
                        {/* <MuiAutocomplete
                          options={trackerOptions || []}
                          getOptionLabel={(option) => `${option.label.toString().split('(')[0]} - ${option.id}`}
                          onChange={(event, value)=>{
                            if(value?.id){
                              setFieldValue('tracker_id', value.id)
                            }
                          }}
                          renderInput={(params)=><MuiTextField {...params} />}
                          className="form-control tracker-id"
                          renderOption={(option)=> <li style={{ fontSize: 14 }}>{`${option.label.toString().split('(')[0]} - ${option.id}`}</li>}
                        />
                        {getIn(touched, 'tracker_id') && getIn(errors, 'tracker_id') && (
                          <div className="invalid-feedback mb-3" style={{ display:'block'}}>{getIn(errors, 'tracker_id')}</div>
                        )} */}
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                        <button
                          type="submit"
                          className="submitbut"
                          disabled={!isValid || isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Dashboard;
