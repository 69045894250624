import React from 'react';
import { isEmpty } from 'lodash';
import DisplayDocument from '../../../components/global/DisplayDocument';
import { weightFormat } from '../../../helpers/utils';

const Information = ({ truckData, showDoc }) => {
  return (
    <>
      <h3>Information:</h3>
      {!isEmpty(truckData) && (
        <div className="mx-3">
          <div className="row">
            <div className="col-6">
              <DisplayDocument
                label="Registration No"
                value={truckData.reg_number}
                type="text"
                textColClassName="document"
              />
            </div>
            <div className="col-6">
              <DisplayDocument
                label="Brand"
                value={truckData.make}
                type="text"
                textColClassName="document"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <DisplayDocument
                label="Model"
                value={truckData.model}
                type="text"
                textColClassName="document"
              />
            </div>
            <div className="col-6">
              <DisplayDocument
                label="Year of Manufacture"
                value={truckData.year}
                type="text"
                textColClassName="document"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <DisplayDocument
                label="Capacity"
                value={weightFormat(truckData.tonnage_weight)}
                type="text"
                textColClassName="document"
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument
                label="Proof of Ownership"
                onClick={() => showDoc(truckData.ownership_proof)}
                type="file"
                documentLabel="Document"
                hasDocument={truckData.ownership_proof ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 document">
              {/* <p><strong>Truck registration:</strong></p> */}
              <DisplayDocument
                // nolabel={true}
                label="Truck registration"
                documentLabel="Document"
                onClick={() => showDoc(truckData.registrtaion)}
                type="file"
                hasDocument={truckData.registrtaion ? true : false}
              />
            </div>
            <div className="col-6">
              <DisplayDocument
                label="Chasis Number"
                type="text"
                value={truckData.chassis_no}
                textColClassName="document"
              />
            </div>
          </div>
          {/* Truck license */}
          <div className="row document-row">
            <div className="col-6 document">
              <p>
                <strong>Truck license:</strong>
              </p>
              <DisplayDocument
                label="Document"
                nolabel={true}
                onClick={() => showDoc(truckData.comercial_vehicle_license)}
                type="file"
                hasDocument={truckData.comercial_vehicle_license ? true : false}
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument
                label="Expiry"
                value={truckData.comercial_vehicle_license_expiry}
                type="text"
              />
            </div>
          </div>
          {/* Road Worthiness Certificate */}
          <div className="row document-row">
            <div className="col-6 document">
              <p>
                <strong>Road Worthiness Certificate:</strong>
              </p>
              <DisplayDocument
                label="Document"
                nolabel={true}
                onClick={() => showDoc(truckData.road_worthiness_certificate)}
                type="file"
                hasDocument={truckData.road_worthiness_certificate ? true : false}
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument
                label="Expiry"
                value={truckData.road_worthiness_certificate_expiry}
                type="text"
              />
            </div>
          </div>
          {/* Third Party Insurance */}
          <div className="row document-row">
            <div className="col-6 document">
              <p>
                <strong>Third Party Insurance:</strong>
              </p>
              <DisplayDocument
                nolabel={true}
                label="Document"
                onClick={() => showDoc(truckData.third_party_insurance)}
                type="file"
                hasDocument={truckData.third_party_insurance ? true : false}
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument
                label="Expiry"
                value={truckData.third_party_insurance_expiry}
                type="text"
              />
            </div>
          </div>
          {/* Hackney Permit */}
          <div className="row document-row">
            <div className="col-6 document">
              <p>
                <strong>Hackney Permit:</strong>
              </p>
              <DisplayDocument
                nolabel={true}
                label="Document"
                onClick={() => showDoc(truckData.hackney_permit)}
                type="file"
                hasDocument={truckData.hackney_permit ? true : false}
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument label="Expiry" value={truckData.hackney_permit_expiry} type="text" />
            </div>
          </div>
          {/* Heavy duty permit */}
          <div className="row document-row">
            <div className="col-6 document">
              <p>
                <strong>Heavy duty permit:</strong>
              </p>
              <DisplayDocument
                nolabel={true}
                label="Document"
                onClick={() => showDoc(truckData.heavy_duty_permit)}
                type="file"
                hasDocument={truckData.heavy_duty_permit ? true : false}
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument
                label="Expiry"
                value={truckData.heavy_duty_permit_expiry}
                type="text"
              />
            </div>
          </div>
          {/* Local Government permit */}
          <div className="row document-row">
            <div className="col-6 document">
              <p>
                <strong>Local Government permit:</strong>
              </p>
              <DisplayDocument
                nolabel={true}
                label="Document"
                onClick={() => showDoc(truckData.local_govt_permit)}
                type="file"
                hasDocument={truckData.local_govt_permit ? true : false}
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument
                label="Expiry"
                value={truckData.local_govt_permit_expiry}
                type="text"
              />
            </div>
          </div>
          {/* Local Government permit */}
          <div className="row document-row">
            <div className="col-6 document">
              <p>
                <strong>NPA Sticker:</strong>
              </p>
              <DisplayDocument
                nolabel={true}
                label="Document"
                onClick={() => showDoc(truckData.npa_sticker)}
                type="file"
                hasDocument={truckData.npa_sticker ? true : false}
              />
            </div>
            <div className="col-6 document">
              <DisplayDocument label="Expiry" value={truckData.npa_sticker_expiry} type="text" />
            </div>
          </div>
        </div>
      )}
      <hr />
    </>
  );
};

export default Information;
