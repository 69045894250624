import React from 'react';
import classnames from 'classnames';
import TextField from '../UI/TextField';

const PhoneNumber = (props) => {
  const { onChange, className, error, onBlur, value } = props;

  const InputClasses = classnames(
    'form-control',
    {
      'is-invalid': !!error,
    },
    className,
  );

  return (
    <TextField
      id="mobile_number"
      type="text"
      className={InputClasses}
      placeholder="Phone Number without Country Code*"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
    />
  );
};

export default PhoneNumber;
