import React from 'react';

const VerificationModalSteps = ({ step, isEditMode, registrationAmount, user, paystatus }) => {
  const paymentAvailable = registrationAmount !== 0;
  const regFeePaid = user.registration_fee_paid === 1;

  const stepsList = [
    <li key="step1">
      <div
        className={`${
          step === 1 || step === 2 || step === 3 || step === 4 || isEditMode
            ? 'tab-icon'
            : 'tab-icon1'
        }`}
      >
        <i className="fa fa-check" aria-hidden="true"></i>
      </div>
      Upload Documents
    </li>,
    <li key="step2">
      <div className={`${step === 2 || step === 3 || step === 4 ? 'tab-icon' : 'tab-icon1'}`}>
        <i className="fa fa-check" aria-hidden="true"></i>
      </div>
      View Documents
    </li>,
    <li key="step4">
      <div
        className={`${
          (!paymentAvailable && step === 3) || (paymentAvailable && step === 4)
            ? 'tab-icon'
            : 'tab-icon1'
        }`}
      >
        <i className="fa fa-check" aria-hidden="true"></i>
      </div>
      Finish
    </li>,
  ];
  // if registration amount is zero then don't show Payment step
  if (paymentAvailable) {
    const paymentStep = (
      <li key="step3">
        <div className={`${step === 4 || regFeePaid || paystatus ? 'tab-icon' : 'tab-icon1'}`}>
          <i className="fa fa-check" aria-hidden="true"></i>
        </div>
        Payment
      </li>
    );
    stepsList.splice(2, 0, paymentStep);
  }

  return (
    <div className="col-sm-3">
      <ul>{stepsList}</ul>
    </div>
  );
};

export default VerificationModalSteps;
