import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Formik, getIn } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { GET, POST } from '../../services/rest.service';
import config from '../../../config';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import TextField from '../../components/UI/TextField';
import TextareaInput from '../../components/UI/TextareaInput';
import { showModal } from '../../redux/error/ErrorAction';
import ShareBtn from '../../components/global/ShareButton';

const BlogCommentReplyForm = ({ type, blog, comment, onFinish }) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{ name: '', email: '', comment: '' }}
      validationSchema={Yup.object({
        name: Yup.string().required('Name is Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is Required'),
        comment: Yup.string().required('Comment is required'),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // console.log('values',values)
        dispatch(showLoading());
        setSubmitting(true);

        const url = type === 'comment' ? '/blogs/createComment' : '/blogs/createReply';
        const payload =
          type === 'comment'
            ? {
                blog_uuid: blog.uuid,
                created_by: values.name,
                comment: values.comment,
              }
            : {
                comment_uuid: comment.uuid,
                created_by: values.name,
                reply: values.comment,
              };

        POST(url, payload)
          .then(({ data }) => {
            resetForm();
            dispatch(
              showModal(`${type === 'comment' ? 'Comment' : 'Reply'} sent succssefully!`, true),
            );
            dispatch(hideLoading());
            setSubmitting(false);
            onFinish();
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row services-block1" style={{ padding: '0', border: 'none' }}>
            <div className="col-sm-6">
              <TextField
                id="name"
                name="name"
                type="text"
                placeholder="Name*"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getIn(touched, 'name') && getIn(errors, 'name')}
              />
              <TextField
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email*"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getIn(touched, 'email') && getIn(errors, 'email')}
              />
            </div>
            <div className="col-sm-6">
              <TextareaInput
                id="comment"
                name="comment"
                placeholder="Comment*"
                cols=""
                rows="4"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getIn(touched, 'comment') && getIn(errors, 'comment')}
              />
            </div>
            <div className="col-sm-12 send-comment-btn">
              <button
                type="submit"
                className="post-button"
                style={{ marginTop: '0' }}
                disabled={isSubmitting}
              >
                {type === 'comment' ? 'Send' : 'Reply'}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const BlogDetails = (props) => {
  const dispatch = useDispatch();
  const [blog, setBlog] = useState({});
  const [commentReplying, setCommentReplying] = useState('');

  const getBlogDetails = () => {
    const {
      match: { params },
    } = props;
    dispatch(showLoading());

    GET(`/blogs/show/${params.slug}`)
      .then(({ data }) => {
        setBlog(data.blog);
        dispatch(hideLoading());
      })
      .catch((err) => console.log('error fetching blogs'));
  };

  useEffect(() => {
    getBlogDetails();
  }, []);

  const replyToComment = (cid) => setCommentReplying(commentReplying === cid ? '' : cid);

  // data for sharing the blog
  const shareData = {
    title: 'GoAgent Blog',
    text: blog.title,
    url: window.location.href,
  };

  return (
    <div id="home-page">
      <Helmet>
        <title>GoAgent - Blog Details</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main">
        <section id="blog" className="payment-policy">
          <div className="container">
            <h2 style={{ marginBottom: '20px' }}>Blogs</h2>
            <div className="blog-section1">
              {!isEmpty(blog) && (
                <>
                  <img
                    src={`${config.api.URL.replace('/api/v1', '')}/${blog.image}`}
                    width="100%"
                    alt=""
                    style={{ marginBottom: '20px' }}
                  />
                  <span>
                    By - {blog.created_by}, {moment(blog.created_at).format('Do MMMM YYYY')}
                  </span>
                  <br />
                  <h5 style={{ marginTop: 5 }}>{blog.title}</h5>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{blog.description}</p>
                </>
              )}
            </div>
            {/* <div className="blog-section2">
              <div className="row">
                <div className="col-sm-9">
                  <div className="tag-block">TAGS: </div>
                  <ul>
                    <li>
                      <a href="#" className="active">
                        Business
                      </a>
                    </li>
                    <li>
                      <a href="#">Politics</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li>
                    <li>
                      <a href="#">Networking</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-3">
                  <div className="tag-block">
                    SHARE IT:{' '}
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>{' '}
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>{' '}
                    <a href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h3 style={{ textAlign: 'left' }}>Related Post</h3>
              </div>
              <div className="col-sm-4">
                <div className="blog-section">
                  <img src={blogImg} width="100%" alt="" />
                  <h5>Lorem Ipsum is simply dummy text of the printing</h5>
                  <span>By - John Doe, May 5, 2021</span>
                  <br />
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting ind ustry. Lorem Ipsum has been the </p>
                  <a href="">Read More...</a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="blog-section">
                  <img src={blogImg1} width="100%" alt="" />
                  <h5>Lorem Ipsum is simply dummy text of the printing</h5>
                  <span>By - John Doe, May 5, 2021</span>
                  <br />
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting ind ustry. Lorem Ipsum has been the </p>
                  <a href="">Read More...</a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="blog-section">
                  <img src={blogImg2} width="100%" alt="" />
                  <h5>Lorem Ipsum is simply dummy text of the printing</h5>
                  <span>By - John Doe, May 5, 2021</span>
                  <br />
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting ind ustry. Lorem Ipsum has been the </p>
                  <a href="">Read More...</a>
                </div>
              </div>
            </div> */}
            {!isEmpty(blog) && (
              <div className="blog-section3">
                <div className="row">
                  <div className="col-sm-12">
                    <h3 style={{ textAlign: 'left', marginBottom: '40px' }}>
                      Comments: {blog.comments ? blog.comments.length : 0}
                      <ShareBtn data={shareData} />
                    </h3>
                  </div>
                  {blog.comments &&
                    blog.comments.length > 0 &&
                    blog.comments.map((comment) => (
                      <div
                        className="col-sm-12  col-xs-12"
                        style={{ position: 'relative' }}
                        key={comment.uuid}
                      >
                        <div className="replay">
                          <button onClick={() => replyToComment(comment.uuid)}>
                            Replay <i className="fa fa-reply" aria-hidden="true"></i>
                          </button>
                        </div>
                        <h5 style={{ marginBottom: '5px' }}>{comment.created_by}</h5>
                        <span>{moment(comment.created_at).format('Do MMMM YYYY')}</span>
                        <br />
                        <p style={{ marginTop: '5px' }}>{comment.comment}</p>
                        {/* REPLYS */}
                        {comment.replies &&
                          comment.replies.length > 0 &&
                          comment.replies.map((reply) => (
                            <div style={{ marginLeft: 40 }}>
                              <h5 style={{ marginBottom: '5px' }}>{reply.created_by}</h5>
                              <span>{moment(reply.created_at).format('Do MMMM YYYY')}</span>
                              <br />
                              <p style={{ marginTop: '5px' }}>{reply.reply}</p>
                            </div>
                          ))}
                        {/* REPLY FORM */}
                        {commentReplying === comment.uuid && (
                          <div className="blog-section4 send-massage">
                            <BlogCommentReplyForm
                              type="reply"
                              blog={blog}
                              comment={comment}
                              onFinish={getBlogDetails}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="blog-section4 send-massage">
              <BlogCommentReplyForm type="comment" blog={blog} onFinish={getBlogDetails} />
            </div>
          </div>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
export default BlogDetails;
