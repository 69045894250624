import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { capitalize } from 'lodash';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import SuspensionFormModal from './SuspensionFormModal';
import { suspensionUserTypes } from '../../helpers/constants';
import SelectBox from '../../components/UI/SelectBox';
import SearchBar from '../../components/global/SearchBar';
import {
  getUserType,
  displayAmount,
  getName,
  addQueryParamToUrl,
  getQueryParamValue,
} from '../../helpers/utils';
import Pagination from '../../components/global/Pagination';
import { usePagination } from '../../helpers/customHooks';

const identityStatusClassname = (status, classnames) => {
  let cn = 'p-2 alert ';
  switch (status) {
    case 0:
      cn += 'alert-info';
      break;
    case 1:
      cn += 'alert-success';
      break;
    case 2:
      cn += 'alert-danger';
      break;
    case 3:
      cn += 'alert-warning';
      break;
    default:
      break;
  }
  return cn + ' ' + classnames;
};

const UserlList = (props) => {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.enumData);

  const [userList, setUserList] = useState([]);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(getQueryParamValue(props.location, 'utype'));
  const [showSuspensionModal, setShowSuspensionModal] = useState(false);
  const toggleSuspensionModal = () => setShowSuspensionModal((s) => !s);
  const [searchText, setSearchText] = useState('');
  const [tab, setTab] = useState(getQueryParamValue(props.location, 'tab')?.toString() || '1');

  const getUserList = (page, perPage, searchText, getSuspendedUsers, userType) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };

    if (getSuspendedUsers) payload.identity_verified = 3;
    if (userType) payload.user_type = userType;
    if (searchText) payload.search = searchText;

    POST('/users/getUsers', payload)
      .then(({ data }) => {
        // console.log(data);
        setUserList(data.users);
        dispatch(hideLoading());
        setTotalPages(data.total_count / perPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());

        setUserList([]);
      });
  };

  useEffect(() => {
    if (tab === '1') {
      getUserList(selectedPage, itemsPerPage, '', true);
    } else if (tab === '2') {
      if (searchText || userType) {
        getUserList(selectedPage, itemsPerPage, searchText, false, userType);
      } else {
        getUserList(selectedPage, itemsPerPage, '', false);
      }
    }
  }, [selectedPage, searchText, tab, userType, itemsPerPage]);

  const refreshTabData = () => {
    if (tab === '1') {
      getUserList(selectedPage, itemsPerPage, '', true);
    } else if (tab === '2') {
      if (searchText || userType) {
        getUserList(selectedPage, itemsPerPage, searchText, false, userType);
      } else {
        getUserList(selectedPage, itemsPerPage, '', false);
      }
    }
  };

  const handleSuspend = (u) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to suspend ${getName(u)}?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        toggleSuspensionModal();
        setUserId(u.uuid);
      }
    });
  };

  const handleRemoveSuspension = (u) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to remove suspension?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        POST(`/admin/activateUser`, { uuid: u.uuid })
          .then(() => {
            dispatch(hideLoading());
            dispatch(showModal('User Activated successfully', true));
            refreshTabData();
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    });
  };

  const resetTabStates = () => {
    setUserList([]);
    setSelectedPage(1);
    setTotalPages(0);
    setSearchText('');
    setUserType('');
  };

  const switchTab = (tab) => {
    // reset all states
    resetTabStates();

    // set new tab
    setTab(tab);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'tab', tab);
  };

  const handleSearchClear = () => {
    setUserList([]);
    setSelectedPage(1);
    setTotalPages(0);
    setSearchText('');
  };

  const handleSearch = (text) => {
    setSearchText(text || '');
  };

  const handleUserTypeChange = (e) => {
    const val = e.target.value;
    handleSelected({ selected: 0 });
    addQueryParamToUrl(props.history, 'utype', val);
    setUserType(val);
  };

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>Agent &amp; Client Suspension</h2>
            <div className="servicetab">
              <ul className="nav nav-tabs responsive" id="nonResponsiveTabs1">
                <li className={`test-class ${tab === '1' ? 'active' : ''}`}>
                  <a className="deco-none red-class" onClick={() => switchTab('1')}>
                    Suspended Users
                  </a>
                </li>
                <li className={`test-class ${tab === '2' ? 'active' : ''}`}>
                  <a onClick={() => switchTab('2')}>User Details</a>
                </li>
              </ul>

              <div className="tab-content responsive">
                {tab === '1' && (
                  <div className={`tab-pane ${tab === '1' ? 'active' : ''}`} id="resp-tab4">
                    <div className="table-responsive clearingtable agent-bids-table">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            {/* <th>Status</th> */}
                            <th>Type</th>
                            <th>Suspended By</th>
                            <th>Suspension Reason</th>
                            <th>Suspension Fine</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList.length > 0 &&
                            userList.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  <strong>{x.user_id || 'N/A'}</strong>
                                </td>
                                <td>{getName(x)}</td>
                                <td>{x.email}</td>
                                {/* <td>
                                  <p className={identityStatusClassname(x.identity_verified)}>
                                    {capitalize(
                                      serviceData?.identity_verified[x.identity_verified],
                                    )}
                                  </p>
                                </td> */}
                                <td>{getUserType(serviceData, x)}</td>
                                <td>{serviceData?.user_types?.[x.suspended_by] || 'N/A'}</td>
                                <td>{x.suspension_reason || 'N/A'}</td>
                                <td>{displayAmount(x.suspension_fine) || 'N/A'}</td>
                                <td>
                                  <div>
                                    {x.identity_verified === 3 && (
                                      <a
                                        onClick={() => handleRemoveSuspension(x)}
                                        className="review mx-1 my-1"
                                      >
                                        Remove Suspension
                                      </a>
                                    )}
                                    <a
                                      onClick={() =>
                                        props.history.push(`/lawyer/user/details/${x.uuid}`)
                                      }
                                      className="review mx-1"
                                    >
                                      View Details
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {userList.length <= 0 && <h3 className="py-3">No Data Found!</h3>}
                    </div>
                  </div>
                )}
                {tab === '2' && (
                  <div className={`tab-pane ${tab === '2' ? 'active' : ''}`} id="resp-tab4">
                    <div className="table-responsive clearingtable agent-bids-table">
                      <div className="heading-with-btn">
                        <div></div>
                        <div className="col-lg-3 col-sm-12">
                          <SearchBar
                            id="search"
                            onSearch={handleSearch}
                            onClearSearchText={handleSearchClear}
                          />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                          <SelectBox
                            id="user_type"
                            name="user_type"
                            className="form-control"
                            placeholder="Select User type"
                            value={userType}
                            options={suspensionUserTypes}
                            onChange={handleUserTypeChange}
                          />
                        </div>
                      </div>
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList.length > 0 &&
                            userList.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  <strong>{x.user_id || 'N/A'}</strong>
                                </td>
                                <td>{getName(x)}</td>
                                <td>{x.email}</td>
                                <td>
                                  <p className={identityStatusClassname(x.identity_verified)}>
                                    {capitalize(
                                      serviceData?.identity_verified[x.identity_verified],
                                    )}
                                  </p>
                                </td>
                                <td>
                                  <div>
                                    {x.identity_verified === 1 && (
                                      <a onClick={() => handleSuspend(x)} className="review mx-1">
                                        Suspend
                                      </a>
                                    )}
                                    {x.identity_verified === 3 && (
                                      <a
                                        onClick={() => handleRemoveSuspension(x)}
                                        className="review  mx-1"
                                      >
                                        Remove Suspension
                                      </a>
                                    )}
                                    <a
                                      onClick={() =>
                                        props.history.push(`/lawyer/user/details/${x.uuid}`)
                                      }
                                      className="review mx-1"
                                    >
                                      View Details
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {userList.length <= 0 && <h3 className="py-3">No Data Found!</h3>}
                    </div>
                  </div>
                )}
              </div>
              {userList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
        {showSuspensionModal && (
          <SuspensionFormModal
            isOpen={showSuspensionModal}
            toggle={() => {
              toggleSuspensionModal();
              setUserId(null);
            }}
            userId={userId}
            refreshTabData={refreshTabData}
          />
        )}
      </div>
    </>
  );
};

export default UserlList;
