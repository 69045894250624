import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { capitalize, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import classnames from 'classnames';
import { GET, POST } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import AppLoader from '../../components/loaders/AppLoader';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import PlaceBidModal from './Bids/PlaceBidModal';
import JobDetailsModal from './JobDetailsModal';
import VerificationModal from '../../components/Modals/AgentVerificationModal';
import { DATE_FORMAT, TIME_FORMAT } from '../../helpers/constants';
import {
  accessGeneralOrCargoType,
  getQueryParamValue,
  checkAgentOperatesOnThisPort,
} from '../../helpers/utils';
import { showSuspensionModal } from '../../redux/user/UserActions';
// import DocumentsVerifyingMsg from '../../components/Modals/DocumentsVerifyingMsg';
import Pagination from '../../components/global/Pagination';
import { usePagination } from '../../helpers/customHooks';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  // console.log(user);

  const [verficationModal, setVerificationModal] = useState(false);
  const toggleVerificationModal = () => setVerificationModal((s) => !s);
  // const [documentVerifyingModal, setDocumentVerifyingModal] = useState(false);
  // const toggleDocumentVerifyingModal = () => setDocumentVerifyingModal((s) => !s);

  const serviceData = useSelector((state) => state.enumData);
  const agentPortsOfOperations = useSelector((state) => state.user.agentPortsOfOperations);

  const [jobList, setJobList] = useState([]);
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const [truckCheck, setTruckCheck] = useState(false);

  const [jobId, setJobId] = useState('');
  const [edit, setEdit] = useState(false);
  const [customValue, setCustomValue] = useState(0);
  const [bidModal, setBidModal] = useState(false);

  const getJobList = (type) => {
    // console.log('type', type);
    dispatch(showLoading());
    const params = {
      page: type.selectedPage,
      per_page: type.itemsPerPage,
    };
    if (type.type === '5') {
      params.sort_type = 'desc';
      params.sort_by = 'created_at';
    }

    GET(`/jobs/getByStatus/${type.type}`, params)
      .then(({ data }) => {
        // console.log('data', data);

        const jobs = [];
        data.jobs?.length > 0 &&
          data.jobs.forEach((j) => {
            if (!j.current_user_bid) {
              jobs.push(j);
            }
          });
        setJobList(jobs);
        setTotalPages(data.total_count / type.itemsPerPage);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  //checkEligible
  const checkEligible = () => {
    swal({
      title: 'No Trucks Found',
      text:
        'You have not registered any truck yet. To be able to bid for transport jobs on GoAgent, please register your trucks.',
      icon: 'error',
      buttons: ['Cancel', 'Register'],
    }).then((result) => {
      if (result) {
        props.history.push('/forwarding-agent/trucks');
      }
    });
  };

  const toggleBidModal = useCallback(
    (id, val) => {
      setEdit(false);

      setBidModal(!bidModal);
      if (!bidModal) {
        setCustomValue(val);
        setJobId(id);
      } else {
        setJobId('');
      }
    },
    [bidModal],
  );

  //truck status
  const checkStatus = () => {
    POST(`/users/isEligibleForBid`)
      .then(({ data }) => {
        // console.log('truck eligible', data);
        if (data.has_trucks >= 1 && data.has_truck_verfied == true) {
          setTruckCheck(true);
        } else {
          setTruckCheck(false);
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };

  useEffect(() => {
    let pagination = {
      type: '5',
      selectedPage: selectedPage,
      itemsPerPage,
    };
    getJobList(pagination);
    checkStatus();
  }, [selectedPage, itemsPerPage]);

  const [jobModal, setJobModal] = useState(false);
  const toggleJobModal = (id) => {
    setJobModal((s) => !s);
    if (id) {
      setJobId(id);
    }
  };

  const handlePlaceBid = (a, b) => {
    toggleBidModal(a, b);
  };

  const onPlaceBid = async (jobDetails) => {
    if (
      jobDetails.is_forwarding_only === false &&
      !checkAgentOperatesOnThisPort(agentPortsOfOperations, jobDetails.clearing_discharge_port)
    ) {
      dispatch(
        showModal(
          'You cannot place bid on this job since it discharges outside of your ports of operation',
        ),
      );
      return null;
    }
    const regFeePaid = user?.registration_fee_paid === 1;
    const userIdVerified = user?.identity_verified === 1;
    const userSuspended = user?.identity_verified === 3;

    // show Undertaking message to clearing agent
    const clearingText = `
      By proceeding with this bid, you consent to the following:

      1. GoAgent will pay customs duty directly.
      2. You will execute the job at the exact amount you bid. 
      3. You will receive 90% of your bid amount in advance, and the balance after successful completion of the job. 

      Kindly note that failure to comply with the terms of this bid will lead to suspension from this platform.
      `;
    // show Undertaking message to Transporter/Forwarding agent
    const transportText = `
      By proceeding with this bid, you consent to the following:

      1. You will execute the job at the exact amount you bid. 
      2. You will receive 90% of your bid amount in advance, and the balance after successful completion of the job. 

      Kindly note that failure to comply with the terms of this bid will lead to suspension from this platform.
      `;

    const result = await swal({
      title: 'Undertaking',
      text: jobDetails.is_forwarding_only ? transportText : clearingText,
      icon: 'warning',
      buttons: ['Cancel', 'Proceed'],
      className: 'bid-undertaking-popup',
    });

    if (result) {
      if (userSuspended) {
        dispatch(showSuspensionModal());
      } else if (!userIdVerified) {
        toggleVerificationModal();
      }
      // else if (!userIdVerified) {
      //   toggleDocumentVerifyingModal();
      // }
      else if (user.type === 'forwarding_agent' && !truckCheck) {
        checkEligible();
      } else {
        handlePlaceBid(jobDetails.uuid, jobDetails.custom_duty);
      }
    } else {
      setJobId('');
    }
  };

  const closePlaceBidModal = useCallback(() => toggleBidModal('', ''), [toggleBidModal]);

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }
  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>New Jobs</h2>
            <div className="table-responsive clearingtable">
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <thead>
                  <tr>
                    <th>Job ID</th>
                    <th>Job Descriptions</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {jobList.length > 0 &&
                    jobList.map((x, index) => {
                      const AgentOperatesOnJobPort = checkAgentOperatesOnThisPort(
                        agentPortsOfOperations,
                        x.clearing_discharge_port,
                      );
                      const isClearingAgent = user.type === 'clearing_agent';
                      const placeBidClass = classnames('detailsdiv2', {
                        'disabled-btn': !AgentOperatesOnJobPort && isClearingAgent,
                      });
                      return (
                        <tr key={index}>
                          <td>{`${x.job_id}-${x.is_forwarding_only ? 'T' : 'C'}` || 'N/A'}</td>
                          <td className="description1">
                            <div className=" row">
                              <div className="col-lg-6 col-sm-12">
                                <h4>{capitalize(serviceData.job.category[x.category]) || 'N/A'}</h4>
                                <p>{x.type && capitalize(serviceData.job.vehicle_type[x.type])}</p>
                                <p>{x.type_of_cargo && accessGeneralOrCargoType(x, serviceData)}</p>
                              </div>
                              <div className="col-lg-6 col-sm-12 align-self-center">
                                <a
                                  onClick={() => toggleJobModal(x.uuid)}
                                  className="detailsdiv color-secondary"
                                >
                                  View Details
                                </a>
                              </div>
                            </div>
                          </td>
                          {/* <td>
                          {user.identity_verified === 1 ? (
                            user.type === 'forwarding_agent' ? (
                              <Link to="/forwarding-agent/bids" className="detailsdiv">
                                View Bids
                              </Link>
                            ) : (
                              <Link to="/clearing-agent/bids" className="detailsdiv">
                                View Bids
                              </Link>
                            )
                          ) : (
                            <a className="detailsdiv" onClick={() => window.location.reload()}>
                              View Bids
                            </a>
                          )}
                        </td> */}
                          <td>
                            <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                            <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                          </td>
                          <td>
                            <a onClick={() => onPlaceBid(x)} className={placeBidClass}>
                              Place a Bid
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
            </div>
            {jobList.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
      {jobModal && (
        <JobDetailsModal isOpen={jobModal} toggle={() => toggleJobModal('')} jobId={jobId} />
      )}
      {bidModal && (
        <PlaceBidModal
          bidModal={bidModal}
          toggleModal={closePlaceBidModal}
          edit={edit}
          jobId={jobId}
          getJobList={() =>
            getJobList({
              type: '5',
              selectedPage: selectedPage,
              itemsPerPage,
            })
          }
          customValue={customValue}
          setEdit={setEdit}
          setJobId={setJobId}
          job={jobList.find((j) => j.uuid === jobId)}
        />
      )}
      <VerificationModal
        verficationModal={verficationModal}
        toggleVerificationModal={toggleVerificationModal}
        container="clearing-agent-page"
      />
      {/* {documentVerifyingModal && (
        <DocumentsVerifyingMsg
          isOpen={documentVerifyingModal}
          toggle={toggleDocumentVerifyingModal}
          container="clearing-agent-page"
        />
      )} */}
    </>
  );
};

export default Dashboard;
