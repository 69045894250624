import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayAmount } from '../../helpers/utils';
import Payments from '../global/Payments';
import { getLoggedInUser, hideSuspensionModal } from '../../redux/user/UserActions';
import { PAYMENT_RELATED_TO_SUSPENSION } from '../../helpers/constants';

const SuspensionModal = ({ isOpen, toggle, container }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [showPayments, setShowPayments] = useState(false);
  const handleFinePay = () => setShowPayments(true);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      container={container}
      className="modal-md suspension-modal"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="text-center">
          <h3 className="text-danger">
            You are Suspended <i className="fa fa-exclamation-triangle"></i>
          </h3>
          <h4 className="mt-5">
            <strong>Suspension Reason: </strong>
          </h4>
          <p>{user?.suspension_reason}</p>
          <hr />
          <p className="fw-normal">Please pay the fine below to continue</p>
          {!showPayments && (
            <div className="text-center">
              <button type="button" onClick={handleFinePay} className="submitbut">
                Pay {displayAmount(user?.suspension_fine)}
              </button>
            </div>
          )}

          {showPayments && (
            <Payments
              paymentAmount={user?.suspension_fine}
              paymentRelatedTo={PAYMENT_RELATED_TO_SUSPENSION}
              onPaymentSuccess={() => {
                dispatch(getLoggedInUser(history));
                setTimeout(() => dispatch(hideSuspensionModal()), 2000);
              }}
              nextButtonClassName="submitbut next-btn"
              backButtonClassName="submitbut back-btn btn-light mt-5"
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuspensionModal;
