import React from 'react';
import { isEmpty } from 'lodash';
import { isMobile, getUserName } from '../../../helpers/utils';
import ShowClientDocuments from '../../../components/global/ClientVerification/ShowClientDocument';
import DisplayDocument from '../../../components/global/DisplayDocument';
import { displayVMData } from '../../../components/global/DisplayVerifymeData';
import AuthRepDocFields from '../../../components/global/ClientVerification/DocumentFields/AuthRep';

const ImportersDetails = ({ userDetails, images, formValues, user, showDoc, clientType }) => (
  <div className="profilecont">
    <div className="profilecontleft">
      <h4 style={{ fontSize: '32px', color: '#000', fontWeight: '600' }} className="mb-5">
        {getUserName(userDetails)}
      </h4>
      <h5
        style={{
          fontSize: '20px',
          color: '#000',
          fontWeight: '600',
          marginBottom: '20px',
        }}
        className="mb-5"
      >
        {userDetails?.user_id}
      </h5>
      {clientType !== 'application_client' && (
        <>
          <DisplayDocument
            label="Email"
            value={userDetails?.email}
            type="text"
            textColClassName="document mx-2"
          />
          <DisplayDocument
            label="Phone number"
            value={userDetails?.mobile_number}
            type="text"
            textColClassName="document mx-2"
          />
        </>
      )}

      <div>
        {clientType !== 'application_client' && (
          <>
            <hr />
            <h3 className="mt-3">Documents</h3>
          </>
        )}
        <ShowClientDocuments
          formValues={{
            ...formValues,
            ...images,
            user: userDetails,
          }}
          clientType={userDetails.sub_type || userDetails.type}
          repValues={null}
          showAuthRepDetails={false}
          showDocumentModal={(doc) => showDoc(doc)}
          userType={user.type}
        />
      </div>
      {clientType !== 'application_client' && !isEmpty(userDetails) && (
        <div>
          <hr />
          <AuthRepDocFields values={userDetails} clientType={clientType} />
        </div>
      )}
    </div>
    <div className="profilecontright">
      {isMobile()?.matches && <hr />}
      {userDetails?.verify_me_data?.length > 0 && <h3>NIN Phone Details</h3>}
      {userDetails?.verify_me_data?.length > 0 &&
        userDetails.verify_me_data.map((vm) => displayVMData(vm, true))}
    </div>
  </div>
);

export default ImportersDetails;
