import React from 'react';

const ScrollToStart = ({ addHeaderClass }) => (
  <span
    onClick={() => {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }}
    className={`back-to-top d-flex align-items-center justify-content-center ${
      addHeaderClass ? 'active' : ''
    }`}
  >
    <i className="bi bi-arrow-up-short"></i>
  </span>
);

export default ScrollToStart;
