import { GET_DATA, RESET_DATA } from './AbokifxActions';

const initialState = [];

const AbokifixReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DATA:
      return {
        ...payload,
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};
export default AbokifixReducer;
