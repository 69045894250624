import { Formik, getIn } from 'formik';
import React from 'react';
import { object, ref, string } from 'yup';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/error/ErrorAction';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { PASSWORD_REGEX } from '../../helpers/constants';
import PasswordField from '../../components/global/Password';

const UpdatePassword = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <section className="bodycont">
          <div>
            <h2>Update Password</h2>
            <div className="personal-info">
              <Formik
                initialValues={{
                  current_password: '',
                  new_password: '',
                  new_password_confirmation: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(showLoading());
                  // console.log(values);
                  POST(`/auth/password/change`, values)
                    .then(({ data }) => {
                      dispatch(showModal('Password updated successfully!', true));
                      dispatch(hideLoading());
                      props.history.goBack();
                    })
                    .catch((err) => {
                      // console.log(err);
                      let errMsg =
                        err?.message?.firstNameInPassword ||
                        err?.message?.lastNameInPassword ||
                        err?.message?.companyNameInPassword ||
                        err?.message ||
                        '';

                      dispatch(showModal(errMsg || 'Something went wrong!'));
                      dispatch(hideLoading());
                      setSubmitting(false);
                    });
                }}
                validationSchema={object().shape({
                  current_password: string()
                    .required('Enter your password')
                    .min(8, 'Password must contain at least 8 characters'),
                  new_password: string()
                    .matches(
                      PASSWORD_REGEX,
                      'Password must contain minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                    )
                    .required('Enter your new password')
                    .test(
                      'pw-same-as-old',
                      'New Password cannot be same as Current Password',
                      (newPw, others) => {
                        if (newPw === others?.parent?.current_password) {
                          return false;
                        }
                        return true;
                      },
                    ),
                  new_password_confirmation: string()
                    .required('Please confirm your new password')
                    .oneOf([ref('new_password')], 'Passwords does not match'),
                })}
              >
                {(renderProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  } = renderProps;

                  return (
                    <form className={'row'} onSubmit={handleSubmit}>
                      <div className="col-sm-6 text-left">
                        <PasswordField
                          id="current_password"
                          placeholder="Current Password"
                          password={values.current_password}
                          showPassword={values.showCP}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          toggleShowPw={() => setFieldValue('showCP', values.showCP ? false : true)}
                          error={
                            getIn(touched, 'current_password') && getIn(errors, 'current_password')
                          }
                        />
                      </div>
                      <div className="col-sm-6 text-left">
                        <PasswordField
                          id="new_password"
                          placeholder="New Password"
                          password={values.new_password}
                          showPassword={values.showNP}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          toggleShowPw={() => setFieldValue('showNP', values.showNP ? false : true)}
                          error={getIn(touched, 'new_password') && getIn(errors, 'new_password')}
                        />
                      </div>
                      <div className="col-sm-6 text-left">
                        <PasswordField
                          id="new_password_confirmation"
                          placeholder="Confirm Password"
                          password={values.new_password_confirmation}
                          showPassword={values.showNPC}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          toggleShowPw={() =>
                            setFieldValue('showNPC', values.showNPC ? false : true)
                          }
                          error={
                            getIn(touched, 'new_password_confirmation') &&
                            getIn(errors, 'new_password_confirmation')
                          }
                        />
                      </div>
                      <div className="buttonsec">
                        <button
                          type="button"
                          onClick={() => props.history.goBack()}
                          className="submitbut btn-light"
                        >
                          Back
                        </button>
                        <button type="submit" className="submitbut" disabled={isSubmitting}>
                          Update
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UpdatePassword;
