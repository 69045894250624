import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const NoInsuranceWarning = ({
  isOpen,
  toggle,
  container = 'client-page',
  onProceed,
  onBuyInsurance,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      container={container}
      className="modal-md document-verifying-msg"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalHeader className="p-0">
        <h3>Warning!</h3>
      </ModalHeader>
      <ModalBody>
        <div className="text-center fs-2">
          <p>
            By proceeding without insurance, your cargo is at risk of accident, theft, vandalization
            and other risks. We strongly recommend insurance cover for all forwarding jobs.
          </p>
          <hr />
          <div className="buttonsec1 px-3">
            <button type="button" className="submitbut color-secondary" onClick={onBuyInsurance}>
              Buy Insurance
            </button>
            <button type="button" className="submitbut" onClick={onProceed}>
              Proceed without insurance
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NoInsuranceWarning;
