import { GET } from '../../services/rest.service';

export const ADD_DATA = 'ADD_DATA';
export const RESET_DATA = 'RESET_DATA';
export const ADD_PORTS_DATA = 'ADD_PORTS_DATA';
export const RESET_PORTS_DATA = 'RESET_PORTS_DATA';

export const addEnumData = () => {
  return (dispatch) => {
    GET('/public/global/vars')
      .then(({ data }) => {
        dispatch({
          type: ADD_DATA,
          payload: {
            ...data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_DATA,
          payload: {},
        });
      });
  };
};

export const addPortsData = () => {
  return (dispatch) => {
    GET('/ports/portofoperations')
      .then(({ data }) => {
        dispatch({
          type: ADD_PORTS_DATA,
          payload: data.portofoperations,
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_PORTS_DATA,
        });
      });
  };
};
