import React from 'react';
import { Helmet } from 'react-helmet';
import PublicFooter from '../../components/global/PublicFooter';

import PublicHeader from '../../components/global/PublicHeader';

const TermsConditions = () => {
  return (
    <div id="home-page">
      <Helmet>
        <title>GoAgent - Terms and Conditions</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main">
        <section id="terms-conditions" className="terms-conditions">
          <div className="container">
            <h2>Terms and Conditions</h2>
            <div className="clering-goagent-block">
              <h3>ACCEPTANCE OF THE TERMS</h3>
              <p className="header-sub">
                These Terms of Use (these “Terms”) constitute a binding and enforceable legal
                contract between GoAgent Limited, its affiliated companies (together, the
                “Administrator”, “we”, “us”) and you. Kindly read these Terms thoroughly.
              </p>
              <p>
                Your access and use of the goagent.com.ng website and mobile applications, as well
                as any service, content, and data available via them (together, the “Service” or the
                “Platform”) are governed by these Terms.
              </p>
              <p>
                If you do not agree with any part of these Terms, or if you are not eligible or
                authorized to be bound by the Terms, please avoid, and desist from accessing or
                using the Service.
              </p>
              <p>
                Please also review our Privacy Policy. The terms of the Privacy Policy and other
                supplemental terms, rules, policies, or documents that may be posted on the Platform
                from time to time are hereby expressly incorporated herein by reference. We reserve
                the right, in our sole discretion, to make changes or modifications to these Terms
                at any time and for any reason with or without prior notice and you hereby agree and
                accept all such further changes, amendment or update any time you access or use the
                Service.
              </p>
              <h3>IMPORTANT DISCLAIMERS</h3>
              <p>
                <strong>
                  DISCLAIMERS. Notwithstanding anything else set forth in this Agreement to the
                  contrary and to the maximum extent permitted by Applicable Law:
                </strong>
              </p>
              <p>
                <strong>
                  THE SERVICES ARE PROVIDED "AS IS" AND “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY
                  KIND, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ANY IMPLIED WARRANTIES OF
                  EXPECTATION OF PRIVACY, FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY,
                  NON-INFRINGEMENT OR TITLE, OR ANY WARRANTIES THAT MAY ARISE OUT OF COURSE OF
                  DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WE MAKE NO
                  REPRESENTATION, WARRANTY OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS,
                  QUALITY, SUITABILITY, AVAILABILITY, COMPLETENESS OR ACCURACY OF THE SERVICES, OR
                  THAT THE SERVICES WILL BE UNINTERRUPTED OR FREE OF ERRORS, VIRUSES OR OTHER
                  HARMFUL COMPONENTS. IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE FOR ANY
                  DAMAGES (WHETHER CONSEQUENTIAL, DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL,
                  EXEMPLARY OR OTHERWISE), INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR
                  PROPERTY DAMAGE RELATED TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE USE
                  OF, OR INABILITY TO USE THE SERVICES, OR FOR ANY OF THE CONTENT OR PRODUCTS
                  OBTAINED THROUGH OR OTHERWISE IN CONNECTION WITH THE SERVICES, IN EACH CASE,
                  REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT
                  OR OTHER THEORIES OF LIABILITY, AND ALSO REGARDLESS OF WHETHER WE WERE GIVEN
                  ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE. WE DO NOT GUARANTEE THAT
                  THE SERVICES, OR ANY PORTION THEREOF, WILL FUNCTION ON ANY PARTICULAR HARDWARE OR
                  DEVICE. ADDITIONALLY, THE SERVICES MAY BE SUBJECT TO MALFUNCTIONS AND DELAYS
                  INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS.
                </strong>
              </p>
              <p>
                <strong>
                  THE ADMINISTRATOR SHALL NOT BE LIABLE FOR ANY POTENTIAL LOSS, DIRECTLY OR
                  INDIRECTLY WITH THE SERVICE OR ANY ASSUMPTION OF TRADEMARK IN RESPECT OF
                  THIRD-PARTY INTELLECTUAL PROPERTY ON THE PLATFORM. EACH CUSTOMER SHALL BE SOLELY
                  LIABLE AND RESPONSIBLE FOR ANY INFRINGEMENT OF SUCH INTELLECTUAL PROPERTY. FOR THE
                  AVOIDANCE OF DOUBT, THE ADMINISTRATOR SHALL NOT BE LIABLE FOR ANY INTELLECTUAL
                  PROPERTY INFRINGEMENT IN CONNECTION WITH THE USE AND ACCESS TO THE PLATFORM.
                </strong>
              </p>
              <p>
                <strong>
                  IN ADDITION TO THE FOREGOING, IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES
                  ARISING OUT OF, OR IN ANY WAY CONNECTED WITH A THIRD PARTY'S UNAUTHORIZED ACCESS
                  TO YOUR PRIVATE INFORMATION, ADMINISTRATOR’S ACCOUNT CREDENTIALS, ACCOUNT OR USER
                  CONTENT, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, STRICT
                  LIABILITY, TORT OR OTHER THEORIES OF LIABILITY, AND ALSO, REGARDLESS OF WHETHER WE
                  WERE GIVEN ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE. WE DO NOT
                  CONTROL, ENDORSE OR TAKE RESPONSIBILITY FOR ANY USER CONTENT OR THIRD-PARTY
                  CONTENT AVAILABLE ON, OR LINKED TO BY THE SERVICES (INCLUDING INFORMATION ON THE
                  SERVICES PROVIDED BY CLEARING AGENTS, FOWARDING AGENTS, IMPORTERS, CARRIERS,
                  SHIPPERS OR DRIVERS). WE SHALL NOT BE LIABLE FOR ANY TRANSACTION OR RELATIONSHIP
                  BETWEEN YOU AND ANY THIRD PARTY (INCLUDING ANY CLEARING AGENTS, FOWARDING AGENTS,
                  IMPORTERS, SHIPPER, CARRIER, OR DRIVER), OR ANY DELAY OR FAILURE IN PERFORMANCE
                  RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL.
                </strong>
              </p>
              <h3>ACCOUNT REGISTRATION</h3>
              <p>
                In order to use certain features of the Service you need to register an account on
                the Platform (the “Account”) and provide certain information about yourself and or
                your company as prompted by the registration page on the Platform.
              </p>
              <p>
                You may create a user account as:
                <ul>
                  <li>Importer as an individual, registered company or other legal entities.</li>
                  <li>Clearing Agent as a registered company.</li>
                  <li>Transporter as a registered company.</li>
                </ul>
              </p>
              <p>
                Except with the Administrator’s approval, a User may only register one Account on
                the Platform. We may cancel or terminate a User’s Account if we have reasons to
                suspect that the User has concurrently registered or is in control of two or more
                Accounts. Further, we may reject User’s application for registration for any reason.
              </p>
              <p>
                Upon registration on the Platform, the Administrator shall assign the Account and
                issue a User ID and password (the latter shall be chosen by a registered User during
                registration) to each registered User. The Account may have a web-based email
                account with limited storage space for the Users to send or receive emails.
              </p>
              <p>
                You will be required to provide personal and business information at the point of
                registration. You will also be required to verify your Account by providing personal
                and corporate information and documents. The details of such requirements are hereby
                incorporated into these Terms.
              </p>
              <p>
                You acknowledge that you are solely responsible for safeguarding and maintaining the
                confidentiality of access details to your Account and that you are fully responsible
                and liable for any activity performed using your Account access details.
              </p>
              <p>
                You hereby represent and warrant to us that you have reached the age of majority or
                are accessing the Service under the supervision of a parent or legal guardian. All
                users who are minors in the jurisdiction in which they reside (generally under the
                age of 18 or 21 as the case may be) must have the permission of and be directly
                supervised by their parent or guardian to use the Service. If you are a minor, you
                must have your parent or guardian read and agree to these Terms prior to you using
                the Service.
              </p>
              <p>
                We reserve the right to suspend or terminate your Account, or your access to the
                Service, with or without notice to you, in the event that you breach any of these
                Terms.
              </p>
              <p>
                You agree to immediately notify us of any unauthorized use, or suspected
                unauthorized use of your Account or any other breach of security. We cannot and
                shall not under any circumstances be liable for any loss or damage arising from your
                failure to comply with the above requirements.
              </p>
              <h3>OUR SERVICE</h3>
              <p>
                The Platform is an online service platform that connects importers with clearing
                agents and transporters, as well as monitor and control the transaction. The
                clearing agents and transporters will bid for the clearing or transport jobs posted
                by an importer and the agent with the lowest bid will automatically get the job
                except where the importer opted for a different clearing or transporter.
              </p>
              <p>
                The Administrator itself is not an importer, clearing agent or transporter, as well
                as not a provider of any service posted by users on the Platform. In addition, the
                Administrator is neither a marketer nor a person acting on user's behalf with
                respect to the marketing of any goods or services posted on the Platform.
              </p>
              <p>
                Clearing agents and transporters shall be solely responsible to remit any applicable
                taxes resulting from the services they provided as clearing agents or transporters
                on the Platform.
              </p>
              <p>
                The Administrator reserves a right to delete or block access to job or bid posted by
                users without any notice in the event of: (i) receiving of judgments of competent
                court of law; (ii) claim of a holder of intellectual property rights to terminate
                infringement of his/her rights by a user on the Platform; (iii) infringements of
                rights or legal interests of other users, legal entities, or individuals upon their
                reasonable request; (iv) detecting that job or bid posted by the users on the
                Platform breaches these Terms or any applicable law, regulation, rule or standard.
              </p>
              <p>
                The Administrator retains the right to implement any changes to the Service (whether
                to free or paid features) at any time, with or without notice. You acknowledge that
                a variety of Administrator’s actions may impair or prevent you from accessing the
                Service at certain times and/or in the same way, for limited periods or permanently,
                and agree that the Administrator has no responsibility or liability as a result of
                any such actions or results, including, without limitation for the deletion of, or
                failure to make available to you, any content, or services.
              </p>
              <p>
                Each user of the Service is solely responsible for any and all his or her User
                Content (as defined below). Because we do not control the User Content, you
                acknowledge and agree that we are not responsible for any User Content and we make
                no guarantees regarding the accuracy, currency, suitability, or quality of any User
                Content, and we assume no responsibility for any User Content.
              </p>
              <p>
                Your interactions with other Service users are solely between you and such user. You
                agree that the Administrator will not be responsible for any loss or damage incurred
                as the result of any such interactions.
              </p>
              <p>
                In the event of a dispute between you and any User, an ADR mechanism has been
                created on the Platform, where such disputes that qualify to be adjudicated. The
                Administrator has provided an unbiased third-party legal practitioner to adjudicate
                over disputes between Users in connection with the Service. You agree that you will
                provide all pieces of evidence and necessary information relating to such lodged
                dispute. You hereby also agree to be bound by the decision of the adjudicator. The
                adjudicator may request for further information/documents, and you agree to provide
                such. Failure to comply with requests from the adjudicator may be a ground for
                dismissal of complaint or entering a default decision against an irresponsive User.
              </p>
              <p>
                You agree that you are solely responsible for any fraud, misrepresentation,
                misconduct, negligence, infringement of any intellectual property, breach of any law
                in respect of the User Content or any part thereof.
              </p>
              <p>
                We may allow Users to access content, products or services offered by third parties
                through hyperlinks (in the form of word link, banners, channels or otherwise), API
                or otherwise to such third parties' web sites. You are cautioned to read such web
                sites' terms and conditions and/or privacy policies before using the Sites. You
                acknowledge that the Administrator has no control over such third parties' web
                sites, does not monitor such web sites, and shall not be responsible or liable to
                anyone for such web sites, or any content, products or services made available on or
                through such web sites.
              </p>
              <p>
                You hereby release us, our officers, employees, agents, and successors from claims,
                demands and all losses, damages, rights, claims, and actions of any kind, including
                personal injuries, death, property damage, fraud, negligence, or any breach of law
                that is either directly or indirectly related to, or arises from any interactions
                with, or conduct of any Service users or any Third-Party Ads.
              </p>
              <h3>SUBSCRIPTION FEE AND COMMISSION</h3>
              <p>
                The Platform is generally free; however, it contains payable services. Clearing
                agents and transporters shall pay a non-refundable subscription fee of N10,000.00
                annually to be able to use the Service (the “Subscription Fee”).
              </p>
              <p>
                We reserve the right, in our sole discretion, to change Subscription Fee and
                acceptable payment methods from time to time and for any reason.
              </p>
              <p>
                The Subscription Fee is non-refundable due to the nature of online services, and you
                agree not to sue us or claim and refund for the fees.
              </p>
              <p>
                For each clearing job on the Platform, a commission of 3% of the custom duty shall
                be paid to the Administrator (the “Clearing Job Commission”). The Clearing Job
                Commission shall be calculated and paid together with the clearing agent fees,
                custom duty, other taxes, terminal, and shipping charges, as well as other handling
                fees as may be applicable.
              </p>
              <p>
                Similarly, each transport job on the Platform a commission of 5% of the transport
                job cost shall be paid to the Administrator (the “Transport Job Commission”). The
                Clearing Job Commission shall be calculated and paid together with the transporter
                fees, any applicable charges and taxes, and other handling fees as may be
                applicable.
              </p>

              <h3>PAYMENT POLICY</h3>
              <p>
                When making payment for the Service, you will be able to choose a payment method
                that is most suitable for you. There are links to payment gateways where you will
                make your payments and choose your preferable mode of payment.
              </p>
              <p>
                The Administrator reserves the right, in its sole discretion, to change the
                features, fees, and acceptable payment methods from time to time and for any reason
                without incurring any liability.
              </p>
              <p>
                Unless otherwise provided by mandatory rules of the applicable law, any fees paid
                for the Service are non-refundable due to the online nature of the services, but
                where the circumstance warrant, refunds may be made to a User.
              </p>
              <p>
                The Administrator does not guarantee any results of job placed or bids placed on the
                Platform and will not be held liable if they do not meet your expectations.
              </p>

              <h3>SUSPENSION AND PENALTY</h3>
              <p>
                These Terms must be strictly adhered to. Clearing agents and transporters must
                uphold the highest professional standard and quality of service while using the
                Service on our Platform. Any User who breaches these Terms or other professional
                standard in respect of the Service shall, without notice, be suspended from the
                Platform, and a penalty be imposed on such User for violating these Terms. The
                User’s Account shall not be reactivated until such penalty is fully paid.
              </p>
              <p>
                The Penalty shall be commensurate to damage or claims by aggrieved User where the
                violation is linked to the Service in connection with another User. The
                Administrator shall determine the appropriate penalty on a case-by-case basis.
              </p>
              <p>
                We reserve the right not to reactivate any such suspended Account and use the
                penalty for restitution of damage caused to the other Users.
              </p>

              <h3>USER REPRESENTATIONS AND WARRANTIES</h3>
              <p>By using the Service, you represent and warrant that:</p>
              <p>You have the legal capacity, and you agree to comply with these Terms;</p>
              <p>
                If you register yourself as a representative of a legal entity, you are authorized
                by the legal entity to act as such, and to enter into an agreement with us and with
                users of the Platform. For the avoidance of doubt, as a representative, your
                principal is also bound by these Terms;
              </p>
              <p>
                You have attained the age of legal capacity (18 years or 21 years as the case may
                be);
              </p>
              <p>
                You will or have provided true, accurate, and complete information in your Account;
              </p>
              <p>
                You will immediately update your information on your Account as they change in order
                to maintain its truthfulness, accuracy, and completeness;
              </p>
              <p>
                You will immediately change data for access to the Platform if you have a suspicion
                that your Account access details were compromised or probably used by the third
                parties;
              </p>
              <p>You will notify the Administrator of any unauthorized access to your Account;</p>
              <p>
                You will not provide any false or misleading information about your identity or
                location in your Account;
              </p>
              <p>
                You will use the Service in strict abidance by applicable laws, regulations, rules,
                guidelines;
              </p>
              <p>You will not use the Service for any illegal or unauthorized purpose;</p>
              <p>
                You will not post on the Platform, jobs or bids that offer for sale or exchange of
                any prohibited or illegal Items.
              </p>
              <p>
                You will not post on the Platform jobs, or bids that infringe other person’s rights
                or interests, including any intellectual property rights or any other personal or
                proprietary rights of any third party.
              </p>
              <p>You will not post on the Platform, clearing and/or transport jobs that include:</p>
              <p>false, misleading, or deceptive statements;</p>
              <p>contraband in whatever form as prohibited by applicable laws;</p>
              <p>
                personal or identifying information about minors or other persons without the proper
                consent;
              </p>
              <p>pornographic, overtly sexual materials;</p>
              <p>
                depictions that encourage illegal or reckless use of weapons and dangerous objects,
                or facilitate the purchase of firearms or ammunition;
              </p>
              <p>
                advocacy of hate, violence, discrimination, racism, xenophobia, ethnic conflicts;
              </p>
              <p>appeals to violence and unlawful actions;</p>
              <p>offers of prostitution or other services contradicting moral or legal norms;</p>
              <p>services, provision of which is prohibited by the applicable law;</p>
              <p>
                information of solely promotional nature with no offers of specific goods or
                services;
              </p>
              <p>
                counterfeit and imitated goods or unauthorized copies. Unauthorized copies include
                also goods having been acquired by illegal means, pirated, or stolen; and
              </p>
              <p>
                direct or indirect references to any other web sites, references, or information
                about websites competing with the Platform;
              </p>
              <p>
                You will not use software and pursue any other actions aimed to interfere with the
                normal operation of the Platform;
              </p>
              <p>
                You will not promote or distribute unsolicited commercial emails, chain letters,
                Ponzi schemes through the Platform or by any other means towards other users of the
                Platform;
              </p>
              <p>
                You will not copy, modify, distribute any other User Content without consent of the
                respective user;
              </p>
              <p>
                You will not harvest or otherwise collect information about users, including email
                addresses, phone numbers, without their consent or otherwise violate the privacy of
                another person;
              </p>
              <p>
                You will not download, store, post, distribute and provide access to, or in any
                other way use worms, viruses, trojans, and other malware; and
              </p>
              <p>
                You have the necessary license or are otherwise authorized, as required by
                applicable law, to conduct your business, import goods into Nigeria, operate and
                conduct a clearing agent business or transporter business, as the case may be.
              </p>

              <h3>INDEMNITY</h3>
              <p>
                You agree to indemnify and hold the Administrator, its successors, subsidiaries,
                affiliates, related companies, its suppliers, licensors and partners, and the
                officers, directors, employees, agents, and representatives of each of them harmless
                from any claim or demand, including costs and attorneys’ fees, made by any third
                party due to or arising out of: (i) your use of the Platform; (ii) your User
                Content; (iii) your violation of any representation, warranty, covenant, or
                obligation stipulated in these Terms; (iv) your violation of any applicable law,
                industry-standard, regulation, guideline, rule; (v) any transaction entered into by
                you via the Platform or your violation of terms of such transaction. The
                Administrator reserves the right, at your expense, to assume the exclusive defence
                and control of any matter for which you are required to indemnify us, and you agree
                to cooperate with our defence of these claims.
              </p>

              <h3>LIMITATION OF LIABILITY</h3>
              <p>
                <strong>
                  IN NO EVENT SHALL WE (AND OUR AFFILIATES) BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                  ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
                  PUNITIVE DAMAGES ARISING FROM THESE TERMS OR YOUR USE OF, OR INABILITY TO USE THE
                  SERVICE, OR THIRD-PARTY ADS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                  SUCH DAMAGES. ACCESS TO, AND USE OF THE SERVICE, AND THIRD-PARTY ADS ARE AT YOUR
                  OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                  COMPUTING SYSTEM OR LOSS OF DATA RESULTING THEREFROM.
                </strong>
              </p>
              <p>
                <strong>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, YOU AGREE THAT THE
                  AGGREGATE LIABILITY OF THE ADMINISTRATOR TO YOU FOR ANY AND ALL CLAIMS ARISING
                  FROM THE USE OF THE SERVICE, CONTENT OF JOBS OR BIDS, IS LIMITED TO THE LESSER OF
                  THE AMOUNTS YOU HAVE PAID, IF ANY, TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO
                  ANY CAUSE OF ACTION ARISING OR ₦500,000.
                </strong>
              </p>

              <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
              <p>
                Information you submit to us as part of your registration, and any data, text,
                pictures and other materials that you may submit or post on the Platform (the “User
                Content”) remain your intellectual property, and the Administrator does not claim
                any ownership of the copyright or other proprietary intellectual property rights in
                such registration information and the User Content. Notwithstanding the foregoing,
                you agree that the Administrator may retain copies of all registration information
                and the User Content and use such information and the User Content as reasonably
                necessary for, or incidental to its operation of the Service and as described in
                these Terms and our Privacy Policy.
              </p>
              <p>
                You hereby grant the Administrator the non-exclusive, worldwide, transferable,
                perpetual, irrevocable right to copy, modify, adapt, store, publish, distribute,
                publicly display and perform, communicate and make available to the public the User
                Content in connection with the Service as well as for the Administrator’s marketing,
                advertising, and other purposes.
              </p>
              <p>
                You agree and represent and warrant that your use of the Service, or any portion
                thereof, will neither infringe nor violate the rights of any other party or breach
                any contract or legal duty to any other parties.
              </p>
              <p>
                Materials on the Platform, except those posted by the user, including but not
                limited to texts, software, scripts, graphics, photos, sounds, music, videos,
                interactive functions, etc. ("Materials") and trademarks, service marks and logos
                included in it ("Marks") belong to or are licensed by the Administrator representing
                items of copyright and of any other intellectual property rights. Any use of such
                Materials and Marks without prior notice of the Administrator is not allowed.
              </p>
              <strong>
                Notice for Claims of Intellectual Property Violations and Copyright Infringement
              </strong>
              <p>
                If you are a holder of intellectual property rights, or a person authorized to act
                in the name of a holder of intellectual property rights, and you reasonably believe
                that information that is posted to the Platform in some way infringes your
                intellectual property rights or intellectual property rights of a person, in whose
                name you act, you may provide notification to the Administrator requiring to delete
                such material. In this regard, you shall warrant that your appeal has a legal basis,
                and you act in good faith, according to law.
              </p>
              <p>
                When providing relevant notification concerning infringement of rights, you shall
                ensure that your request corresponds to the form below and includes the following:
              </p>
              <p>
                an appeal should include the physical or electronic signature of a person empowered
                for acting in the name of a holder of the right, which is believed to be infringed;
              </p>
              <p>
                the objects of intellectual property right, rights on which were supposedly
                infringed shall be specified. If several objects exist, the entire list of such
                items shall be provided;
              </p>
              <p>
                you shall specify materials (with an indication of specific URL-pages), which are
                stated to infringe rights or themselves are the objects of infringement;
              </p>
              <p>
                you shall provide contact information so that the Administrator would be able to
                contact you, for example, address, phone number, and email address;
              </p>
              <p>
                signed application with regard to your faithful and reasonable belief in those
                materials being the objects of complaint concerning infringement of intellectual
                property rights are used without a right holder’s or its representative’s consent,
                and also that this is not allowed by law;
              </p>
              <p>
                signed application that a holder of intellectual property rights releases the
                Administrator from any third parties' claims related to deletion of relevant
                materials by the Administrator;
              </p>
              <p>
                signed application that information contained in a notification is accurate under
                penalty of perjury, and you are authorized to act in the name of a holder of the
                exclusive right, which has been supposedly infringed;
              </p>
              <p>
                statutory regulations which you believe to be violated in connection to using of
                disputable content;
              </p>
              <p>state, in which territory you believe the rights to be infringed;</p>
              <p>
                copies of documents establishing rights for an object of intellectual property
                right, which is subject to security, as well as a document that confirms powers for
                acting in the holder’s name, in attachments to your appeal.
              </p>
              <p>The relevant notification shall be sent to email support@goagent.com.ng</p>

              <h3>GOVERNING LAW AND JURISDICTION</h3>
              <p>
                These Terms shall be governed in accordance with the laws of the Federal Republic of
                Nigeria.
              </p>
              <p>
                Any dispute arising out of, or in connection with these Terms, including any
                question regarding its existence, validity, or termination, shall be referred to and
                finally resolved by arbitration under the Arbitration and Conciliation Act (Cap.
                A18) 2004 Laws of the Federation.
              </p>
              <p>
                The number of arbitrators shall be one. The seat of arbitration shall be Lagos,
                Nigeria. The language to be used in the arbitral proceedings shall be English.
              </p>

              <h3>Notices</h3>
              <p>
                All legal notices or demands to, or upon the Administrator shall be made in writing
                and sent to the Administrator personally, by courier or certified mail to the
                following entity and address: GoAgent Limited, whose registered office address is at
                7B Hall Lane, Off Oduduwa Road, Apapa, Lagos, Nigeria Attn: Legal Department.
              </p>
              <p>
                All legal notices or demands to, or upon a User shall be effective if either
                delivered personally, sent by courier, certified mail, by facsimile or email to the
                last-known correspondence, fax or email address provided by the User to the
                Administrator, or by posting such notice or demand on an area of the Platform that
                is publicly accessible without a charge. Notice to a User shall be deemed to be
                received by such User if and when:
              </p>
              <p>
                a) we are able to demonstrate that communication, whether in physical or electronic
                form, has been sent to such User, or
              </p>
              <p>
                b) immediately upon posting such notice on an area of the Platform that is publicly
                accessible without charge.
              </p>
              <p>
                11.3 You agree that all agreements, notices, demands, disclosures, and other
                communications that the Administrator sends to you electronically will satisfy any
                legal requirement that such communication should be in writing.
              </p>

              <h3>General Provisions</h3>
              <p>
                Subject to any additional agreements, the Terms constitute the entire agreement
                between you and the Administrator with respect to, and govern your use of the Sites
                and Services, superseding any prior written or oral agreements in relation to the
                same subject matter herein.
              </p>
              <p>
                The Administrator and you are independent contractors, and no agency, partnership,
                joint venture, employee-employer, or franchiser-franchisee relationship is intended
                or created by these Terms.
              </p>
              <p>
                If any provision of these Terms is held to be invalid or unenforceable, such
                provision shall be deleted and the remaining provisions shall remain valid and be
                enforced.
              </p>
              <p>
                Headings are for reference purposes only and in no way define, limit, construe or
                describe the scope or extent of such clause.
              </p>
              <p>
                The Administrator’s failure to enforce any right or failure to act with respect to
                any breach by you under the Terms will not constitute a waiver of that right nor a
                waiver of our rights to act with respect to subsequent or similar breaches.
              </p>
              <p>
                The Administrator shall have the right to assign these Terms (including all of our
                rights, titles, benefits, interests, and obligations and duties in the Terms) to any
                person or entity without prior consent or notice to you. You may not assign, in
                whole or part, the Terms to any person or entity.
              </p>
              <p>
                In no event shall the Administrator be liable for any failure to comply with these
                Terms to the extent that such failure arises from factors outside the
                Administrator's reasonable control.
              </p>

              <h3>CONTACT</h3>
              <p>
                If you want to send any notice under these Terms, or have any questions regarding
                the Service, you may contact us at:{' '}
                <a href="mailto:support@goagent.com.ng">support@goagent.com.ng</a>.
              </p>
              <p>Last updated: November 4, 2021.</p>
            </div>
          </div>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
export default TermsConditions;
