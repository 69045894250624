import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { capitalize, isEmpty, get } from 'lodash';
import { useParams } from 'react-router-dom';
import Pdf from 'react-to-pdf';
import { POST } from '../../../services/rest.service';
import { showLoading, hideLoading } from '../../../redux/loader/LoaderActions';
import { showModal } from '../../../redux/error/ErrorAction';
import {
  displayAmount,
  // accessGeneralOrCargoType,
  // calculatePercentage,
} from '../../../helpers/utils';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../helpers/constants';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';

const ReceiptDetails = (props) => {
  const dispatch = useDispatch();
  const { receiptId } = useParams();
  const serviceData = useSelector((state) => state.enumData);
  // const { user } = useSelector((state) => state.user);
  const [details, setDetails] = useState(null);

  const docRef = useRef(null);

  useEffect(() => {
    if (receiptId) {
      dispatch(showLoading());
      POST('/payment/getPaymentReciept', { payment_id: receiptId })
        .then(({ data }) => {
          // console.log('>> data',data);
          setDetails(data.payment);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());
          dispatch(showModal(err.message));
        });
    }
  }, [receiptId]);

  // console.log('>> details', details);

  // const taxPercentage = 7.5;
  // const taxAmount = details?.amount
  //   ? calculatePercentage(taxPercentage, Number(details.amount).toFixed(2))
  //   : 0;

  return (
    <div id="tabblock4" className="tabcontent">
      <div className="bodycont">
        <div className="">
          <div className="tab-pane active" id="resp-tab7">
            <div>
              <h2>
                <span onClick={props.history.goBack} style={{ cursor: 'pointer', float: 'left' }}>
                  <LeftArrowSvg />
                </span>
              </h2>
            </div>
            <div className="clearfix"></div>
            <div className="receipt-container" id="receiptContainer">
              {!isEmpty(details) && (
                <div className="receipt" ref={docRef}>
                  <div className="header-text">Receipt</div>
                  <div className="row m-3">
                    <div className="col-12 text-center">
                      <h3>Go Agent Limited</h3>
                      <address>
                        GoAgent Plaza, 62 Kofo Abayomi Avenue, <br />
                        Apapa, Lagos, Nigeria.
                      </address>
                    </div>
                    <div className="col-4 text-center mb-5">
                      <p>
                        <strong>Receipt #:</strong> <br /> {`#${get(details, 'job.job_id', '--')}`}
                      </p>
                    </div>

                    <div className="col-4 text-center mb-5">
                      <p>
                        <strong>Date:</strong> <br />{' '}
                        {moment(details.created_at).format(DATE_TIME_FORMAT)}
                      </p>
                    </div>

                    <div className="col-4 text-center mb-5">
                      <p>
                        <strong>Payment method:</strong>
                        <br /> {`${capitalize(get(details, 'channel', '--'))}`}
                      </p>
                    </div>
                    <div className="col-12 receipt-summary">
                      <h5>
                        <u>Summary</u>
                      </h5>
                      <div className="row">
                        <div className="col-6 text-right mb-4">
                          {details.job.job_type_tags.map((y, i) => (
                            <span key={i}>
                              {capitalize(y === 'forwarding' ? 'Transport' : y)} Service
                            </span>
                          ))}
                          <span>
                            {details.job.category && (
                              <>{` - ${capitalize(
                                serviceData.job.category[details.job.category],
                              )}`}</>
                            )}
                          </span>
                          <span>
                            {details.job.type && (
                              <>{` - ${capitalize(
                                serviceData.job.vehicle_type[details.job.type],
                              )}`}</>
                            )}
                          </span>
                        </div>
                        <div className="col-6 mb-4">
                          {displayAmount(details.amount, details.channel === 'paypal')}
                        </div>

                        <div className="col-6 text-right">
                          <strong>Amount Paid</strong>
                        </div>
                        <div className="col-6">
                          {displayAmount(details.amount, details.channel === 'paypal')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-3 mt-5">
                    <div className="col-12 text-center">
                      For any queries contact GoAgent at{' '}
                      <a href="mailto: info@goagent.com.ng">info@goagent.com.ng</a> or call at{' '}
                      <a href="tel: +23413434340">+234 1 343 4340</a>,
                      <a href="tel: +2347001239595">+234 700 123 9595</a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="buttonsec1" style={{ textAlign: 'center' }}>
              <a onClick={props.history.goBack} className="backbut">
                Back
              </a>
              <Pdf
                targetRef={docRef}
                className="backbut"
                filename={`receipt-#${get(details, 'job.job_id', '--')}`}
                x={25}
                y={30}
                s={0.3}
              >
                {({ toPdf }) => (
                  <a onClick={toPdf} className="backbut">
                    Download as PDF
                  </a>
                )}
              </Pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptDetails;
