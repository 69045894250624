import React from 'react';
import ReactPaginate from 'react-paginate';

const selectOptions = [10, 20, 30, 40, 50];

const Pagination = (props) => {
  const { pageCount, onPageChange, selectedPage, setItemsPerPage, itemsPerPage } = props;

  return (
    <div className="pagination-row">
      <select
        className="page-count-select"
        id="source"
        name="source"
        value={itemsPerPage}
        onChange={setItemsPerPage}
        title="Page Count"
      >
        {selectOptions.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      <ReactPaginate
        previousLabel={'<<'}
        nextLabel={'>>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
        forcePage={selectedPage - 1}
      />
    </div>
  );
};

export default Pagination;
