import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import AppLoader from '../../components/loaders/AppLoader';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { GET, POST } from '../../services/rest.service';
import Chat from '../../components/UI/Chat';
import { displayAmount } from '../../helpers/utils';
import DriverBehaviour from '../../components/global/DriverBehaviour';
import { DATE_TIME_FORMAT } from '../../helpers/constants';
import {
  generateHarshDrivingReport,
  getHarshDrivingReportStatus,
  getHarshDrivingReport,
} from '../../services/navixy.service';
import { LeftArrowSvg } from '../../components/UI/CommanUI';
import DisplayDocument from '../../components/global/DisplayDocument';
import NavixyWebLocator from '../../components/global/NavixyWebLocator';
import { useChat } from '../../helpers/customHooks';

const Jobstatus = (props) => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(props.location.search).get('id');
  const job_id = params;
  const serviceData = useSelector((state) => state.enumData);
  const [chatbox, setChatbox] = useState(false);
  const [chatlist, setChatlist] = useState([]);
  const [msg, setMsg] = useState('');
  const { user } = useSelector((state) => state.user);

  const [trackerId, setTrackerId] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  //get Job Deta ils
  const jobDetails = async () => {
    try {
      dispatch(showLoading());
      const jobRes = await POST(`/jobs/getClearingCargoStatus`, { job_uuid: job_id });
      // console.log('>> jobRes', jobRes);
      setDetails(jobRes.data);
      if (jobRes?.data?.job?.truck_uuid) {
        const truckDataResp = await POST(`/trucks/getDetails`, {
          truck_uuid: jobRes.data.job.truck_uuid,
        });
        // console.log('>>. truckDataResp', truckDataResp)
        const trackerid = truckDataResp?.data?.truck?.tracker_id;
        if (trackerid) setTrackerId(trackerid);
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    jobDetails();
  }, []);

  // initialize and subcsribe to chat here
  const handleChatEvent = (data1) => {
    if (data1.chat_type === 'forwarding') {
      if (!chatbox) {
        // set unread count when chat is not openend
        setUnreadCount((prevState) => prevState + 1);
      }

      setChatlist((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById('chat-top');
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    dispatch(showModal('you have a new message', true));
  };
  useChat(user.uuid, chatbox, handleChatEvent);

  //chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${job_id}`)
      .then(({ data }) => {
        // console.log(data);
        const oc = data.chats.operator?.length > 0 ? data.chats.operator.reverse() : [];
        setChatlist(oc);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const chatToggle = () => {
    if (!chatbox) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount(0);
    }
    setChatbox(!chatbox);
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }
    const payLoad = {
      job_uuid: job_id,
      sender_uuid: user.uuid,
      receiver_uuid: details.job.operator_uuid,
      chat_type: 'forwarding',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        setChatlist((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
        // getChat();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
      // const objDiv = document.getElementById('chat-top');
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };
  //end chat code

  // driver behaviour
  const [harshDrivingData, setHarshDrivingData] = useState({});

  const fetchHarshDrivingData = useCallback(
    async (trackerId) => {
      try {
        dispatch(showLoading());
        // harsh driving
        // job?.assigned_at is starting date of job
        const fromDate =
          details?.job?.assigned_at ||
          moment()
            .startOf('day')
            .format(DATE_TIME_FORMAT);

        // job?.forwarding_completed_at is completion date of job
        const toDate =
          details?.job?.forwarding_completed_at ||
          moment()
            .endOf('day')
            .format(DATE_TIME_FORMAT);
        const harshDriving = await generateHarshDrivingReport({
          trackerId: trackerId,
          from: fromDate,
          to: toDate,
        });
        // console.log('>> harshDriving',harshDriving);

        if (harshDriving?.success && harshDriving?.id) {
          // adding a one second delay for now
          await new Promise((r) => setTimeout(r, 2000));

          const hdrStatus = await getHarshDrivingReportStatus(harshDriving.id);

          if (hdrStatus?.success && hdrStatus?.percent_ready === 100) {
            const hdReport = await getHarshDrivingReport(harshDriving.id);
            // console.log('>> hdReport',hdReport);
            setHarshDrivingData(hdReport?.report || {});
          }
        }
        dispatch(hideLoading());
      } catch (error) {
        dispatch(showModal(error.message));
        dispatch(hideLoading());
      }
    },
    [dispatch, details?.job?.assigned_at, details?.job?.forwarding_completed_at],
  );

  //fetch Truck data
  useEffect(() => {
    if (trackerId) {
      fetchHarshDrivingData(trackerId);
    }
  }, [trackerId, fetchHarshDrivingData]);

  const completeJob = () => {
    swal({
      title: 'Job Completed',
      text: `Are you sure you want to do complete this job?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        const payLoad = {
          job_uuid: job_id,
          completed_at: moment().format(DATE_TIME_FORMAT), // done
        };
        dispatch(showLoading());
        POST(`/jobs/update`, payLoad)
          .then(({ data }) => {
            dispatch(showModal('Job Marked as Completed', true));
            dispatch(hideLoading());
            jobDetails();
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    });
  };

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <div className="tab-pane active" id="resp-tab7">
              <>
                <div>
                  <h2>
                    <span
                      onClick={() => props.history.goBack()}
                      style={{ cursor: 'pointer', float: 'left' }}
                    >
                      <LeftArrowSvg />
                    </span>
                    Transport Service
                  </h2>
                </div>
                {/* <h3>General Cargo</h3> */}
                <div style={{ position: 'relative' }}>
                  {!isEmpty(details.forwardingBid) && (
                    <div className="card job-status-card">
                      <div className="card-body">
                        <div className="row">
                          {/* <h3>Transport Service</h3> */}
                          <div className="col-12 col-md-12 col-sm-12 mt-2">
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Job Id"
                                value={details.job.job_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Bid Price"
                                value={displayAmount(details.forwardingBid.offer_price)}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Agent Id"
                                value={details.forwardingBid.agent.user_id}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Destination"
                                value={details.job.destination}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-6">
                              <DisplayDocument
                                label="Port of Discharge"
                                value={serviceData.port_locations[details.job.port_of_discharge]}
                                type="text"
                                textColClassName="document"
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3>Truck Current Location:</h3>
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <NavixyWebLocator trackerId={trackerId} />
                          </div>
                        </div>
                        <hr />
                        {/* Harsh Driving */}
                        <DriverBehaviour data={harshDrivingData} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="buttonsec relative-right-10">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="submitbut btn-light"
                  >
                    Back
                  </button>
                  {!details?.job?.completed_at && (
                    <button type="button" onClick={completeJob} className="submitbut">
                      Job Completed
                    </button>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {details?.job?.forwarding_status !== '8' && (
        <Chat
          name="Us"
          chatbox={chatbox}
          user={user}
          chatToggle={chatToggle}
          chatlist={chatlist}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
          unreadMsgsCount={unreadCount}
        />
      )}
    </>
  );
};

export default Jobstatus;
