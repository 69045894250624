import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { Modal, ModalBody } from 'reactstrap';
import { defaultStaticRanges } from './staticRanges';
import { isMobile } from '../../../helpers/utils';

const DateRangeModal = (props) =>{
  const { ranges, onChange, toggle, isOpen, container, format } = props;
  
  const KEY = ranges.key;
  // console.log('>> KEY', KEY)
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [selectionRange, setSelectionRange] = useState(ranges);

  const handleSelect = (v) => {
    // console.log('>> v', v)
    const { startDate, endDate } = v?.[KEY] || {};

    const newValue = {
      startDate,
      endDate,
      key: KEY
    };
    setSelectionRange(newValue);

    if (focusedRange[1] === 0) {
      // console.log("set startDate", startDate);
    }
    if (focusedRange[1] === 1) {
      // console.log("set endDate", endDate);
      // this will call onchange when the end date is selected (instead of triggering on both start date and end date selection)
      onChange({ [KEY]: newValue });
    }
  };

  const isMobileDevice = isMobile()?.matches;

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className='row'>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            months={2}
            direction={isMobileDevice ? 'vertical' : "horizontal"}
            format={format}
            focusedRange={focusedRange}
            onRangeFocusChange={setFocusedRange}
            staticRanges={defaultStaticRanges}
            inputRanges={[]}
            className='date-range-picker'
            renderStaticRangeLabel={(range) => (
            // this will call onchange when static range is selected like (This Month, Last Month etc...)
              <div onClick={() =>onChange({ 
                  [KEY]: {
                    startDate: range.range().startDate,
                    endDate: range.range().endDate,
                    key: KEY
                  }
                })}>
                {range.label}
              </div>
            )}
          />
        </div>
      </ModalBody>
    </Modal>
  )
};

export default DateRangeModal;