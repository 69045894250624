import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, getIn } from 'formik';
import moment from 'moment';
import { get, capitalize } from 'lodash';
import swal from 'sweetalert';
import { object, string, number, mixed } from 'yup';
import TextField from '../../../components/UI/TextField';
import SelectBox from '../../../components/UI/SelectBox';
import { POST, DELETE } from '../../../services/rest.service';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import convertJsonToFormData from '../../../helpers/helper';
import FIleField from '../../../components/UI/FIleField';
import { acceptedFileTypes } from '../../../helpers/constants';
import DisplayDocument from '../../../components/global/DisplayDocument';
import { showDocumentModal } from '../../../redux/user/UserActions';
import { getQueryParamValue } from '../../../helpers/utils';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';

const dateFormat = 'YYYY-MM-DD';
const size = {
  '1': '20ft',
  '2': '40ft',
};
const axel = {
  '1': 'single',
  '2': 'double',
};
const hooks = [4, 6, 8];
let initialValues = {
  reg_number: '',
  make: '',
  model: '',
  year: '',
  size: '',
  tonnage_weight: '',
  no_of_hooks: '',
  agent_uuid: '',
  npa_sticker: '',
  road_worthiness_certificate: '',
  third_party_insurance: '',
  vehicle_registration: '',
  ownership_proof: '',
  comercial_vehicle_license: '',
  hackney_permit: '',
  no_of_tyres: '',
  chassis_no: '',
  comercial_vehicle_license_expiry: '',
  road_worthiness_certificate_expiry: '',
  third_party_insurance_expiry: '',
  hackney_permit_expiry: '',
  heavy_duty_permit: '',
  heavy_duty_permit_expiry: '',
  local_govt_permit: '',
  local_govt_permit_expiry: '',
};

const FileFieldWrap = ({
  value,
  label,
  name,
  setFieldValue,
  handleBlur,
  touched,
  errors,
  showLabel,
}) => {
  return (
    <div className="fileUploads" style={{ background: value ? '#01a8af' : '' }}>
      {showLabel && (
        <span>
          <i className="fa fa-file-text-o" aria-hidden="true"></i> {label}
        </span>
      )}
      <FIleField
        type="file"
        accept={acceptedFileTypes}
        className="uploads"
        name={name}
        onChange={(event) => setFieldValue(name, event.target.files[0])}
        onBlur={handleBlur}
        error={getIn(touched, name) && getIn(errors, name)}
      />
    </div>
  );
};

const TruckList = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [truckModal, setTruckModal] = useState(false);
  const [truckData, setTruckData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [truck_uuid, setTruck_uuid] = useState('');
  const [formValues, setFormValues] = useState({});
  const [images, setImages] = useState({});
  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  const toggleTruckModal = () => {
    setTruckModal(!truckModal);
    setFormValues(null);
    setIsEditMode(false);
    setImages({});
  };
  const changeModelName = () => {
    setIsEditMode(true);
  };
  const { user } = useSelector((state) => state.user);
  const serviceData = useSelector((state) => state.enumData);

  // console.log('frwd', get(user, 'uuid'));
  useEffect(() => {
    getTruckList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);
  //Show Truck List data function
  const getTruckList = (page, perPage) => {
    dispatch(showLoading());
    const payData = {
      agent_uuid: get(user, 'uuid'),
      page: page,
      per_page: perPage,
    };
    POST(`/trucks/getTruckByAgent`, payData)
      .then(({ data }) => {
        // console.log(data);
        setTruckData(data.trucks);
        setTotalPages(data.total_count / perPage);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  //end function

  //edit truck
  const editTruck = (item) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to edit this truck?',
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      // console.log(item);
      if (result) {
        dispatch(showLoading());
        setImages({
          ...images,
          road_worthiness_certificate: item.road_worthiness_certificate,
          third_party_insurance: item.third_party_insurance,
          vehicle_registration: item.vehicle_registration,
          ownership_proof: item.ownership_proof,
          comercial_vehicle_license: item.comercial_vehicle_license,
          hackney_permit: item.comercial_vehicle_license,
          npa_sticker: item.npa_sticker,
          heavy_duty_permit: item.heavy_duty_permit,
          local_govt_permit: item.local_govt_permit,
        });
        const modifiedItem = {
          ...item,
          road_worthiness_certificate: null,
          third_party_insurance: null,
          vehicle_registration: null,
          ownership_proof: null,
          comercial_vehicle_license: null,
          hackney_permit: null,
          npa_sticker: null,
          heavy_duty_permit: null,
          local_govt_permit: null,
          comercial_vehicle_license_expiry: item.comercial_vehicle_license_expiry
            ? moment(item.comercial_vehicle_license_expiry).format(dateFormat)
            : '',
          road_worthiness_certificate_expiry: item.road_worthiness_certificate_expiry
            ? moment(item.road_worthiness_certificate_expiry).format(dateFormat)
            : '',
          third_party_insurance_expiry: item.third_party_insurance_expiry
            ? moment(item.third_party_insurance_expiry).format(dateFormat)
            : '',
          hackney_permit_expiry: item.hackney_permit_expiry
            ? moment(item.hackney_permit_expiry).format(dateFormat)
            : '',
          heavy_duty_permit_expiry: item.heavy_duty_permit_expiry
            ? moment(item.heavy_duty_permit_expiry).format(dateFormat)
            : '',
          local_govt_permit_expiry: item.local_govt_permit_expiry
            ? moment(item.local_govt_permit_expiry).format(dateFormat)
            : '',
        };
        setFormValues(modifiedItem);
        setTruck_uuid(item.uuid);
        changeModelName();
        setTruckModal(!truckModal);
        dispatch(hideLoading());
      }
    });
    // console.log(item);
  };

  // delete truck
  const deleteTruck = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this truck?',
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());

        DELETE(`/trucks/${id}`)
          .then(({ data }) => {
            // console.log('data', data);
            const deleteItem = truckData.filter((obj) => obj.uuid !== id);
            setTruckData(deleteItem);
            dispatch(hideLoading());
            dispatch(showModal(`Truck Deleted successfully!`, true));
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
          });
      }
    });
  };

  const getTruckStatus = (status) => {
    if (status == 1) {
      return 'Approved Truck';
    } else if (status == 2) {
      return 'Resticted Truck';
    }
    return '';
  };

  const getTruckStatusCN = (status) => {
    switch (status) {
      case '1':
        return 'alert-success';
      case '2':
        return 'alert-danger';
      case '3':
        return 'alert-info';
      case '4':
        return 'alert-warning';
      case '5':
        return 'alert-warning';
      default:
        return 'alert-info';
    }
  };

  const getTruckStatusClassName = (status) => {
    if (status == 1) {
      return 'approved';
    } else if (status == 2) {
      return 'resticted';
    }
    return '';
  };

  // console.log('>> formValues', formValues)
  return (
    <>
      <div id="tabblock5" className="tabcontent">
        <div className="bodycont">
          <div>
            <div className="heading-with-btn mb-3">
              <div>
                <h2>My Trucks</h2>
              </div>
              <div>
                <button type="button" onClick={toggleTruckModal} className="simpleBtn">
                  Add New Truck
                </button>
              </div>
            </div>
            <div className="table-responsive clearingtable">
              <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                <thead>
                  <tr>
                    <th>Truck ID</th>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Year of Manufacture</th>
                    <th>Size</th>
                    <th>Registration no</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(truckData) && truckData.length !== 0 ? (
                    truckData.map((item, i) => (
                      <tr key={i} className={getTruckStatusClassName(item.status)}>
                        <td>
                          {item.status != 1 && <span>{getTruckStatus(item.status)}</span>}
                          <strong>{item.truck_id}</strong>
                        </td>
                        <td>{item.make}</td>
                        <td>{item.model}</td>
                        <td>{item.year}</td>
                        <td>{size[item.size]}</td>
                        <td>{item.reg_number}</td>
                        <td>
                          <p className={`alert ${getTruckStatusCN(item.status)}`}>
                            {capitalize(serviceData.truck.status[item.status])}
                          </p>
                        </td>
                        <td>
                          <ul className="list-inline m-0">
                            <li className="list-inline-item">
                              <button
                                className="btn btn-success editButton btn-sm rounded-0"
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="View"
                                onClick={() =>
                                  history.push(`/forwarding-agent/trucks/details/${item.uuid}`)
                                }
                              >
                                <i className="fa fa-eye"></i>
                              </button>
                            </li>
                            <li className="list-inline-item">
                              <button
                                className="btn btn-success editButton btn-sm rounded-0"
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                                onClick={() => editTruck(item)}
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                            </li>
                            <li className="list-inline-item">
                              <button
                                className="btn btn-danger btn-sm deleteButton rounded-0"
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => deleteTruck(item.uuid)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No Truck Founds!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {truckData.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={truckModal}
        toggle={toggleTruckModal}
        container="clearing-agent-page"
        className="modal-xl"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={toggleTruckModal}>
            &times;
          </button>
        </div>
        <ModalBody>
          <div className="reson-reject">
            <h5>{isEditMode ? 'Edit Truck' : 'Add Truck'}</h5>
            <Formik
              initialValues={formValues || initialValues}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                // console.log('>> values', values);
                const payload = {
                  reg_number: values.reg_number,
                  make: values.make,
                  model: values.model,
                  year: values.year,
                  size: values.size,
                  tonnage_weight: values.tonnage_weight,
                  no_of_hooks: values.no_of_hooks,
                  agent_uuid: get(user, 'uuid'),
                  npa_sticker: values.npa_sticker,
                  road_worthiness_certificate: values.road_worthiness_certificate,
                  third_party_insurance: values.third_party_insurance,
                  vehicle_registration: values.vehicle_registration,
                  ownership_proof: values.ownership_proof,
                  comercial_vehicle_license: values.comercial_vehicle_license,
                  hackney_permit: values.hackney_permit,
                  fileStatus: isEditMode ? false : true,
                  no_of_tyres: values.no_of_tyres,
                  chassis_no: values.chassis_no,
                  comercial_vehicle_license_expiry: values.comercial_vehicle_license_expiry
                    ? moment(values.comercial_vehicle_license_expiry).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  road_worthiness_certificate_expiry: values.road_worthiness_certificate_expiry
                    ? moment(values.road_worthiness_certificate_expiry).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )
                    : null,
                  third_party_insurance_expiry: values.third_party_insurance_expiry
                    ? moment(values.third_party_insurance_expiry).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  hackney_permit_expiry: values.hackney_permit_expiry
                    ? moment(values.hackney_permit_expiry).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  heavy_duty_permit: values.heavy_duty_permit,
                  heavy_duty_permit_expiry: values.heavy_duty_permit_expiry
                    ? moment(values.heavy_duty_permit_expiry).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  local_govt_permit: values.local_govt_permit,
                  local_govt_permit_expiry: values.local_govt_permit_expiry
                    ? moment(values.local_govt_permit_expiry).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                };
                // console.log('>> payload', payload);
                let formData = convertJsonToFormData(payload);
                // console.log(...formData);

                if (!isEditMode) {
                  dispatch(showLoading());

                  POST(`/trucks/create`, formData)
                    .then((data) => {
                      // console.log('data', data);
                      toggleTruckModal();
                      dispatch(showModal('Truck created successfully', true));
                      getTruckList(selectedPage, itemsPerPage);
                      dispatch(hideLoading());
                    })
                    .catch((err) => {
                      dispatch(showModal(err.message));
                      dispatch(hideLoading());
                    });
                } else {
                  const editPayload = {
                    truck_uuid: truck_uuid,
                    reg_number: values.reg_number,
                    make: values.make,
                    model: values.model,
                    year: values.year,
                    size: values.size,
                    tonnage_weight: values.tonnage_weight,
                    no_of_hooks: values.no_of_hooks,
                    npa_sticker: values.npa_sticker,
                    road_worthiness_certificate: values.road_worthiness_certificate,
                    third_party_insurance: values.third_party_insurance,
                    vehicle_registration: values.vehicle_registration,
                    ownership_proof: values.ownership_proof,
                    comercial_vehicle_license: values.comercial_vehicle_license,
                    hackney_permit: values.hackney_permit,
                    no_of_tyres: values.no_of_tyres,
                    chassis_no: values.chassis_no,
                    comercial_vehicle_license_expiry: values.comercial_vehicle_license_expiry
                      ? moment(values.comercial_vehicle_license_expiry).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )
                      : null,
                    road_worthiness_certificate_expiry: values.road_worthiness_certificate_expiry
                      ? moment(values.road_worthiness_certificate_expiry).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )
                      : null,
                    third_party_insurance_expiry: values.third_party_insurance_expiry
                      ? moment(values.third_party_insurance_expiry).format('YYYY-MM-DD HH:mm:ss')
                      : null,
                    hackney_permit_expiry: values.hackney_permit_expiry
                      ? moment(values.hackney_permit_expiry).format('YYYY-MM-DD HH:mm:ss')
                      : null,
                    heavy_duty_permit: values.heavy_duty_permit,
                    heavy_duty_permit_expiry: values.heavy_duty_permit_expiry
                      ? moment(values.heavy_duty_permit_expiry).format('YYYY-MM-DD HH:mm:ss')
                      : null,
                    local_govt_permit: values.local_govt_permit,
                    local_govt_permit_expiry: values.local_govt_permit_expiry
                      ? moment(values.local_govt_permit_expiry).format('YYYY-MM-DD HH:mm:ss')
                      : null,
                  };
                  let editformData = convertJsonToFormData(editPayload);
                  //console.log(...editformData);
                  dispatch(showLoading());

                  POST(`/trucks/update`, editformData)
                    .then((data) => {
                      // console.log('data', data);
                      toggleTruckModal();
                      dispatch(showModal('Truck updated successfully', true));
                      getTruckList(selectedPage, itemsPerPage);
                      dispatch(hideLoading());
                    })
                    .catch((err) => {
                      dispatch(showModal(err.message));
                      dispatch(hideLoading());
                    });
                }
              }}
              validationSchema={object().shape({
                reg_number: string().required('Please add registration number'),
                make: string().required('Please add brand name'),
                model: string().required('Please add model name'),
                year: number().required('Please add year of manufacture'),
                size: number().required('Please select truck size'),
                tonnage_weight: number().required('Please add weight (in tons)'),
                no_of_hooks: number().required('Please select no of hooks'),

                // npa_sticker: mixed().when('fileStatus', {
                //   is: true,
                //   then: mixed().required('A file is required'),
                // }),
                // road_worthiness_certificate: mixed().when('fileStatus', {
                //   is: true,
                //   then: mixed().required('A file is required'),
                // }),
                // third_party_insurance: mixed().when('fileStatus', {
                //   is: true,
                //   then: mixed().required('A file is required'),
                // }),
                // vehicle_registration: mixed().when('fileStatus', {
                //   is: true,
                //   then: mixed().required('A file is required'),
                // }),
                // ownership_proof: mixed().when('fileStatus', {
                //   is: true,
                //   then: mixed().required('A file is required'),
                // }),
                // comercial_vehicle_license: mixed().when('fileStatus', {
                //   is: true,
                //   then: mixed().required('A file is required'),
                // }),
                // hackney_permit: mixed().when('fileStatus', {
                //   is: true,
                //   then: mixed().required('A file is required'),
                // }),

                no_of_tyres: number().required('Please add number of tyres'),
                chassis_no: string().required('Please add chassis number'),
                // comercial_vehicle_license_expiry:
              })}
            >
              {(renderProps) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                } = renderProps;

                return (
                  <form className={'row add-truck-form'} onSubmit={handleSubmit}>
                    <div className="col-sm-6 col-lg-6 text-left mb-3">
                      <label>Registration Number</label>
                      <TextField
                        id="reg_number"
                        type="text"
                        className="form-control"
                        placeholder="Registration Number"
                        value={values.reg_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'reg_number') && getIn(errors, 'reg_number')}
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>Brand</label>

                      <TextField
                        id="make"
                        type="text"
                        className="form-control"
                        placeholder="Brand"
                        value={values.make}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'make') && getIn(errors, 'make')}
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>Model</label>

                      <TextField
                        id="model"
                        type="text"
                        className="form-control"
                        placeholder="Model"
                        value={values.model}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'model') && getIn(errors, 'model')}
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>Year of Manufacture</label>

                      <TextField
                        id="year"
                        type="number"
                        className="form-control"
                        placeholder="Year of Manufacture"
                        value={values.year}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'year') && getIn(errors, 'year')}
                        min="0"
                        onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>Select Size</label>

                      <SelectBox
                        id="size"
                        name="size"
                        className="form-control"
                        placeholder="Select Size"
                        value={values.size}
                        options={size}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'size') && getIn(errors, 'size')}
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>How many tons can your truck carry? (to be reviewed annually)</label>

                      <TextField
                        id="tonnage_weight"
                        type="number"
                        className="form-control"
                        placeholder="Capacity (in tons)"
                        value={values.tonnage_weight}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'tonnage_weight') && getIn(errors, 'tonnage_weight')}
                        min="0"
                        onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>Select Hooks</label>

                      <SelectBox
                        id="no_of_hooks"
                        name="no_of_hooks"
                        className="form-control"
                        placeholder="Select Hooks"
                        value={values.no_of_hooks}
                        options={hooks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'no_of_hooks') && getIn(errors, 'no_of_hooks')}
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>Number of Tyres</label>

                      <TextField
                        id="no_of_tyres"
                        type="number"
                        className="form-control"
                        placeholder="Number of Tyres"
                        value={values.no_of_tyres}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'no_of_tyres') && getIn(errors, 'no_of_tyres')}
                        min="0"
                        onWheel={(e) => e.target.blur()} // to prevent value change when mouse wheel scroll over this field
                      />
                    </div>
                    <div className="col-sm-6 text-left mb-3">
                      <label>Chassis Number</label>

                      <TextField
                        id="chassis_no"
                        type="text"
                        className="form-control"
                        placeholder="Chassis Number"
                        value={values.chassis_no}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'chassis_no') && getIn(errors, 'chassis_no')}
                        min="0"
                      />
                    </div>

                    <div className="col-sm-6 text-left mb-3">
                      {images.ownership_proof && (
                        <DisplayDocument
                          label="Proof of ownership"
                          onClick={() => showDoc(images.ownership_proof)}
                          type="file"
                          fileColClassName="mb-1"
                          nolabel={true}
                        />
                      )}
                      <label>Proof of Ownership:</label>
                      <FileFieldWrap
                        name="ownership_proof"
                        value={values.ownership_proof}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        label="Proof of Ownership"
                      />
                    </div>

                    {/* <div className="col-sm-6 text-left mb-3">
                      {images.vehicle_registration && (
                        <DisplayDocument
                          label="Truck Registration"
                          onClick={() => showDoc(images.vehicle_registration)}
                          type="file"
                          fileColClassName="mb-1"
                          nolabel={true}
                        />
                      )}
                      <label>Truck Registration:</label>
                      <FileFieldWrap
                        name="vehicle_registration"
                        value={values.vehicle_registration}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        label="Truck Registration"
                      />
                    </div> */}

                    {/* Truck license */}
                    {/* <div className="col-12">
                      <div className="row document-group text-left file-group">
                        <div className="col-sm-6 text-left mb-3">
                          <label>Truck license:</label>
                          {images.comercial_vehicle_license && (
                            <DisplayDocument
                              label="Truck License"
                              onClick={() => showDoc(images.comercial_vehicle_license)}
                              type="file"
                              fileColClassName="mb-1"
                              nolabel={true}
                            />
                          )}
                          <FileFieldWrap
                            name="comercial_vehicle_license"
                            value={values.comercial_vehicle_license}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-sm-6 text-left mb-3">
                          <label>Expiry:</label>
                          <TextField
                            id="comercial_vehicle_license_expiry"
                            type="date"
                            className="form-control"
                            value={values.comercial_vehicle_license_expiry}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              getIn(touched, 'comercial_vehicle_license_expiry') &&
                              getIn(errors, 'comercial_vehicle_license_expiry')
                            }
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* Certificate of Road Worthiness */}
                    {/* <div className="col-12">
                      <div className="row document-group text-left file-group">
                        <div className="col-sm-6 text-left mb-3">
                          <label>Certificate of Road Worthiness:</label>
                          {images.road_worthiness_certificate && (
                            <DisplayDocument
                              label="Certificate of road worthiness"
                              onClick={() => showDoc(images.road_worthiness_certificate)}
                              type="file"
                              fileColClassName="mb-1"
                              nolabel={true}
                            />
                          )}
                          <FileFieldWrap
                            name="road_worthiness_certificate"
                            value={values.road_worthiness_certificate}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-sm-6 text-left mb-3">
                          <label>Expiry:</label>
                          <TextField
                            id="road_worthiness_certificate_expiry"
                            type="date"
                            className="form-control"
                            value={values.road_worthiness_certificate_expiry}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              getIn(touched, 'road_worthiness_certificate_expiry') &&
                              getIn(errors, 'road_worthiness_certificate_expiry')
                            }
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* Third Party Insurance */}
                    {/* <div className="col-12">
                      <div className="row document-group text-left file-group">
                        <div className="col-sm-6 text-left mb-3">
                          <label>Third Party Insurance:</label>
                          {images.third_party_insurance && (
                            <DisplayDocument
                              label="Third Party Insurance"
                              onClick={() => showDoc(images.third_party_insurance)}
                              type="file"
                              fileColClassName="mb-1"
                              nolabel={true}
                            />
                          )}
                          <FileFieldWrap
                            name="third_party_insurance"
                            value={values.third_party_insurance}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-sm-6 text-left mb-3">
                          <label>Expiry:</label>
                          <TextField
                            id="third_party_insurance_expiry"
                            type="date"
                            className="form-control"
                            value={values.third_party_insurance_expiry}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              getIn(touched, 'third_party_insurance_expiry') &&
                              getIn(errors, 'third_party_insurance_expiry')
                            }
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* Hackney Permit */}
                    {/* <div className="col-12">
                      <div className="row document-group text-left file-group">
                        <div className="col-sm-6 text-left mb-3">
                          <label>Hackney Permit:</label>
                          {images.hackney_permit && (
                            <DisplayDocument
                              label="Hackney Permit"
                              onClick={() => showDoc(images.hackney_permit)}
                              type="file"
                              fileColClassName="mb-1"
                              nolabel={true}
                            />
                          )}
                          <FileFieldWrap
                            name="hackney_permit"
                            value={values.hackney_permit}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-sm-6 text-left mb-3">
                          <label>Expiry:</label>
                          <TextField
                            id="hackney_permit_expiry"
                            type="date"
                            className="form-control"
                            value={values.hackney_permit_expiry}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              getIn(touched, 'hackney_permit_expiry') &&
                              getIn(errors, 'hackney_permit_expiry')
                            }
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* Heavy Duty Permit */}
                    {/* <div className="col-12">
                      <div className="row document-group text-left file-group">
                        <div className="col-sm-6 text-left mb-3">
                          <label>Heavy Duty Permit:</label>
                          {images.heavy_duty_permit && (
                            <DisplayDocument
                              label="Heavy Duty Permit"
                              onClick={() => showDoc(images.heavy_duty_permit)}
                              type="file"
                              fileColClassName="mb-1"
                              nolabel={true}
                            />
                          )}
                          <FileFieldWrap
                            name="heavy_duty_permit"
                            value={values.heavy_duty_permit}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-sm-6 text-left mb-3">
                          <label>Expiry:</label>
                          <TextField
                            id="heavy_duty_permit_expiry"
                            type="date"
                            className="form-control"
                            value={values.heavy_duty_permit_expiry}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              getIn(touched, 'heavy_duty_permit_expiry') &&
                              getIn(errors, 'heavy_duty_permit_expiry')
                            }
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* Local Government Permit */}
                    {/* <div className="col-12">
                      <div className="row document-group text-left file-group">
                        <div className="col-sm-6 text-left mb-3">
                          <label>Local Government Permit:</label>
                          {images.local_govt_permit && (
                            <DisplayDocument
                              label="Local Government Permit"
                              onClick={() => showDoc(images.local_govt_permit)}
                              type="file"
                              fileColClassName="mb-1"
                              nolabel={true}
                            />
                          )}
                          <FileFieldWrap
                            name="local_govt_permit"
                            value={values.local_govt_permit}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-sm-6 text-left mb-3">
                          <label>Expiry:</label>
                          <TextField
                            id="local_govt_permit_expiry"
                            type="date"
                            className="form-control"
                            value={values.local_govt_permit_expiry}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              getIn(touched, 'local_govt_permit_expiry') &&
                              getIn(errors, 'local_govt_permit_expiry')
                            }
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-sm-6 text-left mb-3">
                      {images.npa_sticker && (
                        <DisplayDocument
                          label="NPA sticker"
                          onClick={() => showDoc(images.npa_sticker)}
                          type="file"
                          fileColClassName="mb-1"
                          nolabel={true}
                        />
                      )}
                      <label>NPA Sticker:</label>
                      <FileFieldWrap
                        name="npa_sticker"
                        value={values.npa_sticker}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                      />
                    </div> */}
                    <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                      <button type="submit" className="submitbut" disabled={isSubmitting}>
                        {isEditMode ? 'Update Truck' : 'Add Truck'}
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TruckList;
