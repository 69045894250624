import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';
import IMG2 from '../../../assets/img/landing-img2.png';
import IMG6 from '../../../assets/img/landing-img6.png';
import IMG7 from '../../../assets/img/landing-icon1.png';
import IMG8 from '../../../assets/img/landing-icon2.png';
import IMG9 from '../../../assets/img/landing-icon3.png';
import IMG10 from '../../../assets/img/landing-icon4.png';
import BLCKIMG from '../../../assets/img/landing-blockimg1.jpg';
import BLCKIMG2 from '../../../assets/img/landing-blockimg2.jpg';
import BLCKIMG3 from '../../../assets/img/landing-blockimg3.jpg';
import BLCKIMG4 from '../../../assets/img/landing-blockimg4.jpg';
import BLCKIMG6 from '../../../assets/img/landin-blockimg6.png';
import BLCKIMG7 from '../../../assets/img/landin-blockimg7.png';
import BLCKIMG8 from '../../../assets/img/landin-blockimg8.png';
import BLCKIMG10 from '../../../assets/img/landin-blockimg10.png';
import BLCKIMG11 from '../../../assets/img/landin-blockimg11.png';
import BLCKIMG12 from '../../../assets/img/landin-blockimg12.png';
import BLCKIMG14 from '../../../assets/img/landin-blockimg14.png';
import BLCKIMG15 from '../../../assets/img/landin-blockimg15.png';
import BLCKIMG16 from '../../../assets/img/landin-blockimg16.png';
import BLCKIMG17 from '../../../assets/img/landin-blockimg17.png';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { getCopyrightYear } from '../../helpers/utils';

const ImpoterLanding = (props) => {
  const copyrightYear = getCopyrightYear();

  return (
    <>
      <PublicHeader defaultClass="inner-menu" />
      <div id="landing-page">
        <Helmet>
          <title>GoAgent for Importers</title>
        </Helmet>

        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 header-text">
                <div>
                  <h1>GoAgent for importers</h1>
                  <br />
                  <h3>
                    You can remotely clear and transport your Cargo in Nigeria, wherever you are in
                    the world, and track everything in real-time!
                  </h3>
                  <button type="button" onClick={() => props.history.push('/login')}>
                    CREATE YOUR FREE ACCOUNT HERE
                  </button>
                </div>
              </div>
              <div className="col-sm-5 text-align" data-aos="fade-up">
                <img src={IMG2} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
        <div className="body-block">
          <div className="container">
            <div className="header-block">
              <h2>A Safer way to Clear & Transport Your Cargo</h2>
            </div>
            <div className="row">
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG7} alt="" />
                </div>
                <h4>Hire Reliable Agents for Lower Prices</h4>

                <p>
                  We register reliable Clearing Agents and Transporters, have them bid on your job,
                  and monitor their execution and delivery, all at affordable rates you can’t find
                  anywhere else.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG8} alt="" />
                </div>
                <h4>Extremely Easy Access</h4>

                <p>
                  Post and manage your clearing and transport jobs using your smart phone, from
                  anywhere in the world, and have it done on time to your satisfaction. No stories!
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG9} alt="" />
                </div>
                <h4>Track Progress of Your Cargo</h4>

                <p>
                  Track the progress of your clearing and transport jobs in real time, on our
                  user-friendly platform, enabled with Google Maps.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG10} alt="" />
                </div>
                <h4>Money Back Guarantee!</h4>

                <p>
                  In the extremely unlikely situation that anything goes wrong with your job, simply
                  raise a dispute and get a refund! That’s how much we trust our platform.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="simple-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-8" data-aos="fade-up">
                <h2>Safe, Simple & Efficient Clearing & Transporting in Nigeria.</h2>
                <p>
                  Post your Clearing and Transporting Jobs for Reputable Agents to Bid for. GoAgent
                  eliminates your risks 100%, and gives you the best value for your money.
                </p>
                <button
                  className="callToActionBtn"
                  type="button"
                  onClick={() => props.history.push('/registration')}
                >
                  CREATE YOUR FREE ACCOUNT HERE
                </button>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <img src={IMG6} width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div id="services" className="services">
          <div className="container">
            <h2>Why GoAgent?</h2>
            <p className="text-align">
              All our services are carefully packaged to ensure a Win-Win solution for all
              concerned. This is why we insist on due-diligence for all parties on our platform. Not
              only do we verify our Customs-certified Agents and Transporters, we also validate our
              Importers, made up of small, medium to large and very large-scale businesses. As long
              as safety is your top priority, your decision to go with GoAgent is 100% on track.{' '}
              <NavLink to="/registration">Discover More About Our Safe Platform Here</NavLink>
            </p>
            <p className="text-align">
              As an Importer, Customs Clearance and Transportation of your Cargo from any port in
              Nigeria to your desired destination can be a very exhausting struggle, every step of
              the way, such as the following:
            </p>
            <br />
            {/* Problem #1 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #1: Port Clearing Delays</h3>
                      <p>
                        When clearing and transport jobs go wrong, it disrupts your supply chain,
                        puts sales on hold, and leads to huge, unplanned costs. These costs
                        determine what the price of your goods will be in the market, and that's if
                        your goods even make it to the market at all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #1 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG7} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #1: Automated Solutions </h3>
                      <p>
                        Our automated processes and intimate knowledge of the typical road-blocks at
                        Nigerian Ports puts us in the best position to fast-track the clearing and
                        transportation of your cargo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #2 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG2} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #2: Fake Clearing Agents</h3>
                      <p>
                        You are probably already aware that majority of the people parading
                        themselves as Clearing Agents are not clearing agents; rather, they are
                        dubious people searching for importers to dupe, and when you fall into their
                        hands, they will abscond with your money, and keep telling you to wait for
                        them until demurrage piles up on your Cargo, to a point where your only
                        option is to abandon your Cargo at the port and just walk away. Those
                        abandoned goods are what the Nigerian Customs auction to the general public
                        from time to time. Such pain! And this happens a lot.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #2 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG15} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #2: Reliable & Verified Agents (With ratings & reviews)</h3>
                      <p>
                        We not only register, but also regulate Reliable and Verified Clearing
                        Agents on our platform. You can engage these Customs-certified Agents from
                        anywhere you are in the world, and from the comfort of your smart phone.
                        This means you don’t need to worry about falling into the hands of dubious
                        clearing agents. Now, you can focus on your area of core competence, while
                        we mind your Cargo clearing task for you safely and securely.
                      </p>
                      <p>
                        To ensure constant excellence on our platform we also carry out regular
                        ratings and reviews, based on Customer feedback, Peer ratings and our own
                        Back-end Updates. Our Transparent Platform allows you to view in real-time,
                        what other Importers are saying about our Verified Clearing Agents and you
                        simply pick, based on your preferences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #3 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG3} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #3: Short Payment of Customs Duties & Exorbitant Rates</h3>
                      <p>
                        Some Clearing Agents will receive Custom Duties from you in full, and pay
                        Nigerian Customs only half of what’s due (or something close to it), and
                        then induce someone to take your Cargo out of the port. However, once your
                        Cargo is out of the Port, they are completely done with you. Yet, the
                        Nigerian Customs aren't done with you—they have actually just begun with
                        you. This is usually where more headaches begin.
                      </p>
                      <p>
                        Few meters away from the Port (and until you get to your destination), there
                        are uncountable Customs check-points. And if the Agent you used short-paid
                        Custom Duties without your knowledge, You will end up paying 3x the amount
                        you were meant to pay initially (as fine for an offense you knew nothing
                        about), and if you don’t pay this fine, you risk your Cargo being impounded.
                      </p>
                      <p>
                        Many people have fallen victim to this dubious practice, and a lot of them
                        have lost their businesses to these dubious Clearing Agents. Don't be a
                        victim!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #3 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG8} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>
                        Solution #3: Full Payment of Customs Duties Assured (No risk of cargo
                        impoundment)
                      </h3>
                      <p>
                        GoAgent eliminates the risks that come with short-payment of Customs Duties,
                        by paying your Customs Duties directly to the Nigerian Customs, not through
                        any Agent. This way, you never have to worry about the risk of Cargo
                        Impoundment as a result of Short-payment of Customs Duties.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #4 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG4} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>
                        Problem #4: Agent Renegotiating or Absconding After Commencement of Job
                      </h3>
                      <p>
                        Some Agents will give you the lowest quotes just to get your job, but will
                        turn back and renegotiate with you when they commence the process. The sad
                        thing is you cannot afford to change an Agent halfway into a clearing job;
                        the only option unsuspecting importers are therefore left with is to pay the
                        new rate. This is a common sharp practice in the shipping industry, which
                        you must guard against.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #4 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG17} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>
                        Solution #4: Transparent & Insured Transactions; All Agreements are Binding.
                      </h3>
                      <p>
                        Reliable Agents are hard to find, and even when you find them by yourself,
                        their fees are often very high. That is why GoAgent gives you the rare
                        opportunity of getting the lowest prices on your jobs, by having Reliable
                        and Verified Clearing Agents and Transporters bid for your jobs. This way,
                        you’re not subjected to unhealthy prices or price changes. Trust our
                        platform for the best rates ever.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #5 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG14} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #5: Transportation Irregularities</h3>
                      <p>
                        <i>
                          Imagine this scenario: Just when you are done clearing your cargo, and you
                          are like "Sigh, what a relief!” Relief? But, not just yet!
                        </i>
                      </p>

                      <p>
                        Truth is, the transport job is a whole new chapter of pain, when you choose
                        to go by yourself. To begin with, do you know that most truck owners give
                        out their trucks for just 30% of the price you pay for a transport job? Yes,
                        just 30%. The rest goes into the pockets of ‘middlemen’, people who don’t
                        lift a single pin in the entire process of Cargo transportation.
                      </p>

                      <p>
                        Even after paying this exorbitant fee in advance, a rickety truck shows up
                        to pick your cargo, that type of truck that breaks down after every few
                        hundred kilometers. This puts you through enormous emotional stress and
                        financial loss; and leaves you waiting for your goods, while your
                        competitors take away your hard-earned customers.
                      </p>

                      <p>
                        A lot of people (good, hard-working people) have been put out of business by
                        the bad choices they made with their clearing and transport jobs. Don't be
                        one of them.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #5 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG10} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>
                        Solution #5: Carefully Vetted Transporters & Monitored Transport Processes
                      </h3>
                      <p>
                        With GoAgent, you never have to worry about middle men and their activities
                        at the various Ports. They do not interfere with our Registered and Verified
                        Transporters, as we have put structures in place to insulate our processes
                        from middle-men.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #6 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG11} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>
                        Problem #6: Cargo Vandalization, ‘Port Rats’ & Non-performance of Job.
                      </h3>
                      <p>
                        A common problem in ports all over the world is vandalisation of Cargo at
                        the ports. This can be both frustrating and also lead to huge losses for
                        Importers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #6 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG16} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>
                        Solution #6: Tested & Trusted, Dedicated Security Protocols, Quick Dispute
                        Resolution & Compensation
                      </h3>
                      <p>
                        To ensure that your imported Cargo is protected from vandalisation, GoAgent
                        has dedicated security protocols to watch over all vehicles and goods of its
                        registered importers. With GoAgent, you will never suffer any loss due to
                        Cargo vandalisation. With GoAgent, you never have to worry about poor
                        services from Clearing Agents, as only reliable Agents are registered on our
                        platform. In the rare and unlikely event that anything goes wrong, GoAgent
                        has a dedicated dispute resolution mechanism in place to compensate you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #7 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG6} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #7: Privacy Concerns</h3>
                      <p>
                        It can be both embarrassing, and even unsafe for complete strangers to know
                        your actual identity before agreeing to do business with you online.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #7 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG12} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #7: Our Safety Protocols Will Please You</h3>
                      <p>
                        Because we value your privacy, your identity never appears to other users on
                        our platform, whether it’s a fellow Importer or even to the Clearing Agent
                        or Transporter bidding for your job.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="app-ready">
          <div className="container">
            <h3>Ease of Doing Business is 100% Confirmed</h3>
            <p>
              GoAgent is the First platform in the Nigerian Maritime industry to offer a Safe,
              Automated, Self-service platform for port Cargo Clearing and Forwarding. If Safer,
              Faster and Better are priority to you, you’re in the best hands with GoAgent!
            </p>
            <p>
              Say goodbye to all your Cargo clearing and Transportation problems. Discover How We
              Keep Your Goods Safe and Secure from start to finish.
            </p>
            <div className="text-align">
              <button type="button" onClick={() => props.history.push('/registration')}>
                CREATE YOUR FREE ACCOUNT HERE
              </button>
            </div>
          </div>
        </div>
        <footer>
          ©{copyrightYear} GoAgent |<Link to="/privacy-policy">Privacy Policy</Link>|{' '}
          <Link to="/terms-and-conditions">Terms & Conditions</Link>|<Link to="/faq">FAQ</Link>
        </footer>
      </div>
      <PublicFooter />
    </>
  );
};

export default ImpoterLanding;
