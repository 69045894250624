export const applicationUserType = {
  public_user: 'Public User',
  managenmnt_operator: 'operator',
  application_client: 'client',
  application_corporate_client: 'client',
  forwarding_agent: 'forwarding-agent',
  clearing_agent: 'clearing-agent',
  legal_lawer: 'lawyer',
  work_supervisor: 'supervisor',
  truck_inspector: 'truck-inspector',
};

export const SUPER_ADMIN_USER_ROLE = 'application_super_admin';

const fileTypes = '.pdf';
export const acceptedFileTypes = fileTypes;

export const TOTAL_SHARE_PERCENTAGE_REQUIRED = 50;

export const paymentStatus = {
  '1': 'Success',
  '2': 'Failed',
  '3': 'Pending',
  '4': 'Inactive',
};

// >>> notification types start
// NT -> NOTIFICATION TYPE

// Client
// used in MAIN MENU
export const NT_C_MY_JOBS = [
  'BidCreatedNotification',
  'BidAcceptedNotification',
  'JobCompletedNotification',
  'ForwardingCommenseNotification',
];
export const NT_C_DISPUTES = [
  'DisputeResolvedNotification',
  'DisputeApprovedNotification',
  'DisputeCreatedNotification',
];
export const NT_C_PAYMENTS = ['PaymentDoneNotification'];
// used in job's tabs section
export const NT_C_JOBS_UNDER_BIDDING = ['BidCreatedNotification', 'BidAcceptedNotification'];
export const NT_C_JOBS_COMPLETED = ['JobCompletedNotification'];

// Clearing agent
export const NT_CA_NEW_JOBS = ['JobCreatedNotification'];
export const NT_CA_MY_JOBS = [
  'BidCreatedNotification',
  'BidAcceptedNotification',
  'JobCompletedNotification',
];
export const NT_CA_DISPUTES = [
  'DisputeResolvedNotification',
  'DisputeApprovedNotification',
  'DisputeCreatedNotification',
];
export const NT_CA_PROFILE = ['DocAcceptedNotification'];
// used in job's tabs section
export const NT_CA_JOBS_UNDER_BIDDING = ['BidCreatedNotification', 'BidAcceptedNotification'];
export const NT_CA_JOBS_COMPLETED = ['JobCompletedNotification'];

// Forwarding agent
export const NT_FA_NEW_JOBS = ['JobCreatedNotification'];
export const NT_FA_MY_JOBS = [
  'BidCreatedNotification',
  'BidAcceptedNotification',
  'JobCompletedNotification',
];
export const NT_FA_DISPUTES = [
  'DisputeResolvedNotification',
  'DisputeApprovedNotification',
  'DisputeCreatedNotification',
];
export const NT_FA_PROFILE = ['DocAcceptedNotification'];
export const NT_FA_MY_TRUCKS = ['TruckVerificationScheduledNotification'];

// Lawyer
export const NT_L_DISPUTES = [
  'DisputeResolvedNotification',
  'DisputeApprovedNotification',
  'DisputeCreatedNotification',
];
export const NT_L_PROFILE = ['DocAcceptedNotification'];
export const NT_L_APPROVAL = ['UserCreatedNotification'];
// used in job's tabs section

// Operator
// used in MAIN MENU
export const NT_ON_MY_JOBS = [
  'JobCreatedNotification',
  'BidCreatedNotification',
  'BidAcceptedNotification',
  'JobCompletedNotification',
];
export const NT_ON_DISPUTES = [
  'DisputeResolvedNotification',
  'DisputeApprovedNotification',
  'DisputeCreatedNotification',
];
// used in job's tabs section
export const NT_ON_NEW_JOBS = ['JobCreatedNotification'];
export const NT_ON_JOBS_COMPLETED = ['JobCompletedNotification'];

// Truck inspector
export const NT_TI_DASHBOARD = ['TruckCreatedNotification'];

// >>> notification types end

export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const COUNTER_BID_MINIMUM = 10000;

export const suspensionUserTypes = {
  application_client: 'Individual Importer',
  application_corporate_client: 'Corporate Importer',
  forwarding_agent: 'Transporter',
  clearing_agent: 'Clearing Agent',
};

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,16}$/;

export const SERVICETYPE_CLEARING = 'SERVICETYPE_CLEARING';
export const SERVICETYPE_FORWARDING = 'SERVICETYPE_FORWARDING';
export const SERVICETYPE_SUSPENSION = 'SERVICETYPE_SUSPENSION';
export const SERVICETYPE_SUBSCRIPTION = 'SERVICETYPE_SUBSCRIPTION';

export const PAYMENT_RELATED_TO_JOB = 'job_amount_fee';
export const PAYMENT_RELATED_TO_SUSPENSION = 'suspension';
export const PAYMENT_RELATED_TO_SUBSCRIPTION = 'registration';

export const OTP_TIMER_SECONDS = 30;

export const VERIFYME_TYPE = 'nin_phone';

export const PHONE_NUMBER_REGEX = /^[0-9]+$/;

export const USER_ROLE_SUPER_ADMIN = 'application_super_admin';
export const USER_ROLE_OPERATOR = 'managenmnt_operator';
export const USER_ROLE_APPLICATION_CLIENT = 'application_client';
export const USER_ROLE_APPLICATION_CORPORATE_CLIENT = 'application_corporate_client';

export const SUB_USER_ROLE_CLIENT_INC_COMPANY = 'incorporated_company';
export const SUB_USER_ROLE_CLIENT_INC_TRUSTEE = 'incorporated_trustee';
export const SUB_USER_ROLE_CLIENT_FREEZONE_ENTITY = 'free_zone_entity';
export const SUB_USER_ROLE_CLIENT_GOV_ENTITY = 'government_entity';
export const SUB_USER_ROLE_CLIENT_PVT_ENTITY = 'government_approved_private_entity';

export const USER_ROLE_FORWARDING_AGENT = 'forwarding_agent';
export const USER_ROLE_CLEARING_AGENT = 'clearing_agent';
export const USER_ROLE_LAWYER = 'legal_lawer';
export const USER_ROLE_SUPERVISOR = 'work_supervisor';
export const USER_ROLE_TRUCK_INSPECTOR = 'truck_inspector';

export const CURRENCY_USD = 'USD';
export const CURRENCY_NGN = 'NGN';
