import React from 'react';
import classnames from 'classnames';

import { InputFeedback } from './TextField';

const FIleField = ({ type, name, className, error, onChange, ...props }) => {
  const InputClasses = classnames(
    'input',
    {
      'is-invalid': !!error,
    },
    className,
  );
  return (
    <>
      <input type={type ? type : 'text'} name={name} className={InputClasses} onChange={onChange} {...props} />
      <InputFeedback error={error} />
    </>
  );
};

export default FIleField;
