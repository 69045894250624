import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import AppLoader from '../../../components/loaders/AppLoader';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET } from '../../../services/rest.service';
import {
  accessGeneralOrCargoType,
  toTitleCase,
  displayAmount,
  addQueryParamToUrl,
  getQueryParamValue,
  getJobStatus,
  getUserName,
} from '../../../helpers/utils';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';

const JobList = (props) => {
  const params = useParams();
  const operatorId = params.operatorId || '';

  const [tab, setTab] = useState(getQueryParamValue(props.location, 'tab')?.toString() || '6');
  const serviceData = useSelector((state) => state.enumData);
  // console.log(serviceData);
  const dispatch = useDispatch();
  const [jobList, setJobList] = useState([]);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const [operatorDetails, setOperatorDetails] = useState({});
  const [serviceType, setServiceType] = useState(
    getQueryParamValue(props.location, 'serviceType') || 'clearing',
  );
  const [completedClearing, setCompletedClearing] = useState([]);
  const [completedForwarding, setCompletedForwarding] = useState([]);

  const handleSetServiceType = (serviceType) => {
    setServiceType(serviceType);
    setSelectedPage(1);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'serviceType', serviceType);
  };

  const resetTabStates = () => {
    setJobList([]);
    setSelectedPage(1);
    setTotalPages(0);
  };

  const switchTab = (data) => {
    resetTabStates();
    setTab(data);
    // getJobList(data);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'tab', data);
  };

  // fetch operator details
  const getOperatorDetails = (id) => {
    dispatch(showLoading());
    GET(`/users/getDetails/${id}`)
      .then(({ data }) => {
        // console.log('data', data.user);
        setOperatorDetails(data.user);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  useEffect(() => {
    if (operatorId) {
      getOperatorDetails(operatorId);
    }
  }, [operatorId]);

  const getJobList = (payload) => {
    // console.log('payload', payload);
    dispatch(showLoading());
    GET(
      `/jobs/getOpearatorJobsByStatus/${payload.type}/${payload.operator}?page=${payload.selectedPage}&per_page=${payload.itemsPerPage}`,
    )
      .then(({ data }) => {
        // console.log('data', data);
        setJobList(data.jobs);
        if (payload.type === '8') {
          const clearing = data.jobs.filter((obj) => obj.is_forwarding_only === false);
          setCompletedClearing(clearing);
          const forwarding = data.jobs.filter((obj) => obj.is_forwarding_only === true);
          setCompletedForwarding(forwarding);
          if (payload.serviceType === 'clearing') {
            setTotalPages(data.clearing_count / payload.itemsPerPage);
          } else {
            setTotalPages(data.forwarding_count / payload.itemsPerPage);
          }
        } else {
          setTotalPages(data.total_count / payload.itemsPerPage);
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  useEffect(() => {
    if (operatorId) {
      let pagination = {
        type: tab,
        selectedPage: selectedPage,
        operator: operatorId,
        serviceType: serviceType,
        itemsPerPage,
      };
      getJobList(pagination);
    }
  }, [selectedPage, tab, operatorId, serviceType, itemsPerPage]);

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="tabblock2" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2 style={{ padding: '0px' }}>
              <span
                onClick={() => {
                  props.history.goBack();
                }}
                style={{ cursor: 'pointer', float: 'left' }}
              >
                <LeftArrowSvg />
              </span>
              Operator Jobs
            </h2>
            <h5 className="operator-heading">
              {getUserName(operatorDetails)} - {operatorDetails?.user_id}
            </h5>
            <div className="servicetab">
              <ul className="nav nav-tabs responsive" id="nonResponsiveTabs1">
                <li className={`test-class ${tab === '6' ? 'active' : ''}`}>
                  <a className="deco-none red-class" onClick={() => switchTab('6')}>
                    New Jobs
                  </a>
                </li>
                <li className={`test-class ${tab === '1' ? 'active' : ''}`}>
                  <a onClick={() => switchTab('1')}>Ongoing Jobs</a>
                </li>
                <li className={`${tab === '8' ? 'active' : ''}`}>
                  <a onClick={() => switchTab('8')}>Completed Jobs</a>
                </li>
              </ul>

              <div
                className="tab-content responsive"
                style={{ paddingTop: '25px', paddingBottom: '25px' }}
              >
                {tab === '6' && (
                  <div className={`tab-pane ${tab === '6' ? 'active' : ''}`} id="resp-tab4">
                    <div className="table-responsive clearingtable">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Category</th>
                            <th>Job Type</th>
                            <th>Date Posted</th>
                            {/* <th>Status</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList &&
                            jobList.length > 0 &&
                            jobList.map((x, index) => (
                              <tr key={index}>
                                <td>{x.job_id || 'N/A'}</td>
                                <td className="description1">
                                  <h4>
                                    {(x && toTitleCase(serviceData.job.category[x.category])) ||
                                      'N/A'}
                                  </h4>
                                  <p>{x && toTitleCase(serviceData.job.vehicle_type[x.type])}</p>
                                  <p>{x && accessGeneralOrCargoType(x, serviceData)}</p>
                                  <p>{x && serviceData.job && getJobStatus(x, serviceData)}</p>
                                </td>
                                <td>
                                  {x &&
                                    x.job_type_tags.map((y, i) => (
                                      <div key={i}>
                                        <p
                                          className={`${
                                            y == 'clearing'
                                              ? 'alert  alert-info'
                                              : 'alert  alert-success'
                                          }`}
                                        >
                                          {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                        </p>
                                      </div>
                                    ))}
                                </td>
                                <td>
                                  {x.created_at && (
                                    <>
                                      <h4>{moment(x.created_at).format(DATE_FORMAT)}</h4>
                                      <h4>{moment(x.created_at).format(TIME_FORMAT)}</h4>
                                    </>
                                  )}
                                </td>
                                {/* <td>
                                  {x.operator_comment ?  (
                                    <p className="alert alert-warning">
                                      Edited
                                    </p>
                                  ) : (
                                    <p className='alert  alert-success'>
                                      New
                                    </p>
                                  )}
                                </td> */}
                                <td>
                                  <a
                                    onClick={() => {
                                      props.history.push(
                                        `/supervisor/operator/new-job/${x.uuid}/details`,
                                      );
                                    }}
                                    className="review"
                                  >
                                    View Details
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h4>No Jobs Found!</h4>}
                    </div>
                  </div>
                )}
                {tab === '1' && (
                  <div className={`tab-pane ${tab === '1' ? 'active' : ''}`} id="resp-tab5">
                    <div className="table-responsive clearingtable">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Category</th>
                            <th>Job Type</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList.length > 0 &&
                            jobList.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  {`${x.job_id}-${x.is_forwarding_only ? 'T' : 'C'}` || 'N/A'}
                                </td>
                                <td className="description1">
                                  <div className="row text-center">
                                    <div className="col-lg-12 col-sm-12">
                                      {toTitleCase(serviceData.job.category[x.category]) || 'N/A'}
                                      <br />
                                      <p>
                                        {x.type &&
                                          toTitleCase(serviceData.job.vehicle_type[x.type])}
                                      </p>
                                      <p>
                                        {x.type_of_cargo &&
                                          accessGeneralOrCargoType(x, serviceData)}
                                      </p>
                                    </div>
                                    {/* <div className="col-lg-6 col-sm-12">
                                      <a onClick={() => props.history.push(`/operator/new-job/${x.uuid}/details`)} className="operator-job-details">
                                        Job Details
                                      </a>
                                    </div> */}
                                  </div>
                                </td>
                                <td>
                                  {x.job_type_tags.map((y, i) => (
                                    <div key={i}>
                                      <p
                                        className={`${
                                          y == 'clearing'
                                            ? 'alert  alert-info'
                                            : 'alert  alert-success'
                                        }`}
                                      >
                                        {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                      </p>
                                    </div>
                                  ))}
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      props.history.push(
                                        `/supervisor/operator/ongoing-job/${x.uuid}/details`,
                                      );
                                    }}
                                    className="review"
                                  >
                                    View Details
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h4>No Jobs Found!</h4>}
                    </div>
                  </div>
                )}
                {tab === '8' && (
                  <div className={`tab-pane ${tab === '8' ? 'active' : ''}`} id="resp-tab6">
                    <div className="servicetabout">
                      <div className="resistertab">
                        <ul className="nav nav-tabs responsive" id="nonResponsiveTabs">
                          <li
                            className={`test-class ${serviceType === 'clearing' ? 'active' : ''}`}
                          >
                            <a
                              className="deco-none red-class"
                              onClick={() => handleSetServiceType('clearing')}
                            >
                              Clearing Service
                            </a>
                          </li>
                          <li
                            className={`test-class ${serviceType === 'transport' ? 'active' : ''}`}
                          >
                            <a onClick={() => handleSetServiceType('transport')}>
                              Transport Service
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content responsive">
                          {serviceType === 'clearing' && (
                            <div
                              className={`tab-pane ${serviceType === 'clearing' ? 'active' : ''}`}
                              id="resp-tab7"
                            >
                              <div className="table-responsive clearingtable">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                  <thead>
                                    <tr>
                                      <th>Job ID</th>
                                      <th>Job Type</th>
                                      <th>Price</th>
                                      <th>Date Started</th>
                                      <th>Date Completed</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {completedClearing.length !== 0 ? (
                                      completedClearing.map((obj, i) => (
                                        <tr key={i}>
                                          <td>
                                            {obj.job_id}-{obj.is_forwarding_only ? 'T' : 'C'}
                                          </td>
                                          <td>
                                            {toTitleCase(serviceData.job.category[obj.category]) ||
                                              'N/A'}
                                          </td>
                                          <td>{displayAmount(obj.clearing_bid)}</td>
                                          <td className="pt-2">
                                            {obj.assigned_at ? (
                                              <>
                                                <p>{moment(obj.assigned_at).format(DATE_FORMAT)}</p>
                                                <p>{moment(obj.assigned_at).format(TIME_FORMAT)}</p>
                                              </>
                                            ) : (
                                              <p>N/A</p>
                                            )}
                                          </td>
                                          <td className="pt-2">
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(DATE_FORMAT)}
                                            </p>
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(TIME_FORMAT)}
                                            </p>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="5">
                                          <h3>No Data Found!</h3>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          {serviceType === 'transport' && (
                            <div
                              className={`tab-pane ${serviceType === 'transport' ? 'active' : ''}`}
                              id="resp-tab8"
                            >
                              <div className="table-responsive clearingtable">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                  <thead>
                                    <tr>
                                      <th>Job ID</th>
                                      <th>Job Type</th>
                                      <th>Price</th>
                                      <th>Date Started</th>
                                      <th>Date Completed</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {completedForwarding.length !== 0 ? (
                                      completedForwarding.map((obj, i) => (
                                        <tr key={i}>
                                          <td>
                                            {obj.job_id}-{obj.is_forwarding_only ? 'T' : 'C'}
                                          </td>
                                          <td>
                                            {toTitleCase(serviceData.job.category[obj.category]) ||
                                              'N/A'}
                                          </td>
                                          <td>{displayAmount(obj.forwarding_bid)}</td>
                                          <td className="pt-2">
                                            {obj.assigned_at ? (
                                              <>
                                                <p>{moment(obj.assigned_at).format(DATE_FORMAT)}</p>
                                                <p>{moment(obj.assigned_at).format(TIME_FORMAT)}</p>
                                              </>
                                            ) : (
                                              <p>N/A</p>
                                            )}
                                          </td>
                                          <td className="pt-2">
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(DATE_FORMAT)}
                                            </p>
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(TIME_FORMAT)}
                                            </p>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="5">
                                          <h3>No Data Found!</h3>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {jobList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobList;
