import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import AppLoader from '../../components/loaders/AppLoader';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { GET } from '../../services/rest.service';
import {
  displayAmount,
  accessGeneralOrCargoType,
  toTitleCase,
  addQueryParamToUrl,
  getQueryParamValue,
  getJobStatus,
} from '../../helpers/utils';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  NT_ON_NEW_JOBS,
  NT_ON_JOBS_COMPLETED,
} from '../../helpers/constants';
import NotificationMenuItem from '../../components/global/NotificationBadge';
import { readNotification } from '../../redux/notification/NotificationActions';
import Pagination from '../../components/global/Pagination';
import { usePagination } from '../../helpers/customHooks';
import BidDetailsModal from '../../components/Modals/BidDetailsModal';

const Dashboard = (props) => {
  const [tab, setTab] = useState(getQueryParamValue(props.location, 'tab')?.toString() || '6');
  const serviceData = useSelector((state) => state.enumData);
  // console.log(serviceData);
  const dispatch = useDispatch();
  const [jobList, setJobList] = useState([]);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const [serviceType, setServiceType] = useState(
    getQueryParamValue(props.location, 'serviceType') || 'clearing',
  );
  const [completedClearing, setCompletedClearing] = useState([]);
  const [completedForwarding, setCompletedForwarding] = useState([]);
  const [viewBid, setViewBid] = useState(null);
  const [jobCustomDuty, setJobCustomDuty] = useState(0);

  const handleSetServiceType = (serviceType) => {
    setServiceType(serviceType);
    setSelectedPage(1);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'serviceType', serviceType);
  };

  const resetTabStates = () => {
    setJobList([]);
    setSelectedPage(1);
    setTotalPages(0);
    setServiceType('clearing');
    setCompletedClearing([]);
    setCompletedForwarding([]);
  };

  const switchTab = (data, notificationTypes) => {
    // reset all states
    resetTabStates();

    setTab(data);
    // getJobList(data);
    addQueryParamToUrl(props.history, 'page', 1);
    addQueryParamToUrl(props.history, 'tab', data);
    // mark the notifications of the tab as read
    if (notificationTypes) {
      dispatch(readNotification({ notificationTypes }));
    }
  };

  useEffect(() => {
    let pagination = {
      type: tab,
      selectedPage: selectedPage,
      serviceType: serviceType,
      itemsPerPage,
    };
    getJobList(pagination);
  }, [selectedPage, tab, serviceType, itemsPerPage]);

  const getJobList = (type) => {
    // console.log('type', type);
    dispatch(showLoading());
    GET(`/jobs/getOpearatorJobsByStatus/${type.type}`, {
      page: type.selectedPage,
      per_page: type.itemsPerPage,
      // sort_type: 'desc',
      // sort_by: 'created_at',
    })
      .then(({ data }) => {
        // console.log('data', data);
        if (type.type === '5') {
          // for jobs whose bid is accepted and payment is not done
          // we need to display them at the top of the list
          const bidAcceptedJobs = [];
          const underBiddingJobs = [];
          data.jobs.forEach((j) => {
            if (j.clearing_status === '9' || j.forwarding_status === '9') {
              bidAcceptedJobs.push(j);
            } else {
              underBiddingJobs.push(j);
            }
          });

          setJobList([...bidAcceptedJobs, ...underBiddingJobs]);
        } else {
          setJobList(data.jobs);
        }
        if (type.type == 8) {
          const clearing = data.jobs.filter((obj) => obj.is_forwarding_only === false);
          setCompletedClearing(clearing);
          const forwarding = data.jobs.filter((obj) => obj.is_forwarding_only === true);
          setCompletedForwarding(forwarding);
          if (type.serviceType === 'clearing') {
            setTotalPages(data.clearing_count / type.itemsPerPage);
          } else {
            setTotalPages(data.forwarding_count / type.itemsPerPage);
          }
        } else {
          setTotalPages(data.total_count / type.itemsPerPage);
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <div className="bodycont operator-block">
          <div>
            <h2>Manage Jobs</h2>
            <div className="servicetab">
              <ul className="nav nav-tabs responsive" id="nonResponsiveTabs1">
                <li className={`test-class ${tab === '6' ? 'active' : ''}`}>
                  <a className="deco-none red-class" onClick={() => switchTab('6', NT_ON_NEW_JOBS)}>
                    <NotificationMenuItem title="New Jobs" types={NT_ON_NEW_JOBS} />
                  </a>
                </li>
                <li className={`test-class ${tab === '5' ? 'active' : ''}`}>
                  <a onClick={() => switchTab('5')}>Jobs under Bidding</a>
                </li>
                <li className={`test-class ${tab === '1' ? 'active' : ''}`}>
                  <a onClick={() => switchTab('1')}>Ongoing Jobs</a>
                </li>
                <li className={`${tab === '8' ? 'active' : ''}`}>
                  <a onClick={() => switchTab('8', NT_ON_JOBS_COMPLETED)}>
                    <NotificationMenuItem title="Completed Jobs" types={NT_ON_JOBS_COMPLETED} />
                  </a>
                </li>
              </ul>

              <div
                className="tab-content responsive"
                style={{ paddingTop: '25px', paddingBottom: '25px' }}
              >
                {tab === '6' && (
                  <div className={`tab-pane ${tab === '6' ? 'active' : ''}`} id="resp-tab4">
                    <div className="table-responsive clearingtable">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Category</th>
                            <th>Job Type</th>
                            <th>Date Posted</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList &&
                            jobList.length > 0 &&
                            jobList.map((x, index) => (
                              <tr key={index}>
                                <td>{x.job_id || 'N/A'}</td>
                                <td className="description1">
                                  <h4>
                                    {(x && toTitleCase(serviceData.job.category[x.category])) ||
                                      'N/A'}
                                  </h4>
                                  <p>{x && toTitleCase(serviceData.job.vehicle_type[x.type])}</p>
                                  <p>{x && accessGeneralOrCargoType(x, serviceData)}</p>
                                  <p>{x && serviceData.job && getJobStatus(x, serviceData)}</p>
                                </td>
                                <td>
                                  {x &&
                                    x.job_type_tags.map((y, i) => (
                                      <div key={i}>
                                        <p
                                          className={`${
                                            y == 'clearing'
                                              ? 'alert  alert-info'
                                              : 'alert  alert-success'
                                          }`}
                                        >
                                          {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                        </p>
                                      </div>
                                    ))}
                                </td>
                                <td>
                                  {x.created_at && (
                                    <>
                                      <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                                      <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <div>
                                    {x.operator_comment ? (
                                      <p className="alert alert-warning">Edited</p>
                                    ) : (
                                      <p className="alert  alert-success">New</p>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      props.history.push(`/operator/new-job/${x.uuid}/details`);
                                    }}
                                    className="review"
                                  >
                                    View Details
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                    </div>
                  </div>
                )}
                {tab === '5' && (
                  <div className={`tab-pane ${tab === '5' ? 'active' : ''}`} id="resp-tab4">
                    <div className="table-responsive clearingtable">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Category</th>
                            <th>Job Type</th>
                            <th>Date Posted</th>
                            <th>Status</th>
                            <th>Bid Details</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList &&
                            jobList.length > 0 &&
                            jobList.map((x, index) => (
                              <tr key={index}>
                                <td>{x.job_id || 'N/A'}</td>
                                <td className="description1">
                                  <h4>
                                    {(x && toTitleCase(serviceData.job.category[x.category])) ||
                                      'N/A'}
                                  </h4>
                                  <p>{x && toTitleCase(serviceData.job.vehicle_type[x.type])}</p>
                                  <p>{x && accessGeneralOrCargoType(x, serviceData)}</p>
                                  <p>{x && serviceData.job && getJobStatus(x, serviceData)}</p>
                                </td>
                                <td>
                                  {x &&
                                    x.job_type_tags.map((y, i) => (
                                      <div key={i}>
                                        <p
                                          className={`${
                                            y == 'clearing'
                                              ? 'alert  alert-info'
                                              : 'alert  alert-success'
                                          }`}
                                        >
                                          {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                        </p>
                                      </div>
                                    ))}
                                </td>
                                <td>
                                  {x.created_at && (
                                    <>
                                      <p>{moment(x.created_at).format(DATE_FORMAT)}</p>
                                      <p>{moment(x.created_at).format(TIME_FORMAT)}</p>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <div>
                                    {x.clearing_status === '9' || x.forwarding_status === '9' ? (
                                      <p className="alert alert-warning">Bid Accepted</p>
                                    ) : (
                                      <p className="alert  alert-success">Under Bidding</p>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <p>
                                    Winning Bid:
                                    {x.higest_bid ? (
                                      <strong className="text-success">
                                        {displayAmount(x.winning_bid)}
                                      </strong>
                                    ) : (
                                      'N/A'
                                    )}
                                  </p>
                                  <p>
                                    Highest Bid:{' '}
                                    {x.higest_bid ? (
                                      <strong>{displayAmount(x.higest_bid)}</strong>
                                    ) : (
                                      'N/A'
                                    )}
                                  </p>
                                  {(x.clearing_accepted_bid || x.forwarding_accepted_bid) && (
                                    <a
                                      className="review color-secondary"
                                      onClick={() => {
                                        setViewBid(
                                          x.clearing_accepted_bid || x.forwarding_accepted_bid,
                                        );
                                        setJobCustomDuty(x.custom_duty);
                                      }}
                                    >
                                      View Accepted Bid
                                    </a>
                                  )}
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      props.history.push(`/operator/new-job/${x.uuid}/details`);
                                    }}
                                    className="review"
                                  >
                                    View Details
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                    </div>
                  </div>
                )}
                {tab === '1' && (
                  <div className={`tab-pane ${tab === '1' ? 'active' : ''}`} id="resp-tab5">
                    <div className="table-responsive clearingtable">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Category</th>
                            <th>Job Type</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobList.length > 0 &&
                            jobList.map((x, index) => (
                              <tr key={index}>
                                <td>
                                  {`${x.job_id}-${x.is_forwarding_only ? 'T' : 'C'}` || 'N/A'}
                                </td>
                                <td className="description1">
                                  <div className="row">
                                    <div className="col-lg-6 col-sm-12">
                                      {toTitleCase(serviceData.job.category[x.category]) || 'N/A'}
                                      <br />
                                      <p>
                                        {x.type &&
                                          toTitleCase(serviceData.job.vehicle_type[x.type])}
                                      </p>
                                      <p>
                                        {x.type_of_cargo &&
                                          accessGeneralOrCargoType(x, serviceData)}
                                      </p>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 align-self-center">
                                      <a
                                        onClick={() =>
                                          props.history.push(`/operator/new-job/${x.uuid}/details`)
                                        }
                                        className="review color-secondary"
                                      >
                                        Job Details
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {x.job_type_tags.map((y, i) => (
                                    <div key={i}>
                                      <p
                                        className={`${
                                          y == 'clearing'
                                            ? 'alert  alert-info'
                                            : 'alert  alert-success'
                                        }`}
                                      >
                                        {capitalize(y === 'forwarding' ? 'Transport' : y)}
                                      </p>
                                    </div>
                                  ))}
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      props.history.push(`/operator/ongoing-job/${x.uuid}/details`);
                                    }}
                                    className="review"
                                  >
                                    View Details
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {jobList.length <= 0 && <h3>No Jobs Found!</h3>}
                    </div>
                  </div>
                )}
                {tab === '8' && (
                  <div className={`tab-pane ${tab === '8' ? 'active' : ''}`} id="resp-tab6">
                    <div className="servicetabout">
                      <div className="resistertab">
                        <ul className="nav nav-tabs responsive" id="nonResponsiveTabs">
                          <li
                            className={`test-class ${serviceType === 'clearing' ? 'active' : ''}`}
                          >
                            <a
                              className="deco-none red-class"
                              onClick={() => handleSetServiceType('clearing')}
                            >
                              Clearing Service
                            </a>
                          </li>
                          <li
                            className={`test-class ${serviceType === 'transport' ? 'active' : ''}`}
                          >
                            <a onClick={() => handleSetServiceType('transport')}>
                              Transport Service
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content responsive">
                          {serviceType === 'clearing' && (
                            <div
                              className={`tab-pane ${serviceType === 'clearing' ? 'active' : ''}`}
                              id="resp-tab7"
                            >
                              <div className="table-responsive clearingtable">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                  <thead>
                                    <tr>
                                      <th>Job ID</th>
                                      <th>Job Type</th>
                                      <th>Price</th>
                                      <th>Date Started</th>
                                      <th>Date Completed</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {completedClearing.length !== 0 ? (
                                      completedClearing.map((obj, i) => (
                                        <tr key={i}>
                                          <td>
                                            {obj.job_id}-{obj.is_forwarding_only ? 'T' : 'C'}
                                          </td>
                                          <td>
                                            {toTitleCase(serviceData.job.category[obj.category]) ||
                                              'N/A'}
                                          </td>
                                          <td>{displayAmount(obj.clearing_bid)}</td>
                                          <td className="pt-2">
                                            {obj.assigned_at ? (
                                              <>
                                                <p>{moment(obj.assigned_at).format(DATE_FORMAT)}</p>
                                                <p>{moment(obj.assigned_at).format(TIME_FORMAT)}</p>
                                              </>
                                            ) : (
                                              <p>N/A</p>
                                            )}
                                          </td>
                                          <td className="pt-2">
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(DATE_FORMAT)}
                                            </p>
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(TIME_FORMAT)}
                                            </p>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="5">
                                          <h3>No Data Found!</h3>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          {serviceType === 'transport' && (
                            <div
                              className={`tab-pane ${serviceType === 'transport' ? 'active' : ''}`}
                              id="resp-tab8"
                            >
                              <div className="table-responsive clearingtable">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                  <thead>
                                    <tr>
                                      <th>Job ID</th>
                                      <th>Job Type</th>
                                      <th>Price</th>
                                      <th>Date Started</th>
                                      <th>Date Completed</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {completedForwarding.length !== 0 ? (
                                      completedForwarding.map((obj, i) => (
                                        <tr key={i}>
                                          <td>
                                            {obj.job_id}-{obj.is_forwarding_only ? 'T' : 'C'}
                                          </td>
                                          <td>
                                            {toTitleCase(serviceData.job.category[obj.category]) ||
                                              'N/A'}
                                          </td>
                                          <td>{displayAmount(obj.forwarding_bid)}</td>
                                          <td className="pt-2">
                                            {obj.assigned_at ? (
                                              <>
                                                <p>{moment(obj.assigned_at).format(DATE_FORMAT)}</p>
                                                <p>{moment(obj.assigned_at).format(TIME_FORMAT)}</p>
                                              </>
                                            ) : (
                                              <p>N/A</p>
                                            )}
                                          </td>
                                          <td className="pt-2">
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(DATE_FORMAT)}
                                            </p>
                                            <p>
                                              {moment(
                                                obj.clearing_completed_at ||
                                                  obj.forwarding_completed_at,
                                              ).format(TIME_FORMAT)}
                                            </p>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="5">
                                          <h3>No Data Found!</h3>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {jobList.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
        <BidDetailsModal
          isOpen={!!viewBid}
          toggle={() => setViewBid(null)}
          container="tabblock1"
          customDuty={jobCustomDuty}
          bidId={viewBid}
        />
      </div>
    </>
  );
};

export default Dashboard;
