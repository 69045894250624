import React from 'react';
import { Helmet } from 'react-helmet';
import PublicFooter from '../../components/global/PublicFooter';

import PublicHeader from '../../components/global/PublicHeader';
import ContactUsForm from '../../components/global/ContactUsForm';

const ContactUs = () => {
  return (
    <div id="home-page">
      <Helmet>
        <title>GoAgent - Contact Us</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main">
        <section id="clering-goagent" className="clering-goagent">
          <div className="container">
            <h2>Contact Us</h2>
          </div>
        </section>
        <ContactUsForm />
        <section id="services" className="services">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 padding-right1">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.1543927749395!2d3.3696486061908137!3d6.443126455237932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8bad93c2c96b%3A0x76a5cb9df9b09ba!2s62%20Kofo%20Abayomi%20Ave%2C%20Apapa%20Quays%20100246%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sin!4v1634797694679!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  id="contact-us-map"
                ></iframe>
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div className="contact-us">
                    <h3>Our Headquarters</h3>
                    <p style={{ marginBottom: '10px' }}>
                      <span className="head-quater1">GoAgent Plaza</span>
                      <br />
                      <b>62, Kofo Abayomi Avenue,</b>
                      <br />
                      <b>Apapa, Lagos,</b>
                      <br />
                      <b>Nigeria.</b>
                    </p>

                    <address>
                      <p className="call-block">
                        Email: <a href="mailto: info@goagent.com.ng">info@goagent.com.ng</a>
                      </p>
                      <p className="call-block">
                        Phone number: <a href="tel: +23414549933">+234 1 454 9933</a>
                      </p>
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
export default ContactUs;
