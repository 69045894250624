import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const FwJobCompleteConfirm = ({ isOpen, toggle, container = 'client-page', onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="text-center">
          <h3 className="text-success">Job Completed</h3>
          <hr />
          <p className="fw-normal fs-2">This job is marked as completed by Transporter</p>
          <p className="fw-normal fs-2">Please confirm</p>
          <div className="buttonsec">
            <button type="button" onClick={onConfirm} className="submitbut">
              Yes, Complete Job
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FwJobCompleteConfirm;
