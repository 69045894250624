import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const FeatureNotAvailable = ({ isOpen, toggle, container }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      container={container}
      className="modal-md feature-na-modal"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="message alert alert-warning">
          <h4 className="my-4 mx-2">
            <strong>
              Sorry, currently we are only accepting registrations, we will be open for job postings
              very soon.
              <br />
              You will be notified when we do. Thank you.
            </strong>
          </h4>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FeatureNotAvailable;
