import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Formik, getIn, FieldArray } from 'formik';
import NumberFormat from 'react-number-format';
import { array, object, string } from 'yup';
import { InputFeedback } from '../../components/UI/TextField';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import { getErrorClassNames } from '../../helpers/utils';

const JobApproveModal = ({ isOpen, toggle, jobDetails, onApprove }) => {
  const dispatch = useDispatch();
  const [isShow, setIsshow] = useState(0);
  useEffect(() => {
    if (jobDetails?.job_type_tags) {
      jobDetails?.job_type_tags[0] === 'forwarding' && setIsshow(1);
    }
  }, [jobDetails]);
  const handleSubmit = (values) => {
    // delete the job_id field added earlier for displaying in form
    values.minimum_bid_amounts.forEach((i) => delete i.job_id);
    const payload = {
      job_uuid: jobDetails.uuid,
      custom_duty: Number(values.custom_duty),
      job_props: values.minimum_bid_amounts,
    };

    dispatch(showLoading());
    POST(`/jobs/operatorApproveJob`, payload)
      .then(({ data }) => {
        // console.log('data', data);
        let jobData = { ...jobDetails, clearing_status: data.job.clearing_status };
        dispatch(hideLoading());
        dispatch(showModal(`Service approved successfully!`, true));
        onApprove(jobData);
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  // minimum bid amounts for each job in a service requrest.
  const minimumBidAmounts = [];

  // for job types vehicle-container and general cargo, we will have containers from which transport jobs are created
  // this is for the transport part of the job
  if (jobDetails.children_packages?.length > 0) {
    jobDetails.children_packages.forEach((cp) => {
      minimumBidAmounts.push({
        job_id: cp.job_id,
        uuid: cp.uuid,
        minimum_bid_amount: '',
        type: 'Transport',
      });
    });
  }

  // this is for the clearing part of the job (is not asked for transport-only job)
  if (jobDetails.clearing_status != '11') {
    minimumBidAmounts.push({
      job_id: jobDetails.job_id,
      uuid: jobDetails.uuid,
      minimum_bid_amount: '',
      type: 'Clearing',
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} container="client-page" className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5>Approval</h5>
          <Formik
            initialValues={{
              custom_duty: '',
              minimum_bid_amounts: minimumBidAmounts,
            }}
            onSubmit={handleSubmit}
            validationSchema={object().shape({
              custom_duty: isShow == 0 ? string().required('Custom Duty is required') : string(),
              minimum_bid_amounts: array().of(
                object().shape({
                  minimum_bid_amount: string().required('Minimum Bid Amount is required'),
                }),
              ),
            })}
          >
            {(renderProps) => {
              const { values, touched, errors, setFieldValue, handleSubmit } = renderProps;

              // console.log('>> values, touched, errors,', values, touched, errors);
              return (
                <form className={'row'} onSubmit={handleSubmit}>
                  <Fragment>
                    {
                      isShow === 0 &&
                      <div className="col-sm-12 text-left">
                        <label>Custom Duty:</label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          decimalSeparator="."
                          value={values.custom_duty}
                          className={getErrorClassNames(
                            getIn(touched, 'custom_duty') && getIn(errors, 'custom_duty'),
                          )}
                          displayType="input"
                          type="text"
                          thousandSeparator={true}
                          prefix=""
                          id="custom_duty"
                          name="custom_duty"
                          onValueChange={(e) => setFieldValue('custom_duty', e.value)}
                        />
                        <InputFeedback
                          error={getIn(touched, 'custom_duty') && getIn(errors, 'custom_duty')}
                        />
                      </div>
                    }
                    <FieldArray
                      name="minimum_bid_amounts"
                      render={(arrayHelpers) => (
                        <>
                          {values.minimum_bid_amounts?.length > 0 &&
                            values.minimum_bid_amounts.map((job, cpIndex) => {
                              const fieldName = `minimum_bid_amounts.${cpIndex}.minimum_bid_amount`;
                              return (
                                <div className="col-sm-12 text-left" key={job.job_uuid}>
                                  <label>
                                    Minimum Bid Amount for <strong>{` ${job.type} `}</strong> Job
                                    (ID): {job.job_id}
                                  </label>
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator="."
                                    value={job.minimum_bid_amount}
                                    className={getErrorClassNames(
                                      getIn(touched, fieldName) && getIn(errors, fieldName),
                                    )}
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    prefix=""
                                    id={fieldName}
                                    name={fieldName}
                                    onValueChange={(e) => setFieldValue(fieldName, e.value)}
                                  />
                                  <InputFeedback
                                    error={getIn(touched, fieldName) && getIn(errors, fieldName)}
                                  />
                                </div>
                              );
                            })}
                        </>
                      )}
                    />
                  </Fragment>

                  <div className="buttonsec1" style={{ textAlign: 'center' }}>
                    <button type="submit" className="submitbut">
                      Submit
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default JobApproveModal;
