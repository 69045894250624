import React from 'react';

import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import AppLoader from '../../components/loaders/AppLoader';
const Notification = () => {
  const { user } = useSelector((state) => state.user);
  // console.log(user);
  if (isEmpty(user.notifications)) {
    <AppLoader />;
  }
  return (
    <>
      <div id="tabblock6" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>My Notification</h2>
            {user.notifications && user.notifications.length !== 0 ? (
              user.notifications.map((obj, i) => (
                <div className="progressdiv" key={i}>
                  <div className="progressheading">
                    <span>{i + 1}</span>
                    {obj.data.message}
                  </div>
                  {/* <a href="#" className="detailsdiv">
                          View Details
                        </a> */}
                  <div className="clearfix"></div>
                </div>
              ))
            ) : (
              <h4 className='text-center'>You do not have a notification</h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
