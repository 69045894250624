import React, { useEffect, useState } from 'react';
import { get, round } from 'lodash';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import StaticStarUI from '../../../components/UI/StaticStarUI';
import { isMobile } from '../../../helpers/utils';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET } from '../../../services/rest.service';

const JobReviewsListModal = (props) => {
  const { reviewAgentId, isOpen, toggle } = props;
  const dispatch = useDispatch();
  const [reviewList, setReviewList] = useState([]);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
  } = usePagination();

  useEffect(() => {
    if (isOpen) {
      dispatch(showLoading());
      GET(`users/getJobRatings/${reviewAgentId}`, { per_page: itemsPerPage, page: selectedPage })
        .then(({ data }) => {
          setReviewList(data.ratings);
          setTotalPages(data.total_count / itemsPerPage);
          dispatch(hideLoading());
        })
        .catch((err) => {
          setReviewList([]);
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  }, [reviewAgentId, isOpen, selectedPage, itemsPerPage]);

  const handleSelected = ({ selected }) => {
    // console.log('bids selected', selected);
    setSelectedPage(selected + 1);
  };

  const handlePageCountChange = (e) => {
    handleSelected({ selected: 0 });
    setItemsPerPage(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={'client-page'} className="modal-xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject nopadding-sm">
          <h5>Reviews List</h5>
          {reviewList.length ? (
            <>
              {isMobile()?.matches ? (
                <ul className="reviews-list">
                  {reviewList.map((x, index) => (
                    <li>
                      <div>
                        <StaticStarUI rating={get(x, 'rating') ? round(get(x, 'rating'), 1) : 0} />
                      </div>
                      <p>{x.feedback || 'N/A'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="table-responsive clearingtable">
                  <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <thead>
                      <tr>
                        <th>Rating</th>
                        <th>Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviewList.map((x, index) => (
                        <tr key={index}>
                          <td className="col-3">
                            <StaticStarUI
                              rating={get(x, 'rating') ? round(get(x, 'rating'), 1) : 0}
                            />
                          </td>
                          <td className="col-9">{x.feedback || 'N/A'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          ) : (
            <h3>No Reviews Found!</h3>
          )}
          <div className="d-flex justify-content-end">
            {reviewList.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default JobReviewsListModal;
