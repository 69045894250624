import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { IconButton, Input, InputAdornment } from '@material-ui/core';
import InputFeedback from './InputFeedback';

const SearchIcon = () => <i className="bi bi-search fs-2" />;
const ClearIcon = () => <i className="bi bi-x fs-2" />;

const SearchBar = (props) => {
  const { id, name, onSearch, onClearSearchText, className, error, onBlur, value } = props;
  const [searchText, setSearchText] = useState(value || '');

  useEffect(() => {
    if (value) setSearchText(value);
  }, [value]);

  const InputClasses = classnames(
    'form-control searchbar',
    {
      'is-invalid': !!error,
    },
    className,
  );

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Input
        id={id}
        name={name}
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e?.target?.value || '')}
        className={InputClasses}
        placeholder="Search..."
        onBlur={onBlur}
        endAdornment={
          <InputAdornment position="end">
            {searchText && (
              <IconButton
                aria-label="clear icon"
                onClick={() => {
                  setSearchText('');
                  onClearSearchText();
                }}
                onMouseDown={handleMouseDownPassword}
              >
                <ClearIcon />
              </IconButton>
            )}
            <IconButton
              aria-label="search icon"
              onClick={() => onSearch(searchText)}
              onMouseDown={handleMouseDownPassword}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        onKeyUp={(event) => {
          // trigger search when user presses enter key
          if (event.keyCode === 13) onSearch(searchText);
        }}
      />
      <InputFeedback error={error} />
    </>
  );
};

export default SearchBar;
