import { ADD_DATA, ADD_PORTS_DATA, RESET_DATA, RESET_PORTS_DATA } from './EnumActions';

const initialState = {
  registration_social_networks: {
    '1': 'facebook',
    '2': 'google',
    '3': 'blogs',
    '4': 'tv',
    '5': 'others',
  },
  port_locations: {
    '1': 'Apapa Port',
    '2': '40ftTin Can Island Port',
  },
  user_types: {
    public_user: 'Public User',
    managenmnt_operator: 'Managenmnt Operator',
    application_client: 'Application Client',
    application_corporate_client: 'Application Corporate Client',
    forwarding_agent: 'Transporter',
    clearing_agent: 'Clearing Agent',
    truck_inspector: 'Truck Inspector',
    legal_lawer: 'Application LAWER',
    work_supervisor: 'Work Supervisor',
    application_super_admin: 'Application Super Admin',
  },
  job_types: {
    '1': 'clearing',
    '2': 'forwarding',
  },
  job: {
    status: {
      '1': 'Active',
      '2': 'Pending',
      '3': 'Suspended',
      '4': 'Inactive',
      '5': 'Under Biding',
      '6': 'Waiting for Approval',
    },
    category: {
      '1': 'vehicle',
      '2': 'general cargo',
      '3': 'bulk cargo',
    },
    type_of_cargo: {
      '1': 'single item',
      '2': 'multiple items',
    },
    vehicle_state: {
      '1': 'run & drive',
      '2': 'tow',
      '3': 'forklift',
    },
    vehicle_type: {
      '1': 'roro',
      '2': 'container',
      '3': 'bulk cargo',
    },
  },
  truck: {
    axel: {
      '1': 'single',
      '2': 'double',
    },
    hooks: [4, 6, 8],
    size: {
      '1': '20ft',
      '2': '40ft',
    },
  },
};
const EnumReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_DATA:
      return {
        ...payload,
      };
    case RESET_DATA:
      return initialState;

    case ADD_PORTS_DATA:
      return {
        ...state,
        ports: payload,
      };
    case RESET_PORTS_DATA:
      return {
        ...state,
        ports: [],
      };
    default:
      return state;
  }
};
export default EnumReducer;
