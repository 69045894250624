import React from 'react';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash';
// import { useHistory } from 'react-router-dom';
import TextField from '../../UI/TextField';
import FIleField from '../../UI/FIleField';
import config from '../../../../config';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import convertJsonToFormData from '../../../helpers/helper';
import OTPFormField from './FormFields/OTP';
import { acceptedFileTypes } from '../../../helpers/constants';
// import { getLoggedInUser } from '../../../redux/user/UserActions';
import { VERIFYME_TYPE, PHONE_NUMBER_REGEX } from '../../../helpers/constants';
import PhoneNumber from '../PhoneNumber';
import { useVerifyme } from '../../../helpers/customHooks';

const userSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  // address: Yup.string().when('clientType',{
  //   is: 'free_zone_entity',
  //   then: Yup.string().required('Address is required'),
  // }),
  dob: Yup.string().required('DOB is required'),
  nationality: Yup.string().required('Nationality is required'),
  mobile_number: Yup.string()
    .required('Phone Number is required')
    .matches(PHONE_NUMBER_REGEX, 'Enter Phone Number without Country Code and without Space'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  // identity_proof: Yup.string().when('category',{
  //   is: 1,
  //   then: Yup.string().required('Mobile  Number is required'),
  // })
});
const baseURL = config.api.BASE_URL;

const FormFields = (props) => {
  const { toggleAuthRepForm, showRightModal, clientType, repValues = {}, getUserDetails } = props;
  const dispatch = useDispatch();
  // const history = useHistory();

  // initialize verifyme hook
  const {
    isDocVerified,
    showOTPField,
    verifyMeData,
    setIsDocVerified,
    setShowOTPField,
    setVerifyMeData,

    storeVerifyMeData,
    sendMsgOtp,
    verifyDocument,
    verifyUserOtp,
  } = useVerifyme(dispatch, repValues?.phone_verified_at ? true : false);

  const closeModal = () => {
    setIsDocVerified(false);
    setVerifyMeData({});
    setShowOTPField(false);
    toggleAuthRepForm();
  };

  return (
    <div
      className="modal right fade right-slide in"
      id="authRepModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
      style={{ display: showRightModal ? 'block' : 'none' }}
    >
      {showRightModal && (
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="myModalLabel2">
                Authorized Representative Details
              </h4>
            </div>

            <div className="modal-body">
              <Formik
                initialValues={{
                  clientType,
                  first_name: get(repValues, 'first_name', '') || '',
                  last_name: get(repValues, 'last_name', '') || '',
                  address: get(repValues, 'address', '') || '',
                  dob: get(repValues, 'dob', '') || '',
                  nationality: get(repValues, 'nationality', '') || '',
                  mobile_number: get(repValues, 'mobile_number', '') || '',
                  email: get(repValues, 'email', '') || '',
                  // identity_proof: '',
                  authorisation_letter: '',
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    const payload = {
                      user_uuid: repValues.uuid,
                      first_name: values.first_name,
                      last_name: values.last_name,
                      dob: values.dob,
                      nationality: values.nationality,
                      mobile_number: values.mobile_number,
                      email: values.email,
                      // identity_proof: values.identity_proof,
                    };

                    if (clientType !== 'free_zone_entity') {
                      payload.address = values.address;
                    }

                    if (
                      clientType === 'government_entity' ||
                      clientType === 'government_approved_private_entity'
                    ) {
                      payload.authorisation_letter = values.authorisation_letter;
                    }

                    // console.log('AUTHORIZED REP FORM payload',payload)
                    dispatch(showLoading());

                    const response = await POST(`/users/update`, convertJsonToFormData(payload));
                    //console.log('data', data);

                    resetForm();
                    setSubmitting(false);
                    // dispatch(getLoggedInUser(history));

                    if (!isEmpty(verifyMeData)) {
                      // save verifymeData to our database
                      await storeVerifyMeData(repValues.uuid, 'user');
                    }
                    getUserDetails();

                    toggleAuthRepForm();
                    dispatch(showModal('Details Updated successfully', true));
                    dispatch(hideLoading());
                  } catch (error) {
                    dispatch(showModal(error.message));
                    dispatch(hideLoading());
                    setSubmitting(false);
                  }
                }}
                validationSchema={userSchema}
              >
                {(renderProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                  } = renderProps;
                  let showVerificationBtn = false;

                  if (
                    values.dob &&
                    values.mobile_number &&
                    values.first_name &&
                    values.last_name &&
                    !isDocVerified
                    // && isEmpty(verifyMeData)     // >> Verifyme Verification related
                  ) {
                    showVerificationBtn = true;
                  }

                  return (
                    <form className="row form-new" onSubmit={handleSubmit}>
                      <div className="col-sm-6">
                        <label className="v2-text-label v2-text">First Name</label>
                        <TextField
                          id="first_name"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'first_name') && getIn(errors, 'first_name')}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="v2-text-label v2-text">Last Name</label>
                        <TextField
                          id="last_name"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'last_name') && getIn(errors, 'last_name')}
                        />
                      </div>
                      {(clientType === 'government_entity' ||
                        clientType === 'government_approved_private_entity') && (
                        <div className="col-sm-12 mt-2">
                          {repValues.authorisation_letter && (
                            <div className="form-group">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${baseURL}/${repValues.authorisation_letter}`}
                              >
                                <i className="fa fa-file-text-o"></i> Authorisation letter
                              </a>
                            </div>
                          )}

                          <div
                            className="fileUploads"
                            style={{ background: values.authorisation_letter ? '#01a8af' : '' }}
                          >
                            <span>
                              <i className="fa fa-file-text-o" aria-hidden="true"></i> Authorisation
                              letter
                            </span>
                            <FIleField
                              type="file"
                              accept={acceptedFileTypes}
                              name="authorisation_letter"
                              onChange={(event) =>
                                setFieldValue('authorisation_letter', event.target.files[0])
                              }
                              className="uploads"
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-sm-12 mt-2">
                        <label className="v2-text-label v2-text">Email</label>
                        <TextField
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'email') && getIn(errors, 'email')}
                        />
                      </div>
                      <div className="col-sm-12 mt-2">
                        <label className="v2-text-label v2-text">Phone Number</label>
                        <PhoneNumber
                          value={values.mobile_number}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue('mobile_number', value);
                            if (value) {
                              const oldPhone = get(repValues, 'mobile_number');
                              if (value !== oldPhone) {
                                setIsDocVerified(false);
                              } else if (value === oldPhone) {
                                setIsDocVerified(true);
                              }
                            } else {
                              setIsDocVerified(false);
                            }
                          }}
                          onBlur={handleBlur}
                          error={getIn(touched, 'mobile_number') && getIn(errors, 'mobile_number')}
                        />
                      </div>
                      <div className="col-sm-12 mt-2">
                        <label className="v2-text-label v2-text">Date Of Birth</label>
                        <TextField
                          id="dob"
                          type="date"
                          className="form-control"
                          value={values.dob}
                          max={moment().format('YYYY-MM-DD')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'dob') && getIn(errors, 'dob')}
                        />
                      </div>
                      <div className="col-sm-12 mt-2">
                        <label className="v2-text-label v2-text">Nationality</label>
                        <TextField
                          id="nationality"
                          type="text"
                          className="form-control"
                          placeholder="Nationality"
                          value={values.nationality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'nationality') && getIn(errors, 'nationality')}
                        />
                      </div>
                      <>
                        {/* <div className="col-sm-12 mt-2">
                          {repValues.identity_proof && (
                            <div className="form-group">
                              <a target="_blank" rel="noopener noreferrer" href={`${baseURL}/${repValues.identity_proof}`}>
                                <i className="fa fa-file-text-o"></i> National Identity Card Image
                              </a>
                            </div>
                          )}
                  
                          <div className="fileUploads" style={{ background: values.authorisation_letter ? '#01a8af' : '' }}>
                            <span>
                                <i className="fa fa-file-text-o" aria-hidden="true"></i> National Identity Card Image
                            </span>
                            <FIleField
                              type="file"
                              className={`${getIn(touched, 'identity_proof') && getIn(errors, 'identity_proof') ? 'Upload is-invalid' : 'Upload'}`}
                              name="identity_proof"
                              onChange={(event) => setFieldValue('identity_proof', event.target.files[0])}
                              onBlur={handleBlur}
                            />
                            {getIn(touched, 'identity_proof') && getIn(errors, 'identity_proof') ? (
                              <div className="invalid-feedback mb-3">{getIn(errors, 'identity_proof')}</div>
                            ) : null}
                          </div>
                        </div> */}
                      </>
                      {clientType !== 'free_zone_entity' && (
                        <div className="col-sm-12 mt-2">
                          <label className="v2-text-label v2-text">Residential Address</label>
                          <TextField
                            id="address"
                            type="text"
                            className="form-control"
                            placeholder="Residential Address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getIn(touched, 'address') && getIn(errors, 'address')}
                          />
                        </div>
                      )}

                      {/* NIN/BVN/DL verification button */}
                      {showVerificationBtn && !showOTPField && (
                        <div className="col-sm-12 mt-2">
                          <button
                            className="form-control submitbut mt-2"
                            type="button"
                            onClick={async () => {
                              const sft = await setFieldTouched('mobile_number', true);
                              if (!sft?.mobile_number) {
                                verifyDocument(VERIFYME_TYPE, {
                                  referenceId: values.mobile_number,
                                  dob: moment(values.dob).format('DD-MM-YYYY'),
                                  firstname: values.first_name,
                                  lastname: values.last_name,
                                });
                              }
                            }}
                          >
                            Verify Phone Number
                          </button>
                        </div>
                      )}
                      {/* NIN/BVN/DL verification OTP FIELD */}
                      {showOTPField && (
                        <div className="col-sm-12 mt-2">
                          <OTPFormField
                            {...renderProps}
                            phone={values.mobile_number}
                            verifyUserOtp={(phone, otp) => verifyUserOtp(phone, otp, 'user')}
                            resendOtp={sendMsgOtp}
                          />
                        </div>
                      )}

                      <div className="col-sm-12 mt-2">
                        <button
                          className=" form-control submitbut mt-2"
                          type="submit"
                          disabled={!isDocVerified}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormFields;
