import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import convertJsonToFormData from '../../helpers/helper';
import { getRegYearType } from '../../helpers/utils';
import ClearingForwardingDocFields from '../global/ClearingForwardingFormDocFields';

const COAgentDocumentUploadModal = (props) => {
  const {
    isOpen,
    toggle,
    container,
    agentType,
    details,
    showDoc,
    images,
    getUserDetails,
    formValues,
  } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const serviceData = useSelector((state) => state.enumData);

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-lg">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject nopadding-sm">
          <h3>Upload Documents</h3>
          <Formik
            initialValues={{
              rc_no: get(formValues, 'rc_no', '') || '',
              reg_year: (images && getRegYearType(images)) || '',
              incorporation_certificate: '',
              memorandum_article_association: '',
              corporate_address_proof: '',
              registration_application_status: '',
              ctc_cac_1_1: '',
              cac_2: '',
              cac_7: '',
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              const payload = {
                rc_no: values.rc_no,
                user_uuid: details?.user?.uuid || '',
                incorporation_certificate: values.incorporation_certificate,
                memorandum_article_association: values.memorandum_article_association,
                corporate_address_proof: values.corporate_address_proof,
                registration_certificate: values.registration_certificate,
                registration_application_status: values.registration_application_status,
                ctc_cac_1_1: values.ctc_cac_1_1,
                cac_2: values.cac_2,
                cac_7: values.cac_7,
              };

              let formData = convertJsonToFormData(payload);

              dispatch(showLoading());

              POST(`/users/uploadUserDocs`, formData)
                .then((data) => {
                  //console.log('data', data);
                  setSubmitting(false);
                  getUserDetails();
                  toggle();
                  dispatch(showModal('Documents uploaded successfully', true));
                  dispatch(hideLoading());
                })
                .catch((err) => {
                  setSubmitting(false);
                  dispatch(showModal(err.message));
                  dispatch(hideLoading());
                });
            }}
          >
            {(renderProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = renderProps;

              return (
                <form onSubmit={handleSubmit}>
                  <ClearingForwardingDocFields
                    values={values}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    details={details}
                    showDoc={showDoc}
                    userType={user.type}
                    agentType={agentType}
                    images={images}
                    className="lawyer-doc-upload"
                  />
                  <div className="buttonsec">
                    <button className="submitbut" type="submit">
                      Upload Documents
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default COAgentDocumentUploadModal;
