import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';

const NotificationMenuItem = (props) => {
  const user = useSelector((state) => state.user.user);

  const { title, types } = props;
  const [notificationsCount, setNotificationsCount] = useState(0);

  useEffect(() => {
    // console.log('>> Change in notifications')
    const notifications = user.unread_notifications || [];
    let nfc = 0;
    if (notifications?.length > 0) {
      notifications.forEach((item) => {
        if (item.type) {
          const ntypes = item.type.split('\\');
          // App\\Domains\\Bid\\Notifications\\BidCreatedNotification
          // 0      1       2       3               4
          // after splitting item.type at '\\'
          // we are comparing the 4th string for deciding type
          if (ntypes?.length > 0 && types?.find((x) => x === ntypes[4])) {
            nfc += 1;
          }
        }
      });
    }
    setNotificationsCount(nfc);
  }, [user, types]);

  return (
    <Badge badgeContent={notificationsCount} color="secondary" invisible={notificationsCount === 0}>
      <span>{title}</span>
    </Badge>
  );
};

export default NotificationMenuItem;
