import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { GET } from '../../services/rest.service';
import Pagination from '../../components/global/Pagination';
import { usePagination } from '../../helpers/customHooks';
import { getUserName } from '../../helpers/utils';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const {
    selectedPage,
    setSelectedPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    setTotalPages,
  } = usePagination();

  //page changes
  const handleSelected = ({ selected }) => {
    // console.log('selected', );
    setSelectedPage(selected + 1);
  };

  const getUserList = (page, perPage) => {
    dispatch(showLoading());
    const payload = {
      page: page,
      per_page: perPage,
    };
    GET('/users/getOperatorList', payload)
      .then(({ data }) => {
        // console.log(data);
        setUserList(data.operators);
        dispatch(hideLoading());
        setTotalPages(data.total_count / perPage);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());

        setUserList([]);
      });
  };
  useEffect(() => {
    getUserList(selectedPage, itemsPerPage);
  }, [selectedPage, itemsPerPage]);

  const handleJobsView = (id) => {
    return props.history.push(`/supervisor/operator/${id}/jobs`);
  };

  const handleDisputesView = (id) => {
    return props.history.push(`/supervisor/operator/${id}/disputes`);
  };

  const handlePageCountChange = (e) => {
    handleSelected({ selected: 0 });
    setItemsPerPage(e.target.value);
  };

  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>Operators List</h2>
            <div className="tab-pane active" id="resp-tab7">
              <div className="table-responsive clearingtable">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th>Operator Name</th>
                      <th>Operator ID</th>
                      <th>Contact Details</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.length > 0 &&
                      userList.map((x, index) => (
                        <tr key={index}>
                          <td>{getUserName(x)}</td>
                          <td>{x.user_id || 'N/A'}</td>
                          <td className="description1">
                            <p>Email: {x.email}</p> <p>Mobile: {x.mobile_number}</p>
                          </td>
                          <td>
                            <div className="starsec1">
                              <a
                                className="review mx-1 mb-1"
                                onClick={() => handleJobsView(x.uuid)}
                              >
                                View Jobs
                              </a>
                              <a className="review" onClick={() => handleDisputesView(x.uuid)}>
                                Pending Disputes
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {userList.length <= 0 && <h4 className="w-100 text-center">No Data Found !!</h4>}
              </div>
              {userList?.length > 0 && (
                <Pagination
                  pageCount={totalPages}
                  onPageChange={handleSelected}
                  selectedPage={selectedPage}
                  setItemsPerPage={handlePageCountChange}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
