import React from 'react';
import { getIn } from 'formik';
import TextField from '../../../UI/TextField';
import FIleField from '../../../UI/FIleField';
import { acceptedFileTypes } from '../../../../helpers/constants';
import DisplayDocument from '../../../global/DisplayDocument';

const FormFields = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    images,
    showDoc,
  } = props;

  return (
    <>
      <div className="col-sm-12 col-lg-12 mb-3">
        {images.cac_it_1 && (
          <DisplayDocument
            label="Form CAC IT/1 (Incorporated Trustees Application Form)"
            onClick={() => showDoc(images.cac_it_1)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div className="fileUploads" style={{ background: values.cac_it_1 ? '#01a8af' : '' }}>
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Form CAC IT/1 (Incorporated
            Trustees Application Form)
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="cac_it_1"
            onChange={(event) => setFieldValue('cac_it_1', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">CAC/IT Number</label>
          <TextField
            id="cac_it_no"
            type="text"
            name="cac_it_no"
            className="form-control"
            placeholder="CAC/IT Number"
            value={values.cac_it_no}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'cac_it_no') && getIn(errors, 'cac_it_no')}
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        {images.corporate_address_proof && (
          <DisplayDocument
            label="Proof of corporate address(Utility bills or Tax payment receipt)"
            onClick={() => showDoc(images.corporate_address_proof)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.corporate_address_proof ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Proof of corporate
            address(Utility bills or Tax payment receipt)
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="corporate_address_proof"
            onChange={(event) => setFieldValue('corporate_address_proof', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>
    </>
  );
};

export default FormFields;
