import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import swal from 'sweetalert';
import {
  USER_ROLE_APPLICATION_CLIENT,
  USER_ROLE_CLEARING_AGENT,
  USER_ROLE_FORWARDING_AGENT,
} from '../../../helpers/constants';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import { extractUserDocs, getName } from '../../../helpers/utils';
import { showDocumentModal } from '../../../redux/user/UserActions';
import SuspensionFormModal from '../SuspensionFormModal';
import COAgentDocumentUploadModal from '../../../components/Modals/COAgentDocUploadModal';
import COClientDocumentUploadModal from '../../../components/Modals/COClientDocUploadModal';
import ImportersDetails from './ImportersDetails';
import AgentsDetails from './AgentsDetails';
import SuspensionHistoryTable from './SuspensionHistoryTable';
import AgentPortOfOperations from './AgentPortOfOperations';

const UserDetails = (props) => {
  const {
    match: { params },
  } = props;
  // user id from url
  const USER_ID = params?.id;

  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [details, setDetails] = useState({});

  const [images, setImages] = useState({});
  const [formValues, setFormValues] = useState({});
  const [userId, setUserId] = useState(null);
  const [showSuspensionModal, setShowSuspensionModal] = useState(false);
  const toggleSuspensionModal = () => setShowSuspensionModal((s) => !s);

  const { user } = useSelector((state) => state.user);
  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  const [showDocUploadModal, setShowDocUploadModal] = useState(false);

  const toggleDocUploadModal = () => setShowDocUploadModal((s) => !s);

  const getUserDetails = () => {
    dispatch(showLoading());

    GET(`/users/getDetails/${USER_ID}`)
      .then(({ data }) => {
        // console.log(data);
        setUserDetails(data.user);
        setDetails(data);
        if (data.user.type == 'clearing_agent' || data.user.type == 'forwarding_agent') {
          if (!isEmpty(data.documents)) {
            const item = extractUserDocs(data);

            setImages({
              incorporation_certificate: item.incorporation_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,
              registration_certificate: item.registration_certificate,
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
            });
            const modifiedItem = {
              ...item,
              incorporation_certificate: null,
              memorandum_article_association: null,
              corporate_address_proof: null,
              registration_certificate: null,
              registration_application_status: null,
              ctc_cac_1_1: null,
              cac_2: null,
              cac_7: null,
            };
            // console.log(modifiedItem);
            setFormValues(modifiedItem);
          } else {
            setFormValues({});
          }
        } else {
          const item = extractUserDocs(data);

          if (!isEmpty(item)) {
            setImages({
              // incorporated company
              // rc_no: item.rc_no, // this is text document
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
              incorporation_certificate: item.incorporation_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,

              // Incorporated trustee
              cac_it_no: item.cac_it_no,
              cac_it_1: item.cac_it_1,
              // rc_no: item.rc_no, // already present above
              // corporate_address_proof: item.corporate_address_proof, // already present above

              // Free zone entity
              // nepza_no: item.nepza_no,  // this is text document
              nepza_operating_license: item.nepza_operating_license,
              ctc_situation_notice: item.ctc_situation_notice,
              director_particulars: item.director_particulars,
              nepza_registration_certificate: item.nepza_registration_certificate,
              share_capital_statement: item.share_capital_statement,
              return_allotement_statement: item.return_allotement_statement,
              // memorandum_article_association: item.memorandum_article_association, // already present above

              // Government Entity & Private entity (share same text fields) // already present above
              // corporate_address: item.corporate_address,  // this is text document
              // website_url: item.website_url, // this is text document
              // email: item.email, // this is text document

              // Government Entity
              official_gazetted_establishment_law: item.official_gazetted_establishment_law,

              // Private entity
              governement_approval_document: item.governement_approval_document,
              business_owner_document: item.business_owner_document,
            });
            const modifiedItem = {
              ...item,
              national_identity_card: null,
              rc_no: item.rc_no,
              registration_application_status: null,
              ctc_cac_1_1: null,
              cac_2: null,
              cac_7: null,
              incorporation_certificate: null,
              memorandum_article_association: null,
              corporate_address_proof: null,

              // Incorporated trustee
              cac_it_no: null,
              cac_it_1: null,
              // rc_no: null, // already present above
              // corporate_address_proof: null, // already present above

              // Free zone entity
              nepza_no: item.nepza_no,
              nepza_operating_license: null,
              ctc_situation_notice: null,
              director_particulars: null,
              nepza_registration_certificate: null,
              share_capital_statement: null,
              return_allotement_statement: null,
              // memorandum_article_association: null, // already present above

              // Government Entity & Private entity (share same text fields) // already present above
              corporate_address: item.corporate_address,
              website_url: item.website_url,
              email: item.email,

              // Government Entity
              official_gazetted_establishment_law: null,

              // Private entity
              governement_approval_document: null,
              business_owner_document: null,
            };
            // console.log(modifiedItem);
            setFormValues(modifiedItem);
          } else {
            setFormValues({});
          }
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
        setUserDetails({});
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const refreshPageData = () => {
    getUserDetails();
  };

  const handleSuspend = (u) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to suspend ${getName(u)}?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        toggleSuspensionModal();
        setUserId(u.uuid);
      }
    });
  };

  const handleRemoveSuspension = (u) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to remove suspension?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        POST(`/admin/activateUser`, { uuid: u.uuid })
          .then(() => {
            dispatch(hideLoading());
            dispatch(showModal('User Activated successfully', true));
            refreshPageData();
          })
          .catch((err) => {
            dispatch(showModal(err.message));
            dispatch(hideLoading());
          });
      }
    });
  };

  const clientType = userDetails.sub_type || userDetails.type;
  // console.log('>> userDetails', userDetails);
  return (
    <div id="tabblock4" className="tabcontent">
      <div className="bodycont">
        <div className="">
          <h2>
            <span
              onClick={() => props.history.goBack()}
              style={{ cursor: 'pointer', float: 'left' }}
            >
              <LeftArrowSvg />
            </span>
            User Details
          </h2>
          <div className="card">
            {/* Details, Documents */}
            <div className="personal-info p-0 mb-4">
              <>
                {userDetails.type == 'clearing_agent' || userDetails.type == 'forwarding_agent' ? (
                  <AgentsDetails
                    userDetails={userDetails}
                    images={images}
                    formValues={formValues}
                    details={details}
                    showDoc={showDoc}
                  />
                ) : (
                  <ImportersDetails
                    userDetails={userDetails}
                    images={images}
                    formValues={formValues}
                    user={user}
                    showDoc={showDoc}
                    clientType={clientType}
                  />
                )}
              </>
            </div>
            {/* SUSPENSION HISTORY TABLE */}
            <SuspensionHistoryTable userId={USER_ID} userDetails={userDetails} />
            <hr />
            {/* Ports of Operation */}
            {userDetails.type === 'clearing_agent' && (
              <>
                <AgentPortOfOperations userId={USER_ID} />
                <hr />
              </>
            )}

            {/* Action Buttons */}
            <div className="buttonsec text-center">
              {userDetails.identity_verified === 1 && (
                <a onClick={() => handleSuspend(userDetails)} className="submitbut mx-1">
                  Suspend
                </a>
              )}
              {userDetails.identity_verified === 3 && (
                <a onClick={() => handleRemoveSuspension(userDetails)} className="submitbut mx-1">
                  Remove Suspension
                </a>
              )}
              {clientType !== 'application_client' && (
                <a onClick={toggleDocUploadModal} className="submitbut mx-1 btn-light">
                  Upload Documents
                </a>
              )}
            </div>
          </div>
        </div>

        {showSuspensionModal && (
          <SuspensionFormModal
            isOpen={showSuspensionModal}
            toggle={() => {
              toggleSuspensionModal();
              setUserId(null);
            }}
            userId={userId}
            refreshTabData={refreshPageData}
          />
        )}
        {(userDetails.type === USER_ROLE_CLEARING_AGENT ||
          userDetails.type === USER_ROLE_FORWARDING_AGENT) &&
          showDocUploadModal && (
            <COAgentDocumentUploadModal
              isOpen={showDocUploadModal}
              toggle={toggleDocUploadModal}
              container="lawear-page"
              agentType={userDetails.type}
              details={details}
              showDoc={showDoc}
              images={images}
              getUserDetails={getUserDetails}
              formValues={formValues}
            />
          )}
        {userDetails.type === USER_ROLE_APPLICATION_CLIENT && showDocUploadModal && (
          <COClientDocumentUploadModal
            isOpen={showDocUploadModal}
            toggle={toggleDocUploadModal}
            container="lawear-page"
            details={details}
            showDoc={showDoc}
            clientType={clientType}
            getUserDetails={getUserDetails}
            images={images}
            formValues={formValues}
          />
        )}
      </div>
    </div>
  );
};

export default UserDetails;
