import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { showModal } from '../../redux/error/ErrorAction';
import Pusher from 'pusher-js';
import LogoWhite from '../../../assets/img/logo-white.png';
import userImage from '../../../assets/img/placeHolderImage.png';
import { applicationUserType } from '../../helpers/constants';
import { logoutUser, getLoggedInUser } from '../../redux/user/UserActions';
import swal from 'sweetalert';
import config from '../../../config';
import moment from 'moment';
import { POST } from '../../services/rest.service';
import { getUserName } from '../../helpers/utils';
// import WebinarBanner from '../global/WebinarBanner';
import ScrollToStart from '../UI/ScrollToStart';

export const logoutConfirmationModal = (dispatch, history, email) => {
  swal({
    title: 'Are you sure?',
    text: 'Are you sure that you want to logout?',
    icon: 'warning',
    buttons: ['No', 'Yes'],
  }).then((result) => {
    if (result) {
      dispatch(logoutUser(history, email));
    }
  });
};

const UserHeader = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [notice, setNotice] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [addHeaderClass, setHeaderClass] = useState(false);

  useEffect(() => {
    const calculateHeader = (e) => {
      if (window.scrollY >= 100) {
        setHeaderClass(true);
      } else {
        setHeaderClass(false);
      }
    };
    window.addEventListener('scroll', calculateHeader);

    return () => {
      window.removeEventListener('scroll', calculateHeader);
    };
  }, []);

  // console.log(user);
  const [unseenList, setUnseenList] = useState([]);

  const toggleNotice = () => {
    setNotice(!notice);
  };
  // document.body.addEventListener('click', setNotice(false));
  const envent_name = `Illuminate\\Notifications\\Events\\BroadcastNotificationCreated`;
  let date = Date.now();
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // console.log('effect1');
    if (user.unread_notifications) {
      setUnseenList(user.unread_notifications);
    }
    function handleClickOutside(event) {
      if (wrapperRef.current !== null) {
        // console.log(wrapperRef.current);
        // console.log(wrapperRef.current.contains(event.target));
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setNotice(false);

          // clearing all the notifications, when user clicks outside notifications list component
          // send empty string when we need to clear all notifications
          readNotification('');
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, user]);

  useEffect(() => {
    const pusher = new Pusher('2c4a0502ac9f198e2eaa', {
      cluster: 'ap2',
      encrypted: true,
    });
    //5edd2728-7c0f-4b9e-ae63-4272cc86c831
    const channel = pusher.subscribe(`Notification.User.${user.uuid}`);
    channel.bind(envent_name, (data1) => {
      //console.log('unseenlist', unseenList);
      setUnseenList((prevState) => [...prevState, { data: data1, create_at: date }]);

      dispatch(showModal('you have a notification', true));

      // when we recieve user is verified notification then fetch the logged in user details so restrictions will be removed
      const docVerifiedNotificationType =
        'App\\Domains\\User\\Notifications\\DocAcceptedNotification';
      if (data1.type === docVerifiedNotificationType) {
        dispatch(getLoggedInUser());
      }
    });

    return () => pusher.unsubscribe(`Notification.User.${user.uuid}`);
    // Bind the event listener
  }, []);
  const readNotification = (obj) => {
    // console.log('console', obj);
    POST(`/users/markAsReadNotification`, { notification_id: obj })
      .then(({ data }) => {
        // console.log(unseenList);
        // const undata = unseenList.filter((item) => item.id !== obj.id);
        // // console.log('>> undata',undata);
        // let msg = 'you have read a notification';
        // if(undata.length === 0){
        //   msg = 'you have read all notifications';
        // }
        // dispatch(showModal(msg, true));
        dispatch(getLoggedInUser());
        // setUnseenList(undata);
        setNotice(false);
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };

  // useEffect(() => {
  //   if (notice) {
  //     readNotification(['']);
  //   }
  // }, [notice]);

  const displayName = getUserName(user);

  return (
    <>
      {/* THIS IS COMMENTED FOR THE TIME BEING  */}
      {/* <WebinarBanner /> */}
      <header id="header" className="header fixed-top dashboard-top">
        <div className="container-fluid ">
          <a
            className="logo d-flex align-items-center"
            onClick={() => {
              history.push(`/${applicationUserType[get(user, 'type')]}/dashboard`);
            }}
          >
            <img src={LogoWhite} alt="" width="233" />
          </a>

          <div className="loginright">
            {/* Profile & Notification icon */}
            <div className="notification">
              <a
                id="news"
                onClick={() => {
                  toggleNotice();
                  if (notice) {
                    // clearing all the notifications, after user closes the notification list component by clicking again on bell icon
                    // send empty string when we need to clear all notifications
                    readNotification('');
                  }
                }}
              >
                <i className="fa fa-bell" aria-hidden="true" style={{ color: '#29bec1' }}></i>
              </a>
              <span>{unseenList && unseenList.length}</span>
            </div>
            <div className="loginleft">
              <a
                className="topprofile"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setNotice(false)}
              >
                <img
                  src={
                    get(user, 'avatar')
                      ? `${config.api.URL.replace('/api/v1', '')}/${get(user, 'avatar')}`
                      : userImage
                  }
                  alt=""
                />

                <div className="copyright" style={{ color: 'white' }}>
                  {/* {get(user, 'first_name')} */}
                  {displayName}
                </div>
              </a>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <span>
                    <strong>Name: </strong>
                    {displayName}
                  </span>
                </li>
                <li>
                  <span>
                    <strong>ID: </strong>
                    {get(user, 'user_id')}
                  </span>
                </li>
                <li>
                  <a
                    onClick={() => {
                      history.push(`/${applicationUserType[get(user, 'type')]}/dashboard`);
                    }}
                    className="link"
                  >
                    Dashboard
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      logoutConfirmationModal(dispatch, history, get(user, 'email'));
                    }}
                    className="text-danger"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>

            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
      </header>
      {notice && (
        <div
          className="popover fade bottom in n-1"
          role="tooltip"
          id="popover430299"
          ref={wrapperRef}
        >
          <div className="arrow n-2"></div>
          {/* <h3 className="popover-title">
            Notification 
            {unseenList?.length > 0 && (<a onClick={readNotification} className='clear-all-btn'>Clear All</a>)}
          </h3> */}
          <div className="popover-content">
            <div className="notification-block">
              {unseenList && unseenList.length !== 0 ? (
                unseenList.map((obj, i) => (
                  <div
                    key={i}
                    style={{ cursor: 'pointer' }} /* onClick={() => readNotification(obj)} */
                  >
                    <div className="row pb-2 mt-2">
                      <div className="col-sm-2">
                        <i className="fa fa-bell bells" aria-hidden="true"></i>
                      </div>
                      <div className="col-sm-10 notification-mid">
                        <p>{obj.data.message}</p>
                        <div className="min-ago">
                          {moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                      </div>
                    </div>
                    <hr className="m-0" />
                  </div>
                ))
              ) : (
                <div className="row">
                  <div className="col-sm-12 notification-mid">
                    <p>Your don't have a notification.</p>
                  </div>
                  {/* <div className="col-sm-3 min-ago">3 min ago</div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ScrollToStart addHeaderClass={addHeaderClass} />
    </>
  );
};
export default UserHeader;
