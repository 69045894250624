import React from 'react';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Input from 'react-phone-number-input/input';
import contactUsImg from '../../../assets/img/contact-us-img.jpg';
import TextField from '../../components/UI/TextField';
import TextareaInput from '../../components/UI/TextareaInput';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';

const ContactUsForm = () => {
  const dispatch = useDispatch();
    return (
        <section id="send-massage" className="send-massage">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 paddingRight1">
                <img src={contactUsImg} width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <h5 style={{ color: '#007679' }}>Send Us a Message</h5>
                <p>Do you have any inquiry or feedback for us? We would be happy to hear from you. Please reach out to us. Thank you.</p>
                <Formik
                  initialValues={{ name:'', email:'', mobile:'', message:''}}
                  validationSchema={Yup.object({
                    name: Yup.string().required('Name is Required'),
                    email: Yup.string().email('Invalid email address').required('Email is Required'),
                    mobile: Yup.string().required('Mobile is required'),
                    message: Yup.string().required('Message is required'),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    // send here
                    dispatch(showLoading());
                    setSubmitting(true);
                    POST('/storeContactUs',values)
                    .then(({data})=>{
                      resetForm();
                      dispatch(showModal('Message sent succssefully!', true));
                      dispatch(hideLoading());
                      setSubmitting(false);
                    })
                    .catch((err)=>{
                      dispatch(showModal(err.message));
                      dispatch(hideLoading());
                      setSubmitting(false)
                    });
                  }}
                >
                  {({values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField 
                        id="name"
                        name="name" 
                        type="text" 
                        placeholder="Name*"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'name') && getIn(errors, 'name')}
                      /> 
                      <TextField 
                        id="email"
                        name="email" 
                        type="email" 
                        placeholder="Enter your email*"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'email') && getIn(errors, 'email')}
                      />
                      <Input
                        value={values.mobile}
                        id="mobile"
                        className={`${getIn(touched, 'mobile') && getIn(errors, 'mobile') ? 'is-invalid' : ''}`}
                        placeholder="Mobile*"
                        onBlur={handleBlur}
                        error={getIn(touched, 'mobile') && getIn(errors, 'mobile')}
                        onChange={(number) => {
                          setFieldValue('mobile', number);
                        }}
                      />
                      {touched.mobile && errors.mobile && <div className="invalid-feedback  mb-3  d-block">{errors.mobile}</div>}
                      <TextareaInput 
                        id="message"
                        name="message" 
                        placeholder="Message*" 
                        cols="" 
                        rows="6"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'message') && getIn(errors, 'message')}
                      />            
                      <button type="submit" className="post-button" disabled={isSubmitting}>
                        Send
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
    )
}

export default ContactUsForm;