import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_USER,
  USER,
  SHOW_SUSPENSION_MODAL,
  HIDE_SUSPENSION_MODAL,
  SHOW_DOCUMENT_MODAL,
  HIDE_DOCUMENT_MODAL,
  SAVE_AGENT_PORTS,
} from './UserActions';

const initialState = {
  isLoggedIn: sessionStorage.getItem('_GATKEN') ? true : undefined,
  type: undefined,
  user: {},
  message: '',
  displaySuspensionModal: false,
  displayDocumentModal: false,
  documentToDisplay: '',
  agentPortsOfOperations: [],
};
const UserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        isLoggedIn: payload.isLoggedIn,
        user: { ...payload.user },
        message: payload.message,
        type: payload.type,
      };

    case LOGIN_FAILURE:
      return {
        isLoggedIn: payload.isLoggedIn,
        user: payload.user,
        message: payload.message,
        type: payload.type,
        status: payload.status,
      };

    case USER:
      return {
        ...state,
        user: { ...payload.user },
        type: payload.type,
      };

    case UPDATE_USER:
      return {
        isLoggedIn: true,
        type: payload.type,
        message: 'User is logged in',
        user: {
          ...state.user,
          ...payload.user,
        },
      };

    case LOGOUT:
      return payload;

    case SHOW_SUSPENSION_MODAL:
      return {
        ...state,
        displaySuspensionModal: true,
      };

    case HIDE_SUSPENSION_MODAL:
      return {
        ...state,
        displaySuspensionModal: false,
      };

    case SHOW_DOCUMENT_MODAL:
      return {
        ...state,
        displayDocumentModal: true,
        documentToDisplay: payload,
      };

    case HIDE_DOCUMENT_MODAL:
      return {
        ...state,
        displayDocumentModal: false,
        documentToDisplay: '',
      };

    case SAVE_AGENT_PORTS:
      return {
        ...state,
        agentPortsOfOperations: payload,
      };
    default:
      return state;
  }
};
export default UserReducer;
