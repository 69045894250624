import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';
import IMG2 from '../../../assets/img/landing-img2.png';
import IMG6 from '../../../assets/img/landing-img6.png';
import IMG7 from '../../../assets/img/landing-icon2.png';
import IMG8 from '../../../assets/img/landing-icon6.png';
import IMG9 from '../../../assets/img/landing-icon5.png';
import IMG10 from '../../../assets/img/landing-icon7.png';
import BLCKIMG from '../../../assets/img/landing-blockimg1.jpg';
import BLCKIMG4 from '../../../assets/img/landing-blockimg4.jpg';
import BLCKIMG6 from '../../../assets/img/landin-blockimg6.png';
import BLCKIMG8 from '../../../assets/img/landin-blockimg8.png';
import BLCKIMG10 from '../../../assets/img/landin-blockimg10.png';
import BLCKIMG11 from '../../../assets/img/landin-blockimg11.png';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { getCopyrightYear } from '../../helpers/utils';

const ClearingLanding = (props) => {
  const copyrightYear = getCopyrightYear();

  return (
    <>
      <PublicHeader defaultClass="inner-menu" />
      <div id="landing-page">
        <Helmet>
          <title>GoAgent for Clearing Agents</title>
        </Helmet>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 header-text">
                <div>
                  <h1>GoAgent for clearing agents</h1>
                  <br />
                  <h3>
                    Are you a clearing agent looking for more jobs? We register Importers who post
                    jobs daily. Start making more money with us today!
                  </h3>
                  <button type="button" onClick={() => props.history.push('/login')}>
                    CREATE YOUR FREE ACCOUNT HERE
                  </button>
                </div>
              </div>
              <div className="col-sm-5 text-align" data-aos="fade-up">
                <img src={IMG2} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
        <div className="body-block">
          <div className="container">
            <div className="header-block">
              <h2>A Safer, Better, Faster way to Improve Your Clearing Business</h2>
              <p>Helping Custom-Certified Agents to Maximise Their Business Potentials.</p>
            </div>
            <div className="row">
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG7} alt="" />
                </div>
                <h4>Access to Ready Customers, every day.</h4>

                <p>
                  You will meet Importers whose Cargo are already at the Port; Importers who are
                  looking for Clearing Agents just like you.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG8} alt="" />
                </div>
                <h4>Protection From Money Loss</h4>

                <p>
                  Many Importers refuse to pay the balance after completion of their clearing jobs.
                  GoAgent protects you from loss of money to such dishonest Importers.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG9} alt="" />
                </div>
                <h4>Protection From False Declaration of Cargo</h4>

                <p>
                  Many Importers falsely declare their Cargo to evade paying the correct custom
                  duties, or because they brought in contraband Cargo. With GoAgent, you will not
                  deal with this problem anymore.
                </p>
              </div>
              <div className="col-sm-3" data-aos="fade-up">
                <div className="text-align">
                  <img src={IMG10} alt="" />
                </div>
                <h4>Removal of Meddlesome Middle-men.</h4>

                <p>
                  Middlemen usually make more money from clearing jobs for adding absolutely no
                  value. GoAgent removes middlemen and connects you directly to the Importers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="simple-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                <h2>Trusted Money-making Machine for Clearing Agents in Nigeria.</h2>
                <p>Register with GoAgent and take your business to the next level.</p>
                <button
                  type="button"
                  className="callToActionBtn"
                  onClick={() => props.history.push('/registration')}
                >
                  CREATE YOUR FREE ACCOUNT HERE
                </button>
              </div>
              <div className="col-sm-4" data-aos="fade-up">
                <img src={IMG6} width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div id="services" className="services">
          <div className="container">
            <h2>Why GoAgent?</h2>
            <p className="text-align">
              All our services are carefully packaged to ensure a Win-Win solution for all
              concerned. This is why we insist on due-diligence for all parties on our platform. Not
              only do we verify our Customs-certified Agents and Transporters, we also validate our
              Importers, made up of small, medium to large and very large-scale businesses. As long
              as safety is your top priority, your decision to go with GoAgent is 100% on track.{' '}
              <NavLink to="/registration">Discover More About Our Safe Platform Here</NavLink>
            </p>
            <p className="text-align">
              If you are a Clearing Agent, you are probably already familiar with the problems
              associated with Customs Clearance in Nigeria, and perhaps difficult customers too.
            </p>
            <p className="text-align">
              Honest and hardworking Clearing Agents should not go out of business simply because
              they don’t have connections; and you certainly should not have to beg for your balance
              after successfully completing a job. Also, by no means should Importers be allowed to
              trick any agent into clearing a falsely-declared Cargo. Such practices must not be
              allowed to go unchecked, and that is why we created this platform to help you, not
              just to make more money, but also to allow you do your job without worrying about the
              issues mentioned above.
            </p>
            <p className="text-align">
              Here are just a few benefits of being a Clearing Agent on GoAgent platform:
            </p>
            <br />

            {/* Problem #1 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #1: Not Making Enough Sales</h3>
                      <p>
                        Not getting enough well-paying importers to work for can be very frustrating
                        for a Clearing Agent. This is a very valid fear amongst registered Customs
                        Clearing Agents.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #1 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG6} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #1: More Jobs; More Money Guaranteed!</h3>
                      <p>
                        Without spending a dime to advertise your services as a Custom-certified
                        Clearing Agent, you will meet and interact daily with Importers whose Cargo
                        are already on the sea; Importers who are looking for reliable Clearing
                        Agents just like you. What’s more? Every minute you refresh your app, you
                        get more of such Importers, every other day. There is just no end to this as
                        Importers continue to import Cargo into Nigeria. What could possibly be
                        better than this, for your business?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #2 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #2: Losing Money to Middlemen</h3>
                      <p>
                        Middlemen who arrange back-door clearing deals mostly make more money than
                        the Clearing Agents who suffer and bear the risk of serving the Importer
                        (The Client).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #2 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG10} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>
                        Solution #2: Middlemen Removed! Their Commissions now Stay in Your Pocket.
                      </h3>
                      <p>
                        On GoAgent, middlemen are completely removed as the platform connects you
                        directly with the importer, and thus, transferring all the commission of
                        middlemen into your pocket. This way, you’re making more money and you will
                        never have to be at the mercy of middlemen again.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #3 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #3: Risk of Not Getting Payment Balance Upon Job Completion</h3>
                      <p>
                        Most importers refuse to pay outstanding balance to hardworking clearing
                        agents after successful completion of their jobs. This is frustrating and
                        discouraging after living up to your end of the deal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #3 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG8} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #3: Upfront Collection of your money by Go Agent</h3>
                      <p>
                        We ensure that our Verified Importers pay you agreed fees as shown on our
                        Transparent Bidding Platform. In the event that they default, you will get
                        your money from us, because we always receive full moneys in advance and pay
                        the Clearing Agent after satisfactory completion of the job. So, there’s no
                        risk of losing your balance of payment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem #4 */}
            <div className="services-block1" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG4} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Problem #4: False Declaration of Cargo</h3>
                      <p>
                        Most Importers are guilty of false-declaration of their Cargo to evade
                        paying the correct duties and VAT, or because they want to smuggle
                        contraband items such as arms, drugs and others. When their false
                        declaration goes wrong, they disappear and leave the Clearing Agent
                        neck-deep in their mess. Many agents have gone to jail because of this.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Solution #3 */}
            <div className="services-block1 landing-solution-bg" data-aos="fade-up">
              <div className="row">
                <div className="col-md-4 col-lg-4 padding-right1">
                  <img src={BLCKIMG11} width="100%" alt="" className="block-img" />
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="services-block">
                    <div>
                      <h3>Solution #4: Full Protection from False Declaration of Cargo</h3>
                      <p>
                        Although the Importers you will be working with have been thoroughly vetted
                        and verified, GoAgent ensures that, in the unlikely event that a contraband
                        is found in a Cargo (something you know nothing about), or if an Importer
                        under-declares items, the Importer will be held responsible and made to
                        answer for their offense, not you. This is why we also do proper
                        due-diligence of all our Importers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="app-ready">
          <div className="container">
            <h3>Say goodbye to all problems in your Clearing Agency business.</h3>
            <p>
              Discover How We Keep Our Agents Making More Money, in a Safe and Secure Environment.
            </p>
            <div className="text-align">
              <button type="button" onClick={() => props.history.push('/registration')}>
                CREATE YOUR FREE ACCOUNT HERE
              </button>
            </div>
          </div>
        </div>
        <footer>
          ©{copyrightYear} GoAgent |<Link to="/privacy-policy">Privacy Policy</Link>|{' '}
          <Link to="/terms-and-conditions">Terms & Conditions</Link>|<Link to="/faq">FAQ</Link>
        </footer>
      </div>
      <PublicFooter />
    </>
  );
};

export default ClearingLanding;
