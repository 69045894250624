import React from 'react';
import { getIn } from 'formik';
import TextField from '../../../UI/TextField';
import FIleField from '../../../UI/FIleField';
import { acceptedFileTypes } from '../../../../helpers/constants';
import DisplayDocument from '../../../global/DisplayDocument';

const FormFields = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    images,
    showDoc,
  } = props;

  return (
    <>
      <div className="col-sm-12 col-lg-12 mb-3">
        {images.governement_approval_document && (
          <DisplayDocument
            label="Government Approval Document"
            onClick={() => showDoc(images.governement_approval_document)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.governement_approval_document ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Government Approval Document
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="governement_approval_document"
            onChange={(event) =>
              setFieldValue('governement_approval_document', event.target.files[0])
            }
            className="uploads"
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        {images.business_owner_document && (
          <DisplayDocument
            label="Document showing business owners"
            onClick={() => showDoc(images.business_owner_document)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.business_owner_document ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Document showing business
            owners
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="business_owner_document"
            onChange={(event) => setFieldValue('business_owner_document', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">Corporate Address</label>
          <TextField
            id="corporate_address"
            type="text"
            name="corporate_address"
            className="form-control"
            placeholder="Corporate Address"
            value={values.corporate_address}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'corporate_address') && getIn(errors, 'corporate_address')}
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">Website</label>
          <TextField
            id="website_url"
            type="url"
            name="website_url"
            className="form-control"
            placeholder="Website"
            value={values.website_url}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'website_url') && getIn(errors, 'website_url')}
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">Email</label>
          <TextField
            id="email"
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'email') && getIn(errors, 'email')}
          />
        </div>
      </div>
    </>
  );
};

export default FormFields;
