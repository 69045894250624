import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DisplayDocument from './DisplayDocument';
import DisplayFields from './DirectorShareholderDocFields';
import { getRegYearType } from '../../helpers/utils';

const ShowClearingForwardingDocs = (props) => {
  const {
    images = {},
    showDoc,
    formValues,
    userType,
    details = {},
    shareholdersColClassName,
    showShareHolderSelect = false,
  } = props;
  const [shareholder, setShareholder] = useState(() => {
    if (details?.shareholders?.length > 0) {
      return details.shareholders[0].uuid;
    } else {
      return '';
    }
  });

  const { user } = useSelector((state) => state.user);
  const shareHolderColClassName = shareholdersColClassName || 'col-lg-6 col-sm-12 nopadding-sm';

  let regCertDocLabel = '';
  let regCertTextLabel = '';
  if (userType === 'clearing_agent') {
    regCertDocLabel =
      'Certificate of registration with Nigeria Customs Service as a clearing agent (Document)';
    regCertTextLabel =
      'Certificate of registration with Nigeria Customs Service as a clearing agent (Certificate number)';
  }
  // else {
  //   regCertDocLabel =
  //     'Certificate of registration with Council for the Regulation of Freight Transport in Nigeria (CRFFN) (Document)';
  //   regCertTextLabel =
  //     'Certificate of registration with Council for the Regulation of Freight Transport in Nigeria (CRFFN) (Certificate number)';
  // }
  const regYear = getRegYearType(images);

  return (
    <div className="row mx-0">
      {/* {regYear === 1 && (
        <>
          <DisplayDocument
            label="Status Report (Application for Registration)"
            onClick={() => showDoc(images.registration_application_status)}
            type="file"
            fileColClassName="document"
            documentLabel="Document"
            hasDocument={images.registration_application_status ? true : false}
          />
        </>
      )}
      {regYear === 2 && (
        <>
          <DisplayDocument
            label="CTC of Form CAC 1.1 (Application for Registration)"
            onClick={() => showDoc(images.cac_it_1)}
            type="file"
            fileColClassName="document"
            documentLabel="Document"
            hasDocument={images.cac_it_1 ? true : false}
          />
        </>
      )}
      {regYear === 3 && (
        <>
          <DisplayDocument
            label="Form CAC 2 (Statement of Share Capital and Return of Allotment)"
            onClick={() => showDoc(images.cac_2)}
            type="file"
            fileColClassName="document"
            documentLabel="Document"
            hasDocument={images.cac_2 ? true : false}
          />
          <DisplayDocument
            label="Form CAC 7 (Particulars of Directors)"
            onClick={() => showDoc(images.cac_7)}
            type="file"
            fileColClassName="document"
            documentLabel="Document"
            hasDocument={images.cac_7 ? true : false}
          />
        </>
      )}
      <DisplayDocument
        label="Certificate of incorporation"
        onClick={() => showDoc(images.incorporation_certificate)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={images.incorporation_certificate ? true : false}
      />
      <DisplayDocument
        label="Memorandum and Articles of Association"
        onClick={() => showDoc(images.memorandum_article_association)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={images.memorandum_article_association ? true : false}
      />
      <DisplayDocument
        label="Proof of corporate address (Utility bills or Tax payment receipt)"
        onClick={() => showDoc(images.corporate_address_proof)}
        type="file"
        fileColClassName="document"
        documentLabel="Document"
        hasDocument={images.corporate_address_proof ? true : false}
      /> */}

      {userType === 'clearing_agent' && (
        <>
          <DisplayDocument
            label={regCertDocLabel}
            onClick={() => showDoc(images.registration_certificate)}
            type="file"
            fileColClassName="document"
            documentLabel="Document"
            hasDocument={images.registration_certificate ? true : false}
          />
          <DisplayDocument
            label={regCertTextLabel}
            type="text"
            textColClassName="document"
            value={formValues.registration_certificate_no}
          />
          {/* <DisplayDocument
            label="Port of Operation"
            type="text"
            textColClassName="document"
            value={formValues.port_of_operation}
          /> */}
        </>
      )}
      {/* RC Number needs to be Displayed to Agents */}
      <DisplayDocument
        label="RC NO"
        type="text"
        textColClassName="document"
        value={formValues.rc_no}
      />
      <>
        <h3 className="mt-3">Director Details</h3>
        {details?.directors?.length > 0 ? (
          details.directors.map((director) => (
            <DisplayFields
              type="directors"
              values={director}
              key={director.uuid}
              showDoc={showDoc}
            />
          ))
        ) : user.type === 'clearing_agent' || user.type === 'forwarding_agent' ? (
          <p>Director not available. Please add Director</p>
        ) : null}
      </>
      {/* <>
        {showShareHolderSelect ? (
          <div className="row mt-3 mx-3">
            <div className="col-lg-9 col-sm-12">
              <h3 style={{ textAlign: 'right', paddingRight: 50 }}>Shareholders Details</h3>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="form-group">
                <select
                  className="form-controls"
                  id="director"
                  style={{ width: '100%' }}
                  value={shareholder}
                  onChange={(e) => setShareholder(e.target.value)}
                >
                  {details?.shareholders?.length > 0 &&
                    details.shareholders.map((obj, i) => (
                      <option value={obj.uuid} key={obj.uuid}>
                        {obj.first_name} {obj.last_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        ) : (
          <h3 style={{ paddingTop: 10 }}>Shareholders Details</h3>
        )}
        {details?.shareholders?.length > 0 ? (
          <>
            {showShareHolderSelect ? (
              (() => {
                const shareHolder = details?.shareholders.find((s) => s.uuid === shareholder);
                if (shareHolder) {
                  return (
                    <DisplayFields
                      type="shareholders"
                      values={shareHolder}
                      key={shareHolder.uuid}
                      showDoc={showDoc}
                    />
                  );
                }
              })()
            ) : (
              <>
                {details.shareholders.map((shareholder, index) => (
                  <div className={shareHolderColClassName} key={shareholder.uuid}>
                    <h4>Shareholder {index + 1}</h4>
                    <DisplayFields
                      type="shareholders"
                      values={shareholder}
                      key={shareholder.uuid}
                      showDoc={showDoc}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        ) : user.type === 'clearing_agent' || user.type === 'forwarding_agent' ? (
          <p>Shareholders not available. Please add Shareholders</p>
        ) : null}
      </> */}
    </div>
  );
};

export default ShowClearingForwardingDocs;
