import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import UpdatePassword from '../components/global/UpdatePassword';
import UserHeader from '../components/global/UserHeader';
import AppLoader from '../components/loaders/AppLoader';
// import MessageList from '../containers/ClearingAgent/Messages/MessageList';
import EditProfile from '../containers/Lawyer/Profile/EditProfile';
import Profile from '../containers/Lawyer/Profile/Profile';
import ClientApprovalDetails from '../containers/Lawyer/ClientApproval/ClientApprovalDetails';
import ClientApprovalList from '../containers/Lawyer/ClientApproval/ClientApprovalList';
import DisputesDetails from '../components/global/DisputesDetails';
import DisputesList from '../components/global/DisputesList';
import { ValidateRoutes } from './ClientRoute';
import Notification from '../components/global/Notification';
import NotificationMenuItem from '../components/global/NotificationBadge';
import { NT_L_DISPUTES, NT_L_PROFILE, NT_L_APPROVAL } from '../helpers/constants';
import { hideDocumentModal } from '../redux/user/UserActions';
import DocumentModal from '../components/Modals/DocumentModal';
import UserList from '../containers/Lawyer/UserList';
import { readNotification } from '../redux/notification/NotificationActions';
import UserDetails from '../containers/Lawyer/UserDetails/Details';
import ApprovedUsers from '../containers/Lawyer/ApprovedUsers';

const LawyerRoute = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userStoreState = useSelector((state) => state.user);
  const { user } = userStoreState;

  const [siderMemuToggle, setSiderMemuToggle] = useState(false);

  const { match } = props;
  const { pathname } = props.location;
  if (isEmpty(user)) {
    return <AppLoader />;
  }

  const onMenuClick = (path, notificationTypes) => {
    history.push(path);
    setSiderMemuToggle(false);

    // mark the notifications of the menu as read
    if (notificationTypes) {
      dispatch(readNotification({ notificationTypes }));
    }
  };

  return (
    <div id="lawear-page">
      <Helmet>
        <title>GoAgent</title>
      </Helmet>
      <UserHeader />
      <main id="main" className="main-block">
        <div className="bodycont">
          <div style={{ clear: 'both' }}></div>

          <div className="tab">
            <div className="cormen-btn">
              <label htmlFor="cormid"></label>
            </div>
            <div className="cormen">
              <input
                id="cormid"
                type="checkbox"
                checked={siderMemuToggle}
                onChange={(e) => setSiderMemuToggle(e.target.checked)}
              />
              <ul>
                <button
                  className={`tablinks ${
                    pathname.includes('/lawyer/dashboard') || pathname.includes('/lawyer/disputes')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => onMenuClick('/lawyer/dashboard', NT_L_DISPUTES)}
                >
                  <NotificationMenuItem title="Disputes" types={NT_L_DISPUTES} />
                </button>{' '}
                <button
                  className={`tablinks ${pathname.includes('/client-approval') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/lawyer/client-approval-list', NT_L_APPROVAL)}
                >
                  <NotificationMenuItem title="Client Approval" types={NT_L_APPROVAL} />
                </button>
                <button
                  className={`tablinks ${
                    pathname.includes('/clearingagent-approval') ? 'active' : ''
                  }`}
                  onClick={() => onMenuClick('/lawyer/clearingagent-approval-list', NT_L_APPROVAL)}
                >
                  <NotificationMenuItem title="Agent Approval (C)" types={NT_L_APPROVAL} />
                </button>
                <button
                  className={`tablinks ${
                    pathname.includes('/fowardingagent-approval') ? 'active' : ''
                  }`}
                  onClick={() => onMenuClick('/lawyer/fowardingagent-approval-list', NT_L_APPROVAL)}
                >
                  <NotificationMenuItem title="Agent Approval (T)" types={NT_L_APPROVAL} />
                </button>
                {/* <button className={`tablinks ${pathname.includes('/messages') ? 'active' : ''}`} onClick={() => onMenuClick('/lawyer/messages')}>
                  My Message
                </button> */}
                <button
                  className={`tablinks ${pathname.endsWith('/users') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/lawyer/users')}
                >
                  Users
                </button>
                <button
                  className={`tablinks ${pathname.includes('/approved') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/lawyer/users/approved')}
                >
                  Approved Users
                </button>
                <button
                  className={`tablinks ${pathname.includes('/notifications') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/lawyer/notifications')}
                >
                  My Notification
                </button>
                <button
                  className={`tablinks ${
                    pathname.includes('/lawyer/profile') ||
                    pathname.includes('/lawyer/edit-profile')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => onMenuClick('/lawyer/profile', NT_L_PROFILE)}
                >
                  <NotificationMenuItem title="Profile" types={NT_L_PROFILE} />
                </button>
              </ul>
            </div>
          </div>

          <Switch>
            <ValidateRoutes
              exact
              path={`${match.url}/dashboard`}
              component={DisputesList}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/dispute/details`}
              component={DisputesDetails}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />

            <ValidateRoutes
              exact
              path={`${match.url}/profile`}
              component={Profile}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/profile/update-password`}
              component={UpdatePassword}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />

            <ValidateRoutes
              exact
              path={`${match.url}/edit-profile`}
              component={EditProfile}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            {/* <ValidateRoutes exact path={`${match.url}/messages`} component={MessageList} has_permission={get(user, 'type') === 'legal_lawer'} /> */}
            <ValidateRoutes
              exact
              path={`${match.url}/clearingagent-approval-list`}
              component={ClientApprovalList}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/clearingagent-approval/details`}
              component={ClientApprovalDetails}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/fowardingagent-approval-list`}
              component={ClientApprovalList}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/fowardingagent-approval/details`}
              component={ClientApprovalDetails}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/client-approval-list`}
              component={ClientApprovalList}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/client-approval/details`}
              component={ClientApprovalDetails}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/notifications`}
              component={Notification}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/users`}
              component={UserList}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />

            <ValidateRoutes
              exact
              path={`${match.url}/user/details/:id`}
              component={UserDetails}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />
            <ValidateRoutes
              exact
              path={`${match.url}/users/approved`}
              component={ApprovedUsers}
              has_permission={get(user, 'type') === 'legal_lawer'}
            />

            <Redirect from={match.url} to={`${match.url}/dashboard`} />
          </Switch>
          <div className="clearfix"></div>
        </div>
        {/* Modal to Display Document */}
        {userStoreState?.displayDocumentModal && (
          <DocumentModal
            isOpen={userStoreState.displayDocumentModal}
            toggle={() => dispatch(hideDocumentModal())}
            documentToShow={userStoreState.documentToDisplay}
            container="lawear-page"
          />
        )}
      </main>
    </div>
  );
};

export default LawyerRoute;
