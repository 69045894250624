import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, ErrorMessage, Formik } from 'formik';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FIleField from '../../UI/FIleField';
import { acceptedFileTypes } from '../../../helpers/constants';
import DisplayDocument from '../DisplayDocument';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { showDocumentModal } from '../../../redux/user/UserActions';
import convertJsonToFormData from '../../../helpers/helper';

const validationSchema = Yup.object().shape({
  portsOfOperation: Yup.array()
    .of(
      Yup.object().shape({
        port_of_operation: Yup.string().required('Port is required'),
        customs_license: Yup.string().required('Please add Customs License'),
      }),
    )
    .uniqueProperty('port_of_operation', 'Port is Already Selected'),
});

const AddPortsModal = ({ isOpen, toggle, agentPorts, onPortAddSuccess }) => {
  const serviceData = useSelector((state) => state.enumData);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const showDoc = useCallback((docc) => dispatch(showDocumentModal(docc)), [dispatch]);

  // filter already added ports from the ports list
  const portOptions = serviceData?.ports?.filter((p) => {
    if (agentPorts?.length > 0 && agentPorts.find((ap) => ap.port_uuid === p.uuid)) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      container="tabblock1"
      className="modal-lg document-verifying-msg"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalHeader className="p-0">
        <h3>Add Port of Operations</h3>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            portsOfOperation: [{ port_of_operation: '', customs_license: '', id: uuidv4() }],
          }}
          enableReinitialize
          onSubmit={(values) => {
            // console.log('>> values', values);

            const payload = {
              agent_uuid: user.uuid,
              port: values.portsOfOperation.map((p) => ({
                port_uuid: p.port_of_operation,
                document_name: p.port_of_operation,
                document_value: p.customs_license,
                document_type: 'file',
              })),
            };
            const formData = convertJsonToFormData(payload);

            dispatch(showLoading());
            POST('/ports/addAgentOperationalPort', formData)
              .then(() => {
                dispatch(showModal('Port of Operations Updated successfully', true));
                dispatch(hideLoading());
                onPortAddSuccess();
              })
              .catch((err) => {
                dispatch(showModal(err.message));
                dispatch(hideLoading());
              });
          }}
          validationSchema={validationSchema}
        >
          {(renderProps) => {
            const { values, handleChange, handleSubmit, setFieldValue } = renderProps;

            return (
              <form className={'row mx-2'} onSubmit={handleSubmit}>
                <div className="complian-block mb-5 w-100">
                  <div className="profilecont p-3">
                    <FieldArray
                      name="portsOfOperation"
                      render={(arrayHelpers) => (
                        <>
                          {values.portsOfOperation?.length > 0 &&
                            values.portsOfOperation.map((port, index) => (
                              <div className="row mb-3" key={port.id}>
                                <div className="col-sm-5">
                                  <select
                                    className="select-controls"
                                    id={`portsOfOperation.${index}.port_of_operation`}
                                    name={`portsOfOperation.${index}.port_of_operation`}
                                    value={port.port_of_operation}
                                    onChange={handleChange}
                                  >
                                    <option value="" key="poo">
                                      Select Port of Operation
                                    </option>
                                    {portOptions?.length > 0 &&
                                      portOptions.map((obj) => (
                                        <option value={obj.uuid} key={obj.uuid}>
                                          {capitalize(obj.port_of_operation)}
                                        </option>
                                      ))}
                                  </select>
                                  <p className="text-danger">
                                    <ErrorMessage
                                      name={`portsOfOperation.${index}.port_of_operation`}
                                    />
                                  </p>
                                </div>
                                <div className="col-sm-5">
                                  {port.customs_license &&
                                    typeof port.customs_license == 'string' && (
                                      <DisplayDocument
                                        label={'Customs License'}
                                        onClick={() => showDoc(port.customs_license)}
                                        type="file"
                                        fileColClassName="form-document"
                                        nolabel={true}
                                      />
                                    )}

                                  <div
                                    className="fileUploads"
                                    style={{
                                      background:
                                        typeof port.customs_license == 'object' ? '#01a8af' : '',
                                    }}
                                  >
                                    <span>
                                      <i className="fa fa-file-text-o" aria-hidden="true"></i>{' '}
                                      Customs License
                                    </span>
                                    <FIleField
                                      type="file"
                                      accept={acceptedFileTypes}
                                      className="uploads"
                                      name={`portsOfOperation.${index}.customs_license`}
                                      onChange={(event) =>
                                        setFieldValue(
                                          `portsOfOperation.${index}.customs_license`,
                                          event.target.files[0],
                                        )
                                      }
                                    />
                                  </div>
                                  <p className="text-danger">
                                    <ErrorMessage
                                      name={`portsOfOperation.${index}.customs_license`}
                                    />
                                  </p>
                                </div>

                                {values.portsOfOperation.length > 1 && (
                                  <div className="col-lg-1 col-md-1 col-xs-2 col-sm-2">
                                    <button
                                      type="button"
                                      className="btn-red mt-4"
                                      onClick={arrayHelpers.pop}
                                    >
                                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                )}

                                {values.portsOfOperation.length !== 0 &&
                                  values.portsOfOperation.length - 1 === index && (
                                    <div className="col-lg-1 col-md-1 col-xs-2 col-sm-2">
                                      <button
                                        type="button"
                                        className="btn-green mt-4"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            port_of_operation: '',
                                            customs_license: '',
                                            id: uuidv4(),
                                          })
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  )}
                              </div>
                            ))}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="buttonsec">
                  <button type="button" className="submitbut btn-light" onClick={toggle}>
                    Cancel
                  </button>
                  <button type="submit" className="submitbut">
                    Submit
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddPortsModal;
