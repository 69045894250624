import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../redux/error/ErrorAction';
import { POST, GET } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { isEmpty, capitalize } from 'lodash';
import AppLoader from '../loaders/AppLoader';
import swal from 'sweetalert';
import Chat from '../UI/Chat';
import DisplayDocument from './DisplayDocument';
import { showDocumentModal } from '../../redux/user/UserActions';
import RejectModal from '../Modals/RejectModal';
import { getDisputeStatusClassname, getName } from '../../helpers/utils';
import { LeftArrowSvg } from '../UI/CommanUI';
import { useChat } from '../../helpers/customHooks';
import DisputeModal from '../../components/Modals/DisputeModal';

const UserDetails = ({ details }) => {
  return (
    <div>
      <p>Name: {getName(details)}</p>
      <p>Email: {details.email}</p>
      <p>Mobile: {details.mobile_number}</p>
    </div>
  );
};

const DisputesDetails = (props) => {
  const [details, setDetails] = useState({});
  const [chatbox, setChatbox] = useState(false);
  const [chatlist, setChatlist] = useState([]);
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();
  const params = new URLSearchParams(props.location.search).get('id');
  const bid_id = params;
  const serviceData = useSelector((state) => state.enumData);
  const [rejectModal, setRejectModal] = useState(false);
  const toggleRejectModal = () => setRejectModal((s) => !s);
  const [unreadCount, setUnreadCount] = useState(0);

  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  const { user } = useSelector((state) => state.user);

  const [disputeModal, setDisputeModal] = useState(false);
  const toggleDisputeModal = () => setDisputeModal((s) => !s);

  // console.log(user);
  const isClearingJob =
    details?.job?.job_type_tags?.length > 0 &&
    details?.job?.job_type_tags.find((jt) => jt === 'clearing');

  const insuranceIsPurchased = details?.job?.insurance_purchashed_on;

  useEffect(() => {
    getDisputesDetails();
  }, []);
  const getDisputesDetails = () => {
    // console.log('type', type);
    dispatch(showLoading());
    POST(`/jobs/getDispute`, { dispute_uuid: bid_id })
      .then(({ data }) => {
        //console.log('data', data);

        setDetails(data.dispute);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  const hanndleApprove = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Approve?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        const payload = {
          dispute_uuid: bid_id,
        };
        POST(`/jobs/disputeApproved`, payload)
          .then(({ data }) => {
            let msg = '';
            if (!isClearingJob && insuranceIsPurchased) {
              msg = 'Insurance Indemnity';
            } else {
              msg = 'Dispute';
            }
            msg += ' Approved Sucessfully!!';

            dispatch(showModal(msg, true));
            dispatch(hideLoading());
            getDisputesDetails();
            // history.push('/admin/users');
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
            // history.goBack();
          });
      }
    });
  };

  //reject dispute
  const rejectDispute = (values) => {
    dispatch(showLoading());
    const payloadR = {
      dispute_uuid: bid_id,
      rejection_comment: values.comment,
    };
    POST(`/jobs/disputeRejected`, payloadR)
      .then(({ data }) => {
        let msg = '';
        if (!isClearingJob && insuranceIsPurchased) {
          msg = 'Insurance Indemnity';
        } else {
          msg = 'Dispute';
        }
        msg += ' Rejected Sucessfully!!';

        dispatch(showModal(msg, true));
        getDisputesDetails();
        toggleRejectModal();
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  const hanndleReject = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Reject?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        toggleRejectModal();
      }
    });
  };
  //reject user
  const hanndleResolved = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Resolved?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        const payloadR = {
          dispute_uuid: bid_id,
        };
        POST(`/jobs/disputeResolved`, payloadR)
          .then(({ data }) => {
            let msg = '';
            if (!isClearingJob && insuranceIsPurchased) {
              msg = 'Insurance Indemnity';
            } else {
              msg = 'Dispute';
            }
            msg += ' Resolved Sucessfully!!';

            dispatch(showModal(msg, true));
            dispatch(hideLoading());
            getDisputesDetails();

            // history.push('/admin/users');
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
            // history.goBack();
          });
      }
    });
  };

  // initialize and subcsribe to chat here
  const handleChatEvent = (data1) => {
    if (data1.chat_type) {
      if (!chatbox) {
        // set unread count when chat is not openend
        setUnreadCount((prevState) => prevState + 1);
      }

      setChatlist((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById('chat-top');
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    dispatch(showModal('you have a new message', true));
  };
  useChat(user.uuid, chatbox, handleChatEvent);

  // chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${details.job.uuid}`)
      .then(({ data }) => {
        // console.log(data);
        const ac = data.chats.admin?.length > 0 ? data.chats.admin.reverse() : [];
        setChatlist(ac);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const chatToggle = () => {
    if (!chatbox) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount(0);
    }
    setChatbox(!chatbox);
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }
    const payLoad = {
      job_uuid: details.job.uuid,
      sender_uuid: user.uuid,
      receiver_uuid: '083f14fd-ef64-4ae5-a099-fa2a492d7f43',
      chat_type:
        user.type === 'application_client'
          ? 'client'
          : user.type === 'clearing_agent'
          ? 'clearing'
          : user.type === 'forwarding_agent'
          ? 'forwarding'
          : '',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        // console.log(data);
        setChatlist((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
        getChat();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
    const objDiv = document.getElementById('chat-top');
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
      const objDiv = document.getElementById('chat-top');
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };

  // handle delete dispute
  const handleDeleteDispute = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Delete Dispute?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        const payload = {
          dispute_uuid: details.uuid,
        };
        POST('/jobs/deleteDispute', payload)
          .then(() => {
            dispatch(showModal('Dispute Deleted Sucessfully!', true));
            dispatch(hideLoading());
            props.history.goBack();
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
          });
      }
    });
  };

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  let pageHeading = '';
  if (!isClearingJob && insuranceIsPurchased) {
    pageHeading = 'Insurance Indemnity';
  } else {
    pageHeading = 'Dispute';
  }
  pageHeading += ' Details';

  const completedJob =
    details?.job?.clearing_status === '8' || details?.job?.forwarding_status === '8';
  const disputeBeingReviewed = details?.status === 1;
  const showDisputeEdit = disputeBeingReviewed && completedJob;
  const showDeleteDispute = disputeBeingReviewed;

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div>
            <div>
              <h2>
                <span
                  onClick={() => props.history.goBack()}
                  style={{ cursor: 'pointer', float: 'left' }}
                >
                  <LeftArrowSvg />
                </span>
                {pageHeading}
              </h2>
            </div>
            <div className="operator-info">
              <div className="disputes-block">
                <div className="row">
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <h5>
                      {(details.job &&
                        capitalize(serviceData.job.category[details.job.category])) ||
                        'N/A'}
                    </h5>
                    <h6>
                      {details.job && details.job.job_id}-
                      {details.job && details.job.is_forwarding_only ? 'T' : 'C'}
                    </h6>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12  text-right-lg">
                    <h4>
                      {details.job && details.job.is_forwarding_only
                        ? 'Transport Service'
                        : 'Clearing Service'}
                    </h4>
                  </div>
                </div>
                <p>Description: {details && details.message}</p>
                <p>
                  Status:{' '}
                  <span className={getDisputeStatusClassname(details.status)}>
                    {details && details.status && serviceData.dispute_status[details.status]}
                  </span>
                </p>
              </div>
              <div>
                <h6>Evidence</h6>
                {details?.files?.length > 0 &&
                  details.files.map((obj, i) => (
                    <DisplayDocument
                      label={`Document: ${i + 1}`}
                      onClick={() => showDoc(obj)}
                      type="file"
                      fileColClassName="disputes-img"
                    />
                  ))}

                <div className="clearfix"></div>
              </div>

              {user.type === 'legal_lawer' && (
                <>
                  <hr />
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <p className="fs-3 text-left font-bold">Client</p>
                      {details?.client && <UserDetails details={details.client} />}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <p className=" fs-3 text-left font-bold">Agent</p>
                      {details?.agent && <UserDetails details={details.agent} />}
                    </div>
                  </div>
                </>
              )}
              {details.rejection_comment && (
                <div>
                  <p className="alert alert-danger">
                    Reason for Rejection: <strong>{details.rejection_comment}</strong>
                  </p>
                </div>
              )}
              {user.type === 'legal_lawer' && (
                <div className="buttonsec1">
                  {details?.status !== 6 && (
                    <a
                      onClick={hanndleReject}
                      className="submitbut"
                      style={{ backgroundColor: '#b36c6c' }}
                    >
                      Reject
                    </a>
                  )}
                  {details?.status === 2 && (
                    <a
                      onClick={hanndleApprove}
                      className="submitbut"
                      style={{ backgroundColor: '#007679' }}
                    >
                      Approve
                    </a>
                  )}
                  {details?.status === 4 && (
                    <a onClick={hanndleResolved} className="submitbut">
                      {insuranceIsPurchased ? 'Resolve & Send to Insurance' : 'Resolve'}
                    </a>
                  )}
                </div>
              )}
              {user.type === 'managenmnt_operator' && (
                <div className="buttonsec1">
                  {details?.status === 1 && (
                    <a
                      onClick={hanndleReject}
                      className="submitbut"
                      style={{ backgroundColor: '#b36c6c' }}
                    >
                      Reject
                    </a>
                  )}
                  {details?.status === 1 && (
                    <a
                      onClick={hanndleApprove}
                      className="submitbut"
                      style={{ backgroundColor: '#007679' }}
                    >
                      Approve
                    </a>
                  )}
                </div>
              )}
            </div>
            <div className="buttonsec text-center">
              <a onClick={() => props.history.goBack()} className="submitbut btn-light">
                Back
              </a>
              {user.type === 'application_client' && (
                <>
                  {showDisputeEdit && (
                    <a onClick={toggleDisputeModal} className="submitbut">
                      {details?.job?.insurance_purchashed_on
                        ? 'Edit Insurance Indemnity'
                        : 'Edit Dispute'}
                    </a>
                  )}
                  {showDeleteDispute && (
                    <a onClick={handleDeleteDispute} className="submitbut delete-btn">
                      {details?.job?.insurance_purchashed_on
                        ? 'Delete Insurance Indemnity '
                        : 'Delete Dispute '}
                      <i className="fa fa-trash"></i>
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(user.type === 'application_client' ||
        user.type === 'clearing_agent' ||
        user.type === 'forwarding_agent') && (
        <Chat
          name="Us"
          id="us"
          chatbox={chatbox}
          user={user}
          chatToggle={chatToggle}
          chatlist={chatlist}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
          unreadMsgsCount={unreadCount}
        />
      )}
      {/* Reject Modal */}
      <RejectModal
        onSubmit={rejectDispute}
        isOpen={rejectModal}
        toggle={toggleRejectModal}
        container={user.type !== 'legal_lawer' ? 'client-page' : 'lawear-page'}
      />
      {disputeModal && (
        <DisputeModal
          disputeModal={disputeModal}
          toggleDisputeModal={toggleDisputeModal}
          jobDetails={details?.job}
          disputeDetails={details}
          container="client-page"
          refreshData={getDisputesDetails}
        />
      )}
    </>
  );
};

export default DisputesDetails;
