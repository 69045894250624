import AOS from 'aos';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Router from './Router';
import AppLoader from './app/components/loaders/AppLoader';
import ErrorSucessModal from './app/components/Modals/ErrorSucessModal';
import { addEnumData, addPortsData } from './app/redux/enumData/EnumActions';
import { hideModal } from './app/redux/error/ErrorAction';
import { getLoggedInUser, getAgentPortsOfOperation } from './app/redux/user/UserActions';
// import VoipC2CWidget from './app/components/global/VoipC2CWidget';
import FeedbackWidget from './app/components/global/FeedbackWidget';
import {
  refreshToken,
  logoutAfterInactivity,
  trackMouseAndKeyboardActivity,
} from './app/services/refreshToken';
// import { isProd } from './app/helpers/utils';
import {
  USER_ROLE_CLEARING_AGENT,
  USER_ROLE_LAWYER,
  USER_ROLE_OPERATOR,
  USER_ROLE_SUPERVISOR,
  USER_ROLE_TRUCK_INSPECTOR,
} from './app/helpers/constants';

const App = (props) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loading);
  const modal = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(addEnumData()); // fetch the global vars / global data
    dispatch(addPortsData()); // fetch the ports data

    // dispatch(getExchangeData());
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
      once: true,
      mirror: false,
    });
    if (sessionStorage.getItem('_GATKEN')) {
      // refresh immidiately & initialize token refresh interval
      const getUser = () => dispatch(getLoggedInUser(props.history));
      refreshToken(true, getUser);
      trackMouseAndKeyboardActivity();
    }
    // check for inactivity and logout if inactive
    logoutAfterInactivity();
  }, []);

  // Fetching Agent's Ports of operation for clearing agent
  // which is used to filter jobs when placing bids
  useEffect(() => {
    if (user?.type === USER_ROLE_CLEARING_AGENT) {
      dispatch(getAgentPortsOfOperation(user.uuid));
    }
  }, [user, dispatch]);

  const handleToggle = () => {
    dispatch(hideModal());
  };
  const { match } = props;

  const showFeedbacAndCallkWidget =
    !isLoggedIn ||
    (user.type !== USER_ROLE_OPERATOR &&
      user.type !== USER_ROLE_LAWYER &&
      user.type !== USER_ROLE_SUPERVISOR &&
      user.type !== USER_ROLE_TRUCK_INSPECTOR);

  return (
    <>
      {modal.isshow && <ErrorSucessModal onToggle={handleToggle} {...modal} />}
      {loader.isLoading && <AppLoader title={loader.title} />}
      <Router match={match} />
      {showFeedbacAndCallkWidget && <FeedbackWidget />}
      {/* VoipC2CWidget is Hidden in dev mode remove isProd check to display */}

      {/* THIS IS COMMENTED FOR THE TIME BEING  */}
      {/* {showFeedbacAndCallkWidget && isProd() && <VoipC2CWidget />} */}
    </>
  );
};

export default withRouter(App);
