import React from 'react';
import UpdateProfile from '../../../components/global/UpdateProfle';

const EditProfile = (props) => {
  return (
    <>
      {/* <div id="tabblock1" className="tabcontent"> */}
      <UpdateProfile />
      {/* </div> */}
    </>
  );
};

export default EditProfile;
