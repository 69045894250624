import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { isEmpty } from 'lodash';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import { extractUserDocs } from '../../../helpers/utils';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import { showDocumentModal } from '../../../redux/user/UserActions';
import COAgentDocumentUploadModal from '../../../components/Modals/COAgentDocUploadModal';
import COClientDocumentUploadModal from '../../../components/Modals/COClientDocUploadModal';
import {
  USER_ROLE_APPLICATION_CLIENT,
  USER_ROLE_CLEARING_AGENT,
  USER_ROLE_FORWARDING_AGENT,
} from '../../../helpers/constants';
import AgentsDetails from '../UserDetails/AgentsDetails';
import ImportersDetails from '../UserDetails/ImportersDetails';
import AgentPortOfOperations from '../UserDetails/AgentPortOfOperations';

const ClientApprovalDetails = (props) => {
  const { history, match } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const userId = new URLSearchParams(props.location.search).get('id');
  const [userDetails, setUserDetails] = useState({});
  const [details, setDetails] = useState({});
  const [status, setStatus] = useState(0);
  const [images, setImages] = useState({});
  const [formValues, setFormValues] = useState({});
  const [showDocUploadModal, setShowDocUploadModal] = useState(false);

  const toggleDocUploadModal = () => setShowDocUploadModal((s) => !s);

  const path_name = match.path;
  let user_type = 'application_client';
  if (path_name == '/lawyer/fowardingagent-approval/details') {
    user_type = 'forwarding_agent';
  } else if (path_name == '/lawyer/clearingagent-approval/details') {
    user_type = 'clearing_agent';
  } else if (path_name == '/lawyer/client-approval/details') {
    user_type = 'application_client';
  }

  const getUserDetails = () => {
    dispatch(showLoading());

    GET(`/users/getDetails/${userId}`)
      .then(({ data }) => {
        // console.log(data);
        setUserDetails(data.user);
        setDetails(data);
        if (data.user.type == 'clearing_agent' || data.user.type == 'forwarding_agent') {
          if (!isEmpty(data.documents)) {
            const item = extractUserDocs(data);

            setImages({
              incorporation_certificate: item.incorporation_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,
              registration_certificate: item.registration_certificate,
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
            });
            const modifiedItem = {
              ...item,
              incorporation_certificate: null,
              memorandum_article_association: null,
              corporate_address_proof: null,
              registration_certificate: null,
              registration_application_status: null,
              ctc_cac_1_1: null,
              cac_2: null,
              cac_7: null,
            };
            // console.log(modifiedItem);
            setFormValues(modifiedItem);
          } else {
            setFormValues({});
          }
        } else {
          const item = extractUserDocs(data);

          if (!isEmpty(item)) {
            setImages({
              // incorporated company
              // rc_no: item.rc_no, // this is text document
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
              incorporation_certificate: item.incorporation_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,

              // Incorporated trustee
              cac_it_no: item.cac_it_no,
              cac_it_1: item.cac_it_1,
              // rc_no: item.rc_no, // already present above
              // corporate_address_proof: item.corporate_address_proof, // already present above

              // Free zone entity
              // nepza_no: item.nepza_no,  // this is text document
              nepza_operating_license: item.nepza_operating_license,
              ctc_situation_notice: item.ctc_situation_notice,
              director_particulars: item.director_particulars,
              nepza_registration_certificate: item.nepza_registration_certificate,
              share_capital_statement: item.share_capital_statement,
              return_allotement_statement: item.return_allotement_statement,
              // memorandum_article_association: item.memorandum_article_association, // already present above

              // Government Entity & Private entity (share same text fields) // already present above
              // corporate_address: item.corporate_address,  // this is text document
              // website_url: item.website_url, // this is text document
              // email: item.email, // this is text document

              // Government Entity
              official_gazetted_establishment_law: item.official_gazetted_establishment_law,

              // Private entity
              governement_approval_document: item.governement_approval_document,
              business_owner_document: item.business_owner_document,
            });
            const modifiedItem = {
              ...item,
              national_identity_card: null,
              rc_no: item.rc_no,
              registration_application_status: null,
              ctc_cac_1_1: null,
              cac_2: null,
              cac_7: null,
              incorporation_certificate: null,
              memorandum_article_association: null,
              corporate_address_proof: null,

              // Incorporated trustee
              cac_it_no: null,
              cac_it_1: null,
              // rc_no: null, // already present above
              // corporate_address_proof: null, // already present above

              // Free zone entity
              nepza_no: item.nepza_no,
              nepza_operating_license: null,
              ctc_situation_notice: null,
              director_particulars: null,
              nepza_registration_certificate: null,
              share_capital_statement: null,
              return_allotement_statement: null,
              // memorandum_article_association: null, // already present above

              // Government Entity & Private entity (share same text fields) // already present above
              corporate_address: item.corporate_address,
              website_url: item.website_url,
              email: item.email,

              // Government Entity
              official_gazetted_establishment_law: null,

              // Private entity
              governement_approval_document: null,
              business_owner_document: null,
            };
            // console.log(modifiedItem);
            setFormValues(modifiedItem);
          } else {
            setFormValues({});
          }
        }
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());

        setUserDetails({});
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  //approve user
  const hanndleApprove = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Approve?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        const payload = {
          user_uuid: userDetails.uuid,
        };
        POST(`/users/approveDocuments`, payload)
          .then(({ data }) => {
            dispatch(showModal('User Approve Sucessfull!!', true));
            dispatch(hideLoading());
            setStatus(1);

            // history.push('/admin/users');
            history.goBack();
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
            // history.goBack();
          });
      }
    });
  };

  //reject user
  const hanndleReject = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Reject?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        const payloadR = {
          user_uuid: userDetails.uuid,
        };
        POST(`/users/rejectDocuments`, payloadR)
          .then(({ data }) => {
            dispatch(showModal('User Rejected Sucessfull!!', true));
            dispatch(hideLoading());
            setStatus(2);

            history.goBack();
            // history.push('/admin/users');
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
            // history.goBack();
          });
      }
    });
  };

  let heading = '';
  if (user_type == 'clearing_agent') {
    heading = 'Clearing Agent Approval';
  } else if (user_type == 'forwarding_agent') {
    heading = 'Transporter Approval';
  } else if (user_type == 'application_client') {
    heading = 'Client Approval';
  }

  const showDoc = (docc) => dispatch(showDocumentModal(docc));
  const clientType = userDetails.sub_type || userDetails.type;

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>
              <span
                onClick={() => props.history.goBack()}
                style={{ cursor: 'pointer', float: 'left' }}
              >
                <LeftArrowSvg />
              </span>
              {heading}
            </h2>
            <div className="personal-info p-0">
              <>
                {user_type == 'clearing_agent' || user_type == 'forwarding_agent' ? (
                  <AgentsDetails
                    userDetails={userDetails}
                    images={images}
                    formValues={formValues}
                    details={details}
                    showDoc={showDoc}
                  />
                ) : (
                  <ImportersDetails
                    userDetails={userDetails}
                    images={images}
                    formValues={formValues}
                    user={user}
                    showDoc={showDoc}
                    clientType={clientType}
                  />
                )}
              </>
              {userDetails.type === 'clearing_agent' && (
                <>
                  <AgentPortOfOperations userId={userId} />
                  <hr />
                </>
              )}
              <div className="buttonsec">
                {(status == 0 || status == 2) && (
                  <a
                    onClick={hanndleReject}
                    className={`submitbut btn-light ${status == 2 ? 'reject-color' : ''}`}
                  >
                    Reject
                  </a>
                )}
                {clientType !== 'application_client' && (
                  <a onClick={toggleDocUploadModal} className={`submitbut`}>
                    Upload Documents
                  </a>
                )}
                {(status == 0 || status == 1) && (
                  <a
                    onClick={hanndleApprove}
                    className={`submitbut ${status == 1 ? 'success-color' : ''}`}
                  >
                    Approve
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(user_type === USER_ROLE_CLEARING_AGENT || user_type === USER_ROLE_FORWARDING_AGENT) &&
        showDocUploadModal && (
          <COAgentDocumentUploadModal
            isOpen={showDocUploadModal}
            toggle={toggleDocUploadModal}
            container="lawear-page"
            agentType={user_type}
            details={details}
            showDoc={showDoc}
            images={images}
            getUserDetails={getUserDetails}
            formValues={formValues}
          />
        )}
      {user_type === USER_ROLE_APPLICATION_CLIENT && showDocUploadModal && (
        <COClientDocumentUploadModal
          isOpen={showDocUploadModal}
          toggle={toggleDocUploadModal}
          container="lawear-page"
          details={details}
          showDoc={showDoc}
          clientType={clientType}
          getUserDetails={getUserDetails}
          images={images}
          formValues={formValues}
        />
      )}
    </>
  );
};

export default ClientApprovalDetails;
