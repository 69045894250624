import { combineReducers } from 'redux';
import LoaderReducer from './loader/LoaderReducer';
import UserReducer from './user/UserReducer';
import ErrorReducer from './error/ErrorReducer';
import EnumReducer from './enumData/EnumReducer';
import AbokifixReducer from './abokiFX/AbokifixReducer';

const rootReducer = combineReducers({
  loading: LoaderReducer,
  user: UserReducer,
  modal: ErrorReducer,
  enumData: EnumReducer,
  exchange: AbokifixReducer,
});

export default rootReducer;
