import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { subMonths, subDays } from 'date-fns';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { showModal } from '../../../redux/error/ErrorAction';
import { POST } from '../../../services/rest.service';
import DisplayDocument from '../../../components/global/DisplayDocument';
import {
  getFuel,
  getDriverJournal,
  // getTelemetry,
  generateHarshDrivingReport,
  getHarshDrivingReportStatus,
  getHarshDrivingReport,
} from '../../../services/navixy.service';
import DriverJournal from './DriverJournal';
import DriverBehaviour from '../../../components/global/DriverBehaviour';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../helpers/constants';
import Information from './Information';
import { showDocumentModal } from '../../../redux/user/UserActions';
import DateRangeModal from '../../../components/Modals/DateRange/DateRangeModal';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import NavixyWebLocator from '../../../components/global/NavixyWebLocator';

const HARSH_DRIVING = 'HARSH_DRIVING';
const DRIVER_JOURNAL = 'DRIVER_JOURNAL';

const djDefaultRange = {
  startDate: subMonths(new Date(), 1),
  endDate: new Date(),
  key: 'djRange',
};

const hdDefaultRange = {
  startDate: subDays(new Date(), 1),
  endDate: new Date(),
  key: 'hdRange',
};

const TruckDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [truckData, setTruckData] = useState({});
  const [fuelData, setFuelData] = useState({});
  // const [telemetryData, setTelemetryData] = useState({});
  const [driverJournalData, setDriverJournalData] = useState([]);
  // console.log('>> driverJournalData', driverJournalData)
  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  const [trackerId, setTrackerId] = useState(null);

  const [harshDrivingData, setHarshDrivingData] = useState({});
  // state for driver journal
  const [djDateRange, setDjDateRange] = useState(djDefaultRange);
  // state for driver behaviour
  const [hdDateRange, setHdDateRange] = useState(hdDefaultRange);
  const [showDateRange, setShowDateRange] = useState('');

  // get driver journal data
  const fetchDriverJournalData = useCallback(
    async (trackerID, payload) => {
      try {
        dispatch(showLoading());
        const djRes = await getDriverJournal(trackerID, payload);
        // console.log('>> data',data);
        if (djRes?.list) {
          const dj = [...djRes?.list];
          setDriverJournalData(dj.reverse());
        } else {
          setDriverJournalData([]);
        }
        dispatch(hideLoading());
      } catch (error) {
        dispatch(showModal(error.message));
        dispatch(hideLoading());
      }
    },
    [dispatch],
  );

  const fetchHarshDrivingData = useCallback(
    async (trackerID, payload) => {
      try {
        dispatch(showLoading());
        const hdRes = await generateHarshDrivingReport({
          trackerId: trackerID,
          ...payload,
        });
        // console.log('>> hdRes',hdRes);
        if (hdRes?.success && hdRes?.id) {
          // adding a one second delay for now
          await new Promise((r) => setTimeout(r, 2000));

          const hdrStatus = await getHarshDrivingReportStatus(hdRes.id);

          if (hdrStatus?.success && hdrStatus?.percent_ready === 100) {
            const hdReport = await getHarshDrivingReport(hdRes.id);
            // console.log('>> hdReport',hdReport);
            setHarshDrivingData(hdReport?.report || {});
          }
        }
        dispatch(hideLoading());
      } catch (error) {
        dispatch(showModal(error.message));
        dispatch(hideLoading());
      }
    },
    [dispatch],
  );

  const fetchTruckData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const payData = {
        truck_uuid: props.match.params.id,
      };
      const truckDataResp = await POST(`/trucks/getDetails`, payData);
      // console.log('>> truckDataResp',truckDataResp);
      if (truckDataResp?.data?.truck) {
        setTruckData({
          ...truckDataResp.data.truck,
        });
        // Fetch Truck details from NAVIXY
        const trackerID = truckDataResp.data.truck.tracker_id;
        if (trackerID) {
          setTrackerId(trackerID);

          // fuel
          const fuelData = await getFuel(trackerID);
          // console.log('>> fuelData',fuelData);
          setFuelData(fuelData || {});

          // // telemetry
          // const telemetryData = await getTelemetry(trackerID);
          // // console.log('>> telemetryData',telemetryData);
          // setTelemetryData(telemetryData || {});
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showModal(error.message));
      dispatch(hideLoading());
    }
  }, [dispatch, props.match.params.id]);

  //fetch Truck data
  useEffect(() => {
    fetchTruckData();
  }, [fetchTruckData]);

  // fetch driverjournal when a date range is selected
  useEffect(() => {
    // console.log('>> djDateRange', djDateRange);
    if (trackerId) {
      const payload = {
        from: moment(djDateRange.startDate).format(DATE_TIME_FORMAT),
        to: moment(djDateRange.endDate).format(DATE_TIME_FORMAT),
      };
      fetchDriverJournalData(trackerId, payload);
    }
  }, [trackerId, djDateRange, fetchDriverJournalData]);

  // fetch harsh driving data when a date range is selected
  useEffect(() => {
    // console.log('>> hdDateRange', hdDateRange);
    if (trackerId) {
      const payload = {
        from: moment(hdDateRange.startDate).format(DATE_TIME_FORMAT),
        to: moment(hdDateRange.endDate).format(DATE_TIME_FORMAT),
      };
      fetchHarshDrivingData(trackerId, payload);
    }
  }, [trackerId, hdDateRange, fetchHarshDrivingData]);

  const handleDateRangeChange = (v) => {
    // console.log('>> Details >> handleDateRangeChange', v)
    if (showDateRange === HARSH_DRIVING && v.hdRange?.startDate && v.hdRange?.endDate) {
      setHdDateRange(v.hdRange);
    } else if (showDateRange === DRIVER_JOURNAL && v.djRange?.startDate && v.djRange?.endDate) {
      setDjDateRange(v.djRange);
    }
    setShowDateRange('');
  };

  return (
    <>
      <div id="tabblock5" className="tabcontent truck-details">
        <div className="bodycont">
          <div>
            <h2>
              <span
                onClick={() => props.history.goBack()}
                style={{ cursor: 'pointer', float: 'left' }}
              >
                <LeftArrowSvg />
              </span>
              Truck Details
            </h2>
          </div>
          <div className="card job-status-card">
            <div className="card-body">
              <div className="truck-details-content">
                <Information truckData={truckData} showDoc={showDoc} />
                {/* Truck location tracking */}
                <>
                  <h3>Tracking:</h3>
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <NavixyWebLocator trackerId={trackerId} />
                    </div>
                  </div>
                  <hr />
                </>
                {/* Fuel Data */}
                {fuelData?.inputs?.length > 0 && (
                  <>
                    <h3>Fuel consumption:</h3>
                    <div className="col-6">
                      <DisplayDocument
                        label="UNITS"
                        value={`${fuelData?.inputs[0].units || 0}%`}
                        type="text"
                        textColClassName="document"
                      />
                    </div>
                    <hr />
                  </>
                )}
                {/* Telemetry Data */}
                {/* {telemetryData?.inputs?.length > 0 && (
                  <>
                    <hr />
                    <h3>Telemetry:</h3>
                    {telemetryData?.inputs.map((input)=>{
                      return (
                        <div className='col-6'>
                          <DisplayDocument 
                            label={input.label}
                            value={input.units || input.value}
                            type="text"
                            textColClassName="mb-5 txt-detail"
                            textClassName="v2-text-label v2-text"
                          />
                        </div>
                      )}
                    )}
                  </>
                )} */}
                {/* Harsh Driving */}
                <DriverBehaviour
                  data={harshDrivingData}
                  toggleDateRangeModal={() => setShowDateRange(HARSH_DRIVING)}
                  dateRanges={hdDateRange}
                />
                <hr />
                {/* Driver Journal */}
                <DriverJournal
                  data={driverJournalData}
                  toggleDateRangeModal={() => setShowDateRange(DRIVER_JOURNAL)}
                  dateRanges={djDateRange}
                />
              </div>
            </div>
          </div>
          <div className="buttonsec" style={{ textAlign: 'center' }}>
            <button type="button" onClick={() => history.goBack()} className="submitbut btn-light">
              Back
            </button>
          </div>
        </div>
      </div>
      {showDateRange !== '' && (
        <DateRangeModal
          onChange={handleDateRangeChange}
          isOpen={showDateRange !== ''}
          ranges={showDateRange === HARSH_DRIVING ? hdDateRange : djDateRange}
          toggle={() => setShowDateRange('')}
          container="clearing-agent-page"
          format={DATE_FORMAT}
        />
      )}
    </>
  );
};

export default TruckDetails;
