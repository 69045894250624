import React from 'react';
import moment from 'moment';
import StaticStarUI from '../UI/StaticStarUI';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../helpers/constants';
import DisplayDocument from '../../components/global/DisplayDocument';

const DriverBehaviour =(props)=>{
  const { data, toggleDateRangeModal, dateRanges } = props;

  const sheets0Sections0DataBars = data?.sheets?.[0]?.sections?.[0]?.data?.[0]?.bars;
  const sheets0Sections2DataRows0 = data?.sheets?.[0]?.sections?.[2]?.data?.[0]?.rows?.[0];

  return (
    <>
      {dateRanges ? (
        <div className="row">
          <div className='col-lg-8 col-sm-12'>
            <h3>Harsh driving:</h3>
          </div>
          <div className='col-lg-4 col-sm-12 mb-3'>
            <span onClick={toggleDateRangeModal} className='date-range-btn'>
              {/* {moment(dateRanges.startDate).format(DATE_FORMAT)} - {moment(dateRanges.endDate).format(DATE_FORMAT)} */}
              View Harsh Driving History
            </span>
          </div>
        </div>
      ) : (
        <h3>Harsh driving:</h3>
      )}
      {data ? (
        <>
          {data.sheets?.length > 0 && (
            <div className="row mx-0">
              {/* Date time */}
              <div className='col-6'>
                <DisplayDocument 
                  label="From"
                  value={moment(data?.from).format(DATE_TIME_FORMAT)}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className='col-6'>
                <DisplayDocument 
                  label="To"
                  value={moment(data?.to).format(DATE_TIME_FORMAT)}
                  type="text"
                  textColClassName="document"
                />
              </div>
              {/* <hr /> */}
              {/* summary */}
              <div className='col-6'>
                <DisplayDocument 
                  label="Idling Points"
                  value={sheets0Sections0DataBars?.idling?.v || 0}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className='col-6'>
                <DisplayDocument 
                  label="Speeding Points"
                  value={sheets0Sections0DataBars?.speeding?.v || 0}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className='col-6'>
                <DisplayDocument 
                  label="Harsh Driving Points"
                  value={sheets0Sections0DataBars?.harsh_driving?.v || 0}
                  type="text"
                  textColClassName="document"
                />
              </div>
              {/* Details */}
              <div className='col-6'>
                <DisplayDocument 
                  label="Rating"
                  value={(<StaticStarUI rating={sheets0Sections2DataRows0?.rating?.raw || 0} />)}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className='col-6'>
                <DisplayDocument 
                  label="Mileage (km)"
                  value={sheets0Sections2DataRows0?.mileage?.v || 0}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className='col-6'>
                <DisplayDocument 
                  label="Total Penalties"
                  value={sheets0Sections2DataRows0?.penalties_number?.v || 0}
                  type="text"
                  textColClassName="document"
                />
              </div>
              <div className='col-6'>
                <DisplayDocument 
                  label="Average Penalty Points"
                  value={sheets0Sections2DataRows0?.avg_penalty?.v || 0}
                  type="text"
                  textColClassName="document"
                />
              </div>
            </div>
          )}
        </>
      ) :(
        <p>No data available.</p>
      )}
    </>
  )
}

export default DriverBehaviour;