/* REST */
import { GET, POST } from '../../services/rest.service';
import { refreshToken, trackMouseAndKeyboardActivity } from '../../services/refreshToken';
// import store from '../store';
import { hideLoading } from '../loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';
import { get } from 'lodash';
import { applicationUserType } from '../../helpers/constants';

/* ACTIONS */
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

export const SHOW_SUSPENSION_MODAL = 'SHOW_SUSPENSION_MODAL';
export const HIDE_SUSPENSION_MODAL = 'HIDE_SUSPENSION_MODAL';

export const SHOW_DOCUMENT_MODAL = 'SHOW_DOCUMENT_MODAL';
export const HIDE_DOCUMENT_MODAL = 'HIDE_DOCUMENT_MODAL';

export const SAVE_AGENT_PORTS = 'SAVE_AGENT_PORTS';

/* LOGIN */
export const login = (payload, history) => {
  return (dispatch) => {
    POST('/auth/login', { email: payload.email, password: payload.password })
      .then(({ data }) => {
        sessionStorage.setItem('_GATKEN', data._token);
        localStorage.setItem('rememberme', payload.rememberme);
        if (payload.rememberme) {
          localStorage.setItem('email', btoa(payload.email));
          localStorage.setItem('password', btoa(payload.password));
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            isLoggedIn: true,
            user: data.user,
            type: data.user.type,
            message: 'logged in successfully',
          },
        });
        history.push(`/${applicationUserType[get(data, 'user.type')]}/dashboard`);
        dispatch(hideLoading());
        dispatch(getLoggedInUser(history));
        // initialize token refresh interval
        refreshToken();
        trackMouseAndKeyboardActivity();
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            isLoggedIn: false,
            user: {},
            type: undefined,
            message: err.message,
            status: err.status,
          },
        });

        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };
};

export const getLoggedInUser = (history) => {
  return (dispatch) => {
    const url = `/users/me`;
    GET(url)
      .then(({ data }) => {
        dispatch({
          type: USER,
          payload: {
            user: data,
            type: data.type,
          },
        });
      })
      .catch((err) => {
        sessionStorage.removeItem('_GATKEN');

        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            user: {},
            type: undefined,
            isLoggedIn: false,
            message: err.message,
          },
        });
        dispatch(showModal(err.message));

        history.push('/');
        window.location.reload();
      });
  };
};

export const logoutUser = (history, email) => {
  return (dispatch) => {
    POST('/auth/logout', {
      email: email || '',
    })
      .then(({ data }) => {
        sessionStorage.removeItem('_GATKEN');
        dispatch({
          type: LOGOUT,
          payload: {
            isLoggedIn: false,
            type: undefined,
            user: {},
            message: '',
          },
        });
        history.replace('/');
      })
      .catch((err) => {
        sessionStorage.removeItem('_GATKEN');
        dispatch({
          type: LOGOUT,
          payload: {
            isLoggedIn: false,
            type: undefined,
            user: {},
            message: '',
          },
        });
        history.replace('/');
      });
  };
};

// this method is for fetching the Agent's Ports of Operation
// which is used to filter jobs when placing bids
export const getAgentPortsOfOperation = (agentId) => {
  return (dispatch) => {
    POST('/ports/getAgentPort', {
      agent_uuid: agentId,
    })
      .then(({ data }) => {
        dispatch({
          type: SAVE_AGENT_PORTS,
          payload: data.ports,
        });
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };
};

export const showSuspensionModal = () => {
  return {
    type: SHOW_SUSPENSION_MODAL,
  };
};

export const hideSuspensionModal = () => {
  return {
    type: HIDE_SUSPENSION_MODAL,
  };
};

export const showDocumentModal = (document) => {
  return {
    type: SHOW_DOCUMENT_MODAL,
    payload: document,
  };
};

export const hideDocumentModal = () => {
  return {
    type: HIDE_DOCUMENT_MODAL,
  };
};
