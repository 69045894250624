import React from 'react';
import { Helmet } from 'react-helmet';
import PublicFooter from '../../components/global/PublicFooter';

import PublicHeader from '../../components/global/PublicHeader';

const Faq = () => {
  return (
    <div id="home-page">
      <Helmet>
        <title>GoAgent - FAQ</title>
      </Helmet>
      <PublicHeader defaultClass="inner-menu" />
      <main id="main">
        <section id="payment-policy" className="payment-policy">
          <div className="container">
            <h2>FAQ</h2>
            <div className="container">
              <div className="panel-group" id="accordion">
                <div className="faqHeader" style={{ marginTop: 0 }}>
                  General Questions
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                      >
                        How secured is transaction on GoAgent Platform?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      All financial transactions on GoAgent are supported by https security feature,
                      which ensures that customers’ data are well protected at all times. In
                      addition to that security, we value our customers’ privacy and guarantee
                      non-disclosure of any information provided on GoAgent platform.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwo"
                      >
                        Why should I use GoAgent web and app services?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      GoAgent platform offers its client the ability to remotely clear and transport
                      their cargo with zero stress and very low risks using verified, reliable
                      clearing agent and transporter.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                      >
                        Can GoAgent app run on Apple IOS?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      GoAgent mobile app supports all versions of the major mobile platforms
                      including Android, IOS and Windows, and it is optimized to give the best user
                      experience.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseFour"
                      >
                        Are there third-party adverts on GoAgent mobile App?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFour" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      GoAgent app is totally free of any third-party adverts.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseFive"
                      >
                        I have a question that is not listed here
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFive" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Please feel free to contact us via{' '}
                      <a href="mailto:info@goagent.com.ng">info@goagent.com.ng</a>
                    </div>
                  </div>
                </div>
                {/* Agent FAQ's */}
                <div className="faqHeader">Agents</div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseSix"
                      >
                        As a clearing agent, how can I register my company to clear goods using this
                        platform?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseSix" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Visit{' '}
                      <a href="https://goagent.com.ng/registration">goagent.com.ng/registration</a>{' '}
                      to create your account for free and start making more money with GoAgent.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseSeven"
                      >
                        As a transporter, how can I register my company to transport goods using
                        this platform?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseSeven" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Visit{' '}
                      <a href="https://goagent.com.ng/registration">goagent.com.ng/registration</a>{' '}
                      to create your account for free and start making more money with GoAgent.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseEight"
                      >
                        As a transporter, how can I register my trucks on GoAgent?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseEight" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      When your transport company is successfully registered on GoAgent, you will be
                      able to register your trucks on GoAgent by yourself.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseNine"
                      >
                        How can I receive payment to start clearing a cargo after winning the bid?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseNine" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      The moment the client sends funds for the job, as the winning agent, you will
                      receive an advance payment through the bank details you provided during
                      registration to start the clearing process. The remaining balance will be paid
                      after you successfully complete the job.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTen"
                      >
                        Must I participate in the bidding before I get a clearing or transport job?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTen" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Yes, the bidding process is what we use to determine who will do the job as
                      the lowest bidder wins.
                    </div>
                  </div>
                </div>

                {/* Client FAQ's */}
                <div className="faqHeader">Clients</div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseEleven"
                      >
                        How long does it take for my goods to be cleared?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseEleven" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Once an importer pays for his clearing job, and if there is no issue with
                      declaration of cargo or defective documentation, clearing job does not take
                      more than a week to complete.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwelve"
                      >
                        How much is the charge to clear or transport a container?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwelve" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Clearing charges depend on the type of cargo, because government charges
                      different duty on each type of cargo. The price of clearing is also determined
                      through bidding, which may result in each job having a unique price depending
                      on the circumstance of its bidding process.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThirteen"
                      >
                        Must I make full payment before my good is cleared or trasported?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThirteen" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      To ensure uninterrupted work flow, GoAgent requires full payment in advance
                      before the commencement of your clearing job or transport job.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseFourteen"
                      >
                        Can I choose my clearing agent and transporter before the close of bidding?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFourteen" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Yes, GoAgent platform allows importers to review the rating of an agent and
                      choose them before bidding closes, thereby terminating the bidding before its
                      closure.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseFifteen"
                      >
                        How can I make payment, and with which currency?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFifteen" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      GoAgent has integrated multiple payment gateways that allow seamless
                      electronic payment of clearing and transport jobs using either bank transfer
                      or card payment. Also, GoAgent offers the option to either pay in Nigerian
                      Naira or US Dollars.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseSixteen"
                      >
                        How long will my cargo take before they arrive my chosen location?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseSixteen" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      The time taken to deliver cargo to your chosen location depends on distance to
                      be covered. However, no location in Nigeria takes more than a week to deliver
                      cargo to.
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title text-left">
                      <a
                        className="accordion-toggle collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseSeventeen"
                      >
                        Will I be notified when my cargo is cleared or reach my desired location?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseSeventeen" className="panel-collapse collapse">
                    <div className="panel-body text-left">
                      Yes, you will be notified. Additionally, GoAgent app is built to enable you
                      track the real-time progress of your clearing and transport job remotely at
                      every stage.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};

export default Faq;
