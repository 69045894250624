import React from 'react';
import { getIn } from 'formik';
import TextField from '../../../UI/TextField';
import FIleField from '../../../UI/FIleField';
import SelectBox from '../../../UI/SelectBox';
import { acceptedFileTypes } from '../../../../helpers/constants';
import DisplayDocument from '../../../global/DisplayDocument';

const reg_year = {
  1: 'Companies Registered between December 2020 to date',
  2: 'Companies Registered between 2017 and 2020',
  3: 'Companies Registered Pre-2017',
};

const FormFields = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    images,
    showDoc,
  } = props;

  return (
    <>
      <div className="col-sm-12 col-lg-12 mb-3">
        <SelectBox
          id="reg_year"
          name="reg_year"
          className="form-control"
          placeholder="Select Company Registration Year:"
          value={values.reg_year}
          options={reg_year}
          onChange={handleChange}
          error={getIn(touched, 'reg_year') && getIn(errors, 'reg_year')}
        />
      </div>
      {values.reg_year == 1 && (
        <div className="col-sm-12 col-lg-12 mb-3">
          {images.registration_application_status && (
            <DisplayDocument
              label="Status Report (Application for Registration)"
              onClick={() => showDoc(images.registration_application_status)}
              type="file"
              fileColClassName="form-document"
              nolabel={true}
            />
          )}
          <div
            className="fileUploads"
            style={{ background: values.registration_application_status ? '#01a8af' : '' }}
          >
            <span>
              <i className="fa fa-file-text-o" aria-hidden="true"></i> Status Report (Application
              for Registration)
            </span>
            <FIleField
              type="file"
              accept={acceptedFileTypes}
              name="registration_application_status"
              onChange={(event) =>
                setFieldValue('registration_application_status', event.target.files[0])
              }
              className="uploads"
            />
          </div>
        </div>
      )}
      {values.reg_year == 2 && (
        <div className="col-sm-12 col-lg-12 mb-3">
          {images.ctc_cac_1_1 && (
            <DisplayDocument
              label="CTC of Form CAC 1.1 (Application for Registration)"
              onClick={() => showDoc(images.ctc_cac_1_1)}
              type="file"
              fileColClassName="form-document"
              nolabel={true}
            />
          )}
          <div className="fileUploads" style={{ background: values.ctc_cac_1_1 ? '#01a8af' : '' }}>
            <span>
              <i className="fa fa-file-text-o" aria-hidden="true"></i> CTC of Form CAC
              1.1(Application for Registration)
            </span>
            <FIleField
              type="file"
              accept={acceptedFileTypes}
              name="ctc_cac_1_1"
              onChange={(event) => setFieldValue('ctc_cac_1_1', event.target.files[0])}
              className="uploads"
            />
          </div>
        </div>
      )}
      {values.reg_year == 3 && (
        <>
          <div className="col-sm-12 col-lg-12 mb-3">
            {images.cac_2 && (
              <DisplayDocument
                label="Form CAC 2 (Statement of Share Capital and Return of Allotment)"
                onClick={() => showDoc(images.cac_2)}
                type="file"
                fileColClassName="form-document"
                nolabel={true}
              />
            )}
            <div className="fileUploads" style={{ background: values.cac_2 ? '#01a8af' : '' }}>
              <span>
                <i className="fa fa-file-text-o" aria-hidden="true"></i> Form CAC 2 (Statement of
                Share Capital and Return of Allotment)
              </span>
              <FIleField
                type="file"
                accept={acceptedFileTypes}
                name="cac_2"
                onChange={(event) => setFieldValue('cac_2', event.target.files[0])}
                className="uploads"
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-12 mb-3">
            {images.cac_7 && (
              <DisplayDocument
                label="Form CAC 7 (Particulars of Directors)"
                onClick={() => showDoc(images.cac_7)}
                type="file"
                fileColClassName="form-document"
                nolabel={true}
              />
            )}
            <div className="fileUploads" style={{ background: values.cac_7 ? '#01a8af' : '' }}>
              <span>
                <i className="fa fa-file-text-o" aria-hidden="true"></i> Form CAC 7 (Particulars of
                Directors):
              </span>
              <FIleField
                type="file"
                accept={acceptedFileTypes}
                name="cac_7"
                onChange={(event) => setFieldValue('cac_7', event.target.files[0])}
                className="uploads"
              />
            </div>
          </div>
        </>
      )}

      <div className="col-sm-12 col-lg-12 mb-3">
        {images.incorporation_certificate && (
          <DisplayDocument
            label="Certificate of incorporation"
            onClick={() => showDoc(images.incorporation_certificate)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.incorporation_certificate ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Certificate of incorporation
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            className="Uploads"
            name="incorporation_certificate"
            onChange={(event) => setFieldValue('incorporation_certificate', event.target.files[0])}
          />
        </div>
      </div>
      {/* This is Moved outside since this component is used in compliance officer uploads section */}
      {/* <div className="col-sm-12 col-lg-12 mb-3">
        <div className="text-left">
          <label className="v2-text-label v2-text">RC Number</label>
          <TextField
            id="rc_no"
            type="text"
            className="form-control"
            placeholder="RC Number"
            value={values.rc_no}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getIn(touched, 'rc_no') && getIn(errors, 'rc_no')}
          />
        </div>
      </div> */}
      <div className="col-sm-12 col-lg-12 mb-3">
        {images.memorandum_article_association && (
          <DisplayDocument
            label="Memorandum and Articles of Association"
            onClick={() => showDoc(images.memorandum_article_association)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.memorandum_article_association ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Memorandum and Articles of
            Association
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="memorandum_article_association"
            onChange={(event) =>
              setFieldValue('memorandum_article_association', event.target.files[0])
            }
            className="uploads"
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-12 mb-3">
        {images.corporate_address_proof && (
          <DisplayDocument
            label="Proof of corporate address(Utility bills or Tax payment receipt)"
            onClick={() => showDoc(images.corporate_address_proof)}
            type="file"
            fileColClassName="form-document"
            nolabel={true}
          />
        )}
        <div
          className="fileUploads"
          style={{ background: values.corporate_address_proof ? '#01a8af' : '' }}
        >
          <span>
            <i className="fa fa-file-text-o" aria-hidden="true"></i> Proof of corporate
            address(Utility bills or Tax payment receipt)
          </span>
          <FIleField
            type="file"
            accept={acceptedFileTypes}
            name="corporate_address_proof"
            onChange={(event) => setFieldValue('corporate_address_proof', event.target.files[0])}
            className="uploads"
          />
        </div>
      </div>
    </>
  );
};

export default FormFields;
