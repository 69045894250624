import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import AppLoader from '../../components/loaders/AppLoader';
import { getCopyrightYear } from '../../helpers/utils';

const PublicFooter = () => {
  const serviceData = useSelector((state) => state.enumData);
  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  const copyrightYear = getCopyrightYear();

  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div
              className="col-xs-6 col-sm-4 col-lg-4 footer-links"
              style={{ paddingRight: '40px' }}
            >
              <h3>Our Location</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.1543927749395!2d3.3696486061908137!3d6.443126455237932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8bad93c2c96b%3A0x76a5cb9df9b09ba!2s62%20Kofo%20Abayomi%20Ave%2C%20Apapa%20Quays%20100246%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sin!4v1634797694679!5m2!1sen!2sin"
                width="100%"
                height="150"
                style={{ border: '0', borderRadius: '10px' }}
                allowFullScreen=""
                loading="lazy"
                title="new"
              ></iframe>
            </div>
            <div className="col-xs-6 col-sm-4 col-lg-4 footer-links">
              <h3>Contact</h3>
              {serviceData.settings &&
                serviceData.settings.general.social_media.length > 0 &&
                serviceData.settings.general.social_media.map((obj, i) => (
                  <a href={obj.value} target="_blank" key={i}>
                    <i className={obj.icon} aria-hidden="true"></i>
                  </a>
                ))}
              {/* <a href="https://www.facebook.com/GoAgentLimited/" target="_blank">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>{' '}
              <a href="https://twitter.com/GoAgentNG?s=08" target="_blank">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>{' '}
              <a href="https://www.linkedin.com/company/go-agent-limited/about/" target="_blank">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="https://www.instagram.com/p/CS1sMfTtPFV/?utm_source=ig_web_copy_link" target="_blank">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a> */}
              {/* <div className="call">
                <i className="fa fa-phone" aria-hidden="true"></i> <a href="tel:0123 4567 89">Call: 0123 4567 89</a>
              </div> */}
              <div className="call">
                <address>
                  {/* <a href="mailto:someone@example.com">Email: info@goagent.com.ng</a> */}
                  <p className="call">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    Email: <a href="mailto: info@goagent.com.ng">info@goagent.com.ng</a>
                  </p>
                  <p className="call">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    Phone number: <br />
                    <a href="tel: +2342014549933" className="ms-lg-5 ps-lg-5">
                      +234 201 454 9933
                    </a>
                  </p>
                </address>
              </div>
            </div>
            <div className="col-xs-6 col-sm-2 col-lg-2 footer-links">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>

                <li>
                  <NavLink to="/about-us">About us</NavLink>
                </li>
                <li>
                  <NavLink to="/career">Careers</NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/login">Login</NavLink>
                </li>
                <li>
                  <NavLink to="/registration">Create free Account</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-xs-6 col-sm-2 col-lg-2 footer-links">
              <h3>Services</h3>
              <ul>
                <li>
                  <NavLink to="/importers-landing">GoAgent for Importers</NavLink>
                </li>
                <li>
                  <NavLink to="/clearing-agents-landing">GoAgent for Clearing Agents</NavLink>
                </li>
                <li>
                  <NavLink to="/forwarding-agents-landing">GoAgent for Transporters</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <Link to="/faq">FAQ</Link> Copyright ©{copyrightYear} GoAgent. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};
export default withRouter(PublicFooter);
