import React from 'react';
import DisplayDocument from '../../../global/DisplayDocument';

const DisplayFields = ({ values, clientType }) => {
  return (
    <div className="document-group">
      <h5 className="v2-text-label document-group-heading">
        Particulars of Authorized Representative:
      </h5>
      <DisplayDocument
        label="First Name"
        value={values.first_name}
        type="text"
        textColClassName="document"
      />
      <DisplayDocument
        label="Last Name"
        value={values.last_name}
        type="text"
        textColClassName="document"
      />
      {(clientType === 'government_entity' ||
        clientType === 'government_approved_private_entity') && (
        <DisplayDocument
          label="Authorization Letter"
          value={values.authorization_letter}
          type="text"
          textColClassName="document"
        />
      )}
      <DisplayDocument label="Email" value={values.email} type="text" textColClassName="document" />
      <DisplayDocument
        label="Phone Number"
        value={values.mobile_number}
        type="text"
        textColClassName="document"
      />
      <DisplayDocument
        label="Date of birth"
        value={values.dob}
        type="text"
        textColClassName="document"
      />
      <DisplayDocument
        label="Nationality"
        value={values.nationality}
        type="text"
        textColClassName="document"
      />
      {/* {(values.national_identity_no || values.identity_proof) && (
      <div className='document-group'>
        <h5 className="v2-text-label" style={{marginTop: 20, fontWeight: 'bold', textAlign:'center'}}>
          Proof of identification
        </h5> */}

      {/* <DisplayDocument
        label="National Identity number"
        value={values.national_identity_no}
        type="text"
        textColClassName="document"
      /> */}

      {/* <DisplayDocument 
            label="National Identity card"
            onClick={()=>showDoc(values.identity_proof)}
            type="file"
          /> */}
      {/* </div>
    )} */}
      {clientType !== 'free_zone_entity' && (
        <DisplayDocument
          label="Residential Address"
          value={values.address}
          type="text"
          textColClassName="document"
        />
      )}
    </div>
  );
};

export default DisplayFields;
