import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import AppLoader from '../../../components/loaders/AppLoader';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import Chat from '../../../components/UI/Chat';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import { useChat } from '../../../helpers/customHooks';
import ClearingCargoStatus from '../../../components/global/ClearingCargoStatus';
import DisplayDocument from '../../../components/global/DisplayDocument';
import { displayAmount } from '../../../helpers/utils';

const JobStatus = (props) => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const params = new URLSearchParams(props.location.search).get('id');
  const job_id = params;
  const serviceData = useSelector((state) => state.enumData);
  const [chatbox, setChatbox] = useState(false);
  const [chatlist, setChatlist] = useState([]);
  const [msg, setMsg] = useState('');
  const { user } = useSelector((state) => state.user);
  const [unreadCount, setUnreadCount] = useState(0);

  //get Job Details
  const jobDetails = async () => {
    dispatch(showLoading());
    POST(`/jobs/getClearingCargoStatus`, { job_uuid: job_id })
      .then(({ data }) => {
        // console.log(data);
        setDetails(data);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  useEffect(() => {
    jobDetails();
  }, []);

  // initialize and subcsribe to chat here
  const handleChatEvent = (data1) => {
    if (data1.chat_type === 'clearing') {
      if (!chatbox) {
        // set unread count when chat is not openend
        setUnreadCount((prevState) => prevState + 1);
      }

      setChatlist((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById('chat-top');
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    dispatch(showModal('you have a new message', true));
  };
  useChat(user.uuid, chatbox, handleChatEvent);

  //chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${job_id}`)
      .then(({ data }) => {
        // console.log(data);
        const oc = data.chats.operator?.length > 0 ? data.chats.operator.reverse() : [];
        setChatlist(oc);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const chatToggle = () => {
    if (!chatbox) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount(0);
    }
    setChatbox(!chatbox);
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }
    const payLoad = {
      job_uuid: job_id,
      sender_uuid: user.uuid,
      receiver_uuid: details.job.operator_uuid,
      chat_type: 'clearing',
      message: message,
    };

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        // console.log(data);
        setChatlist((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById('chat-top');
        objDiv.scrollTop = objDiv.scrollHeight;
        // getChat();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
    // const objDiv = document.getElementById('chat-top');
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
      // const objDiv = document.getElementById('chat-top');
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };
  //end chat code
  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <div className="tab-pane active" id="resp-tab7">
              <>
                <div>
                  <h2>
                    <span
                      onClick={() => props.history.goBack()}
                      style={{ cursor: 'pointer', float: 'left' }}
                    >
                      <LeftArrowSvg />
                    </span>
                    Cargo Status
                  </h2>
                </div>
                <div style={{ position: 'relative' }}>
                  {!isEmpty(details.clearingBid) && (
                    <div className="row mx-0">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                              <div className="col-lg-4 col-sm-6 col-xs-6">
                                <DisplayDocument
                                  label="Job Id"
                                  value={details.job.job_id}
                                  type="text"
                                  textColClassName="document"
                                />
                              </div>
                              <div className="col-lg-4 col-sm-6 col-xs-6">
                                <DisplayDocument
                                  label="Bid Price"
                                  value={displayAmount(details.clearingBid?.offer_price)}
                                  type="text"
                                  textColClassName="document"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 job-status-container">
                              <ClearingCargoStatus
                                details={details}
                                jobId={job_id}
                                refresh={jobDetails}
                              />
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="buttonsec relative-right-10">
                    <a
                      onClick={() => {
                        props.history.goBack();
                      }}
                      className="backbut"
                    >
                      Back
                    </a>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {details?.job?.clearing_status !== '8' && (
        <Chat
          name="Us"
          id="us"
          chatbox={chatbox}
          user={user}
          chatToggle={chatToggle}
          chatlist={chatlist}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
          unreadMsgsCount={unreadCount}
        />
      )}
    </>
  );
};

export default JobStatus;
