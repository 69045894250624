import React from 'react';

const AppLoader = (props) => (
  <div
    style={{
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      position: 'fixed',
      width: '100%',
      height: '100vh',
      zIndex: '999999',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: 'hidden',
      top: 0,
    }}
  >
    <div className="spinner-border text-info" style={{ width: '5rem', height: '5rem' }} role="status">
      <span className="sr-only">{props.title}</span>
    </div>
  </div>
);

export default AppLoader;
