import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '../../components/UI/TextField';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';

import '../../../assets/css/webinar.css';

const Webinar = (props) => {
  const serviceData = useSelector((state) => state.enumData);
  const dispatch = useDispatch();

  const [showMessage, setShowMessage] = useState({ msg: '', status: false });
  const toggleShowMessage = (msg, status) => setShowMessage({ msg: msg, status: status });

  return (
    <div id="webinar-page">
      <Helmet>
        <title>GoAgent Clearing and Transport - Webinar</title>
      </Helmet>

      {showMessage.msg && (
        <Snackbar
          open={showMessage}
          autoHideDuration={4000}
          onClose={() => toggleShowMessage('', false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={showMessage.status ? 'success' : 'error'}
            onClose={() => toggleShowMessage('', false)}
          >
            <span style={{ fontSize: '1.500rem' }}>{showMessage.msg}</span>
          </MuiAlert>
        </Snackbar>
      )}

      <div id="webinar">
        <main className="row content-center">
          <div>
            <h1 className="webinar-title">Webinar Registration 2022</h1>
          </div>

          <header className="site-header">
            <div className="logo">
              <a href="index.html">Advent.</a>
            </div>
          </header>

          <div id="main-content" className="twelve columns">
            <h3 className="mb-5 color-dark">
              Free Webinar for Importers, Clearing Agents & Transporters
            </h3>

            <h3 className="registration-date mb-5">19th November, 2022</h3>

            <h4 className="color-white">
              You can remotely clear and transport your cargo in Nigeria, wherever you are in the
              world, and track everything in real-time.
            </h4>

            <hr />

            <div id="mc-signup">
              <Formik
                initialValues={{
                  email: '',
                  subscribed_for: '',
                  name: '',
                  mobile: '',
                  company: '',
                  business_type: '',
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email('Invalid email address')
                    .required('Email is Required'),
                  name: Yup.string().required('Name is Required'),
                  business_type: Yup.string().test('bt', 'Business Type is required', (val) => val),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  const payload = {
                    ...values,
                    subscribed_for: 'webinar 1',
                  };

                  dispatch(showLoading());
                  setSubmitting(true);
                  POST('/subscribers/store', payload)
                    .then(({ data }) => {
                      resetForm();
                      toggleShowMessage('Succssefully Registered', true);
                      dispatch(hideLoading());
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      toggleShowMessage('Oops! Something went wrong');
                      dispatch(hideLoading());
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} id="mc-form" className="row">
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <TextField
                        className="input-field"
                        placeholder="Full Name*"
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'name') && getIn(errors, 'name')}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <TextField
                        className="input-field"
                        placeholder="Email Address*"
                        id="email"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'email') && getIn(errors, 'email')}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <TextField
                        type="text"
                        name="mobile"
                        className="input-field"
                        id="mobile"
                        placeholder="Phone Number"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'mobile') && getIn(errors, 'mobile')}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <TextField
                        type="text"
                        name="company"
                        className="input-field"
                        id="company"
                        placeholder="Company"
                        value={values.company}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'company') && getIn(errors, 'company')}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <select
                        name="business_type"
                        id="business_type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getIn(touched, 'business_type') && getIn(errors, 'business_type')}
                      >
                        <option label="Business Type*"></option>
                        <option label="Importer" value="importer"></option>
                        <option label="Clearing Agent" value="clearing-agent"></option>
                        <option label="Transporter" value="transporter"></option>
                      </select>
                      {touched.business_type && errors.business_type && (
                        <div className="invalid-feedback  mb-3  d-block">
                          {errors.business_type}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12"></div>
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                      <button type="submit" className="button" disabled={isSubmitting}>
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
              <p id="form-response"></p>
            </div>
          </div>
        </main>
      </div>

      <footer className="webinar-footer">
        <ul className="footer-social" id="footer-social">
          {serviceData.settings &&
            serviceData.settings.general.social_media.length > 0 &&
            serviceData.settings.general.social_media.map((obj, i) => (
              <li key={i}>
                <a href={obj.value} target="_blank" rel="noreferrer">
                  <i className={obj.icon} aria-hidden="true"></i>
                </a>
              </li>
            ))}
        </ul>

        <ul className="footer-copyright">
          <li>Copyright &copy;2022 GoAgent. All Rights Reserved.</li>
        </ul>
      </footer>
    </div>
  );
};
export default Webinar;
