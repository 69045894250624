import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { capitalize } from 'lodash';
import { POST } from '../../../services/rest.service';
import { showLoading, hideLoading } from '../../../redux/loader/LoaderActions';
import { showModal } from '../../../redux/error/ErrorAction';
import {
  displayAmount,
  accessGeneralOrCargoType,
  getQueryParamValue,
} from '../../../helpers/utils';
import { DATE_FORMAT, TIME_FORMAT } from '../../../helpers/constants';
import Pagination from '../../../components/global/Pagination';
import { usePagination } from '../../../helpers/customHooks';

const PaymentsList = (props) => {
  const dispatch = useDispatch();
  const [paymentList, setPaymentList] = useState([]);

  const {
    selectedPage,
    itemsPerPage,
    totalPages,
    setTotalPages,
    handleSelected,
    handlePageCountChange,
  } = usePagination(
    getQueryParamValue(props.location, 'page'),
    getQueryParamValue(props.location, 'perPage'),
    props.history,
  );

  const serviceData = useSelector((state) => state.enumData);

  useEffect(() => {
    dispatch(showLoading());
    POST('/payment/getAllPaymentReciept', {
      page: selectedPage,
      per_page: itemsPerPage,
      sort_by: 'created_at',
      sort_type: 'asc',
    })
      .then(({ data }) => {
        // console.log(data);
        setTotalPages(data.totalRecords / itemsPerPage);
        setPaymentList(data.payment);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());

        dispatch(showModal(err.message));
      });
  }, [selectedPage, itemsPerPage]);

  return (
    <>
      <div id="tabblock4" className="tabcontent">
        <div className="bodycont">
          <div className="">
            <h2>Transaction History</h2>
            <div className="personal-info1">
              {/* <div className="receiptsfield">
                <input type="text" className="form-control" placeholder="Search..." />
                <input type="button" className="searchicon" />
              </div> */}
              <div className="table-responsive clearingtable">
                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                  <thead>
                    <tr>
                      <th>Job ID</th>
                      <th>Job Details</th>
                      <th>Amount</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentList.length > 0 &&
                      paymentList.map((obj, i) => (
                        <tr key={i}>
                          <td>{obj.job && obj.job.job_id}</td>
                          <td className="description1">
                            <h4>
                              {(obj.job &&
                                capitalize(serviceData.job.category[obj.job.category])) ||
                                'N/A'}
                            </h4>
                            <p>
                              {obj.job && capitalize(serviceData.job.vehicle_type[obj.job.type])}
                            </p>
                            <p>{obj.job && accessGeneralOrCargoType(obj.job, serviceData)}</p>
                          </td>
                          <td>
                            {obj.channel === 'paypal' ? (
                              <strong className="price">
                                {' '}
                                &#x24;{parseFloat(obj.amount).toFixed(2)}
                              </strong>
                            ) : (
                              <strong className="price">{displayAmount(obj.amount)}</strong>
                            )}
                          </td>
                          <td>
                            <p className="datetext">{moment(obj.created_at).format(DATE_FORMAT)}</p>
                            <p className="datetext">{moment(obj.created_at).format(TIME_FORMAT)}</p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {paymentList.length <= 0 && <h3>No Data Found!</h3>}
              </div>
            </div>
            {paymentList.length > 0 && (
              <Pagination
                pageCount={totalPages}
                onPageChange={handleSelected}
                selectedPage={selectedPage}
                setItemsPerPage={handlePageCountChange}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentsList;
