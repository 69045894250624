import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import tick from '../../../../assets/img/tick.png';
import rejected from '../../../../assets/img/rejected.png';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST } from '../../../services/rest.service';
import { getTotalShareVal, extractUserDocs, displayAmount } from '../../../helpers/utils';
import ShowClearingForwardingDocs from '../../global/ShowClearingForwardingDocs';
import { showDocumentModal } from '../../../redux/user/UserActions';
import Payments from '../../global/Payments';
import { PAYMENT_RELATED_TO_SUBSCRIPTION } from '../../../helpers/constants';
import DirectorShareholderForm from '../../global/DirectorShareholderForm';
import { useVerifyme } from '../../../helpers/customHooks';
import VerificationModalSteps from './Steps';
import VerficationModalForm from './Form';

const VerificationModal = ({ verficationModal, toggleVerificationModal, container }) => {
  // console.log(verficationModal);
  const serviceData = useSelector((state) => state.enumData);
  // console.log(serviceData);
  const { user } = useSelector((state) => state.user);
  // console.log(user);
  const regPaymentDone = user?.registration_fee_paid !== 0;

  const registrationAmount =
    user.type === 'clearing_agent'
      ? parseInt(serviceData.settings.general.clearing_registration_fee)
      : parseInt(serviceData.settings.general.forwarding_registration_fee);

  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [shareValues, setShareValues] = useState({});
  const [images, setImages] = useState({});
  const [paystatus, setPaystatus] = useState(false);
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [right, setRight] = useState(false);
  //select box depende register year file
  const [type, setType] = useState('director');
  const [details, setDetails] = useState({});
  const [id, setId] = useState('');
  const [isEdit, setisEdit] = useState(false);
  const [totalShareVal, setTotalShareVal] = useState(0);

  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  // initialize verifyme hook
  const {
    isDocVerified,
    showOTPField,
    verifyMeData,
    setIsDocVerified,
    setShowOTPField,
    setVerifyMeData,

    storeVerifyMeData,
    sendMsgOtp,
    verifyDocument,
    verifyUserOtp,
  } = useVerifyme(dispatch);

  //user details
  const getUserDetails = () => {
    dispatch(showLoading());
    if (user.uuid) {
      GET(`/users/getDetails/${user.uuid}`)
        .then(({ data }) => {
          // console.log('>>>>> data',data);

          // calculate totalShareholder percentage
          const totalShares = getTotalShareVal(data);
          setTotalShareVal(totalShares);
          if (!isEmpty(data.documents)) {
            const item = extractUserDocs(data);
            setDetails({
              ...data,
              documents: item,
            });
            setIsEditMode(true);
            setImages({
              ...images,
              incorporation_certificate: item.incorporation_certificate,
              memorandum_article_association: item.memorandum_article_association,
              corporate_address_proof: item.corporate_address_proof,
              registration_certificate: item.registration_certificate,
              registration_application_status: item.registration_application_status,
              ctc_cac_1_1: item.ctc_cac_1_1,
              cac_2: item.cac_2,
              cac_7: item.cac_7,
            });
            const modifiedItem = {
              ...item,
              incorporation_certificate: null,
              memorandum_article_association: null,
              corporate_address_proof: null,
              registration_certificate: null,
              registration_application_status: null,
              ctc_cac_1_1: null,
              cac_2: null,
              cac_7: null,
            };
            // console.log(modifiedItem);
            setFormValues(modifiedItem);
          } else {
            setDetails(data);
            setIsEditMode(false);
          }

          // registration fee condition
          if (data?.user?.registration_fee_paid !== 0 && registrationAmount !== 0) setStep(4);
          else if (data?.user?.registration_fee_paid !== 0) setStep(3);
          else setStep(2);

          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(showModal(err.message));
          dispatch(hideLoading());
        });
    }
  };
  //useEffect
  useEffect(() => {
    if (verficationModal) getUserDetails();
  }, [verficationModal]);

  // next payment menthod choose step
  const nextPay = () => {
    setStep(2);
  };
  const nextPay2 = () => {
    setStep(3);
  };

  //right sidebar open function
  const rightOpen = (type) => {
    setRight(true);
    setType(type);
    toggleVerificationModal();
  };
  // right siderbar close function
  const rightClose = () => {
    setRight(false);
    toggleVerificationModal();
    setShareValues({});
    setId('');
    setisEdit(false);

    setIsDocVerified(false);
    setShowOTPField(false);
    setVerifyMeData({});
  };

  const editRight = (id, type) => {
    swal({
      title: `Edit ${type}?`,
      text: `Do you want to edit ${type}?`,
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      dispatch(showLoading());
      if (result) {
        setisEdit(true);
        let filt = {};
        if (type === 'shareholder') {
          filt = details.shareholders.filter((obj) => obj.uuid == id);
        } else {
          filt = details.directors.filter((obj) => obj.uuid == id);
        }
        setType(type);
        setId(id);
        setShareValues(filt[0]);

        // >> Verifyme Verification related
        // if (checkVeifymeVerified(filt[0])) {
        //   setIsDocVerified(true);
        // }
        if (filt[0]?.phone_verified_at) {
          setIsDocVerified(true);
        }

        dispatch(hideLoading());
        rightOpen(type);
      }
    });

    // console.log(filt[0]);
  };

  const deleteRight = (id, type) => {
    swal({
      title: `Delete ${type}?`,
      text: `Do you want to delete ${type}?`,
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
    }).then((result) => {
      dispatch(showLoading());
      if (result) {
        if (type === 'shareholder') {
          POST(`/users/deleteShareholder`, { id: id })
            .then(({ data }) => {
              dispatch(showModal(`${type} delete successfully`, true));
              getUserDetails();
              dispatch(hideLoading());
            })
            .catch((err) => {
              dispatch(hideLoading());
            });
        } else {
          POST(`/users/deleteDirector`, { id: id })
            .then(({ data }) => {
              dispatch(showModal(`${type} delete successfully`, true));
              getUserDetails();
              dispatch(hideLoading());
            })
            .catch((err) => {
              dispatch(hideLoading());
            });
        }
      }
    });
  };

  const nextStep = useCallback(() => setStep((s) => s + 1), []);
  const prevStep = useCallback(() => setStep((s) => s - 1), []);

  const handlepaymentSuccess = useCallback(() => {
    nextStep(); // change step
    setPaystatus(true);
    getUserDetails();
    // setStep(4);
  }, [nextStep]);

  const handleBackFromPaymentMethod = useCallback(
    (paymentStep = 1) => {
      if (paymentStep === 1) {
        prevStep();
      }
    },
    [prevStep],
  );

  const regAmountNotAvailable = registrationAmount === 0;

  return (
    <>
      <Modal
        isOpen={verficationModal}
        toggle={toggleVerificationModal}
        container={container}
        className="modal-xl"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={toggleVerificationModal}>
            &times;
          </button>
        </div>
        <ModalBody>
          <>
            <div id="tabblock1" className="tabcontent full-modal-width clearing-varification-mt">
              <h3 className="head-line-pay">
                {user.type == 'clearing_agent'
                  ? `Clearing Agent Documents Upload ${regAmountNotAvailable ? '' : 'and Payment'}`
                  : `Transporter Documents Upload ${regAmountNotAvailable ? '' : 'and Payment'}`}
              </h3>

              <div className="bodycont">
                <div className="">
                  <div className={`row tab-complian ${regAmountNotAvailable ? 'no-payment' : ''}`}>
                    <VerificationModalSteps
                      step={step}
                      isEditMode={isEditMode}
                      registrationAmount={registrationAmount}
                      user={user}
                      paystatus={paystatus}
                    />
                    {step === 1 && (
                      <VerficationModalForm
                        formValues={formValues}
                        details={details}
                        isEditMode={isEditMode}
                        totalShareVal={totalShareVal}
                        user={user}
                        rightOpen={rightOpen}
                        editRight={editRight}
                        images={images}
                        showDoc={showDoc}
                        deleteRight={deleteRight}
                        handleNext={() => nextPay(2)}
                        onDocumentUpdateSuccess={() => {
                          nextPay();
                          setIsEditMode(true);
                          getUserDetails();
                        }}
                      />
                    )}

                    {step === 2 && (
                      <div className="col-sm-9">
                        <div className="head-p-model">
                          <h3 className="head-line">My Profile</h3>

                          <div className="profilecont">
                            <div className="mx-2">
                              {formValues && (
                                <ShowClearingForwardingDocs
                                  showDoc={showDoc}
                                  images={images}
                                  formValues={formValues}
                                  userType={user.type}
                                  details={details}
                                  shareholdersColClassName="col-12"
                                  showShareHolderSelect={true}
                                />
                              )}
                            </div>
                          </div>

                          <div className="buttonsec">
                            <a className=" edit-model-button submitbut" onClick={() => setStep(1)}>
                              Edit
                            </a>
                            {(registrationAmount !== 0 &&
                              details?.user?.registration_fee_paid === 1) ||
                            paystatus ? (
                              <a
                                href="#"
                                className="next-model-button submitbut"
                                onClick={() => setStep(4)}
                              >
                                Next
                              </a>
                            ) : (
                              <a
                                href="#"
                                className="next-model-button submitbut"
                                onClick={() => nextPay2()}
                              >
                                Next
                              </a>
                            )}
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    )}
                    {registrationAmount !== 0 && step === 3 && (
                      <div className="col-sm-9">
                        <div className="head-p-model">
                          <div className="profilecont">
                            <Payments
                              paymentAmount={registrationAmount}
                              paymentRelatedTo={PAYMENT_RELATED_TO_SUBSCRIPTION}
                              onPaymentSuccess={handlepaymentSuccess}
                              handleBackFromPaymentMethod={handleBackFromPaymentMethod}
                              nextButtonClassName="next-model-button submitbut mt-5"
                              backButtonClassName="edit-model-button submitbut mt-5"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {((regAmountNotAvailable && step === 3) ||
                      (!regAmountNotAvailable && step === 4)) && (
                      <div className="col-sm-9">
                        <div className="head-p-model">
                          <div className="profilecont">
                            <div className="payment-info px-0">
                              <div className="finish p-0">
                                {details?.user?.identity_verified === 2 ? (
                                  <>
                                    Please Contact With Admin.
                                    <div>
                                      <img src={rejected} alt="" />
                                    </div>
                                    <h5>Verification rejected !!</h5>
                                    Your Documents Verification has been Rejected
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      Please wait while we verify the documents you uploaded. We
                                      will notify you once this is done. Thank you.
                                    </p>
                                    <div>
                                      <img src={tick} alt="" />
                                    </div>
                                    {registrationAmount !== 0 && regPaymentDone && (
                                      <>
                                        <h5>Payment Successful</h5>
                                        <p>
                                          Your payment of {displayAmount(registrationAmount)} for
                                          annual subscription was successful.
                                        </p>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="buttonsec text-center">
                            <a
                              className=" edit-model-button submitbut"
                              onClick={toggleVerificationModal}
                            >
                              Done
                            </a>
                            <a
                              href="#"
                              className="next-model-button submitbut"
                              onClick={() => setStep(2)}
                            >
                              Show
                            </a>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </ModalBody>
      </Modal>
      {right && (
        <DirectorShareholderForm
          isEdit={isEdit}
          toggle={rightClose}
          type={type}
          shareValues={shareValues}
          onSubmit={async (response) => {
            setShareValues({});
            setRight(false);
            setId('');
            setisEdit(false);

            // this condition is for saving the BVN/NIN verifyme data to our db
            if (!isEmpty(verifyMeData)) {
              let directorOrShareHolderId = '';
              if (type === 'director') {
                directorOrShareHolderId = response?.data?.director?.uuid;
              } else {
                directorOrShareHolderId = response?.data?.shareholder?.uuid;
              }
              let vmId = isEdit ? id : directorOrShareHolderId;
              await storeVerifyMeData(vmId, type);
            }

            getUserDetails();

            // reset verifyme related states
            setIsDocVerified(false);
            setShowOTPField(false);
            setVerifyMeData({});
            toggleVerificationModal();
          }}
          id={id}
          isDocVerified={isDocVerified}
          verifyMeData={verifyMeData}
          setIsDocVerified={setIsDocVerified}
          showDoc={showDoc}
          verifyDocument={verifyDocument}
          showOTPField={showOTPField}
          verifyUserOtp={verifyUserOtp}
          sendMsgOtp={sendMsgOtp}
        />
      )}
    </>
  );
};

export default VerificationModal;
