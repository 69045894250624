import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import userImage from '../../../../assets/img/placeHolderImage.png';

import config from '../../../../config';
import { getUserName } from '../../../helpers/utils';

const Profile = (props) => {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      <div id="tabblock2" className="tabcontent">
        <div className="bodycont">
          {/* <h2>My Profile</h2> */}
          <div className="personal-info ">
            <div className="profilesec">
              <div className="profileimage">
                <img
                  src={
                    get(user, 'avatar')
                      ? `${config.api.URL.replace('/api/v1', '')}/${get(user, 'avatar')}`
                      : userImage
                  }
                  alt=""
                  height="170px"
                  weight="170px"
                />
              </div>
              {getUserName(user)}
            </div>

            <h3>Account Information</h3>

            <div className="profilecont">
              <div className="profilecontleft">
                <p>
                  <strong>Name:</strong> {getUserName(user)}
                </p>
                <p>
                  <strong>Email:</strong> {user.email || 'N/A'}
                </p>
                <p>
                  <strong>Mobile:</strong> {user.mobile_number || 'N/A'}
                </p>
              </div>
              <div className="profilecontright">
                <p>
                  <strong>Address</strong>
                  <br />
                  {user.address || 'N/A'}
                </p>
              </div>
            </div>

            <div className="buttonsec">
              <button
                onClick={() => props.history.push('/operator/profile/update-password')}
                className="submitbut"
              >
                Change Password
              </button>
              <button
                onClick={() => props.history.push('/operator/edit-profile')}
                className="submitbut"
              >
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
