import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ClientRegSuccessModal = ({ isOpen, toggle, container }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="text-center fs-2 alert-success">
          <p>Your registration was successful, please wait while we confirm it. Thank you</p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ClientRegSuccessModal;
