import React from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';

const fieldsNotToDisplay = ['photo', 'signature'];

export const displayVMData = (vm, heading) => {
  if (vm?.data) {
    const objKeys = Object.keys(vm.data);
    return (
      <div className="client-approval-docs mb-3">
        {heading && (
          <>
            <h4>Time Added: {moment(vm.created_at).format('DD-MM-YYYY HH:mm')}</h4>
            <hr />
          </>
        )}
        {objKeys.map((k, index) => {
          if (typeof vm.data[k] === 'object') {
            displayVMData(vm.data[k], false);
          } else {
            if (!fieldsNotToDisplay.includes(k)) {
              return (
                <div className="mb-3" key={`vm-data-${index}`}>
                  <strong>{`${capitalize(k)}:`}</strong> {vm.data[k]}
                </div>
              );
            }
          }
        })}
      </div>
    );
  } else {
    return null;
  }
};
