import React from 'react';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Rating } from '@material-ui/lab';
import { Modal, ModalBody } from 'reactstrap';
import TextareaInput from '../../../components/UI/TextareaInput';
import { POST } from '../../../services/rest.service';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { showModal } from '../../../redux/error/ErrorAction';

const JobReviewForm = (props) => {
  const { isOpen, toggle, job, refreshTabData } = props;
  const dispatch = useDispatch();
  return (
    <Modal isOpen={isOpen} toggle={toggle} container="client-page" className="modal-md">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject">
          <h5>Post a Review</h5>
          <Formik
            initialValues={{ rating: 0, feedback: '' }}
            validationSchema={Yup.object({
              rating: Yup.number().required('Rating is Required'),
              feedback: Yup.string().required('Feedback is required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // send here
              dispatch(showLoading());
              setSubmitting(true);
              const payload = {
                job_uuid: job.uuid,
                agent_uuid: job.clearing_agent_uuid || job.forwarding_agent_uuid,
                ...values,
              };
              POST('/jobs/addRating', payload)
                .then(({ data }) => {
                  resetForm();
                  dispatch(showModal('Message sent succssefully!', true));
                  dispatch(hideLoading());
                  setSubmitting(false);
                  toggle();
                  refreshTabData();
                })
                .catch((err) => {
                  dispatch(showModal(err.message));
                  dispatch(hideLoading());
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Rating
                  style={{ fontSize: 30, margin: 10 }}
                  value={values.rating}
                  onChange={(event, newValue) => {
                    setFieldValue('rating', newValue);
                  }}
                  name="rating"
                />
                {touched.rating && errors.rating && (
                  <div className="invalid-feedback  mb-3  d-block">{errors.rating}</div>
                )}
                <TextareaInput
                  id="feedback"
                  name="feedback"
                  placeholder="Feedback*"
                  cols=""
                  rows="6"
                  value={values.feedback}
                  onChange={handleChange}
                  className="form-control"
                  onBlur={handleBlur}
                  error={getIn(touched, 'feedback') && getIn(errors, 'feedback')}
                />
                <div className="mb-3">
                  <button type="submit" className="submitbut" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default JobReviewForm;
