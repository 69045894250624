import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DisplayDocument from '../DisplayDocument';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { POST } from '../../../services/rest.service';
import { showDocumentModal } from '../../../redux/user/UserActions';
import AddPortsModal from './AddPortsModal';
import { getPortName } from '../../../helpers/utils';

const MODE_VIEW = 'view';
const MODE_EDIT = 'edit';

const PortOfOperations = (props) => {
  const serviceData = useSelector((state) => state.enumData);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  const [agentPorts, setAgentPorts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState(MODE_VIEW);

  const toggleModal = useCallback(() => setIsModalOpen((s) => !s), []);

  const getAgentPorts = useCallback(
    (uid, isUnmounted = false) => {
      dispatch(showLoading());
      POST('/ports/getAgentPort', {
        agent_uuid: uid,
      })
        .then(({ data }) => {
          // console.log(data);
          if (!isUnmounted) setAgentPorts(data.ports);
          dispatch(hideLoading());
        })
        .catch((err) => {
          dispatch(hideLoading());

          dispatch(showModal(err.message));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    let isUnmounted = false; // used for not setting state when component unmounts, prevents memory leaks
    if (user) {
      getAgentPorts(user.uuid, isUnmounted);
    }
    return () => {
      isUnmounted = true;
    };
  }, [user, getAgentPorts]);

  const handlePOOEdit = useCallback(() => setMode(MODE_EDIT), []);
  const handlePOOCancel = useCallback(() => setMode(MODE_VIEW), []);
  const onPortAddSuccess = useCallback(() => {
    toggleModal();
    getAgentPorts(user.uuid);
  }, [user, getAgentPorts, toggleModal]);

  return (
    <>
      <div className="personal-info mb-5">
        <h3>Port of Operations</h3>
        {agentPorts?.length > 0 ? (
          agentPorts.map((ap) => (
            <div className="row mx-0 mt-3" key={ap.uuid}>
              <DisplayDocument
                label={getPortName(ap.port_uuid, serviceData)}
                onClick={() => showDoc(ap.document_value)}
                type="file"
                fileColClassName="document col-lg-6 col-md-6 col-xs-6 col-sm-6"
                documentLabel="Document"
                hasDocument={ap.document_value ? true : false}
              />
              <div className="col-lg-2 col-md-2 col-xs-6 col-sm-6 text-left">
                {ap.document_verified === 0 && (
                  <span className="alert alert-info">Pending Approval</span>
                )}
                {ap.document_verified === 1 && (
                  <span className="alert alert-success">Approved</span>
                )}
                {ap.document_verified === 2 && <span className="alert alert-danger">Rejected</span>}
                {/* {mode === MODE_EDIT && (
                  <button type="button" className="action-btn mt-4" onClick={() => {}}>
                    <i className="fa fa-edit"></i>
                  </button>
                )} */}
              </div>
            </div>
          ))
        ) : (
          <span>Port of Operations not Available!</span>
        )}
        <div className="buttonsec mt-5">
          {mode === MODE_VIEW ? (
            <button className="submitbut" onClick={handlePOOEdit}>
              Modify Port of Operations
            </button>
          ) : (
            <>
              <button className="submitbut" onClick={handlePOOCancel}>
                Cancel
              </button>
              <button className="submitbut" onClick={toggleModal}>
                Add
              </button>
            </>
          )}
        </div>
      </div>
      <AddPortsModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onPortAddSuccess={onPortAddSuccess}
        agentPorts={agentPorts}
      />
    </>
  );
};

export default PortOfOperations;
