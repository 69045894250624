import React, { useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import SvgImg1 from '../UI/SvgImg1';
import SvgImg1Hover from '../UI/SvgImg1Hover';
import SvgImg6 from '../UI/SvgImg6';
import SvgImg6Hover from '../UI/SvgImg6Hover';
import SvgImg7 from '../UI/SvgImg7';
import SvgImg7Hover from '../UI/SvgImg7Hover';
import Assessment from '../UI/Assessment';
import AssessmentHover from '../UI/AssessmentHover';
import PaymentOfTerminalCharges from '../UI/PaymentOfTerminalCharges';
import PaymentOfTerminalChargesHover from '../UI/PaymentOfTerminalChargesHover';
import CustomExamination from '../UI/CustomExamination';
import CustomExaminationHover from '../UI/CustomExaminationHover';
import CustomReleaseAndExitNote from '../UI/CustomReleaseAndExitNote';
import CustomReleaseAndExitNoteHover from '../UI/CustomReleaseAndExitNoteHover';
import PaymentOfShippingCharges from '../UI/PaymentOfShippingCharges';
import PaymentOfShippingChargesHover from '../UI/PaymentOfShippingChargesHover';
import { showModal } from '../../redux/error/ErrorAction';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { DATE_FORMAT, TIME_FORMAT, DATE_TIME_FORMAT } from '../../helpers/constants';

const ClearingCargoStatus = ({ details, refresh, jobId }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [hoveringIcon, setHoveringIcon] = useState(null);

  const userIsClearingAgent = user.type === 'clearing_agent';
  const userIsClient =
    user.type === 'application_client' || user.type === 'application_corporate_client';

  const userIsOperatorNotSupervisor =
    user.type === 'managenmnt_operator' && user.type !== 'work_supervisor';

  const changesStatus = (id) => {
    // console.log('>> id', id);

    const payload = { job_uuid: jobId };

    const dt = moment().format(DATE_TIME_FORMAT);
    if (id === 1) {
      payload.custom_duty_payment = dt;
    }
    if (id === 2) {
      payload.custom_examination = dt;
    }
    if (id === 3) {
      payload.custom_release = dt;
    }
    if (id === 4) {
      payload.terminal_charges_payment = dt;
    }
    if (id === 5) {
      payload.shipping_charges_payment = dt;
    }
    if (id === 6) {
      payload.terminal_release = dt;
    }
    if (id === 7) {
      payload.custom_exit_gate = dt;
    }
    if (id === 8) {
      payload.assessment = dt;
    }

    dispatch(showLoading());
    POST(`/jobs/addClearingCargoStatus`, payload)
      .then(({ data }) => {
        dispatch(showModal('Job status changed successfully', true));
        if (refresh) refresh().then(() => dispatch(hideLoading()));
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  const changeStatusConfirm = (id, chkBoxId) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to change job status?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      const chkBox = document.getElementById(chkBoxId);
      if (result) {
        changesStatus(id);
        if (chkBox) {
          chkBox.checked = true;
        }
      } else {
        chkBox.checked = false;
      }
    });
  };

  return (
    <ul className="genaral-block">
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(8)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`${details.clearingCargoStatus.assessment ? 'active' : ''}`}
        >
          <span className="fw-bold pt-5">Assessment</span>
          {details.clearingCargoStatus.assessment || hoveringIcon === 8 ? (
            <AssessmentHover />
          ) : (
            <Assessment />
          )}
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.assessment ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.assessment &&
          moment(details.clearingCargoStatus.assessment).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.assessment &&
          moment(details.clearingCargoStatus.assessment).format(TIME_FORMAT)}

        {userIsClearingAgent && (
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch8"
              disabled={details.clearingCargoStatus.assessment ? true : false}
              onChange={() => {
                changeStatusConfirm(8, 'myonoffswitch8');
              }}
              defaultChecked={details.clearingCargoStatus.assessment ? true : false}
            />
            <label className="onoffswitch-label" htmlFor="myonoffswitch8">
              <span className="onoffswitch-inner"></span>
              <span className="onoffswitch-switch"></span>
            </label>
          </div>
        )}
      </li>
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(1)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`${details.clearingCargoStatus.custom_duty_payment ? 'active' : ''}`}
        >
          {details.clearingCargoStatus.custom_duty_payment || hoveringIcon === 1 ? (
            <SvgImg1Hover />
          ) : (
            <SvgImg1 />
          )}
          <span className="fw-bold">
            Payment of
            <br />
            Custom Duty
          </span>
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.custom_duty_payment ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.custom_duty_payment &&
          moment(details.clearingCargoStatus.custom_duty_payment).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.custom_duty_payment &&
          moment(details.clearingCargoStatus.custom_duty_payment).format(TIME_FORMAT)}

        {userIsOperatorNotSupervisor &&
          details.clearingCargoStatus &&
          details.clearingCargoStatus.assessment && (
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitch"
                disabled={details.clearingCargoStatus.custom_duty_payment ? true : false}
                onChange={() => {
                  changeStatusConfirm(1, 'myonoffswitch');
                }}
                defaultChecked={details.clearingCargoStatus.custom_duty_payment ? true : false}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitch">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          )}
      </li>
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(5)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`${details.clearingCargoStatus.shipping_charges_payment ? 'active' : ''}`}
        >
          <span className="fw-bold">
            Payment of
            <br />
            Shipping Charges
          </span>
          {details.clearingCargoStatus.shipping_charges_payment || hoveringIcon === 5 ? (
            <PaymentOfShippingChargesHover />
          ) : (
            <PaymentOfShippingCharges />
          )}
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.shipping_charges_payment ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.shipping_charges_payment &&
          moment(details.clearingCargoStatus.shipping_charges_payment).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.shipping_charges_payment &&
          moment(details.clearingCargoStatus.shipping_charges_payment).format(TIME_FORMAT)}
        {userIsClearingAgent &&
          details.clearingCargoStatus &&
          details.clearingCargoStatus.custom_duty_payment && (
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                disabled={details.clearingCargoStatus.shipping_charges_payment ? true : false}
                id="myonoffswitch5"
                onChange={() => {
                  changeStatusConfirm(5, 'myonoffswitch5');
                }}
                defaultChecked={details.clearingCargoStatus.shipping_charges_payment ? true : false}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitch5">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          )}
      </li>
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(4)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`ptc-svg ${
            details.clearingCargoStatus.terminal_charges_payment ? 'active' : ''
          }`}
        >
          {details.clearingCargoStatus.terminal_charges_payment || hoveringIcon === 4 ? (
            <PaymentOfTerminalChargesHover />
          ) : (
            <PaymentOfTerminalCharges />
          )}
          <span className="fw-bold">
            Payment of
            <br />
            Terminal Charges
          </span>
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.terminal_charges_payment ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.terminal_charges_payment &&
          moment(details.clearingCargoStatus.terminal_charges_payment).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.terminal_charges_payment &&
          moment(details.clearingCargoStatus.terminal_charges_payment).format(TIME_FORMAT)}
        {userIsClearingAgent &&
          details.clearingCargoStatus &&
          details.clearingCargoStatus.shipping_charges_payment && (
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitch4"
                disabled={details.clearingCargoStatus.terminal_charges_payment ? true : false}
                onChange={() => {
                  changeStatusConfirm(4, 'myonoffswitch4');
                }}
                defaultChecked={details.clearingCargoStatus.terminal_charges_payment ? true : false}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitch4">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          )}
      </li>
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(2)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`${details.clearingCargoStatus.custom_examination ? 'active' : ''}`}
        >
          <span className="fw-bold">
            Custom <br />
            Examination
          </span>
          {details.clearingCargoStatus.custom_examination || hoveringIcon === 2 ? (
            <CustomExaminationHover />
          ) : (
            <CustomExamination />
          )}
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.custom_examination ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.custom_examination &&
          moment(details.clearingCargoStatus.custom_examination).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.custom_examination &&
          moment(details.clearingCargoStatus.custom_examination).format(TIME_FORMAT)}
        {userIsClearingAgent &&
          details.clearingCargoStatus &&
          details.clearingCargoStatus.terminal_charges_payment && (
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitch2"
                disabled={details.clearingCargoStatus.custom_examination ? true : false}
                onChange={() => {
                  changeStatusConfirm(2, 'myonoffswitch2');
                }}
                defaultChecked={details.clearingCargoStatus.custom_examination ? true : false}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitch2">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          )}
      </li>
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(3)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`${details.clearingCargoStatus.custom_release ? 'active' : ''}`}
        >
          {details.clearingCargoStatus.custom_release || hoveringIcon === 3 ? (
            <CustomReleaseAndExitNoteHover />
          ) : (
            <CustomReleaseAndExitNote />
          )}
          <span className="fw-bold">Custom Release and Exit Note</span>
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.custom_release ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.custom_release &&
          moment(details.clearingCargoStatus.custom_release).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.custom_release &&
          moment(details.clearingCargoStatus.custom_release).format(TIME_FORMAT)}
        {userIsClearingAgent &&
          details.clearingCargoStatus &&
          details.clearingCargoStatus.custom_examination && (
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitch3"
                disabled={details.clearingCargoStatus.custom_release ? true : false}
                onChange={() => {
                  changeStatusConfirm(3, 'myonoffswitch3');
                }}
                defaultChecked={details.clearingCargoStatus.custom_release ? true : false}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitch3">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          )}
      </li>
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(6)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`${details.clearingCargoStatus.terminal_release ? 'active' : ''}`}
        >
          <span className="fw-bold">
            Terminal <br /> Release
          </span>
          {details.clearingCargoStatus.terminal_release || hoveringIcon === 6 ? (
            <SvgImg6Hover />
          ) : (
            <SvgImg6 />
          )}
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.terminal_release ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.terminal_release &&
          moment(details.clearingCargoStatus.terminal_release).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.terminal_release &&
          moment(details.clearingCargoStatus.terminal_release).format(TIME_FORMAT)}
        {userIsClearingAgent &&
          details.clearingCargoStatus &&
          details.clearingCargoStatus.custom_release && (
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitch6"
                disabled={details.clearingCargoStatus.terminal_release ? true : false}
                onChange={() => {
                  changeStatusConfirm(6, 'myonoffswitch6');
                }}
                defaultChecked={details.clearingCargoStatus.terminal_release ? true : false}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitch6">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          )}
      </li>
      <li>
        <a
          onMouseEnter={() => setHoveringIcon(7)}
          onMouseLeave={() => setHoveringIcon(null)}
          className={`${details.clearingCargoStatus.custom_exit_gate ? 'active' : ''}`}
        >
          {details.clearingCargoStatus.custom_exit_gate || hoveringIcon === 7 ? (
            <p className="svg-img7">
              <SvgImg7Hover />
            </p>
          ) : (
            <p className="svg-img7">
              <SvgImg7 />
            </p>
          )}
          <span className="fw-bold">
            Custom <br />
            Exit Gate
          </span>
        </a>
        {userIsClient && (
          <>
            <br />
            {details.clearingCargoStatus.custom_exit_gate ? (
              <span className="active1">Completed</span>
            ) : (
              <span>Not completed</span>
            )}
          </>
        )}
        <br />
        {details.clearingCargoStatus.custom_exit_gate &&
          moment(details.clearingCargoStatus.custom_exit_gate).format(DATE_FORMAT)}
        <br />
        {details.clearingCargoStatus.custom_exit_gate &&
          moment(details.clearingCargoStatus.custom_exit_gate).format(TIME_FORMAT)}
        {userIsClearingAgent &&
          details.clearingCargoStatus &&
          details.clearingCargoStatus.terminal_release && (
            <div className="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                disabled={details.clearingCargoStatus.custom_exit_gate ? true : false}
                id="myonoffswitch7"
                onChange={() => {
                  changeStatusConfirm(7, 'myonoffswitch7');
                }}
                defaultChecked={details.clearingCargoStatus.custom_exit_gate ? true : false}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitch7">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          )}
      </li>
    </ul>
  );
};

export default ClearingCargoStatus;
