import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import convertJsonToFormData from '../../helpers/helper';
import { getRegYearType } from '../../helpers/utils';

import IncorporatedCompanyFormFields from '../global/ClientVerification/FormFields/IncorporatedCompany';
import IncorporatedTrusteesFormFields from '../global/ClientVerification/FormFields/IncorporatedTrustees';
import FreeZoneEntityFormFields from '../global/ClientVerification/FormFields/FreeZoneEntity';
import GovernmentEntityFormFields from '../global/ClientVerification/FormFields/GovernmentEntity';
import PrivateEntityFormFields from '../global/ClientVerification/FormFields/PrivateEntity';
import {
  SUB_USER_ROLE_CLIENT_INC_COMPANY,
  SUB_USER_ROLE_CLIENT_INC_TRUSTEE,
  SUB_USER_ROLE_CLIENT_FREEZONE_ENTITY,
  SUB_USER_ROLE_CLIENT_GOV_ENTITY,
  SUB_USER_ROLE_CLIENT_PVT_ENTITY,
} from '../../helpers/constants';

const COClientDocumentUploadModal = (props) => {
  const {
    isOpen,
    toggle,
    container,
    details,
    showDoc,
    clientType,
    getUserDetails,
    images,
    formValues,
  } = props;
  const dispatch = useDispatch();

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-lg">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div className="reson-reject nopadding-sm">
          <h3>Upload Documents</h3>
          <Formik
            initialValues={{
              // Individual
              mobile_number: get(formValues.user, 'mobile_number', '') || '',

              nationality: get(formValues.user, 'nationality', '') || '',
              dob: get(formValues.user, 'dob', '') || '',
              reg_year: (images && getRegYearType(images)) || '',
              address: get(formValues.user, 'address', '') || '',
              // Incorporated company
              rc_no: get(formValues, 'rc_no', '') || '',

              // Incorporated trustee
              cac_it_no: get(formValues, 'cac_it_no', '') || '',

              // Free zone entity
              nepza_no: get(formValues, 'nepza_no', '') || '',

              // Government Entity & Private entity (share same text fields)
              corporate_address: get(formValues, 'corporate_address', '') || '',
              website_url: get(formValues, 'website_url', '') || '',
              email: get(formValues, 'email', '') || '',
            }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              dispatch(showLoading());
              try {
                const payLoad = {
                  user_uuid: details?.user?.uuid || '',

                  // incorporated company
                  rc_no: values.rc_no,
                  registration_application_status: values.registration_application_status,
                  ctc_cac_1_1: values.ctc_cac_1_1,
                  cac_2: values.cac_2,
                  cac_7: values.cac_7,
                  incorporation_certificate: values.incorporation_certificate,
                  memorandum_article_association: values.memorandum_article_association,
                  corporate_address_proof: values.corporate_address_proof,

                  // Incorporated trustee
                  cac_it_no: values.cac_it_no,
                  cac_it_1: values.cac_it_1,
                  // rc_no: values.rc_no, // already present above
                  // corporate_address_proof: values.corporate_address_proof, // already present above

                  // Free zone entity
                  nepza_no: values.nepza_no,
                  nepza_operating_license: values.nepza_operating_license,
                  ctc_situation_notice: values.ctc_situation_notice,
                  director_particulars: values.director_particulars,
                  nepza_registration_certificate: values.nepza_registration_certificate,
                  share_capital_statement: values.share_capital_statement,
                  return_allotement_statement: values.return_allotement_statement,
                  // memorandum_article_association: values.memorandum_article_association, // already present above

                  // Government Entity & Private entity (share same text fields) // already present above
                  corporate_address: values.corporate_address,
                  website_url: values.website_url,
                  email: values.email,

                  // Government Entity
                  official_gazetted_establishment_law: values.official_gazetted_establishment_law,

                  // Private entity
                  governement_approval_document: values.governement_approval_document,
                  business_owner_document: values.business_owner_document,
                };

                // console.log('CVM', payLoad);

                const formData = convertJsonToFormData(payLoad);

                await POST(`/users/uploadUserDocs`, formData);

                getUserDetails();
                toggle();
                setSubmitting(false);
                dispatch(showModal('Documents uploaded successfully', true));
                dispatch(hideLoading());
              } catch (error) {
                dispatch(showModal(error.message));
                dispatch(hideLoading());
                setSubmitting(false);
              }
            }}
          >
            {(renderProps) => {
              const { handleSubmit } = renderProps;

              return (
                <form onSubmit={handleSubmit} className="form-new">
                  {/* Corporate Type - InCorporated Company */}
                  {clientType === SUB_USER_ROLE_CLIENT_INC_COMPANY && (
                    <IncorporatedCompanyFormFields
                      {...renderProps}
                      showDoc={showDoc}
                      images={images}
                    />
                  )}

                  {/* Corporate Type - InCorporated Trustee */}
                  {clientType === SUB_USER_ROLE_CLIENT_INC_TRUSTEE && (
                    <IncorporatedTrusteesFormFields
                      {...renderProps}
                      showDoc={showDoc}
                      images={images}
                    />
                  )}

                  {/* Corporate Type - Free Zone Entity */}
                  {clientType === SUB_USER_ROLE_CLIENT_FREEZONE_ENTITY && (
                    <FreeZoneEntityFormFields {...renderProps} showDoc={showDoc} images={images} />
                  )}

                  {/* Corporate Type - Government Entity*/}
                  {clientType === SUB_USER_ROLE_CLIENT_GOV_ENTITY && (
                    <GovernmentEntityFormFields
                      {...renderProps}
                      showDoc={showDoc}
                      images={images}
                    />
                  )}

                  {/* Corporate Type - Private Entity created by Government Approval */}
                  {clientType === SUB_USER_ROLE_CLIENT_PVT_ENTITY && (
                    <PrivateEntityFormFields {...renderProps} showDoc={showDoc} images={images} />
                  )}
                  <div className="buttonsec">
                    <button className="submitbut" type="submit">
                      Upload Documents
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default COClientDocumentUploadModal;
