import React from 'react';
import classnames from 'classnames';
import { InputFeedback } from './TextField';

const Label = ({ error, className, children, ...props }) =>
  children ? (
    <label className={className} {...props}>
      {children}
    </label>
  ) : null;

const TextareaInput = ({ type, id, size, label, error, value, onChange, className, labelClasses, ...props }) => {
  const InputClasses = classnames(
    'input',
    {
      'is-invalid': !!error,
    },
    className,
  );
  const FormGroup = classnames('form-group', size);
  const LabelClasses = classnames('form-label', labelClasses);

  return (
    <div className={FormGroup}>
      <Label className={LabelClasses} htmlFor={id} error={error}>
        {label}
      </Label>
      <textarea id={id} className={InputClasses} type={type} value={value} onChange={onChange} {...props}></textarea>
      <InputFeedback error={error} />
    </div>
  );
};

export default TextareaInput;
